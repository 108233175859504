export const PROJECT_SETTINGS_PER_PAGE = 8;
export const PROJECTS_PER_PAGE = 14;
export const DESIGNS_PER_PAGE = 24;
export const USERS_PER_PAGE = 14;
export const MODAL_TABLE_ROWS_PER_PAGE = 5;
export const EQUIPMENTS_PER_PAGE = 20;

export interface PageInfo {
  totalPages: number;
  pageNumber: number;
  totalElements?: number;
}

export interface PageableResponse<T> {
  content: T[];
  pageInfo: PageInfo;
}
