const getChatbotElement = () => {
  return document.getElementsByClassName('livesdk__Draggable')[0] as HTMLElement;
};

export const setChatbotX = (x: number) => {
  const chatbotElement = getChatbotElement();
  if (!chatbotElement) return;
  chatbotElement.style.right = `${x}px`;
};

export const showChatbot = (isShown: boolean) => {
  const chatbotElement = getChatbotElement();
  if (!chatbotElement) return;
  chatbotElement.style.display = isShown ? 'block' : 'none';
};

export const resetChatbot = () => {
  const chatbotElement = getChatbotElement();
  if (!chatbotElement) return;
  chatbotElement.style.right = '0px';
  chatbotElement.style.bottom = '0px';
};
