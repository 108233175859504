export function getDontShowAgainItems() {
  return JSON.parse(localStorage.getItem('dontShowAgain') ?? '[]');
}

export function getHiddenItems() {
  return JSON.parse(localStorage.getItem('hidden') ?? '[]');
}

export function setDontShowAgainItems(content: string) {
  localStorage.setItem('dontShowAgain', content);
}

export function setHiddenItems(content: string) {
  localStorage.setItem('hidden', content);
}
