import React from 'react';
import saveAs from 'file-saver';
import { ToastType, useToasts } from 'utils/hooks/use-toasts';
import { useRestClient } from 'utils/hooks/use-rest-client';
import { SCREEN_LOCATION } from 'utils/constants/toasts';
import { CrossSvg } from 'assets/icons';
import { getApiUrl } from 'utils/api_url';
import { useTranslation } from 'react-i18next';
import { Button } from '@ratedpower/components';

export interface IDownloadFiles {
  entityType: string;
  entityId: string;
}

const DownloadFiles: React.FC<IDownloadFiles> = ({ entityType, entityId }) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const { getFile } = useRestClient();

  const handleDowloadArchives = async () => {
    const endpointMap: { [key: string]: string } = {
      SITE: `/sites/${entityId}/kml`,
      METEO_DATA: `/meteo-datas/${entityId}/download-original`,
      ELEVATION: `/elevations/${entityId}/download-original`,
      HORIZON: `/horizons/${entityId}/download-original`,
      MODULE: `/api/v1/module-models/${entityId}/download`,
      INVERTER: `/api/v1/module-inverter/${entityId}/download`,
      CBA: `/shared-cba-outputs/${entityId}/download`,
    };

    const endpoint = `${getApiUrl()}${endpointMap[entityType] || ''}`;

    const file = await getFile(endpoint);
    if (!file.data || !!file.error) {
      addToast(
        SCREEN_LOCATION.topCenter,
        <>
          <CrossSvg />
          {file.error.message}
        </>,
        ToastType.ERROR,
        4000
      );
      return;
    }
    const fileName = file.response.headers['content-disposition'].split('filename=')[1];
    saveAs(file.data, fileName.replace(/"/g, ''));
  };

  return entityType === 'COMPANY' ? (
    <>-</>
  ) : (
    <Button onClick={handleDowloadArchives} variant="link" text={t('support:download')} noPadding textUnderlined />
  );
};

export default DownloadFiles;
