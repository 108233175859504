import React from 'react';
import { Modal, Typography } from '@ratedpower/components';
import { useCloseModal } from '../../ilc-store';
import { useTranslation } from 'react-i18next';
import { openExternalLink } from 'utils/navigation';
import { setDontShowAgainItems, getDontShowAgainItems } from 'utils/local-storage';

const UserFeedbackModal: React.FC = () => {
  const { t } = useTranslation();
  const closeModal = useCloseModal();

  const updateDontShowAgainItems = () => {
    const dontShowAgainItems = getDontShowAgainItems();
    dontShowAgainItems.push('user-layout-editor-feedback-modal');
    setDontShowAgainItems(JSON.stringify(dontShowAgainItems));
  };

  const handleGiveFeedback = () => {
    openExternalLink('https://forms.office.com/r/3mvZpKigZh');
    updateDontShowAgainItems();
    closeModal();
  };

  return (
    <Modal
      id="user-layout-editor-feedback-modal"
      isActive
      title={t('ilc:modals.user-feedback-modal-title')}
      size="s"
      onClose={closeModal}
      dontShowAgainText={t('action:dont-show-again')}
      buttons={{
        primary: {
          text: t('ilc:modals.user-feedback-modal-action-save'),
          onClick: handleGiveFeedback,
          type: 'button',
        },
        secondary: {
          text: t('action:close'),
          onClick: closeModal,
        },
      }}
    >
      <Typography>{t('ilc:modals.user-feedback-modal-content')}</Typography>
    </Modal>
  );
};

export { UserFeedbackModal };
