/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import * as S from './permission-list.styled';
import { IPermissionToShow, IPermissionType, IRoleListItem } from 'types/roles';
import { usePermissionList } from './hooks/use-permission-list';
import { Checkbox, Typography } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';

interface IProps {
  element?: IRoleListItem;
}

const PermissionList: React.FC<IProps> = ({ element }) => {
  const { t } = useTranslation();

  const { filteredPermissionsToShow, isChecked } = usePermissionList(element);

  const getPermissionTypeTranslation = (type: string) => {
    if (type === 'SAML') return type;
    return t(`user:roles.${type}`);
  };

  return (
    <S.PermissionsContainer>
      <S.PermissionsContainerWrapper>
        {filteredPermissionsToShow.map((permission: IPermissionToShow) => (
          <S.PermissionContainer key={permission.group}>
            <Typography className="group-title" weight="bold">
              {t(`user:roles.${permission.group}`)}
            </Typography>
            <S.CheckboxContainer>
              {permission.permissionType.map((type: IPermissionType) => (
                <Checkbox
                  id={element?.name + type.value}
                  disabled={!element?.editable}
                  key={element?.name + type.value}
                  label={getPermissionTypeTranslation(type.text)}
                  onChange={() => {}}
                  checked={isChecked(type.value)}
                />
              ))}
            </S.CheckboxContainer>
          </S.PermissionContainer>
        ))}
      </S.PermissionsContainerWrapper>
    </S.PermissionsContainer>
  );
};

export default PermissionList;
