import { useEffect } from 'react';
import { useParams } from 'react-router';
import useQuerySearchParams from 'utils/hooks/use-query-search-params';
import { useFetchLayout } from './ilc-store';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { useRestClient } from 'utils/hooks/use-rest-client';
import { useToasts } from 'utils/hooks/use-toasts';

export const useILCLayoutElements = () => {
  const querySearchParams = useQuerySearchParams();
  const { projectId } = useParams<{ projectId: string }>();
  const { get, post } = useRestClient();
  const { addErrorToast } = useToasts();

  const designId = querySearchParams.get('designId');
  const sessionId = querySearchParams.get('sessionId');

  const onErrorCallback = (message: string) => {
    addErrorToast(message, 'top-center');
  };

  const fetchLayout = useFetchLayout();
  const user = useSelector((state: RootState) => state.currentUser.profile?.email);
  useEffect(() => {
    if (!designId || !projectId || !user || !sessionId) return;
    fetchLayout(designId, projectId, user, sessionId, get, post, onErrorCallback);
  }, [designId]);

  return { designId };
};
