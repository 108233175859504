import { useQuery } from '@apollo/client';
import { t } from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useListCompanyTeams } from 'pages/support/company-activity/activity-tab/hooks/use-list-company-teams';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { GET_TEAMS } from 'services/team-gql';
import { IServerFilter, ISort } from 'types/filter';
import { ITeamsFilters } from 'types/teams';
import { getRangeFilters } from 'utils/filters';
import { useCustomQuery } from 'utils/hooks/use-custom-query';
import { useSearch } from 'utils/hooks/use-search';
import { getSortDirection } from 'utils/sort';

interface IUseListTeamsProps {
  infiniteScroll?: boolean;
  pageSize?: number;
  noCompanyId?: boolean;
}

export const useListTeams = ({ infiniteScroll, pageSize, noCompanyId = false }: IUseListTeamsProps) => {
  const { skuTeamManagement: hasContractedTeams } = useFlags();
  const { inputSearchProps, getSearchFilter } = useSearch();
  const { id } = useParams<{ id: string }>();
  const companyId = noCompanyId ? undefined : id;

  const [sortedBy, setSortedBy] = useState<ISort>({
    selector: {
      label: t('name'),
      code: 'name',
    },
    ascendent: false,
  });
  const [serverFilters, setServerFilters] = useState<IServerFilter[]>([]);

  const useListCompanyTeamsObject = useListCompanyTeams({
    infiniteScroll,
    pageSize,
    companyId,
    serverFilters,
  });

  const setTeamsFilters = (filters: ITeamsFilters) => {
    let serverFiltersToSend: IServerFilter[] = [];
    Object.entries(filters).forEach(([key, value]) => {
      if (key === 'countProjects' && value.length) {
        serverFiltersToSend = [
          ...getRangeFilters({
            code: 'NUM_PROJECTS',
            title: '',
            options: value.map((filterValue: string) => ({ code: filterValue, label: '', checked: true })),
          }),
        ];
      }
      if (key === 'countMembers' && value.length) {
        serverFiltersToSend = [
          ...serverFiltersToSend,
          ...getRangeFilters({
            code: 'NUM_MEMBERS',
            title: '',
            options: value.map((filterValue: string) => ({ code: filterValue, label: '', checked: true })),
          }),
        ];
      }
      if (key === 'teamManagers' && value.length) {
        serverFiltersToSend = [
          ...serverFiltersToSend,
          {
            field: 'TEAM_MANAGER',
            operator: 'IN',
            values: value,
          },
        ];
      }
      if (key === 'countries' && value.length) {
        serverFiltersToSend = [
          ...serverFiltersToSend,
          {
            field: 'COUNTRY',
            operator: 'IN',
            values: value,
          },
        ];
      }
    });

    setServerFilters(JSON.parse(JSON.stringify(serverFiltersToSend)));
  };

  const {
    data: customQueryData,
    error: customError,
    refetch,
    loading,
    paginationComponent,
  } = useCustomQuery(
    GET_TEAMS,
    {
      variables: {
        pagination: {
          page: 0,
          size: pageSize,
        },
        filters: serverFilters,
        sorting: {
          property: sortedBy.selector.code,
          direction: getSortDirection(sortedBy.ascendent),
        },
        search: getSearchFilter(),
      },
      fetchPolicy: 'network-only',
      skip: (!infiniteScroll && !pageSize) || !!companyId || !hasContractedTeams,
    },
    infiniteScroll
  );

  const { data, error } = useQuery(GET_TEAMS, {
    variables: {
      filters: serverFilters,
      sorting: {
        property: sortedBy.selector.code,
        direction: getSortDirection(sortedBy.ascendent),
      },
    },
    fetchPolicy: 'network-only',
    skip: !!infiniteScroll || !!pageSize || !!companyId || !hasContractedTeams,
  });

  if (companyId) {
    return {
      loading: useListCompanyTeamsObject.loading,
      error: useListCompanyTeamsObject.error,
      teams: useListCompanyTeamsObject.teams,
      sortedBy: useListCompanyTeamsObject.sortedBy,
      paginationComponent: useListCompanyTeamsObject.paginationComponent,
      inputSearchProps: useListCompanyTeamsObject.inputSearchProps,
      isFirstPage: useListCompanyTeamsObject.isFirstPage,
      setSortedBy: useListCompanyTeamsObject.setSortedBy,
      setTeamsFilters,
      refetch: useListCompanyTeamsObject.refetch,
    };
  }

  const teams = !infiniteScroll && !pageSize ? data?.result?.content : customQueryData?.result?.content;

  return {
    loading,
    error: !infiniteScroll && !pageSize ? error : customError,
    teams,
    sortedBy,
    paginationComponent,
    inputSearchProps,
    isFirstPage: customQueryData?.result?.pageInfo.pageNumber === 0,
    setSortedBy,
    setTeamsFilters,
    refetch,
  };
};
