import React from 'react';
import * as S from './topbar-input-templates.styled';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, TopbarWrapper, Typography } from '@ratedpower/components';
import InputSearch from 'components/form-components/input-search';
import { FilterSvg, NewTabSvg } from 'assets/icons';
import { useKnowledgeBase } from 'utils/knowledge-base/use-knowledge-base';
import { IndexIds } from 'index.ids';
import { useInputTemplatesContext } from './hooks/use-input-templates-context';
import { TitleSize } from 'types/input-templates';
import { openExternalLink } from 'utils/navigation';

interface IProps {
  areFiltersOpened: boolean;
  onClickFilters: (opened: boolean) => void;
  titleSize: TitleSize;
}

const TopBarInputTemplates: React.FC<IProps> = ({ areFiltersOpened, onClickFilters, titleSize }) => {
  const { KBhowToCreateInputTemplates: howToLink } = useKnowledgeBase();
  const { t } = useTranslation();
  const {
    useInputTemplatesObject: { currentTeam, setSelectedTeam, teamOptions, inputSearchProps },
  } = useInputTemplatesContext();

  const TitleComponent = {
    large: (
      <TopbarWrapper
        type="main"
        left={
          <S.InputTemplatesTitleWrapper>
            <Typography size="xl">{t('design-process:templates')}</Typography>
            <Button
              variant="link"
              text={t('design-process:how-to')}
              Icon={NewTabSvg}
              iconPosition="right"
              onClick={() => openExternalLink(howToLink)}
            />
          </S.InputTemplatesTitleWrapper>
        }
      />
    ),
    small: (
      <TopbarWrapper
        type="secondary"
        padding="0 24px"
        left={
          <S.InputTemplatesTitleWrapper>
            <Typography size="xl">{t('design-process:all-templates')}</Typography>
          </S.InputTemplatesTitleWrapper>
        }
        noBorder
      />
    ),
  };

  return (
    <>
      {TitleComponent[titleSize]}
      <TopbarWrapper
        type="secondary"
        padding="0 24px"
        noBorder
        left={<Dropdown options={teamOptions} value={[currentTeam]} onChange={([value]) => setSelectedTeam(value)} />}
        right={
          <>
            <InputSearch
              value={inputSearchProps.value}
              onChange={inputSearchProps.onChange}
              dataTest={IndexIds.Capex.SearchInput}
              size="s"
            />
            <Button
              Icon={FilterSvg}
              variant="ghost"
              iconPosition="right"
              onClick={() => {
                onClickFilters(!areFiltersOpened);
              }}
            />
          </>
        }
      />
    </>
  );
};

export default TopBarInputTemplates;
