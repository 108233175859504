import { gql } from '@apollo/client';
// Simulation Entity: Deprecated
const fragments = {
  basic: gql`
    fragment BasicSimulation on Simulation {
      id
      name
      docsSaved
    }
  `,
};

const fragmentsDTO = {
  mainValues: gql`
    fragment MainValues on SimulationDTO {
      outputs: outputsSimulation {
        ratedPower
        peakPower
        ratioDcAc
        specificProduction
        plantPR
        gcr
        # energyYield: energyToGridAfterAvailability
      }
      energy: energyResults {
        energyYield: energyInjectedToGrid
      }
      interconnection: interconnectionResults {
        dcAcRatioAtPoi: ratioDcAcPointOfInterconnection
        activePowerAtPoi: activePowerPointOfInterconnection
      }
    }
  `,
  capexValues: gql`
    fragment CapexValues on SimulationDTO {
      outputs: outputsSimulation {
        capexResult: billOfQuantitiesResult {
          totalPrice
          specificPrice
          currency
        }
        lcoeResult {
          lcoe
        }
      }
    }
  `,
  financial: gql`
    fragment FinancialValues on SimulationDTO {
      outputs: outputsSimulation {
        financialResultsDTO {
          lcoe
          lcoeCalculationResult
          npv
          irr
          irrCalculationResult
          roi
          roiCalculationResult
          payback
          paybackCalculationResult
          discountedPayback
          discountedPaybackCalculationResult
        }
      }
    }
  `,
  financialYearlyResultValues: gql`
    fragment FinancialYearlyResultValues on SimulationDTO {
      outputs: outputsSimulation {
        financialResultsDTO {
          yearlyResults {
            year
            lcoe
            lcoeCalculationResult
            npv
            irr
            irrCalculationResult
            roi
            roiCalculationResult
            accFreeCashFlow
            revenue
            cost
          }
        }
      }
    }
  `,
  meteo: gql`
    fragment Meteo on SimulationDTO {
      meteoData {
        summary: meteoDataSummary {
          source
          ghi: yearGhiWh
          bhi: yearBhiWh
          dhi: yearDhiWh
          tempAvg: tempAvgHistoric
          tempMin: tempMinHistoric
          tempMax: tempMaxHistoric
        }
      }
    }
  `,
  meteo_full: gql`
    fragment MeteoFull on SimulationDTO {
      meteoData {
        summary: meteoDataSummary {
          source
          resolution: timeResolution
          ghi: yearGhiWh
          bhi: yearBhiWh
          dhi: yearDhiWh
          tempAvg: tempAvgHistoric
          tempMax: tempMaxHistoric
          tempMin: tempMinHistoric
        }
        monthlyProduction: meteoDataValues {
          temp
          ghi
          dhi
          month
        }
      }
    }
  `,
  equipment: gql`
    fragment Equipment on SimulationDTO {
      inputs: inputsDisplay {
        module: moduleModel {
          id
          manufacturer
          model
          type: moduleType
          voltage: maxVoltageIEC
          power: stcWmppDatasheet
        }
        inverter1: inverterModel1 {
          id
          manufacturer
          model
          type
          power: acWmax30
        }
        inverter2: inverterModel2 {
          manufacturer
          model
          type
          power: acWmax30
        }
        structure: structureModel {
          id
          manufacturer
          model
          positionLetter
          positionNumber
          isTracker: structureTypeTracker
        }
      }
    }
  `,
  layoutParams: gql`
    fragment LayoutParams on SimulationDTO {
      inputs: inputsDisplay {
        tiltAngle
        pitchDistance: postToPostDistance
        lvConfig: electricalConfigurationType
        electricalConfig: configElectricalSummary {
          modulesPerString: numModulesPerString
        }
      }
      otherInputs: inputsSimulation {
        minGroundClearance: energyMinimumGroundClearance
        #tiltAngle: energyDcFieldBeta # new
      }
    }
  `,
  otherInputs: gql`
    fragment OtherInputs on SimulationDTO {
      otherInputs: inputsSimulation {
        eastWestStructureEnabled
      }
    }
  `,
  documents: gql`
    fragment Documents on SimulationDTO {
      documents: availableDocuments {
        name: documentStr
        formats: formattedDocuments {
          name: format
          languages: documents {
            lang: language
            url
            filename: fileName
          }
        }
      }
    }
  `,
  site: gql`
    fragment Site on SimulationDTO {
      site {
        name
        siteSummary {
          lat: latitudeSite
          lng: longitudeSite
          utmConvergence: convergenceDeg
          availableArea: area
          suitableArea: areaNet
        }
        location: siteCountryInfo {
          country
          city
        }
      }
      outputs: outputsSimulation {
        gcr
      }
      resultGlobal {
        civil: resultGlobalCivil {
          areaInstalled: civilFenceArea
        }
      }
    }
  `,
  topography: gql`
    fragment Topography on SimulationDTO {
      topography: elevation {
        name
        elevationSummary {
          source
          elevationSite
          elevationMaxSite
          elevationMinSite
          slopeXAvgSite
          slopeXMaxSite
          slopeYAvgSite
          slopeYMaxSite
        }
      }
    }
  `,
  albedo: gql`
    fragment Albedo on SimulationDTO {
      albedoData {
        definition {
          source
          resolution: temporalResolutionDescription
        }
        yearlyResults {
          mean
          totalSamples
          maxSamplesPerMonth
          minSamplesPerMonth
        }
        monthlyData {
          month
          albedoValue
        }
      }
    }
  `,
  horizon: gql`
    fragment Horizon on SimulationDTO {
      horizon {
        horizonSummary {
          source
          horizonAvg
          horizonMax
        }
        profilePicture: horizonProfilePictureUrl
      }
    }
  `,
  energyPrices: gql`
    fragment EnergyPrices on SimulationDTO {
      energyPriceDTO {
        id
        name
        errorMessage
        duration
        publishedDate
        executionDate
        status
        errorType
        energyPriceSummary {
          maximumPrice
          minimumPrice
          averagePrice
          averageDailySpread
        }
        priceDefinitionType
      }
    }
  `,
  energyProduction: gql`
    fragment EnergyProduction on SimulationDTO {
      energy: energyResults {
        monthlyProduction: monthlyResults {
          month
          performanceRatio
          energyToGrid: energyInjectedToGrid
        }
      }
    }
  `,
  energyGainsLosses: gql`
    fragment EnergyGainsLosses on SimulationDTO {
      inputs: inputsDisplay {
        module: moduleModel {
          type: moduleType
        }
      }
      energy: energyResults {
        # Bifacial check
        bifacialGain
        energyLosses {
          # Front-face
          frontPoaLoss
          frontFarShadingLoss
          frontNearShadingLoss
          frontSoilingLoss
          frontIamEffectLoss

          # Monofacial
          poaLoss
          farShadingLoss
          nearShadingLoss
          soilingLoss
          iamEffectLoss

          # Back-face
          groundReflectionLoss
          backPoaLoss
          backShadedGroundLoss
          backFarShadingLoss
          backNearShadingLoss
          backSoilingLoss
          backIamEffectLoss

          # Module
          bifacialityFactorLoss # bifacial
          moduleDegradationLoss
          pvLossIrradiance
          pvLossTemperature
          moduleQuaLoss # gain
          moduleLidLoss
          bifacialMismatchLoss # bifacial
          moduleMisLoss

          # DC system
          stringElectricalShadingLoss
          arrayOhmicLoss

          # Inverter
          inverterLossVoltageThreshold
          inverterLossOverVoltage
          inverterLossPowerThreshold
          inverterLossOverPower
          inverterAuxLoss
          inverterEfficiencyLoss
          inverterCosineOfPhiAdjustment

          # AC system
          invToTrafoLoss

          # Power Station
          powerStationTrafoIronLoss
          powerStationTrafoCopperLoss
          powerStationToSubLoss

          # PV Plant
          plantAuxLoss

          # Substation
          substationTrafoIronLoss
          substationTrafoCopperLoss

          # PV Plant
          deliveryPointLimitLoss
          plantToGridLoss
          plantAvailabilityLoss
          gridAvailabilityLoss
        }
      }
    }
  `,
  listItem: gql`
    fragment ListItem on SimulationDTO {
      createdBy {
        id
        name
        lastname: surname
      }
      publishedDate
      number
      status
      simulationType
      simulationResult
      simulationCategory
      layoutEditorTag
      primaryTag
      secondaryTag
      isFavorite: favourite
      notes
      satellitePicture {
        thumbnail: thumbnailResolutionUrl
        low: lowResolutionUrl
      }
    }
  `,

  layoutResultPicture: gql`
    fragment LayoutResultPicture on SimulationDTO {
      satellitePicture {
        high: highResolutionUrl
      }
    }
  `,
  basic: gql`
    fragment BasicItem on SimulationDTO {
      id
      name
      docsSaved
    }
  `,
  inputsPlotAreas: gql`
    fragment InputsPlotAreas on SimulationDTO {
      inputsPlotAreas {
        inverterModel1Power
        inverterModel2Power
        pitchSpecificDistance
        configElectricalType
        numModulesPerStringInput
        id
        codeAreaOk
        layoutCriteria
        civilRoadsConfigurationType
        civilRoadWidth
        electricalPowerReqMaximumCapacity
        psIncrusted
        layoutAngleDeg
        typeOfLayout
        structurePositionConfig
        gcr
      }
    }
  `,
  civilDesignParams: gql`
    fragment CivilDesignParams on SimulationDTO {
      resultGlobal {
        civil: resultGlobalCivil {
          areaInstalled: civilFenceArea
        }
      }
    }
  `,
};

const commonFragments = {
  designListItem: gql`
    fragment ListItem on DesignDTO {
      id
      name
      docsSaved
      createdBy {
        id
        name
        lastname: surname
      }
      publishedDate
      number
      status
      simulationResult
      simulationCategory: category
      layoutEditorTag
      primaryTag: typeTag
      secondaryTag: editedTag
      isFavorite: favourite
      notes
      satellitePicture {
        thumbnail: thumbnailResolutionUrl
        low: lowResolutionUrl
      }
    }
  `,
};

const pvFragments = {
  siteProperties: gql`
    fragment PVSiteProperties on PVDetailsDTO {
      site {
        name
        siteSummary {
          lat: latitudeSite
          lng: longitudeSite
          utmConvergence: convergenceDeg
          availableArea: area
          suitableArea: areaNet
        }
        location: siteCountryInfo {
          country
          city
        }
      }
    }
  `,
  meteoProperties: gql`
    fragment PVMeteoProperties on PVDetailsDTO {
      meteoData {
        summary: meteoDataSummary {
          source
          ghi: yearGhiWh
          bhi: yearBhiWh
          dhi: yearDhiWh
          tempAvg: tempAvgHistoric
          tempMin: tempMinHistoric
          tempMax: tempMaxHistoric
        }
      }
    }
  `,
  resultsProperties: gql`
    fragment PVResultsProperties on PVDetailsDTO {
      outputs: outputsSimulation {
        ratedPower
        peakPower
        ratioDcAc
        specificProduction
        plantPR
        gcr
        # energyYield: energyToGridAfterAvailability
        capexResult: billOfQuantitiesResult {
          totalPrice
          specificPrice
          currency
        }
        lcoeResult {
          lcoe
        }
      }
      energy: energyResults {
        energyYield: energyInjectedToGrid
      }
      interconnection: interconnectionResults {
        dcAcRatioAtPoi: ratioDcAcPointOfInterconnection
        activePowerAtPoi: activePowerPointOfInterconnection
      }
    }
  `,
  inputsSimulation: gql`
    fragment PVInputsSimulation on PVDetailsDTO {
      inputs: inputsDisplay {
        tiltAngle
        pitchDistance: postToPostDistance
        lvConfig: electricalConfigurationType
        electricalConfig: configElectricalSummary {
          modulesPerString: numModulesPerString
        }
        module: moduleModel {
          id
          manufacturer
          model
          type: moduleType
          voltage: maxVoltageIEC
          power: stcWmppDatasheet
        }
        inverter1: inverterModel1 {
          id
          manufacturer
          model
          type
          power: acWmax30
        }
        inverter2: inverterModel2 {
          manufacturer
          model
          type
          power: acWmax30
        }
        structure: structureModel {
          id
          manufacturer
          model
          positionLetter
          positionNumber
          isTracker: structureTypeTracker
        }
      }
    }
  `,
  civilDesignParams: gql`
    fragment PVCivilDesignParams on PVDetailsDTO {
      resultGlobal {
        civil: resultGlobalCivil {
          areaInstalled: civilFenceArea
        }
      }
    }
  `,
};

const saBessFragments = {
  siteProperties: gql`
    fragment SaBessSiteProperties on SaBessDetailsDTO {
      site {
        siteBatterySummary {
          area
        }
      }
    }
  `,
  simulationInputs: gql`
    fragment SaBessBatteryInputsProperties on SaBessDetailsDTO {
      standaloneBatterySimulationInputs: saBessSimulationInputs {
        inverter {
          id
          model
          manufacturer
          ratedPower
        }
        capacityPerContainer
      }
    }
  `,
  simulationOutputs: gql`
    fragment SaBessBatteryOutputsProperties on SaBessDetailsDTO {
      standaloneBatterySimulationOutputs: saBessSimulationOutputs {
        id
        activePowerAc
        energyCapacity
        operationHours
      }
    }
  `,
};

const fragmentsBessDTO = {
  financial: gql`
    fragment BessFinancialValues on StandAloneBatterySimulationDTO {
      outputs: standaloneBatterySimulationOutputs {
        financialOutputs {
          lcos
          lcosCalculationResult
          npv
          irr
          irrCalculationResult
          payback
          paybackCalculationResult
          discountedPayback
          discountedPaybackCalculationResult
          roi
          roiCalculationResult
          bessCapexTotalPrice
          bessCapexSpecificPrice
        }
      }
    }
  `,
  financialYearlyResultValues: gql`
    fragment BessFinancialYearlyResultsValues on StandAloneBatterySimulationDTO {
      outputs: standaloneBatterySimulationOutputs {
        financialOutputs {
          financialYearlyResults {
            year
            lcos
            lcosCalculationResult
            npv
            irr
            irrCalculationResult
            roi
            roiCalculationResult
            accFreeCashFlow
            revenue
            cost
          }
        }
      }
    }
  `,
};

export const GET_PROJECT_DESIGNS = gql`
  query ProjectSimulations(
    $projectId: ID!
    $pagination: PaginationForm!
    $sorting: [SortOrder]
    $filters: [SimulationFilterEntry]
    $isFavorite: Boolean!
    $search: [String]
  ) {
    result: projectSimulations(
      projectId: $projectId
      pagination: $pagination
      sorting: $sorting
      filters: $filters
      isFavorite: $isFavorite
      search: $search
    ) {
      content {
        ...BasicItem
        ...ListItem
        ...MainValues
        ...Meteo
        ...CivilDesignParams
        ...Equipment
        ...LayoutParams
        ...CapexValues
        ...Site
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
    project(id: $projectId) {
      name
      teamId
    }
  }
  ${fragmentsDTO.basic}
  ${fragmentsDTO.listItem}
  ${fragmentsDTO.mainValues}
  ${fragmentsDTO.meteo}
  ${fragmentsDTO.civilDesignParams}
  ${fragmentsDTO.equipment}
  ${fragmentsDTO.layoutParams}
  ${fragmentsDTO.capexValues}
  ${fragmentsDTO.site}
`;

export const GET_COMPARISON_DESIGNS = gql`
  query ComparisonDesigns($projectId: ID!, $designIds: [ID]!) {
    designs: simulations(ids: $designIds) {
      ...BasicItem
      ...ListItem
      ...MainValues
      ...Meteo
      ...Equipment
      ...Site
      ...EnergyProduction
      ...LayoutParams
      ...CapexValues
      ...InputsPlotAreas
      ...OtherInputs
      ...FinancialValues
      inputsSimulation {
        inputsPerAreaDefinedGlobally
      }
    }
    project(id: $projectId) {
      name
      company {
        id
      }
      teamId
    }
  }
  ${fragmentsDTO.basic}
  ${fragmentsDTO.listItem}
  ${fragmentsDTO.inputsPlotAreas}

  ${fragmentsDTO.mainValues}
  ${fragmentsDTO.meteo}
  ${fragmentsDTO.otherInputs}
  ${fragmentsDTO.equipment}
  ${fragmentsDTO.site}
  ${fragmentsDTO.energyProduction}
  ${fragmentsDTO.layoutParams}
  ${fragmentsDTO.capexValues}
  ${fragmentsDTO.financial}
`;

export const UPDATE_DESIGNS = gql`
  mutation UpdateDesigns($designForms: [UpdateSimulationForm]!) {
    updateDesigns: updateSimulations(simulationForms: $designForms) {
      ...BasicSimulation
    }
  }
  ${fragments.basic}
`;

export const UPDATE_DESIGN_NAME = gql`
  mutation UpdateDesignName($id: ID!, $name: String!) {
    updateSimulationName(id: $id, name: $name) {
      id
    }
  }
`;

export const TOGGLE_FAVORITE_DESIGN = gql`
  mutation toggleFavoriteDesign($designId: ID!) {
    toggleFavoriteDesign(designId: $designId)
  }
`;

export const SAVE_DOCUMENTS = gql`
  mutation saveSimulationDocuments($id: ID!) {
    saveSimulationDocuments(id: $id) {
      id
      docsSaved
    }
  }
`;

export const GET_SIMULATION = gql`
  query Simulation($projectId: ID!, $designId: ID!, $withLayoutPicture: Boolean!) {
    project(id: $projectId) {
      name
    }
    design: simulation(id: $designId) {
      name
      publishedDate
      number
      docsSaved
      documentLanguages
      countDaysUntilDocsExpire
      simulationType
      inputsSimulation {
        inputsPerAreaDefinedGlobally
      }
      ...LayoutResultPicture @include(if: $withLayoutPicture)
      ...MainValues
      ...CapexValues
      ...Documents
      ...Site
      ...Topography
      ...MeteoFull
      ...Albedo
      ...Horizon
      ...EnergyPrices
      ...FinancialValues
    }
  }
  ${fragmentsDTO.layoutResultPicture}
  ${fragmentsDTO.mainValues}
  ${fragmentsDTO.capexValues}
  ${fragmentsDTO.documents}
  ${fragmentsDTO.site}
  ${fragmentsDTO.topography}
  ${fragmentsDTO.meteo_full}
  ${fragmentsDTO.albedo}
  ${fragmentsDTO.horizon}
  ${fragmentsDTO.energyPrices}
  ${fragmentsDTO.financial}
`;

export const GET_SIMULATION_DOCUMENTS = gql`
  query Simulation($projectId: ID!, $designId: ID!) {
    project(id: $projectId) {
      name
    }
    design: simulation(id: $designId) {
      docsSaved
      documentLanguages
      countDaysUntilDocsExpire
      ...Documents
    }
  }

  ${fragmentsDTO.documents}
`;

export const GET_SIMULATION_DETAILED_ENERGY = gql`
  query Simulation($designId: ID!) {
    design: simulation(id: $designId) {
      ...EnergyProduction
      ...EnergyGainsLosses
    }
  }
  ${fragmentsDTO.energyProduction}
  ${fragmentsDTO.energyGainsLosses}
`;

export const GET_SIMULATION_DETAILED_FINANCIAL = gql`
  query Simulation($designId: ID!) {
    design: simulation(id: $designId) {
      ...CapexValues
      ...FinancialValues
      ...FinancialYearlyResultValues
    }
  }
  ${fragmentsDTO.capexValues}
  ${fragmentsDTO.financial}
  ${fragmentsDTO.financialYearlyResultValues}
`;

export const GET_SIMULATION_DETAILED_FINANCIAL_BESS = gql`
  query StandAloneBessSimulation($designId: ID!) {
    design: standAloneBessSimulation(id: $designId) {
      ...BessFinancialValues
      ...BessFinancialYearlyResultsValues
    }
  }
  ${fragmentsBessDTO.financial}
  ${fragmentsBessDTO.financialYearlyResultValues}
`;

export const GET_DESIGN_FILTERS = gql`
  query designFilters($projectId: ID!, $creatorsPagination: PaginationForm!) {
    meteoSources: projectSimulationsMeteoSources(projectId: $projectId)
    creators: projectSimulationsCreators(projectId: $projectId, pagination: $creatorsPagination) {
      content {
        id
        name
        lastname: surname
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
`;

export const GET_AVAILABLE_DOCUMENTS = gql`
  query AvailableSchemaSimulationDocuments {
    availableSchemaSimulationDocuments {
      category
      documents {
        name
        newDocument
      }
    }
  }
`;

export const LOAD_PREPARED_FOR_IMAGE = gql`
  mutation loadCompanyPrepareForImage($id: ID!, $image: Upload) {
    loadCompanyPrepareForImage(id: $id, image: $image) {
      id
    }
  }
`;

export const GET_COMPANY_PREPARED_FOR_IMAGE_ID = gql`
  query getCompanyPreparedForImageIdsByProjectId($id: ID!) {
    companyPreparedForImageIds: getCompanyPreparedForImageIdsByProjectId(id: $id)
  }
`;

export const UPLOAD_ALTERNATIVE_CLIENT_IMAGE = gql`
  mutation uploadAlternativeClientImage($id: ID!, $image: Upload!) {
    uploadAlternativeClientImage(id: $id, image: $image) {
      id
    }
  }
`;

export const GET_DESIGNS_FOR_PROJECT_HOME = gql`
  query designsHomePage(
    $projectId: ID!
    $pagination: PaginationForm!
    $sorting: [SortOrder]
    $filters: [SimulationFilterEntry]
  ) {
    designsHomePage(projectId: $projectId, pagination: $pagination, sorting: $sorting, filters: $filters) {
      content {
        ...ListItem
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
  ${commonFragments.designListItem}
`;

export const GET_DESIGNS_FOR_PROJECT = gql`
  query GetDesignsForProject(
    $projectId: ID!
    $pagination: PaginationForm!
    $sorting: [SortOrder]
    $filters: [SimulationFilterEntry]
    $search: [String]
  ) {
    designsDetails(
      projectId: $projectId
      pagination: $pagination
      sorting: $sorting
      filters: $filters
      search: $search
    ) {
      content {
        ...ListItem
        info {
          ... on PVDetailsDTO {
            ...PVSiteProperties
            ...PVMeteoProperties
            ...PVResultsProperties
            ...PVInputsSimulation
            ...PVCivilDesignParams
          }
          ... on SaBessDetailsDTO {
            ...SaBessSiteProperties
            ...SaBessBatteryInputsProperties
            ...SaBessBatteryOutputsProperties
          }
        }
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
  ${commonFragments.designListItem}
  ${pvFragments.siteProperties}
  ${pvFragments.meteoProperties}
  ${pvFragments.resultsProperties}
  ${pvFragments.inputsSimulation}
  ${pvFragments.civilDesignParams}
  ${saBessFragments.siteProperties}
  ${saBessFragments.simulationInputs}
  ${saBessFragments.simulationOutputs}
`;
