import React, { useState } from 'react';
import { USER_STATUS } from 'utils/constants/users';
import { IServerFilter } from 'types/filter';
import { CheckboxGroup, FilterSidebar, FilterSidebarItem, RadioGroup } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import TeamsFilter from 'components/filters/teams-filter';
import { initialUserFilters } from 'utils/constants/support';
import { ISupportUserFilters } from 'types/support.types';
import UsersFilter from '../activity-tab/filters/users-filter';
import { IUserRoleSummary } from 'types/users';

export interface IUsersTabFiltersPanel {
  id?: string;
  isOpen: boolean;
  roles: IUserRoleSummary[];
  onHide: () => void;
  onApplyFilters: (filters: IServerFilter[]) => void;
}

const UsersTabFiltersPanel: React.FC<IUsersTabFiltersPanel> = ({ id, isOpen, roles, onHide, onApplyFilters }) => {
  const { t } = useTranslation();
  const { isAuthorized } = useAuthorization();
  const [currentFilters, setCurrentFilters] = useState<ISupportUserFilters>(initialUserFilters);

  const handleApply = () => {
    const filters: IServerFilter[] = [];
    Object.entries(currentFilters).forEach(([key, value]) => {
      if (key === 'status' && value) {
        filters.push({ field: 'STATUS', operator: 'IN', values: [value] });
      }
      if (key === 'teamIds' && value.length) {
        filters.push({ field: 'TEAM_ID', operator: 'IN', values: value });
      }
      if (key === 'roleNames' && value.length) {
        filters.push({ field: 'ROLE', operator: 'IN', values: value });
      }
      if (key === 'userIds' && value.length) {
        filters.push({ field: 'ID', operator: 'IN', values: value });
      }
    });
    onApplyFilters(JSON.parse(JSON.stringify(filters)));
  };

  const onToggleFilter = (element: string, checked: boolean, objectKey: keyof ISupportUserFilters) => {
    if (checked) {
      setCurrentFilters({
        ...currentFilters,
        [objectKey]: [...currentFilters[objectKey], element],
      });
      return;
    }
    const index = currentFilters[objectKey].findIndex((current: string) => current === element);
    currentFilters[objectKey].splice(index, 1);
    setCurrentFilters({
      ...currentFilters,
      [objectKey]: [...currentFilters[objectKey]],
    });
  };

  const handleReset = () => {
    setCurrentFilters(initialUserFilters);
  };

  return (
    <FilterSidebar
      onClose={onHide}
      isOpen={isOpen}
      onApply={handleApply}
      onClear={handleReset}
      onApplyTitle={t('action:apply')}
      onClearTitle={t('action:clear')}
      sidebarTitle={t('filters.title', 'Filters')}
    >
      <FilterSidebarItem title={t('user:filters.status')} hasBorderBottom>
        <RadioGroup
          selectedValue={currentFilters.status ?? ''}
          direction="column"
          id={`user-status-${id}`}
          onChange={(option) =>
            setCurrentFilters({
              ...currentFilters,
              status: option.value,
            })
          }
          options={[
            {
              label: t('all'),
              value: '',
            },
            ...Object.keys(USER_STATUS)
              .slice(0, -1)
              .map((option) => ({
                label: t(`user:filters.${USER_STATUS[option]}`),
                value: option,
              })),
          ]}
        />
      </FilterSidebarItem>
      {isAuthorized(Permission.TEAM_SUPPORT_READ) && (
        <TeamsFilter
          teamsChecked={currentFilters.teamIds}
          onChangeTeams={(newOption) => {
            onToggleFilter(newOption.value, newOption.checked, 'teamIds');
          }}
        />
      )}
      {roles && (
        <FilterSidebarItem title={t('user:roles.ROLE')} maxHeightContent="140px" hasBorderBottom>
          <CheckboxGroup
            id="role-filter"
            direction="column"
            onChange={(newOption) => {
              onToggleFilter(newOption.value, newOption.checked, 'roleNames');
            }}
            options={roles.map(({ role, roleName }) => ({
              label: roleName,
              value: role,
              checked: currentFilters.roleNames.includes(role),
            }))}
          />
        </FilterSidebarItem>
      )}
      {isAuthorized(Permission.USER_SUPPORT_READ) && (
        <FilterSidebarItem title={t('company:users')} maxHeightContent="100%" hasBorderBottom>
          <UsersFilter
            onChangeUsers={(users: string[]) => {
              setCurrentFilters({
                ...currentFilters,
                userIds: users,
              });
            }}
          />
        </FilterSidebarItem>
      )}
    </FilterSidebar>
  );
};

export default UsersTabFiltersPanel;
