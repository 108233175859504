/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react';
import * as S from './add-projects-modal.styled';
import { IModalProps, Button, Modal, RatedPowerLoader, RPTable, Typography } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { ModalButtons } from '../../teams-tab.styled';
import { useAddProjectsToTeam } from '../../hooks/use-add-projects-to-team';
import { ContentTopBar } from 'components/content-top-bar';
import ProjectsFilterPanel from 'components/filters/projects-filter-panel/projects-filter-panel';
import { useListTeamProjects } from '../../hooks/use-list-team-projects';
import { useTheme } from 'styled-components';
import { ADD_PROJECT_PAGE_SIZE } from 'utils/teams';
import { PUBLIC_TEAMS_OPTION } from 'utils/constants/users';

interface IProps extends IModalProps {
  selectedTeamId: string;
}

const AddProjectsModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { selectedTeamId, onClose } = props;
  const { table, loading, inputSearchProps, projectFilterPanelProps, paginationComponent } = useListTeamProjects(
    ADD_PROJECT_PAGE_SIZE,
    PUBLIC_TEAMS_OPTION(t).value
  );
  const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>([]);

  const { applyFilters, filtersPanelOpened, setFiltersPanelOpened, setServerFilters } = {
    ...projectFilterPanelProps,
  };
  const { addProjectsToTeam } = useAddProjectsToTeam(selectedTeamId, onClose);

  const onAssignProject = () => {
    addProjectsToTeam(selectedProjectIds);
  };

  const handleRowClick = (row: any) => {
    if (!selectedProjectIds.includes(row.id)) {
      setSelectedProjectIds((currentProjectIds: string[]) => [...currentProjectIds, row.id]);
    } else {
      const selectedProjectIdx = selectedProjectIds.indexOf(row.id);
      const copyOfSelectedProjectIds = [...selectedProjectIds];
      copyOfSelectedProjectIds.splice(selectedProjectIdx, 1);
      setSelectedProjectIds(copyOfSelectedProjectIds);
    }
  };

  return (
    <Modal {...props} size="l">
      <S.Container>
        <S.Topbar>
          <Typography color={theme.primary}>{`${t('user:teams.public-teams')}`}:</Typography>
          {inputSearchProps && (
            <ContentTopBar
              searchPlaceholder={t('user:teams.search-team')}
              searchOnChange={inputSearchProps?.onChange}
              filterOnClick={() => setFiltersPanelOpened(true)}
            />
          )}
        </S.Topbar>

        {table && (
          <S.TableContainer>
            {loading ? (
              <RatedPowerLoader />
            ) : (
              <RPTable
                table={table as any}
                onRowClick={handleRowClick}
                selectedRows={selectedProjectIds}
                zeroResultsFoundText={t('common:zero-results-found')}
              />
            )}
          </S.TableContainer>
        )}

        <ProjectsFilterPanel
          id="add-projects-modal-filter-panel"
          isOpen={filtersPanelOpened}
          onApply={applyFilters}
          onClear={() => {
            setServerFilters([]);
            applyFilters();
          }}
          onClose={() => setFiltersPanelOpened(false)}
          showTeamsFilter={false}
        />

        <S.Pagination>{paginationComponent}</S.Pagination>
        <ModalButtons>
          <Button variant="ghost" text={t('action:cancel')} onClick={onClose} />
          <Button
            variant="accent"
            disabled={!selectedProjectIds.length}
            text={t('user:teams.add-projects')}
            onClick={onAssignProject}
          />
        </ModalButtons>
      </S.Container>
    </Modal>
  );
};

export default AddProjectsModal;
