import React, { PropsWithChildren, useEffect, useMemo, useRef } from 'react';
import NewUserModal from './users-tab/new-user-modal/new-user-modal';
import { GET_SUPPORT_COMPANY, GET_USERS_BY_COMPANY } from 'services/support-gql';
import { useMutation, useQuery } from '@apollo/client';
import { IUseModal, useModal } from 'utils/hooks/use-modal';
import { ICompanyDTO } from 'types/company.types';
import { CREATE_USER_BY_SUPPORT } from 'services/user-gql';
import { useToasts } from 'utils/hooks/use-toasts';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthorization } from 'access-control/can';
import { showHelpButton } from 'redux/help-button-slice';
import { ITab } from '@ratedpower/components';
import { CompanyActivityTabs, companyActivityTabsNames } from 'utils/constants/support';
import { Permission } from 'access-control/permissions';
import { RootState } from 'redux/root-reducer';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_ROLES_BY_CURRENT_USER, GET_USER_ROLE_SUMMARY } from 'services/roles-gql';
import { INewSupportUser, SKUS } from 'types/support.types';
import useForm from 'utils/hooks/use-form';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { errorMessages } from 'utils/constants/error-messages';
import { IStockKeepingUnitDTO } from 'types/subscription-plan';
import { ROLE_TYPE } from 'types/roles';
import { showChatbot } from 'utils/chatbot-positioning';

export interface ICompanyActivity {
  companyId: string;
  loadingCreation: boolean;
  loading: boolean;
  contextCompany: ICompanyDTO;
  authorizedTabs: ITab[];
  newUserFormModal: IUseModal;
  newProjectFormModal: IUseModal;
  handleGoBack: () => void;
  handleGoToTab: (tab: string) => void;
  handleCreateUser: (newUser: INewSupportUser) => void;
  newUserModalComponent: JSX.Element;
  inputs: Record<string, any>;
  setInputs: React.Dispatch<Record<string, any>>;
  initialInputs: Record<string, any>;
  handleInputChangeManual: (name: string, value: any) => void;
  formError: any;
  setFormError: React.Dispatch<any>;
  companyHasContractedTeams: boolean;
}

const Context = React.createContext<ICompanyActivity | null>(null);

export const useCompanyActivityState = (): ICompanyActivity => {
  const contextState = React.useContext(Context);
  if (contextState === null) {
    throw new Error('useCompanyActivityState must be used within a CompanyActivityProvider tag');
  }
  return contextState;
};

export const CompanyActivityProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { addErrorToast, addSuccessToast } = useToasts();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: companyId } = useParams<{ id: string }>();
  const { profile } = useSelector((state: RootState) => state.currentUser);
  const newUserFormModal = useModal();
  const newProjectFormModal = useModal();
  const initialInputs = useRef<Record<string, any>>({});
  const { inputs, handleInputChangeManual, setInputs, formError, setFormError } = useForm(null, {});

  const newUserTemplate = {
    name: '',
    lastname: '',
    email: '',
    role: {
      name: '',
      type: ROLE_TYPE.INTERNAL,
      permissions: [],
    },
    jobTitle: null,
    language: null,
  };

  const onGetCompletedCompanyData = (resolvedCompanyData: ICompanyDTO) => {
    initialInputs.current = {
      name: resolvedCompanyData.name,
      email: resolvedCompanyData.email,
      salesforceId: resolvedCompanyData.salesforceId,
      countryId: resolvedCompanyData.country?.id,
      businessManagerId: resolvedCompanyData.businessManager?.id,
      supportedById: resolvedCompanyData.supportedBy?.id,
      maxSiteToProjectDistance: resolvedCompanyData.maxSiteToProjectDistance,
      showLogoInDocuments: resolvedCompanyData.showLogoInDocuments,
    };
    setInputs(initialInputs.current);
  };
  const dispatch = useDispatch();
  const { isAuthorized } = useAuthorization();

  useEffect(() => {
    dispatch(showHelpButton(false));
    showChatbot(false);
    return () => {
      dispatch(showHelpButton(true));
      showChatbot(true);
    };
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleGoToTab = (tab: string) => {
    let newUrl = `/companies-activity/${companyId}`;
    switch (tab) {
      case companyActivityTabsNames.activity:
        break;
      case companyActivityTabsNames.projectsAndfiles:
        newUrl = newUrl.concat('/projects-and-files');
        break;
      case companyActivityTabsNames.designs:
        newUrl = newUrl.concat('/designs');
        break;
      case companyActivityTabsNames.company:
        newUrl = newUrl.concat('/company');
        break;
      case companyActivityTabsNames.users:
        newUrl = newUrl.concat('/support-users');
        break;
      case companyActivityTabsNames.teams:
        newUrl = newUrl.concat('/support-teams');
        break;
      case companyActivityTabsNames.inputTemplates:
        newUrl = newUrl.concat('/input-templates');
        break;
      default:
        break;
    }
    navigate(newUrl);
  };

  const { data, loading } = useQuery(GET_SUPPORT_COMPANY, {
    variables: {
      id: companyId,
    },
    onCompleted: (data) => {
      const { result } = data;
      onGetCompletedCompanyData(result);
    },
    skip: !companyId,
  });

  const { skuTeamManagement } = useFlags();
  const companyHasContractedTeams = !!data?.result.currentPlan.stockKeepingUnits?.find(
    (sku: IStockKeepingUnitDTO) => sku.name === SKUS.TEAM_MANAGEMENT
  );

  const tabPermissionsMap = {
    [companyActivityTabsNames.activity]: Permission.SUPPORT_ALERT_SUPPORT_READ,
    [companyActivityTabsNames.projectsAndfiles]: Permission.PROJECT_SUPPORT_READ,
    [companyActivityTabsNames.designs]: Permission.SIMULATION_SUPPORT_READ,
    [companyActivityTabsNames.company]: Permission.COMPANY_SUPPORT_READ,
    [companyActivityTabsNames.teams]: Permission.TEAM_SUPPORT_READ,
    [companyActivityTabsNames.users]: Permission.USER_SUPPORT_READ,
    [companyActivityTabsNames.inputTemplates]: Permission.INPUT_TEMPLATE_SUPPORT_WRITE,
  };

  function checkTabAccess(tabs: ITab[]): ITab[] {
    return tabs.filter((tab) => {
      const permission = tabPermissionsMap[tab.id];
      if (tab.id === companyActivityTabsNames.teams) {
        return isAuthorized(permission) && skuTeamManagement && companyHasContractedTeams && tab;
      }
      return isAuthorized(permission) && tab;
    });
  }

  const [createUser, { loading: loadingCreation }] = useMutation(CREATE_USER_BY_SUPPORT, {
    refetchQueries: [GET_USERS_BY_COMPANY, GET_SUPPORT_COMPANY, GET_USER_ROLE_SUMMARY, GET_ROLES_BY_CURRENT_USER],
    onCompleted: () => {
      newUserFormModal.setModalOpen(false);
      addSuccessToast(t('user:user-created-successfully'));
    },
    onError: (graphqlError: any) => {
      const hasDebugInfo: any =
        'debugInfo' in graphqlError.graphQLErrors[0].extensions
          ? graphqlError.graphQLErrors[0].extensions.debugInfo
          : null;
      const errorMessage: string | null =
        hasDebugInfo && 'messageError' in hasDebugInfo ? hasDebugInfo.messageError : null;
      addErrorToast(
        errorMessage && errorMessage === errorMessages.noUsersAvailable
          ? t(`company:subscription-plans.${errorMessages.noUsersAvailable}`)
          : errorMessage && errorMessage === errorMessages.invalidEmail
          ? t('user:error-duplicated-email')
          : errorMessage ?? t('user:user-creation-error')
      );
    },
  });

  const contextCompany: ICompanyDTO = useMemo(() => {
    if (!data) return;
    return data.result;
  }, [data]);

  function handleCreateUser(newUser: INewSupportUser) {
    const userForm = {
      name: newUser.name,
      surname: newUser.lastname,
      email: newUser.email,
      companyId: companyId,
      roleName: newUser.roleName,
      jobTitle: newUser.jobTitle,
      codeLanguage: newUser.language,
      type: newUser.type ?? 'STANDARD_USER',
    };
    createUser({ variables: { userForm } });
  }

  return (
    <Context.Provider
      value={{
        loadingCreation,
        handleCreateUser,
        handleGoBack,
        handleGoToTab,
        companyId: companyId ?? '',
        contextCompany,
        inputs,
        handleInputChangeManual,
        formError,
        setInputs,
        initialInputs: initialInputs.current,
        setFormError,
        loading,
        authorizedTabs: checkTabAccess(CompanyActivityTabs),
        newUserFormModal,
        newProjectFormModal,
        companyHasContractedTeams,
        newUserModalComponent: (
          <NewUserModal
            isActive={newUserFormModal.isOpen}
            loading={loadingCreation}
            showUserTypes={companyId === profile?.companyId}
            title={t('user:new-user')}
            onClose={() => newUserFormModal.setModalOpen(false)}
            onSubmit={(newUser) => {
              handleCreateUser(newUser);
            }}
            user={newUserTemplate}
          />
        ),
      }}
    >
      {children}
    </Context.Provider>
  );
};
