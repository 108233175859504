import { CapexTemplatesListContainer } from 'pages/capex/capex-templates-list/capex-templates-list.styled';
import styled from 'styled-components';

export const InputTemplatesContainer = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.m};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const InputTemplatesCardsContainer = styled(CapexTemplatesListContainer)``;

export const InputTemplatesPaginationWrapper = styled.div`
  .pagination > div {
    height: auto;
    padding-top: ${({ theme }) => theme.v2.spacing.l};
    padding-bottom: ${({ theme }) => theme.v2.spacing.m};
  }
`;
