import { useMemo, useRef, useState } from 'react';
import { PROJECT_SERVER_FILTER_BY_TAB as filtersForView } from 'utils/constants/tabs';
import { useSearch } from 'utils/hooks/use-search';
import { useTranslation } from 'react-i18next';
import { useCustomQuery } from 'utils/hooks/use-custom-query';
import { GET_USER_PROJECTS } from 'services/projects-gql';
import { PROJECTS_PER_PAGE as entriesPerPage } from 'utils/constants/pagination';
import { getSortDirection } from 'utils/sort';
import { IProjectListItem, IProjectsFilters } from 'types/projects';
import { ALL_TEAMS_OPTION } from 'utils/constants/users';
import { objectEquals } from 'utils/objects';
import { track } from 'rudderstack/utils';
import { RudderstackEvent } from 'rudderstack/types';
import { projectFiltersTypes } from 'utils/constants/projects';
import { projectsNumOfDesignFilter } from 'utils/constants/rudderstack';
import { useProjectFilters } from './use-project-filters';

export const useProjects = () => {
  const { t } = useTranslation();
  // view
  const [view, setView] = useState('list');
  const viewNumber = view === 'list' ? '1' : view === 'mixed' ? '2' : '0';
  const mapNumber = view === 'list' ? '0' : view === 'mixed' ? '2' : '1';

  const [tabFilters, setTabFilters] = useState(filtersForView.projects);
  const [selectedTab, setSelectedTab] = useState<string>('projects');
  // filter
  const [teamSelected, setTeamSelected] = useState<string | null>(ALL_TEAMS_OPTION(t).value);
  // search
  const { getSearchFilter, inputSearchProps } = useSearch();
  // sort
  const [sortedBy, setSortedBy] = useState({
    selector: {
      label: t('date'),
      code: 'publishedDate',
    },
    ascendent: false,
  });

  const [hasFoundResultsBanner, setFoundResultsBanner] = useState(false);
  const selectedFiltersRef = useRef<IProjectsFilters | undefined>();

  const { serverFilters, applyFilters, filtersPanelOpened, setFiltersPanelOpened, setServerFilters } =
    useProjectFilters();

  // FETCHING DATA
  const {
    loading,
    data: fetchedData,
    error,
    paginationComponent,
  } = useCustomQuery(GET_USER_PROJECTS, {
    variables: {
      pagination: {
        page: 0,
        size: entriesPerPage,
      },
      sorting: {
        property: sortedBy.selector.code,
        direction: getSortDirection(sortedBy.ascendent),
      },
      filters: [...tabFilters, ...serverFilters],
      isFavorite: selectedTab === 'favorites',
      search: getSearchFilter(),
      teamId: teamSelected,
    },
    fetchPolicy: 'network-only',
    pollInterval: 120000, // 2 min
  });

  const noFiltersApplied = !serverFilters.length && !getSearchFilter().length;
  const zeroProjectsCreated = useMemo<boolean>(() => {
    if (!!error || !!loading || !fetchedData?.projectDesigns) return false;
    const noProjectFiltersApplied = noFiltersApplied && tabFilters === filtersForView.projects;
    const noDesignFound = fetchedData.projectDesigns.content.length < 1;
    return noDesignFound && noProjectFiltersApplied;
  }, [fetchedData, error, loading]);

  const projects: IProjectListItem[] = fetchedData?.result?.content || [];

  const handleViewChange = (newView: string) => {
    setView(newView);
  };

  const handleTabChange = (tab: string) => {
    const newTabFilters = filtersForView[tab];
    if (newTabFilters) {
      setTabFilters(newTabFilters);
      setSelectedTab(tab);
    }
  };

  const handleApplyFilters = (selectedFilters?: IProjectsFilters) => {
    const hasFilterByTeam = !!selectedFilters?.team?.length;
    if (hasFilterByTeam) {
      setTeamSelected(null);
    }
    setFoundResultsBanner(true);
    applyFilters(selectedFilters);

    if (selectedFilters) {
      const selectedFiltersKeys = Object.keys(selectedFilters).filter((key) => selectedFilters[key].length);
      if (!objectEquals(selectedFilters, selectedFiltersRef.current)) {
        rudderstackTrackProjectFilters(
          selectedFiltersKeys,
          selectedFilters.numDesigns !== selectedFiltersRef.current?.numDesigns
        );
        selectedFiltersRef.current = selectedFilters;
      }
    }
  };

  const handleSelectTeam = (team: string) => {
    setTeamSelected(team);
    setFoundResultsBanner(false);
  };

  const handleClearFilters = () => {
    setFoundResultsBanner(false);
    setFiltersPanelOpened(false);
    setServerFilters([]);
  };

  const rudderstackTrackProjectFilters = (selectedFilterCodes: string[], numDesignsHasChanged: boolean) => {
    const filterTrackMappings = {
      [projectFiltersTypes.country]: projectFiltersTypes.country.toLowerCase(),
      [projectFiltersTypes.creator]: projectFiltersTypes.creator.toLowerCase(),
      [projectFiltersTypes.team]: projectFiltersTypes.team.toLowerCase(),
    };

    if (selectedFilterCodes.includes(projectFiltersTypes.numDesigns) && numDesignsHasChanged) {
      track(RudderstackEvent.APPLY_PROJECTS_FILTERS, {
        filter_applied: projectsNumOfDesignFilter,
        tab_selected: selectedTab,
      });
    }

    selectedFilterCodes.forEach((filterCode) => {
      if (filterTrackMappings[filterCode]) {
        track(RudderstackEvent.APPLY_PROJECTS_FILTERS, {
          filter_applied: filterTrackMappings[filterCode],
          tab_selected: selectedTab,
        });
      }
    });
  };

  return {
    projectsError: error,
    projects,
    loading,
    viewNumber,
    mapNumber,
    selectedTab,
    inputSearchProps,
    sortedBy,
    setSortedBy,
    paginationComponent,
    zeroProjectsCreated,
    view,
    teamSelected,
    handleClearFilters,
    handleSelectTeam,
    handleViewChange,
    handleTabChange,
    handleApplyFilters,
    noFiltersApplied,
    hasFoundResultsBanner,
    filtersPanelOpened,
    setFiltersPanelOpened,
  };
};
