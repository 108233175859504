import React from 'react';
import * as S from './input-template-card.styled';
import { EditableInput, Modal, SVGWrapper, Typography } from '@ratedpower/components';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useModal } from 'utils/hooks/use-modal';
import { PencilSvg, TrashSvg } from 'assets/icons';
import ModalEditNotes from 'pages/capex/modal-edit-notes/modal-edit-notes';
import { EditTemplate } from 'types/input-templates';
import { IndexIds } from 'index.ids';

interface InputTemplateCardProps {
  id: string;
  title: string;
  body: { label: string; value: string }[];
  notes: string;
  selected: boolean;
  cursorPointerOnHover: boolean;
  onSelectCard?: (id: string, name: string) => void;
  onEditTemplate?: EditTemplate;
  onDeleteTemplate?: (id: string) => void;
}

const InputTemplateCard: React.FC<InputTemplateCardProps> = ({
  id,
  title,
  body,
  notes,
  selected,
  cursorPointerOnHover,
  onSelectCard,
  onEditTemplate,
  onDeleteTemplate,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const modalEditNotes = useModal();
  const modalDeleteTemplate = useModal();

  const handleClick = (modal) => (e) => {
    modal.setModalOpen(true);
    e.stopPropagation();
  };

  const onClickNotes = onEditTemplate ? handleClick(modalEditNotes) : undefined;
  const onClickDelete = onDeleteTemplate ? handleClick(modalDeleteTemplate) : undefined;

  return (
    <>
      <S.InputTemplateContainer
        $cursorPointerOnHover={cursorPointerOnHover}
        selected={selected}
        onClick={() => onSelectCard && onSelectCard(id, title)}
        data-testid={IndexIds.DesignProcess.LoadInputTemplateCard}
      >
        <S.InputTemplateHeader>
          {onEditTemplate ? (
            <EditableInput id={id} value={title} onEditName={(value: string) => onEditTemplate(id, value, notes)} />
          ) : (
            <Typography size="m" weight="bold">
              {title}
            </Typography>
          )}
          {onDeleteTemplate && (
            <SVGWrapper
              icon={TrashSvg}
              dataTest={IndexIds.InputTemplates.DeleteButton}
              size="s"
              onClick={onClickDelete}
              color={theme.v2.icon.primary.default}
            />
          )}
        </S.InputTemplateHeader>
        <S.InputTemplateContent>
          {body.map((field) => {
            return (
              <S.InputTemplateContentField key={field.label}>
                <Typography size="s" nowrap>
                  {field.label}:
                </Typography>
                <Typography size="s" color={theme.v2.text.secondary.default} nowrap>
                  {field.value ?? '-'}
                </Typography>
              </S.InputTemplateContentField>
            );
          })}
        </S.InputTemplateContent>
        <S.InputTemplateFooter
          hidden={false}
          $cursorPointerOnHover={!!onEditTemplate || cursorPointerOnHover}
          onClick={onClickNotes}
        >
          <div className="notes">
            <Typography size="s">{t('notes')}</Typography>
            <div className="notes-description">
              <p>{notes.length > 0 ? notes.slice(0, 130) : '-'}</p>
              {onEditTemplate && <SVGWrapper size="s" icon={PencilSvg} />}
            </div>
          </div>
        </S.InputTemplateFooter>
      </S.InputTemplateContainer>
      {modalEditNotes.isOpen && (
        <ModalEditNotes
          currentNotes={notes}
          isActive={modalEditNotes.isOpen}
          onSendNotes={(newNotes: string) => {
            onEditTemplate && onEditTemplate(id, title, newNotes);
            modalEditNotes.setModalOpen(false);
          }}
          onCloseModal={() => modalEditNotes.setModalOpen(false)}
          canBeEmpty={true}
          maxLength={500}
        />
      )}
      {modalDeleteTemplate.isOpen && (
        <Modal
          isActive={modalDeleteTemplate.isOpen}
          title={t('design-process:delete-input-template-title')}
          status="error"
          onClose={() => modalDeleteTemplate.setModalOpen(false)}
          buttons={{
            primary: {
              text: t('action:delete'),
              onClick: () => {
                onDeleteTemplate && onDeleteTemplate(id);
                modalDeleteTemplate.setModalOpen(false);
              },
              dataTest: IndexIds.InputTemplates.DeleteButtonConfirm,
            },
            secondary: {
              text: t('action:cancel'),
              onClick: () => modalDeleteTemplate.setModalOpen(false),
            },
          }}
        >
          <Typography>{t('design-process:delete-input-template-description', { inputTemplateName: title })}</Typography>
        </Modal>
      )}
    </>
  );
};

export { InputTemplateCard };
