import styled from 'styled-components';

export const DesignItemDetailsContainer = styled.div`
  display: flex;
  row-gap: ${({ theme }) => theme.v2.spacing.s};
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.v2.spacing.m};

  .section-header {
    width: 100%;
    text-align: left;
    text-transform: capitalize;
  }
`;

export const DesignItemDetailsSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const DesignItemDetailsRow = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.v2.spacing.xs};

  > * {
    min-width: 33%;
  }
`;
