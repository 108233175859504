import {
  AreaParametricInputs,
  DesignInputs,
  DrawableRectangle,
  DrawableLine,
  Point,
  PowerStationElementWithId,
  SelectedElement,
  StructureElementWithId,
  Setback,
  AvailableAreaElement,
  StructureElement,
  SelectedElementWithSlopeData,
  Line,
  NodePosition,
} from '../ilc-types';
import { v4 as uuidv4 } from 'uuid';
import { calculateSlopeAndYInterceptOfPointAndAngle } from './snap-to-line';

export function distance(p1: Point, p2: Point): number {
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;
  return Math.sqrt(dx * dx + dy * dy);
}

export function getPerpendicularAngle(angleInRadians: number): number {
  return angleInRadians - Math.PI / 2;
}

export function degreesToRadians(degrees: number) {
  return degrees * (Math.PI / 180);
}

export const movePointAlongLineUsingAngleAndDistance = (point: Point, angle: number, distance: number) => {
  const dir_X = Math.cos(angle);
  const dir_Y = Math.sin(angle);

  return {
    x: point.x + distance * dir_X,
    y: point.y + distance * dir_Y,
  };
};

export const createRectangleWithCenter = (center: Point, width: number, length: number) => {
  const halfLength = length / 2;
  const halfWidth = width / 2;

  const topLeft = {
    x: center.x - halfLength,
    y: center.y + halfWidth,
  };

  const topRight = {
    x: center.x + halfLength,
    y: center.y + halfWidth,
  };

  const bottomRight = {
    x: center.x + halfLength,
    y: center.y - halfWidth,
  };

  const bottomLeft = {
    x: center.x - halfLength,
    y: center.y - halfWidth,
  };

  return { topLeft, topRight, bottomRight, bottomLeft };
};

export const createRectangleWithCenterWithAngle = (center: Point, width: number, length: number, angle: number) => {
  const { topLeft, topRight, bottomRight, bottomLeft } = createRectangleWithCenter(center, width, length);

  const rotatedTopLeft = rotatePoint(topLeft, center, angle);
  const rotatedTopRight = rotatePoint(topRight, center, angle);
  const rotatedBottomRight = rotatePoint(bottomRight, center, angle);
  const rotatedBottomLeft = rotatePoint(bottomLeft, center, angle);

  return [rotatedTopLeft, rotatedTopRight, rotatedBottomRight, rotatedBottomLeft];
};

export const getMidPointsOfRectangleShortSide = (center: Point, length: number, angle: number): [Point, Point] => {
  const halfLength = length / 2;

  const leftSide = {
    x: center.x - halfLength,
    y: center.y,
  };

  const rightSide = {
    x: center.x + halfLength,
    y: center.y,
  };

  const rotatedLeftSide = rotatePoint(leftSide, center, angle);
  const rotatedRightSide = rotatePoint(rightSide, center, angle);

  return [rotatedLeftSide, rotatedRightSide];
};

export const rotatePoint = (point: Point, center: Point, angle: number) => {
  const s = Math.sin(angle);
  const c = Math.cos(angle);

  const translatedPoint = {
    x: point.x - center.x,
    y: point.y - center.y,
  };

  const xnew = translatedPoint.x * c - translatedPoint.y * s;
  const ynew = translatedPoint.x * s + translatedPoint.y * c;

  return {
    x: xnew + center.x,
    y: ynew + center.y,
  };
};

export function darkenHexColor(hex: string, amount: number): string {
  // Convert HEX to RGB
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);

  // Darken RGB values
  r = Math.max(0, Math.round(r * (1 - amount)));
  g = Math.max(0, Math.round(g * (1 - amount)));
  b = Math.max(0, Math.round(b * (1 - amount)));

  // Convert RGB back to HEX
  const convertedHex = `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}`;

  return convertedHex;
}

const getStructureRectangleMeasurements = (
  selectedReferenceElement: PowerStationElementWithId | StructureElementWithId,
  areaParametricInputs: AreaParametricInputs
) => {
  return {
    length: areaParametricInputs.distanceBetweenStructures * 2 + selectedReferenceElement.length,
    width: areaParametricInputs.clearanceDistance * 2 + selectedReferenceElement.width,
  };
};

const getEastWestStructureRectangleMeasurements = (
  selectedReferenceElement: PowerStationElementWithId | StructureElementWithId,
  areaParametricInputs: AreaParametricInputs
) => {
  const elementWidth = areaParametricInputs.distanceBetweenTables + selectedReferenceElement.width * 2;

  return {
    length: areaParametricInputs.distanceBetweenStructures * 2 + selectedReferenceElement.length,
    width: areaParametricInputs.clearanceDistance * 2 + elementWidth,
  };
};

const getPowerStationRectangleMeasurements = (
  selectedReferenceElement: PowerStationElementWithId | StructureElementWithId,
  areaParametricInputs: AreaParametricInputs
) => {
  return {
    length: areaParametricInputs.distancePsToStructure * 2 + selectedReferenceElement.length,
    width: areaParametricInputs.distancePsToStructure * 2 + selectedReferenceElement.width,
  };
};

const getRectangleMeasurements = (
  element: PowerStationElementWithId | StructureElementWithId,
  areaParametricInputs: AreaParametricInputs
) => {
  if (element.type === 'POWER_STATIONS') {
    return getPowerStationRectangleMeasurements(element, areaParametricInputs);
  }
  if (areaParametricInputs.eastWest) {
    return getEastWestStructureRectangleMeasurements(element, areaParametricInputs);
  }
  return getStructureRectangleMeasurements(element, areaParametricInputs);
};

const getReferenceRectangleMeasurementsForRoads = (
  selectedReferenceElement: PowerStationElementWithId | StructureElementWithId,
  areaParametricInputs: AreaParametricInputs,
  roadWidth: number
) => {
  switch (selectedReferenceElement.type) {
    case 'STRUCTURES': {
      return {
        length: areaParametricInputs.distanceRoadToStructure * 2 + selectedReferenceElement.length + roadWidth,
        width: areaParametricInputs.distanceRoadToStructure * 2 + selectedReferenceElement.width + roadWidth,
      };
    }
    case 'POWER_STATIONS': {
      return {
        length: areaParametricInputs.distancePsToRoad * 2 + selectedReferenceElement.length + roadWidth,
        width: areaParametricInputs.distancePsToRoad * 2 + selectedReferenceElement.width + roadWidth,
      };
    }
  }
};

type SelectedReferenceElement = PowerStationElementWithId | StructureElementWithId | null;

export const calculateSelectedReferenceRectangle = (
  selectedReferenceElement: SelectedReferenceElement,
  designInputs: DesignInputs | null,
  selectedElements: SelectedElement[]
): Array<Point> | null => {
  if (!selectedReferenceElement || !designInputs) return null;

  const areaParametricInputs = designInputs.areaParametricInputs[selectedReferenceElement.areaKey];
  const center = selectedReferenceElement.centroid;

  const calculateForPowerStations =
    selectedReferenceElement.type === 'STRUCTURES' &&
    selectedElements.every((element) => element.type === 'POWER_STATIONS');

  if (calculateForPowerStations) {
    const [selectedReferenceElementLength, selectedReferenceElementWidth] = getElementSize(
      selectedReferenceElement,
      areaParametricInputs
    );
    const length = areaParametricInputs.distancePsToStructure * 2 + selectedReferenceElementLength;
    const width = areaParametricInputs.distancePsToStructure * 2 + selectedReferenceElementWidth;
    return createRectangleWithCenterWithAngle(center, width, length, selectedReferenceElement.angle);
  }
  const { width, length } = getRectangleMeasurements(selectedReferenceElement, areaParametricInputs);
  return createRectangleWithCenterWithAngle(center, width, length, selectedReferenceElement.angle);
};

export const calculateSelectedReferenceRectangleForRoads = (
  selectedReferenceElement: PowerStationElementWithId | StructureElementWithId | null,
  designInputs: DesignInputs | null,
  roadWidth: number | null
): Array<Point> | null => {
  if (!selectedReferenceElement || !designInputs || !roadWidth) return null;

  const areaParametricInputs = designInputs.areaParametricInputs[selectedReferenceElement.areaKey];
  const center = selectedReferenceElement.centroid;
  const { width, length } = getReferenceRectangleMeasurementsForRoads(
    selectedReferenceElement,
    areaParametricInputs,
    roadWidth
  );

  return createRectangleWithCenterWithAngle(center, width, length, selectedReferenceElement.angle);
};

export const calculateAdjacentStructureReferenceRectangle = (
  selectedReferenceElement: PowerStationElementWithId | StructureElementWithId | null,
  designInputs: DesignInputs | null,
  structureTypeLength: number,
  structureTypeWidth: number
): Array<Point> | null => {
  if (!selectedReferenceElement || !designInputs) return null;

  const areaParametricInputs = designInputs.areaParametricInputs[selectedReferenceElement.areaKey];
  const center = selectedReferenceElement.centroid;
  const { width, length } = getRectangleMeasurements(selectedReferenceElement, areaParametricInputs);

  const correctedTypeWidth = areaParametricInputs.eastWest
    ? structureTypeWidth * 2 + areaParametricInputs.distanceBetweenTables
    : structureTypeWidth;

  return createRectangleWithCenterWithAngle(
    center,
    width + correctedTypeWidth,
    length + structureTypeLength,
    selectedReferenceElement.angle
  );
};

export const getMidpoints = (userCoords: Array<Point>) => {
  const midpoints: any = [];
  for (let i = 0; i < userCoords.length; i++) {
    const p1 = userCoords[i];
    const p2 = userCoords[(i + 1) % userCoords.length];
    const newMidPoint = getMidpoint(p1.x, p1.y, p2.x, p2.y);
    midpoints.push(newMidPoint);
  }
  return midpoints;
};

export function getMidpoint(x1: number, y1: number, x2: number, y2: number) {
  return { x: (x1 + x2) / 2, y: (y1 + y2) / 2 };
}

export const pointInPolygon = (point: Point, polygon: Point[]): boolean => {
  let isInside = false;
  let j = polygon.length - 1;

  for (let i = 0; i < polygon.length; i++) {
    if (
      polygon[i].y > point.y !== polygon[j].y > point.y &&
      point.x <
        ((polygon[j].x - polygon[i].x) * (point.y - polygon[i].y)) / (polygon[j].y - polygon[i].y) + polygon[i].x
    ) {
      isInside = !isInside;
    }
    j = i;
  }

  return isInside;
};

export const pointIsWithinAvailableArea = (point: Point, availableArea: AvailableAreaElement): boolean => {
  const polygon = availableArea.exteriorRing;
  return pointInPolygon(point, polygon);
};

export const assignLineColor = (setbackType: Setback['type']) => {
  if (setbackType.endsWith('MIN')) {
    return '#1B1F18';
  }
  return '#878C84';
};

export const setbackPolygonToLines = (points: Point[], setbackType: Setback['type']): DrawableLine[] => {
  const lines: DrawableLine[] = [];

  for (let i = 0; i < points.length; i++) {
    const point1 = points[i];
    const point2 = points[i + 1] || points[0];
    lines.push({
      start: { ...point1, z: 5 },
      end: { ...point2, z: 5 },
      color: assignLineColor(setbackType),
      id: `${point1.x}_${point1.y}_${point2.x}_${point2.y}_${setbackType}`,
    });
  }
  return lines;
};

export const getElementSize = (element: SelectedElement, areaParametricInputs: AreaParametricInputs) => {
  const elementWidth = areaParametricInputs?.eastWest
    ? areaParametricInputs.distanceBetweenTables + element.width * 2
    : element.width;
  return [element.length, elementWidth];
};

export const selectedElementToRectangleWithPosition = (
  element: SelectedElement,
  designInputs: DesignInputs
): DrawableRectangle => {
  const [length, width] = getElementSize(element, designInputs.areaParametricInputs[element.areaKey]);
  return {
    points: createRectangleWithCenterWithAngle(
      { x: element.centroid.x, y: element.centroid.y },
      width,
      length,
      element.angle
    ),
    id: element.id,
    center: { x: element.centroid.x, y: element.centroid.y },
  };
};

export const movePointAlongLineUsingDirectionVector = (origin: Point, directionVector: Point, target: Point) => {
  const movementVector = {
    x: target.x - origin.x,
    y: target.y - origin.y,
  };

  const dotProduct = directionVector.x * movementVector.x + directionVector.y * movementVector.y;

  const scalarProjection = dotProduct / (directionVector.x * directionVector.x + directionVector.y * directionVector.y);

  return {
    x: origin.x + scalarProjection * directionVector.x,
    y: origin.y + scalarProjection * directionVector.y,
  };
};

export function removeMiddle(a: any, b: any, c: any) {
  const cross = (a.x - b.x) * (c.y - b.y) - (a.y - b.y) * (c.x - b.x);
  const dot = (a.x - b.x) * (c.x - b.x) + (a.y - b.y) * (c.y - b.y);
  return cross < 0 || (cross == 0 && dot <= 0);
}

export function convexHull(points: any[]): Point[] {
  points.sort(function (a, b) {
    return a.x != b.x ? a.x - b.x : a.y - b.y;
  });

  const n = points.length;
  const hull: any = [];

  for (let i = 0; i < 2 * n; i++) {
    const j = i < n ? i : 2 * n - 1 - i;
    while (hull.length >= 2 && removeMiddle(hull[hull.length - 2], hull[hull.length - 1], points[j])) hull.pop();
    hull.push(points[j]);
  }

  hull.pop();
  return hull;
}

export const calculateDirectionVectorFromAngle = (angle: number): Point => {
  return {
    x: Math.cos(angle),
    y: Math.sin(angle),
  };
};

export const calculateDirectionVectorFromPoints = (point1: Point, point2: Point): Point => {
  return { x: point2.x - point1.x, y: point2.y - point1.y };
};

export function getPointerFromEvent(
  event: { clientX: number; clientY: number },
  size: { width: number; height: number }
): Point {
  return { x: (event.clientX / size.width) * 2 - 1, y: -(event.clientY / size.height) * 2 + 1 };
}

export const offsetRectangleCentroid = (structure: StructureElement): StructureElement => {
  const offset = structure.width / 2;
  return {
    ...structure,
    centroid: {
      x: structure.centroid.x + offset,
      y: structure.centroid.y - offset,
    },
  };
};

export const filterStructuresAndOffset = (
  elements: SelectedElement[]
): { newStructures: StructureElement[]; originalIds: string[] } => {
  return elements.reduce(
    (acc: { newStructures: StructureElement[]; originalIds: string[] }, elem) => {
      if (elem.type === 'STRUCTURES') {
        const newKey = uuidv4();
        acc.newStructures.push(offsetRectangleCentroid({ ...elem, key: newKey }));
        acc.originalIds.push(elem.id);
      }
      return acc;
    },
    { newStructures: [], originalIds: [] }
  );
};

export const groupSlopesByRoundedYIntercept = (
  elementsData: SelectedElementWithSlopeData[]
): Record<number, SelectedElementWithSlopeData[]> => {
  return elementsData.reduce((acc: Record<number, SelectedElementWithSlopeData[]>, data) => {
    const existingGroupKey = Object.keys(acc).find((key) => Math.abs(Number(key) - data.roundedYIntercept) <= 2);
    if (existingGroupKey) {
      acc[existingGroupKey].push(data);
    } else {
      acc[data.roundedYIntercept] = [data];
    }
    return acc;
  }, {});
};

export const getElementsGroupedByYIntersect = (
  selectedElements: SelectedElement[]
): Record<number, SelectedElementWithSlopeData[]> => {
  const elementsWithSlopeAndRectangle = selectedElements.map((element) => ({
    ...calculateSlopeAndYInterceptOfPointAndAngle(element.centroid, selectedElements[0].angle),
    ...element,
    rectangle: createRectangleWithCenterWithAngle(
      { x: element.centroid.x, y: element.centroid.y },
      element.width,
      element.length,
      element.angle
    ),
  }));

  return groupSlopesByRoundedYIntercept(elementsWithSlopeAndRectangle);
};

export function nodePositionsToLine(start: NodePosition, end: NodePosition): Line {
  const point1: Point = { x: start.x, y: -start.z };
  const point2: Point = { x: end.x, y: -end.z };

  return { point1, point2 };
}

export function moveNodeAlongLine(
  mousePosition: Point,
  initialNodePosition: Point,
  connectionNodes: Point[]
): Point | undefined {
  let closestPoint: Point | null = null;
  let closestDistance = Infinity;
  for (const connectionNode of connectionNodes) {
    const dirVector = calculateDirectionVectorFromPoints(initialNodePosition, connectionNode);
    const closest = movePointAlongLineUsingDirectionVector(initialNodePosition, dirVector, mousePosition);
    const dist = distance(mousePosition, closest);
    if (dist < closestDistance) {
      closestDistance = dist;
      closestPoint = closest;
    }
  }

  if (closestPoint) {
    return closestPoint;
  }
}

export function getParallelLines(line: Line, value: number): [Line, Line] {
  const { point1, point2 } = line;

  const { x: dx, y: dy } = calculateDirectionVectorFromPoints(point1, point2);

  const length = Math.sqrt(dx * dx + dy * dy);
  const perpX = (-dy / length) * value;
  const perpY = (dx / length) * value;

  const line1: Line = {
    point1: { x: point1.x + perpX, y: point1.y + perpY },
    point2: { x: point2.x + perpX, y: point2.y + perpY },
  };

  const line2: Line = {
    point1: { x: point1.x - perpX, y: point1.y - perpY },
    point2: { x: point2.x - perpX, y: point2.y - perpY },
  };

  return [line1, line2];
}

export const getShortestDistance = (line: Line, point: Point): number => {
  const { point1, point2 } = line;

  const A = point2.y - point1.y;
  const B = point1.x - point2.x;
  const C = point2.x * point1.y - point1.x * point2.y;

  return Math.abs(A * point.x + B * point.y + C) / Math.sqrt(A * A + B * B);
};

export const findClosestLineToPoint = (lines: [Line, Line], point: Point): Line => {
  const shortestDistanceToLine1 = getShortestDistance(lines[0], point);
  const shortestDistanceToLine2 = getShortestDistance(lines[1], point);
  if (shortestDistanceToLine1 < shortestDistanceToLine2) {
    return lines[0];
  }
  return lines[1];
};

export const getPolygonCentroid = (points: Point[]): Point => {
  const sum = points.reduce(
    (acc, point) => {
      acc.x += point.x;
      acc.y += point.y;
      return acc;
    },
    { x: 0, y: 0 }
  );

  return {
    x: sum.x / points.length,
    y: sum.y / points.length,
  };
};