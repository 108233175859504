import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { RootState } from 'redux/root-reducer';
import { GET_USER_ROLE_SUMMARY } from 'services/roles-gql';
import { IServerFilter } from 'types/filter';

export const useUserRoleSummary = (filters: IServerFilter[], teamSelected: string | null) => {
  const { profile } = useSelector((state: RootState) => state.currentUser);
  const { id: supportCompanyId } = useParams<{ id: string }>();
  const { data: userRoleSummary, loading: userSummaryLoading } = useQuery(GET_USER_ROLE_SUMMARY, {
    variables: {
      filters: filters?.filter((filter) => filter.field === 'STATUS' || filter.field === 'JOB_TITLE'),
      teamId: teamSelected,
      companyId: supportCompanyId ?? profile?.companyId,
    },
    fetchPolicy: 'network-only',
  });

  return {
    userRoleSummary: userRoleSummary?.result,
    userSummaryLoading,
  };
};
