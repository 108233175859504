import { STATISTICS_TABS as tabs } from './tabs';
import { IStatisticsFilters, TimePeriod } from 'types/statistics';
import { IOption } from 'types/common';
import { dateFilters } from './filters';
import { getDaysBetweenDates, getShortDate } from 'utils/date';
import { AlertSvg, EnergySvg, FolderSvg, LayersSvg, MapPinSvg, MountainSvg, PeopleSvg, SunSvg } from 'assets/icons';
import { ITab } from '@ratedpower/components';
import { DateRange } from 'types/filter';

export const pageTabs: ITab[] = [
  {
    id: 'SIMULATIONS',
    label: `statistics:${tabs.simulations}`,
    Icon: LayersSvg,
  },
  {
    id: 'PROJECTS',
    label: 'project:projects',
    Icon: FolderSvg,
  },
  {
    id: 'USERS',
    label: 'user:user-plural',
    Icon: PeopleSvg,
  },
  {
    id: 'SITES',
    label: `statistics:${tabs.sites}`,
    Icon: MapPinSvg,
  },
  {
    id: 'ELEVATIONS',
    label: `statistics:${tabs.elevations}`,
    Icon: MountainSvg,
  },
  {
    id: 'METEO',
    label: `design:${tabs.meteo}`,
    Icon: SunSvg,
  },
  {
    id: 'ESTIMATE_POWER',
    label: `statistics:${tabs.estimated_power}`,
    Icon: EnergySvg,
  },
  {
    id: 'ERRORS',
    label: `statistics:${tabs.errors}`,
    Icon: AlertSvg,
  },
];

export const initialFilters: IStatisticsFilters = {
  companyIds: [],
  companyCountries: [],
  countries: [],
  isIncludedRatedPower: false,
  dateRange: {
    from: getShortDate(dateFilters[0].value),
    to: getShortDate(dateFilters[0].value),
  },
  simulationCategories: [],
};

export const COMPANY_SEARCH_MANUALLY = 'filters.company-search-manually';
export const NO_FILTERS_SELECTED = 'filters.no-filters-selected';
export const ERROR_RATE_KEY = 'Error rate';
export const SIMULATION_POWER_KEY = 'Simulation Power';
export const ACTIVE_USERS = 'Active Users';

export const CSV_PATH_VARIABLE: { [key: string]: string } = {
  SIMULATIONS: 'SIMULATION_CSV',
  USERS: 'USER_CSV',
  ESTIMATE_POWER: 'ESTIMATE_POWER_CSV',
  PROJECTS: 'PROJECT_CSV',
};

export const TABS_WITH_LIST: string[] = [
  'SIMULATIONS',
  'PROJECTS',
  'USERS',
  'SITES',
  'ELEVATIONS',
  'METEO',
  'ESTIMATE_POWER',
  'ERRORS',
];

export const PAGE_SIZE_OPTIONS: IOption[] = [
  {
    label: '15 rows',
    value: 15,
  },
  {
    label: '30 rows',
    value: 30,
  },
  {
    label: '50 rows',
    value: 50,
  },
];

export const CHART_PAGE_SIZE = 15;

export const getTimePeriodToSend = (selectedDateRange: DateRange) => {
  if (Number(getDaysBetweenDates(new Date(selectedDateRange.from), new Date(selectedDateRange.to))) <= 31) {
    return TimePeriod.DAILY;
  }
  if (Number(getDaysBetweenDates(new Date(selectedDateRange.from), new Date(selectedDateRange.to))) <= 183) {
    return TimePeriod.WEEKLY;
  }
  if (Number(getDaysBetweenDates(new Date(selectedDateRange.from), new Date(selectedDateRange.to))) <= 365) {
    return TimePeriod.MONTHLY;
  }
  return TimePeriod.YEARLY;
};
