import React, { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import * as S from './company-info-tab.styled';
import AlertsForm from './alerts-form/alerts-form';
import Can, { useAuthorization } from 'access-control/can';
import { CompanyFormManagerSelectors } from './company-form-manager-selectors';
import CompanyLogo from 'components/company-logo/company-logo';
import CompanySamlForm from './company-saml-form';
import DeleteCompany from './delete-company/delete-company';
import PlanHistory from './plan-history/plan-history';
import Skeleton from 'react-loading-skeleton';
import { DefaultLogo } from 'assets/icons';
import { IOption } from 'types/common';
import { Permission } from 'access-control/permissions';
import { RemoteImage } from 'utils/hooks/use-remote-image';
import { useCompanyInfoTab } from './use-company-info-tab';
import { useTheme } from 'styled-components';
import { useTranslatedCountries } from 'utils/hooks/use-translated-countries';
import { useTranslation } from 'react-i18next';
import {
  Button,
  InputNumber,
  Dropdown,
  InputText,
  LabelWrapper,
  Banner,
  Separator,
  Typography,
  BaseTemplateInput,
  Toggle,
  LinkText,
} from '@ratedpower/components';
import { IndexIds } from 'index.ids';
import { devSecOpsForm, IsSalesforceApiAvailable } from 'utils/constants/support';
import SalesforceIDInput from './salesforce-id-input';

const CompanyInfoTab: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    customerSupportUsers,
    businessUsers,
    formError,
    loadingOptionsData,
    edition,
    companyUpdateLoading,
    setEdition,
    handleSubmit,
    setNewCompanyLogo,
    inputs,
    handleDiscardChanges,
    handleInputChangeManual,
    loadingCompanyData,
    contextCompany,
  } = useCompanyInfoTab();

  const isLaptop = useMediaQuery({ query: '(max-width: 1440px)' });

  const { countries } = useTranslatedCountries(false, 'id');

  const { isAuthorized } = useAuthorization();
  const loader = useMemo(() => <Skeleton style={{ color: theme.v2.bg.secondary.default }} height="20px" />, [theme]);

  const inputSize = isLaptop ? 's' : 'm';
  const selectedCountry = countries?.find((countryOption: IOption) => inputs.countryId === countryOption.value);

  const handleEditButtonClicked = () => {
    if (edition) {
      handleDiscardChanges();
    }
    setEdition(!edition);
  };

  return (
    <>
      {new Date(contextCompany?.currentPlan.expirationDate) < new Date('2023-06-01') && (
        <Banner
          header="This company has not been yet migrated to Enverus Account Service."
          description={
            <S.BannerDescriptionContainer>
              <Typography>Prior to reactivating the license, please reach out to DevSecOps</Typography>
              <LinkText
                href={devSecOpsForm}
                target="_blank"
                rel="noopener noreferrer"
                color={theme.v2.btn.link.text.hover}
                textUnderlined
              >
                here.
              </LinkText>
            </S.BannerDescriptionContainer>
          }
          status="warning"
          closable
        />
      )}
      <S.CompanyInfoContainer>
        <S.CompanyInfoColumnContainer>
          <S.Block>
            <S.CompanyFormSection>
              <S.CompanyLogoHeadline>
                <Typography weight="bold" size="l">
                  {t('user:account-info')}
                </Typography>
                {isAuthorized(Permission.COMPANY_SUPPORT_WRITE) && (
                  <Button
                    text={t(`action:${edition ? 'cancel' : 'edit'}`)}
                    variant="link"
                    onClick={handleEditButtonClicked}
                    textUnderlined
                  />
                )}
              </S.CompanyLogoHeadline>

              <Typography>{t('company:company-logo-info')}</Typography>
              {loadingCompanyData ? (
                <Skeleton height="110px" width="160px" />
              ) : edition ? (
                <CompanyLogo
                  canSelect={isAuthorized(Permission.COMPANY_SUPPORT_WRITE)}
                  onSelect={(file: File) => setNewCompanyLogo(file)}
                  url={contextCompany?.logo?.lowResUrl}
                  hideInfo
                  customHeight="110px"
                  customWidth="160px"
                />
              ) : (
                <S.CompanyLogoImg>
                  {contextCompany?.logo?.lowResUrl ? (
                    <RemoteImage url={contextCompany?.logo?.lowResUrl} alt="Company Logo" />
                  ) : (
                    <DefaultLogo className="default-img" />
                  )}
                </S.CompanyLogoImg>
              )}

              <Separator />
              <Typography size="l">{t('user:profile')}</Typography>
              <LabelWrapper
                title={t('support:company-name')}
                loading={loadingCompanyData}
                Loader={loader}
                size={inputSize}
                alignItems="center"
              >
                <InputText
                  dataTest={IndexIds.CompaniesActivity.CompanyName}
                  warning={!!formError}
                  name="name"
                  value={inputs.name}
                  readOnly={!edition}
                  onChange={(newValue) => handleInputChangeManual('name', newValue)}
                  size={inputSize}
                  textAlign="right"
                />
              </LabelWrapper>
              <LabelWrapper
                size={inputSize}
                alignItems="center"
                title={t('company:notification-email')}
                loading={loadingCompanyData}
                Loader={loader}
              >
                <BaseTemplateInput
                  warning={!!formError}
                  type="email"
                  name="email"
                  value={inputs.email}
                  size={inputSize}
                  readOnly={!edition}
                  onChange={(newValue) => handleInputChangeManual('email', newValue)}
                  textAlign="right"
                />
              </LabelWrapper>
              <LabelWrapper
                title={t('support:salesforce-id')}
                loading={loadingCompanyData}
                Loader={loader}
                size={inputSize}
                alignItems="center"
                info={edition && IsSalesforceApiAvailable ? t('support:salesforce-id-info') : undefined}
              >
                {!edition ? (
                  <InputText
                    name="salesforceId"
                    value={inputs.salesforceId ?? '-'}
                    size={inputSize}
                    dataTest="salesforce-id"
                    textAlign="right"
                    readOnly
                  />
                ) : (
                  <SalesforceIDInput
                    selectedId={inputs.salesforceId}
                    onChange={(newValue) => handleInputChangeManual('salesforceId', newValue)}
                    textAlign="right"
                  />
                )}
              </LabelWrapper>
              <Separator />
              <Typography size="l">{t('location')}</Typography>
              <LabelWrapper
                title={t('company:country')}
                loading={loadingCompanyData || !contextCompany || !countries}
                Loader={loader}
                size={inputSize}
                alignItems="center"
              >
                <Dropdown
                  name="countryId"
                  options={countries}
                  value={selectedCountry ? [selectedCountry] : []}
                  readonly={!edition}
                  size={inputSize}
                  onChange={([item]) => handleInputChangeManual('countryId', item.value)}
                />
              </LabelWrapper>
              <Separator />
              <Typography size="l">{t('company:managers')}</Typography>
              <CompanyFormManagerSelectors
                loading={loadingOptionsData}
                customerSupportUsers={customerSupportUsers}
                businessUsers={businessUsers}
                edition={edition}
                loader={loader}
                inputs={inputs}
                handleInputChangeManual={handleInputChangeManual}
                inputSize={inputSize}
              />
              <Separator />
              <Typography size="l">{t('support:company-information')}</Typography>
              <LabelWrapper
                title={t('company:projects-purchased')}
                size={inputSize}
                alignItems="baseline"
                loading={loadingCompanyData}
                Loader={loader}
              >
                <S.InputWithUnlimitedOption>
                  <BaseTemplateInput
                    size={inputSize}
                    warning={!!formError}
                    name="projectLimit"
                    value={
                      contextCompany?.currentPlan.unlimitedProjects
                        ? t('company:unlimited')
                        : contextCompany?.currentPlan.projectsPurchased
                    }
                    textAlign="right"
                    readOnly
                  />
                </S.InputWithUnlimitedOption>
              </LabelWrapper>
              <LabelWrapper
                title={t('company:number-of-users')}
                size={inputSize}
                alignItems="baseline"
                loading={loadingCompanyData}
                Loader={loader}
              >
                <S.InputWithUnlimitedOption>
                  <BaseTemplateInput
                    size={inputSize}
                    warning={!!formError}
                    name="userLimit"
                    value={
                      contextCompany?.currentPlan.unlimitedUsers
                        ? t('company:unlimited')
                        : contextCompany?.currentPlan.userLimit
                    }
                    textAlign="right"
                    readOnly
                  />
                </S.InputWithUnlimitedOption>
              </LabelWrapper>
              <LabelWrapper
                title={t('company:number-of-users-created')}
                loading={loadingCompanyData}
                Loader={loader}
                size={inputSize}
                info={t('company:number-of-users-created-info')}
              >
                <BaseTemplateInput
                  size={inputSize}
                  warning={!!formError}
                  name="usersCreated"
                  value={contextCompany?.currentPlan.usersCreated}
                  textAlign="right"
                  readOnly
                />
              </LabelWrapper>
              <Separator />
              <Typography size="l">{t('company:site-boundary', 'Site boundary')}</Typography>
              <LabelWrapper
                title={t('company:kilometers', 'Kilometers')}
                loading={loadingCompanyData}
                Loader={loader}
                size={inputSize}
                alignItems="center"
              >
                <InputNumber
                  warning={!!formError}
                  name="maxSiteToProjectDistance"
                  value={inputs.maxSiteToProjectDistance / 1000}
                  size={inputSize}
                  textAlign="right"
                  onChange={(newValue) =>
                    handleInputChangeManual('maxSiteToProjectDistance', parseInt(newValue) * 1000)
                  }
                  readOnly={!edition}
                />
              </LabelWrapper>
              <Separator />
              <Typography size="l">{t('design:documentation')}</Typography>
              <Typography>{t('support:logo-documentation-info')}</Typography>
              <Toggle
                disabled={!isAuthorized(Permission.COMPANY_SUPPORT_WRITE) || !edition}
                className="logo-documentation-toggle"
                label={t('support:logo-documentation-label')}
                onToggle={() => handleInputChangeManual('showLogoInDocuments', !inputs.showLogoInDocuments)}
                name="showLogoInDocuments"
                isOn={inputs.showLogoInDocuments}
                alignItems="center"
              />
              {formError && (
                <Typography size="s" color={theme.v2.error.text.contrast}>
                  {t(`errors:${formError}`)}
                </Typography>
              )}
              {edition && (
                <S.FormActions>
                  <Button text={t('action:discard')} variant="ghost" onClick={handleEditButtonClicked} fullWidth />
                  <Button
                    text={t('action:save')}
                    variant="accent"
                    loading={companyUpdateLoading}
                    fullWidth
                    onClick={handleSubmit}
                  />
                </S.FormActions>
              )}
            </S.CompanyFormSection>
          </S.Block>

          <S.Block>
            <S.CompanyFormSection>
              <AlertsForm />
            </S.CompanyFormSection>
          </S.Block>
          <S.Block>
            <Can perform={Permission.COMPANY_SUPPORT_SAML}>
              <CompanySamlForm />
            </Can>
          </S.Block>
          <S.Block>
            <Can perform={Permission.COMPANY_SUPPORT_DELETE}>
              <DeleteCompany companyId={contextCompany?.id} companyName={contextCompany?.name} />
            </Can>
          </S.Block>
        </S.CompanyInfoColumnContainer>
        <S.PlansHistorySection>
          <PlanHistory />
        </S.PlansHistorySection>
      </S.CompanyInfoContainer>
    </>
  );
};

export default CompanyInfoTab;
