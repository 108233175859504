import styled from 'styled-components';

export const EmptyContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.v2.spacing.m};
  align-items: center;
  justify-content: center;
  margin: 200px auto 0;
  text-align: center;
  max-width: 400px;
`;

export const EmptyContentButtons = styled.div`
  display: flex;
  column-gap: 8px;
`;
