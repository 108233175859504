import { ICapexTemplate, ICapexTemplateRecalculate, IEntry } from './capex';
import { ApolloQueryResult } from '@apollo/client';
import { MAP_Views } from 'pages/design-process/new-topbar-design-process/map-view-selector/map-view-list';
import { UnitSystem } from './preferences.types';
import {
  IMonthlyEnergyProduction,
  LayoutEditorTag,
  SimulationPrimaryCharacteristicsTag,
  SimulationSecondaryCharacteristicsTag,
} from './design';
import { MeteoSources } from 'utils/constants/meteoDataSources';
import { GenericStatus } from 'utils/constants/status';
import { CalculationResult } from 'pages/design-process/outputs/financial/types';
import { IUseCapexTemplateEditorDesignProcess } from 'pages/design-process/use-capex-template-editor-design-process';
import { InverterType } from 'utils/constants/equipment-constants';
import { BatteryRotationDTO, BessRequirementsType } from 'pages/design-process/shared/batteries-tab/types';

export interface ITopographyFiltersDTO {
  maximumEwSlope: number;
  maximumEwSlopeFilterEnabled: boolean;
  maximumLinkedRowSlope: number;
  maximumLinkedRowSlopeFilterEnabled: boolean;
  maximumNsSlope: number;
  maximumNsSlopeFilterEnabled: boolean;
  maximumUndulationTolerance: number;
  maximumUndulationToleranceFilterEnabled: boolean;
  nsSlopeDifferentiationFilterEnabled: boolean;
  nsDifferentiationDTO: {
    northSlopeFilterEnabled: boolean;
    northSlopeFilterValue: number;
    southSlopeFilterEnabled: boolean;
    southSlopeFilterValue: number;
  };
  removalOfStructuresFromLayoutEnabled: boolean;
}

export interface IEarthworksFiltersDTO {
  ewSlope: number;
  ewSlopeEnabled: boolean;
  nsDifferentiationDTO: {
    northSlopeFilterEnabled: boolean;
    northSlopeFilterValue: number;
    southSlopeFilterEnabled: boolean;
    southSlopeFilterValue: number;
  };
  nsSlope: number;
  nsSlopeEnabled: boolean;
  undulationTolerance: number;
  undulationToleranceEnabled: boolean;
}

export interface IStatus {
  loading: boolean;
  error?: boolean;
  refetch: () => Promise<void | { [key: string]: any }>;
}

export type Input = string | number | boolean | null | any[] | object | undefined | string[];
interface ISliderInput {
  value: number;
  selectedByUser: boolean;
  power?: number;
}

export interface IMinMax {
  max: number;
  min: number;
  value: number;
}

export interface IStringsPerStructureItem {
  stringsPerStructure: number;
  structureLength: number;
  selectedByUser: boolean;
}
interface IStructuresInputs {
  numStringsPerStructure: IMinMax;
  rowsOfStructuresPerMultiStructure: IMinMax;
  structureLength: number;
  stringsPerStructureList: IStringsPerStructureItem[];
}

interface ICollector {
  stringsPerFirstLevel: ISliderInput[];
}

interface ICollectorL2 extends ICollector {
  combinersL1PerL2: IMinMax;
}

interface ILvInputs {
  collectorL1: ICollector;
  collectorL2: ICollectorL2;
}

interface ITopographyAnalysisInputs {
  pileLength: number;
  minimumPileLength: number;
  maximumPostLengthLowerBound: number;
  topographyFiltersDTO: ITopographyFiltersDTO;
  earthWorksDTO: IEarthworksFiltersDTO;
}

export interface ISetbacksDTO {
  distanceFenceToAA: IMinMax;
  distanceFenceToStructure: IMinMax;
  distancePsNotEmbeddedToStructure: IMinMax;
  distanceRoadToPS: IMinMax;
  distanceRoadToStructure: IMinMax;
}

type RASetbacksDTO = {
  distanceToStructure: IMinMax;
  distanceToFence: IMinMax;
};

export type RestrictedAreaTypeDTO = {
  colorHexString: string;
  name: string;
  userDefinedName: string;
  fenceStrategy: RAFenceStrategy;
  roadsStrategy: RARoadsStrategy;
  setbacksDTO: RASetbacksDTO;
};

export type RestrictedAreaTypesSetbacks = {
  [key: string]: RestrictedAreaTypeDTO;
};

interface IHarnessOption {
  power: number;
  selectedByUser: boolean;
  value: number;
}

interface IHarnessInputsDTO {
  isSelected: boolean;
  stringPerHarnessOptions: IHarnessOption[] | [];
}
interface IEnergyProbabilisticStudyDto {
  probabilityValues: number[];
  sigma: number;
}

export interface IHoursStepDTO {
  hoursOfCapacity: number;
  selectedByUser: boolean;
}

interface IDimensions {
  height: number;
  length: number;
  width: number;
}

export enum BessType {
  AC_BESS = 'AC_BESS',
  DC_BESS = 'DC_BESS',
}

export type BessDTO = {
  bessActivated: true;
  bessType: BessType;
};

export type ACBessInverterDTO = {
  inverterId: string;
  inverterApparentRatedPower: number;
  electricalCosPhi: number;
};

export type ACBessDTO = {
  batteryActivated: boolean;
  batteryAreaAvailable: boolean;
  acBessCapacityDTO: {
    acBessCapacityMode: BessRequirementsType;
    numberOfPcs: number;
    acBessHoursStepDTOList: IHoursStepDTO[];
    acBessRatedPower: number;
    acBessRatedPowerAtPoi: number;
  };
  acBessEquipmentDTO: {
    acBessContainerDTO: {
      acBessContainerCapacityDefinition: 'CONTAINER' | 'RACK';
      acBessContainerDimensionsDTO: IDimensions;
      capacityPerContainer: number;
      capacityPerRack: number;
      racksPerContainer: number;
    };
    acBessPcsDTO: {
      acBessInverterDTO: ACBessInverterDTO;
      acBessTransformerDTO: {
        transformerPcsCopperLoss: number;
        transformerPcsEnabled: boolean;
        transformerPcsIronLoss: number;
      };
      apparentPowerPcs: number;
      numberOfInvertersPerPcs: number;
      pcsDimensionsDto: IDimensions;
    };
  };
  acBessLayoutDTO: {
    containerDistance: number;
    pcsDistance: number;
    acBessRotationDTO: BatteryRotationDTO;
  };
  acBessFinancialDTO: {
    bessOpexDTO: {
      opexPerCapacity: number;
      opexPerPower: number;
      opexPerArea: number;
      escalationFactor: ValueMinMax;
    };
    energyPriceIdRef: string;
    energyPriceEscalationRate: ValueMinMax;
    usePvEnergyPrices: boolean;
  };
  acBessDispatchDTO: {
    bessCyclingDTO: BessCyclingDTO;
    bessEfficiencyDTO: BessEfficiencyDTO;
    bessDegradationDTO: BessDegradationDTO;
    chargeFromGrid: boolean;
    dispatchEnabled: boolean;
  };
};

interface BessCyclingDTO {
  initialStateOfCharge: IMinMax;
  minimumStateOfCharge: IMinMax;
  maximumStateOfCharge: IMinMax;
  dailyCycles: IMinMax;
}

interface BessEfficiencyDTO {
  roundTripEfficiency: IMinMax;
}

interface BessDegradationDTO {
  yearlyDegradation: IMinMax;
  endOfLifeStateOfHealth: IMinMax;
}

export type DCBessDTO = {
  dcBessActivated: boolean;
  dcBessCapacityDTO: {
    cRate: number;
    dcBessCapacityPref: BessRequirementsType;
    dcBessRatedPower: number;
    dcBessSupplyHoursDTO: IHoursStepDTO[];
    numOfContainersPerPs: number;
    numberOfPsWithStorage: number;
  };
  dcBessContainerDTO: {
    batteryContainerCapacityDefinition: 'CONTAINER' | 'RACK';
    capacityPerContainer: number;
    capacityPerRack: number;
    racksPerContainer: number;
  };
  dcBessConverterDTO: {
    bessAcRatio: number;
    converterPower: number;
    converterPowerPerInverter: number;
    converterPowerPerPs: number;
    numConvertersPerInv: number;
  };
  dcBessLayoutDTO: {
    dcBessContainerDimensionsDTO: IDimensions;
    dcBessSetbacksDTO: {
      distanceBetweenContainers: IMinMax;
      distanceContainerToPowerStation: IMinMax;
      distanceContainerToRoad: IMinMax;
      distanceContainerToStructure: IMinMax;
    };
  };
  dcBessFinancialDTO: {
    dcBessOpexDTO: {
      opexPerCapacity: number;
      opexPerPower: number;
      escalationFactor: IMinMax;
    };
  };
  dcBessDispatchDTO: {
    bessCyclingDTO: BessCyclingDTO;
    bessEfficiencyDTO: {
      chargeEfficiency: IMinMax;
      dischargeEfficiency: IMinMax;
    };
    bessDegradationDTO: BessDegradationDTO;
    chargeFromGrid: boolean;
    dispatchEnabled: boolean;
  };
};

type TowerShapeType =
  | 'MV_SINGLE_FORK'
  | 'MV_DOUBLE_FORK'
  | 'HV_SINGLE_S'
  | 'HV_SINGLE_H'
  | 'HV_DOUBLE_T'
  | 'HV_DOUBLE_FORK';

export type CapacityOverestimationDTO = {
  capacityOverestimation: number;
};

export type EarthWireSelectionDTO = {
  earthWireIdRef: string;
};

export type NumberOfBundleSelectionDTO = {
  selectedNumberOfConductorsPerPhase: number;
  availableNumberOfConductorsPerPhase: number[];
};

export type NumberOfCircuitsSelectionDTO = {
  availableNumberOfCircuits: number[];
  selectedNumberOfCircuits: number;
};

export type PhaseConductorSelectionDTO = {
  phaseConductorAaacIdRef: string;
  phaseConductorAcsrIdRef: string;
  conductorTypeDto: 'ACSR' | 'AAAC';
};

export type TowerShapeDTO = {
  selectedTowerShape: string;
  availableTowers: TowerShapeType[];
};

export type VoltageDropDTO = {
  maximumVoltageDrop: number;
  minimumVoltageDrop: number;
  voltageDrop: number;
  estimatedVoltageDrop: number;
};

export type RightOfWayBufferDTO = {
  bufferWidth: number;
  recommendedBuffer: number;
  minBuffer: number;
  maxBuffer: number;
};

export type PollutionLevelDTO = {
  selectedPollutionLevel: PollutionLevel;
  availableLevels: PollutionLevel[];
};

export type PollutionLevel = {
  label: string;
  minCreepageDistance: number;
};

export type TargetSpanDTO = {
  targetSpan: number;
  recommendedTargetSpan: number;
  minTargetSpan: number;
  maxTargetSpan: number;
};

type ValueMinMax = {
  value: number;
  min: number;
  max: number;
};
export interface IPowerDistDcAcRatioDTO {
  dcAcRatioBounded: ValueMinMax;
  maxDcAcRatioBounded: ValueMinMax;
  minDcAcRatioBounded: ValueMinMax;
  maxStringsPerInverter: number;
  partialStructuresStringTolerance: number;
}

interface INumMultistructuresBetweenRoadsDTO {
  numMultistructuresBetweenHorizontalRoads: IMinMax;
  numMultistructuresBetweenVerticalRoads: IMinMax;
  distanceBetweenConsecutiveInnerRoadsDTO: {
    distanceBetweenHorizontalRoads: number;
    distanceBetweenVerticalRoads: number;
  };
}

export enum TransformerType {
  YEARLY = 'TWO_WINDING',
  MONTHLY = 'THREE_WINDING',
}

type PowerStationTransformers = {
  numPrimaryInverter: number;
  numSecondaryInverter: number;
  ratedPower: ValueMinMax;
  selectedTransformerType: TransformerType;
  transformerTypeList: TransformerType[];
};

export type PowerStation = {
  apparentPower: number;
  numPrimaryInverter: number;
  numSecondaryInverter: number | null;
  numTransformers: ValueMinMax;
  psId: number;
  shortCircuitImpedance: ValueMinMax;
  selectedByUser: boolean;
  transformers: PowerStationTransformers[];
};

interface IPowerStationSelectionDTO {
  defaultPowerStationDTO: PowerStation;
  nonDefaultPowerStationListDTO: PowerStation[];
}

type SoilingValues = {
  soilingValues: {
    [month: number]: number;
  };
};

export enum SoilingMode {
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY',
}

export enum IElectricalLayoutConfigurationType {
  ADAPTATIVE = 'ADAPTATIVE',
  REGULAR_BLOCKS = 'REGULAR_BLOCKS',
}

type PowerTransformerType = 'TWO_W' | 'THREE_W';
type AvailablePowerTransformerType = { transformerType: PowerTransformerType; enabled: boolean };
export enum PowerTransformerSizingMode {
  CAPACITY = 'POWER_TRANSFORMER_CAPACITY_DEFINITION',
  BAYS = 'INPUT_BAYS_DEFINITION',
}
export enum SubstationWarningType {
  MV_SHORT_CIRCUIT_EXCEEDED = 'MV_SHORT_CIRCUIT_EXCEEDED',
  MV_LINE_BIGGER_THAN_TRANSFORMER = 'MV_LINE_BIGGER_THAN_TRANSFORMER',
  SECONDARY_WINDING_EMPTY = 'SECONDARY_WINDING_EMPTY',
}

export type InputBaysDTO = {
  inputBaysLowerLimit: number;
  inputBaysRecommended: number;
  inputBaysUpperLimit: number;
  numberOfInputBays: number;
};

export type OutputBaysDTO = {
  isOutputBaysEnabled: boolean;
  numberOfOutputBays: number;
  outputBaysLowerLimit: number;
  outputBaysRecommended: number;
  outputBaysUpperLimit: number;
};

export type PowerTransformerTypeSelectionDTO = {
  availablePowerTransformerType: AvailablePowerTransformerType[];
  isPowerTransformerTypeEnabled: boolean;
  selectedPowerTransformerType: PowerTransformerType;
};

export type ShortCircuitImpedanceSelectionDto = {
  lowerLimitShortCircuitImpedance: number;
  selectedShortCircuitImpedance: number;
};

export type PowerTransformerCapacitySelectionDto = {
  availablePowerTransformerCapacities: number[];
  selectedPowerTransformerCapacity: number;
};

export type CapexSimulationInput = {
  templateId?: string;
  templateName?: string;
  templateEntries: IEntry[];
  templateCurrency?: string;
  templateCountryCode?: string | null;
};

export enum CosPhiSizingMode {
  MEET_COSINE_PHI_REQUIREMENT = 'MEET_COSINE_PHI_REQUIREMENT',
  IGNORE = 'IGNORE',
}

export enum CosPhiDeliveryPoint {
  SUBSTATION_INPUT = 'SUBSTATION_INPUT',
  SUBSTATION_OUTPUT = 'SUBSTATION_OUTPUT',
  DELIVERY = 'DELIVERY',
}

export enum ConnectionPointManualSubstationType {
  SINGLE_BUSBAR = 'SINGLE_BUSBAR_SUBSTATION',
  DOUBLE_BUSBAR = 'DOUBLE_BUSBAR_SUBSTATION',
  LINE_TO_TRANSFORMER = 'LINE_TO_TRANSFORMER_SUBSTATION',
}

export enum CivilRoadsConfigurationType {
  HORIZONTAL = 'HORIZONTAL',
  HORIZONTAL_PERIMETER = 'HORIZONTAL_PERIMETER',
  VERTICAL = 'VERTICAL',
  VERTICAL_PERIMETER = 'VERTICAL_PERIMETER',
  FULL_PERIMETER = 'FULL_PERIMETER',
  MINIMUM_PERIMETER = 'MINIMUM_PERIMETER',
  NO_ROADS = 'NO_ROADS',
}

export enum CivilLayoutStructurePositionConfig {
  STANDARD = 'STANDARD',
  ROTATED = 'ROTATED',
  OFFSET = 'OFFSET',
  TURNING_AXIS = 'TURNING_AXIS',
}

export enum PowerDistributionRequired {
  RATIO = 'RATIO',
  PEAK_POWER = 'PEAK_POWER',
}

export type MaxCapacityPowerDistDTO = {
  powerDistributionRequired: PowerDistributionRequired;
};

export enum AAFenceStrategy {
  FOLLOWING_AA = 'FOLLOWING_AA',
  MINIMIZE_LENGTH = 'MINIMIZE_LENGTH',
}

export enum RAFenceStrategy {
  PREVENT_CROSSING = 'PREVENT_CROSSING',
  ALLOW_CROSSING = 'ALLOW_CROSSING',
  KEEP_RA_INSIDE_PV_PLANT = 'KEEP_RA_INSIDE_PV_PLANT',
}

export enum RARoadsStrategy {
  MINIMIZE_CROSSING = 'MINIMIZE_CROSSING',
  ALLOW_CROSSING = 'ALLOW_CROSSING',
}

export type InputsPerAreaDTO = {
  areaId: string;
  civilDistanceConsecutiveStructures: number;
  civilLayoutStructurePositionConfig: CivilLayoutStructurePositionConfig;
  civilLayoutTurningAngle: number;
  civilPsConfigurationInside: boolean;
  civilPerimeterAdaptationMode: string; // TODO type enum
  civilRoadWidth: number;
  civilRoadsConfigurationType: CivilRoadsConfigurationType;
  civilRoadsConfigurationsBlocked: CivilRoadsConfigurationType[];
  civilRoadsConfigurationsInvisible: CivilRoadsConfigurationType[];
  numMultistructuresBetweenRoadsDTO?: INumMultistructuresBetweenRoadsDTO;
  electricalLayoutConfigurationType: IElectricalLayoutConfigurationType;
  electricalPowerReqMaximumCapacity: boolean;
  electricalPowerReqNumInverters1: number;
  electricalPowerReqNumInverters2: number;
  electricalSpecificCapacityPeakPowerEstimation: number;
  electricalSpecificCapacityRatedPowerEstimation: number;
  fenceStrategy: AAFenceStrategy;
  maxCapacityPowerDistDTO: MaxCapacityPowerDistDTO;
  plotAreaDescription: string;
  powerDistDcAcRatioDTO: IPowerDistDcAcRatioDTO;
  setbacksDTO: ISetbacksDTO;
  structurePostToPostDistance: number;
  structurePostToPostDistanceMinimum: number;
  structurePostToPostDistanceRecommended: number;
  plotAreaSize: string;
};

type InputsPerAreaDTOMap = {
  [key: string]: InputsPerAreaDTO;
};

type ShareLvArea = {
  name?: string | null;
  subAreas: string[];
  priorityDefined: boolean;
};

export type AreaShareLv = {
  areas: ShareLvArea[];
  priorityDefined: boolean;
};

export type AreasNameMap = {
  [key: string]: string;
};

type EnergyPriceDTO = {
  energyPricePerYear: number;
  escalationRate: IMinMax;
};

type OpexDetailedDTO = {
  opexPerMWac: number;
  opexPerHa: number;
  unitaryOpex: number;
};

export type Financial = {
  costPerYear: number;
  returnRate: number;
  opexEscalationRate: IMinMax;
  energyPriceDTO: EnergyPriceDTO;
  energyPriceInputMode: EnergyPriceInputModeType;
  opexDetailedDTO: OpexDetailedDTO;
};

export enum EnergyPriceInputModeType {
  FIXED = 'FIXED',
  VARIABLE = 'VARIABLE',
}

export enum CompensationStrategy {
  ONLY_INVERTERS = 'ONLY_INVERTERS',
  INVERTERS_PLUS_CAPACITORS = 'INVERTERS_PLUS_CAPACITORS',
}
export enum CompensationStrategySystems {
  PS_MV_LINES = 'PS_MV_LINES',
  MV_PATHS = 'MV_PATHS',
  ST = 'ST',
  ST_OHL = 'ST_OHL',
  POOLING_ST = 'POOLING_ST',
  POOLING_ST_OHL = 'POOLING_ST_OHL',
}
export enum CompensationStrategyMeasurePoints {
  PV = 'PV',
  SBS = 'SBS',
  ST_INPUT = 'ST_INPUT',
  ST_OUTPUT = 'ST_OUTPUT',
  POOLING_ST_INPUT = 'POOLING_ST_INPUT',
  POOLING_ST_OUTPUT = 'POOLING_ST_OUTPUT',
  GRID = 'GRID',
}
type CapacitorBankDto = {
  availableSystems: CompensationStrategySystems[];
  capacitorBankPoint: CompensationStrategyMeasurePoints;
  compensationStrategy: CompensationStrategy;
  measurementPoints: CompensationStrategyMeasurePoints[];
  pointOfInterconnection: CompensationStrategyMeasurePoints;
};

export enum DistributionStrategies {
  MINIMIZE_ELECTRICAL_MOMENTUM = 'MINIMIZE_ELECTRICAL_MOMENTUM',
  BALANCED_DISTRIBUTION = 'BALANCED_DISTRIBUTION',
  ONE_TO_ONE = 'ONE_TO_ONE',
}
export type CapacityDistributionStrategyDto = {
  availableStrategies: {
    [key in DistributionStrategies]: boolean;
  };
  selectedStrategy: DistributionStrategies;
};

export enum FlexibleSchemaLevelType {
  SBS = 'SBS',
  ST = 'ST',
}

type FlexibleSchemaLevelDto = {
  inVoltage: number;
  level: number;
  levelType: FlexibleSchemaLevelType;
  outVoltage: number;
};
type FlexibleSchemaVoltageLevelDto = {
  maxVoltage: number;
  recommendedVoltage: number;
  voltage: number;
};
type FlexibleSchemaLevelsDto = {
  levels: FlexibleSchemaLevelDto[];
  validSchema: boolean;
  voltageCompleteLevelsOrdered:
    | [FlexibleSchemaVoltageLevelDto, FlexibleSchemaVoltageLevelDto, FlexibleSchemaVoltageLevelDto]
    | [FlexibleSchemaVoltageLevelDto, FlexibleSchemaVoltageLevelDto]
    | [FlexibleSchemaVoltageLevelDto];
};

export type InputsPerOverheadLineDto = {
  capacityOverestimationDto: CapacityOverestimationDTO;
  earthWireSelectionDto: EarthWireSelectionDTO;
  electricalConfigurationErrors: string[];
  finalOhl: boolean;
  numberOfBundleSelectionDto: NumberOfBundleSelectionDTO;
  numberOfCircuitsSelectionDto: NumberOfCircuitsSelectionDTO;
  overheadLineInputsCorrect: boolean;
  phaseConductorSelectionDto: PhaseConductorSelectionDTO;
  towerShapeDto: TowerShapeDTO;
  voltageDropDto: VoltageDropDTO;
  ohlNumber: number;
  unused: boolean;
  rightOfWayBufferDto: RightOfWayBufferDTO;
  pollutionLevelDto: PollutionLevelDTO;
  targetSpanDto: TargetSpanDTO;
};

export type InputsPerFacilityDto = {
  connectionLineToTransformerEnabled: boolean;
  connectionPointEnableManualSubstationType: boolean;
  connectionPointManualSubstationType: ConnectionPointManualSubstationType;
  connectionPointSubstationArrangementType: 'AUTO_SUBSTATION' | 'BREAKING';
  connectionPointSwitchingBreakingEnabled: boolean;
  energyEnableTrafoSubstation: boolean;
  energyTrafoSubstationCopperLoss: number;
  energyTrafoSubstationIronLoss: number;
  inputBaysDto: InputBaysDTO;
  inputsCorrect: boolean;
  outputBaysDto: OutputBaysDTO;
  powerTransformerCapacitySelectionDto: PowerTransformerCapacitySelectionDto;
  powerTransformerSizingEnabled: boolean;
  powerTransformerSizingMode: PowerTransformerSizingMode;
  powerTransformerTypeSelectionDto: PowerTransformerTypeSelectionDTO;
  shortCircuitImpedanceSelectionDto: ShortCircuitImpedanceSelectionDto;
  substationWarning: string[];
  facilityNumber: number;
  pooling: boolean;
  unused: boolean;
};

export type InputsPerFacilities = {
  [facilityId: string]: InputsPerFacilityDto;
};

export type InputsPerOverheadLines = {
  [overheadLineId: string]: InputsPerOverheadLineDto;
};

export type OhlVoltageDropDto = {
  voltageDrop: number;
  maximumVoltageDrop: number;
  minimumVoltageDrop: number;
  estimatedVoltageDrop: number;
};

export type DcAcRatioPoiDTO = {
  dcAcPoiSpecificCapacity: number;
  dcAcRatioPoi: number;
  dcAcRatioPoiEnabled: boolean;
};

export type InputTemplateDto = {
  id: string;
  name: string;
  company: {
    name: string;
  };
  team?: {
    name: string;
  };
  createdBy: {
    id: string;
    name: string;
    surname: string;
  };
  lastModifiedBy: {
    name: string;
    surname: string;
  };
  publishedDate: string;
  modifiedDate: string;
  notes: string;
};

export type InputTemplate = {
  id: string;
  title: string;
  body: { label: string; value: string }[];
  notes: string;
  canEditDelete: boolean;
};

export type CrsEpsgCodeDto = {
  basicEpsgCodeInfo: BasicEpsgCodeInfo;
  distanceToSite: number;
};

type BasicEpsgCodeInfo = {
  id: string;
  epsgCode: string;
  name: string;
  description: string;
  unit: string;
  multiplier: number;
};

type ConvergenceAngles = {
  angleCRSToUTM: number;
  angleCRSToWSG84: number;
};

export type CoordinateReferenceSystemDTO = {
  selectedEpsgCode: CrsEpsgCodeDto | null;
  convergenceAnglesDto: ConvergenceAngles;
  crsConvergenceTooHighWarning: boolean;
  latSiteCenter: number;
  lonSiteCenter: number;
  originEpsgCode: string | null;
};

export interface IInputs {
  html: string;
  areaShareLv?: AreaShareLv;
  areasNameMap?: AreasNameMap;
  cableInputsDTO?: any;
  bessDTO?: BessDTO;
  acBessDTO?: ACBessDTO;
  dcBessDTO?: DCBessDTO;
  capexSimulationInput?: ICapexTemplateRecalculate;
  batteryDTO?: null;
  dcAcRatioPoiDTO?: DcAcRatioPoiDTO;
  civilRoadsConfigurationType?: CivilRoadsConfigurationType;
  civilRoadsConfigurationsBlocked?: CivilRoadsConfigurationType[];
  civilRoadsConfigurationsInvisible?: CivilRoadsConfigurationType[];
  numMultistructuresBetweenRoadsDTO?: INumMultistructuresBetweenRoadsDTO;
  companyTargetName?: string | null;
  civilLayoutStructurePositionConfig?: CivilLayoutStructurePositionConfig;
  topographyAnalysisDTO?: ITopographyAnalysisInputs;
  groupsLvInputsDTO?: ILvInputs;
  structureInputsDTO?: IStructuresInputs;
  setbacksDTO?: ISetbacksDTO;
  restrictedAreaTypesSetbacks?: RestrictedAreaTypesSetbacks;
  coordinateReferenceSystemDTO?: CoordinateReferenceSystemDTO;
  fenceStrategy?: AAFenceStrategy;
  electricalLvSideConfigurationType?: string;
  siteIdRef?: string;
  simulationDocumentLanguages?: string[];
  energyProbabilisticStudyDto?: IEnergyProbabilisticStudyDto;
  inputsPerAreaDTOMap?: InputsPerAreaDTOMap;
  inputsPerAreaDefinedGlobally?: boolean;
  stringPositionDTO?: { [key: string]: string };
  maxCapacityPowerDistDTO?: MaxCapacityPowerDistDTO;
  financial?: Financial;
  harnessInputsDTO?: IHarnessInputsDTO;
  moduleType?: 'MONOFACIAL' | 'BIFACIAL';
  energyLossSoiling?: number;
  energyPriceIdRef?: string;
  frontFaceSoilingMode?: SoilingMode;
  frontFaceSoilingMonthlyValues?: SoilingValues;
  energyLossBackFaceSoiling?: number;
  backFaceSoilingMode?: SoilingMode;
  backFaceSoilingMonthlyValues?: SoilingValues;
  electricalLayoutConfigurationType?: IElectricalLayoutConfigurationType;
  connectionPointManualSubstationType?: ConnectionPointManualSubstationType;
  connectionPointEnableManualSubstationType?: boolean;
  connectionLineToTransformerEnabled?: boolean;
  powerDistDcAcRatioDTO?: IPowerDistDcAcRatioDTO;
  powerStationSelectionDTO?: IPowerStationSelectionDTO;
  mvLinesCurrentLimitDTO?: {
    mvLinesCurrentLimitOptions: number[];
    selectedMvLinesCurrentLimit: number;
  };
  mvLineConfiguration?: MvLineConfiguration;
  interconnectionInputsDto?: {
    capacitorBankDto: CapacitorBankDto;
    capacityDistributionStrategyDto: CapacityDistributionStrategyDto;
    flexibleSchemaLevelsDto: FlexibleSchemaLevelsDto;
    recommendedHvLevel: number;
    inputsPerFacility: InputsPerFacilities;
    inputsPerOverheadLine: InputsPerOverheadLines;
    noOhlVoltageDropDto: OhlVoltageDropDto;
  };
  unitSystem?: UnitSystem;
  inverterCentralModel1IdRef?: string;
  inverterCentralModel1Power?: number;
  inverterCentralModel2Define?: boolean;
  inverterCentralModel2IdRef?: string;
  inverterCentralModel2Power?: number;
  inverterStringModelIdRef?: string;
  inverterStringModelPower?: number;
  powerStationNumInvStringMinPerPsDefault?: number;
  mpptsLvConfiguration?: MpptsLvConfiguration;
  electricalCosPhiInverters?: number;
  inverterType?: InverterType;
  [key: string]: Input;
}

export interface IUpdatedInputs {
  html?: string;
  [key: string]: Input;
}

interface IGoogleMaps {
  lat: number;
  lng: number;
  elements: any;
}

export interface IGenerateDesignForm {
  name: string;
  notes?: string;
  documentsSystemOfUnits: string;
}

export interface IupdateInputsWithAffectsPreview {
  html?: string;
  inputKey: string;
  newValue: any;
}

export interface IProjectInfo {
  project: {
    name: string;
    teamId?: string;
    company: {
      id: string;
      name: string;
    };
    createdBy: CreatedBy;
    lat?: number;
    lng?: number;
    maxDistance?: number;
    uploadedAnySite?: boolean;
  };
}

export type LoadInputTemplateWarnings = string[][];

export interface IDesignProcessInputs {
  inputs: IInputs;
  noSiteFoundError: boolean;
  showInProgressDesign: boolean;
  updateInputs: (
    inputsUpdated: IUpdatedInputs,
    options?: { refetch?: boolean; affectsPreview?: boolean }
  ) => Promise<void>;
  handleResetInputs: () => Promise<void | {
    [key: string]: any;
  }>;
  updateInputsWithAffectsPreview: (params: IupdateInputsWithAffectsPreview) => void;
  optimisticRemoveSite: (siteId: string) => void;
  projectInfo: IProjectInfo;
  projectId: string;
  generateDesign: (form: IGenerateDesignForm) => void;
  interactiveSiteCreation: {
    isCreating: boolean;
    siteId: string | null;
  };
  handleRemoveOldSimulation: () => void;
  goToISC: (isCreating: boolean, siteId?: string) => void;
  refetchProjectInfo: (
    variables?:
      | Partial<{
          id: string;
        }>
      | undefined
  ) => Promise<ApolloQueryResult<any>>;
  handleSwitchSimulation: (simulationId: string) => void;
  handleSwitchToResults: (simulationId: string) => void;
  loadInputTemplateWarnings: LoadInputTemplateWarnings;
  setLoadInputTemplateWarnings: React.Dispatch<React.SetStateAction<LoadInputTemplateWarnings>>;
}

export type ResultsPerAreaDto = {
  [key: string]: {
    ratedPower: number;
    peakPower: number;
    peakPowerMax: number;
    peakPowerRemaining: number;
    dcAcRatio: number | 'NaN';
  };
};

export interface IOutputs {
  estimatePowerBatterySummary: {
    batteryInstalledCapacity: number;
    batteryInstalledEnergy: number;
    batterySupplyDuration: number;
  };
  ratedPower: number;
  peakPower: number;
  peakPowerMax: number;
  peakPowerRemaining: number;
  id: string;
  resultsPerArea: ResultsPerAreaDto;
  ohlFeasibility: {
    isOhlFeasible: boolean;
    ohlFeasible: boolean;
  } | null;
  googleMaps: IGoogleMaps;
}

export enum SimulationStatus {
  NOT_SAVED = 'NOT_SAVED',
  SAVED = 'SAVED',
}

export enum MvLineConfiguration {
  RADIAL_LINE_DEFAULT = 'RADIAL_LINE_DEFAULT',
  RADIAL_LINE_SUBFEEDER_TWO = 'RADIAL_LINE_SUBFEEDER_TWO',
}

export enum SimulationStage {
  NA = 'NA',
  START = 'START',
  BATTERY = 'BATTERY',
  LAYOUT_GRID = 'LAYOUT_GRID',
  LAYOUT_FULL = 'LAYOUT_FULL',
  ENERGY = 'ENERGY',
  CALC_FINISH = 'CALC_FINISH',
  DOC_FINISHED = 'DOC_FINISHED',
  COMPLETED = 'COMPLETED',
}

export type OutputSimulationData = {
  name: string;
  ratedPower: number;
  peakPower: number;
  peakPowerMax: number;
  peakPowerRemaining: number;
  dcAcRatio: number;
};

export type InterconnectionOutputData = {
  substationResults: SubstationOutputData[];
  switchingBreakingStationResults: BreakingStationOutputData[];
  ohlResults: OhlSimulationData[];
  interconnectionResultsGeneral: {
    activePowerPointOfInterconnection: number;
    ratioDcAcPointOfInterconnection: number;
  };
};

export type ACBatteryEnergyOutputData = {
  chargedEnergyFromPv: number;
  consumedEnergyFromGrid: number;
  dischargedEnergyToGrid: number;
  totalDischargedEnergy: number;
  averageDailyCycles: number;
  batteryLifeSpan: number;
  recoveredCurtailment: number;
  averageDailyPriceSpread: number;
  recoveredCurtailmentCalculationResult: CalculationResult;
};

export type DCBatteryEnergyOutputData = {
  chargedEnergyFromPv: number;
  consumedEnergyFromGrid: number;
  dischargedEnergyToGrid: number;
  totalDischargedEnergy: number;
  averageDailyCycles: number;
  batteryLifeSpan: number;
  recoveredCurtailment: number;
  recoveredClipping: number;
  averageDailyPriceSpread: number;
  recoveredCurtailmentCalculationResult: CalculationResult;
  recoveredClippingCalculationResult: CalculationResult;
};

export type SubstationOutputData = {
  substationName: string;
  substationArrangement: string;
  numberTransformers: number;
  transformerType: string;
  biggestTransformerRatedPower: number;
};

export type BreakingStationOutputData = {
  switchingBreakingStationName: string;
  breakingStationCapacity: number;
  numberFeeders: number;
  mvCableCrossSection: number;
};

export type OhlSimulationData = {
  lineName: string;
  lineCapacity: number;
  lineLength: number;
  numTowers: number;
  voltageDrop: number;
  losses: number;
};

export type ResultOutputs = {
  peakPower: number;
  performanceRatio: number;
  ratedPower: number;
  ratioDcAc: number;
  specificPrice: number;
  pvSpecificPrice: number;
  bessSpecificPrice?: number;
  specificProduction: number;
  totalPrice: number;
  bessTotalPrice: number;
  yearZeroEnergyToGridAfterAvailability: number;
  activePowerAc: number;
  energyCapacity: number;
  operationHours: number;
  totalUnusedPeakPower: number;
  maximumPeakPower: number;
  interconnectionOutputData: InterconnectionOutputData;
  outputPlotAreaData: OutputSimulationData[];
  activePowerDc: number;
  energyCapacityDc: number;
  operationHoursDc: number;
  lcoe: number;
  discountedPayback: number;
  irr: number;
  npv: number;
  payback: number;
  roi: number;
  lcoeCalculationResult: CalculationResult;
  discountedPaybackCalculationResult: CalculationResult;
  irrCalculationResult: CalculationResult;
  paybackCalculationResult: CalculationResult;
  roiCalculationResult: CalculationResult;
  acDispatchOutputData: ACBatteryEnergyOutputData;
  dcDispatchOutputData: DCBatteryEnergyOutputData;
};

export type LayoutResults = {
  layoutGrid: boolean;
  layoutFull: boolean;
  layout3D: boolean;
};

export type ResultsPerArea = {
  [areaName: string]: {
    ratedPower: number;
    peakPower: number;
    peakPowerMax: number;
    peakPowerRemaining: number;
    dcAcRatio: number;
  };
};

export type IncrementalResults = {
  loadIncrementalResults: {
    id: string;
    name: string;
    primaryTag: SimulationPrimaryCharacteristicsTag;
    secondaryTag: SimulationSecondaryCharacteristicsTag;
    layoutEditorTag: LayoutEditorTag;
    stage: SimulationStage;
    results: ResultOutputs;
    layoutResults: LayoutResults;
    publishedDate: string;
    expireDocsDate: string;
    hideSimulationDate: string;
    version: string;
    completed: number;
    storeStatus: SimulationStatus;
    resultsPerArea: ResultsPerArea;
    errorMessage: string;
    error: boolean;
    outdated: boolean;
    number: number;
  };
};

export interface IDesignProcessOutputs {
  outputs: any;
  setOutputs: (value: any) => void;
  outputsOutdated: boolean;
  setOutputsOutdated: (value: boolean) => void;
  previewError: string | null;
  setPreviewError: React.Dispatch<React.SetStateAction<string | null>>;
  isPreviewLoading: boolean;
  setIsPreviewLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isGroupedAreasMode: boolean;
  setIsGroupedAreasMode: React.Dispatch<React.SetStateAction<boolean>>;
  incrementalResults?: IncrementalResults;
  incrementalResultsLoading: boolean;
  simulationId: any;
  topbar: {
    mapViewState: MAP_Views;
    setMapViewState: (mapView: MAP_Views) => void;
    mapViewRef: React.MutableRefObject<MAP_Views>;
  };
}
export interface IDesignProcessCapex {
  useCapexTemplateEditorObject: IUseCapexTemplateEditorDesignProcess;
  originalTemplate?: ICapexTemplate;
  isCapexReady: boolean;
}

export type EnergyPrices = {
  id: string;
  name: string;
  errorMessage: string | null;
  duration?: number;
  publishedDate?: string;
  executionDate?: string;
  status: GenericStatus;
  errorType: EnergyPriceErrorType;
  energyPriceSummary: EnergyPriceSummaryDTO;
  priceDefinitionType: PriceDefinitionType;
  priceSource: PriceSource;
  priceCategory: PriceCategoryType;
};

export type PriceDefinitionType = 'TABLE_12X24' | 'YEARLY_TABLE' | 'ALL_YEARS_TABLE';
export enum PriceSource {
  USER_UPLOADED = 'USER_UPLOADED',
  DEFAULT = 'DEFAULT',
}
export enum PriceCategoryType {
  BESS = 'BESS',
  PV = 'PV',
}

type EnergyPriceSummaryDTO = {
  maximumPrice: number;
  minimumPrice: number;
  averagePrice: number;
  averageDailySpread: number;
};

export type EnergyPriceErrorType =
  | 'OK'
  | 'IN_PROGRESS'
  | 'GENERIC'
  | 'IO_EXCEPTION'
  | 'NOT_A_CSV'
  | 'CSV_PARSE_ERRORS'
  | 'CSV_GENERIC'
  | 'CSV_MAXIMUM_FILE_SIZE_EXCEEDED';

export type MeteoData = {
  id: string;
  name: MeteoSources;
  publishedDate: string;
  executionDate: string;
  duration: number;
  status: string;
  errorType: string;
  errorMessage: any;
  originalAvailable: boolean;
  meteoDataSummary: MeteoDataSummary;
  meteoDataValues: IMonthlyEnergyProduction[];
};

type MeteoDataSummary = {
  source: string;
  sourceComment: string;
  timeShift: number;
  timeResolution: string;
  tempMinHistoric: number;
  tempMaxHistoric: number;
  tempAvgHistoric: number;
  yearGhiWh: number;
  yearDhiWh: number;
  yearBhiWh: number;
  tempMinHistoricAt10Wm2: number;
  tempMinHistoricAt50Wm2: number;
  tempMinHistoricAt100Wm2: number;
  tempMinHistoricAt200Wm2: number;
  formattedYearGhiKWh: number;
};

export type Site = {
  id: string;
  createdBy: CreatedBy;
  name: string;
  publishedDate: string;
  executionDate: string;
  duration: number;
  originalFileFormat: string;
  status: string;
  errorType: string;
  languagePack: string;
  errorComponents: any;
  siteSummary: SiteSummary;
  siteCountryInfo: SiteCountryInfo;
  formattedPublishedDateDay: string;
};

type CreatedBy = {
  id: string;
  email: string;
  name: string;
  surname: string;
  jobTitle?: string;
  status?: string;
  codeLanguage: string;
  interfaceUnits?: InterfaceUnits;
  publishedDate?: string;
  lastLogin?: string;
  mfaEnabled?: boolean;
  userCompanyId?: string;
};

type InterfaceUnits = {
  id: string;
  length: string;
  surface: string;
  temperature: string;
};

type SiteSummary = {
  latitudeSite: number;
  longitudeSite: number;
  convergenceDeg: number;
  utmZone: string;
  epsgCode: number;
  areaNet: number;
  areaNet_ha: number;
};

type SiteCountryInfo = {
  country: string;
  countryCode2: string;
  region: string;
  city: string;
  timeZone: number;
};

// TODO: finish typing this if possible
export interface IDesignProcessData {
  site: Site[] | [];
  topography: TopographyData[] | [];
  meteo: MeteoData[] | [];
  horizon: any[];
  albedo: any[];
  energyPrices: EnergyPrices[] | [];
}

export interface TopographyData {
  id: string;
  name: string;
  createdBy: CreatedBy;
  publishedDate: string;
  executionDate: string;
  duration: number;
  status: string;
  errorType: string;
  errorMessage: string;
  elevationSummary: ElevationSummary;
  elevationPoints?: ElevationPoint[];
  elevationSlopePoints?: ElevationSlopePoint[];
}

export interface ElevationSummary {
  id: string;
  elevationMaxSite: number;
  elevationMinSite: number;
  elevationSite: number;
  slopeXAvgSite: number;
  slopeXMaxSite: number;
  slopeYAvgSite: number;
  slopeYMaxSite: number;
  source: string;
  maxEastingUTM: number;
  minEastingUTM: number;
  maxNorthingUTM: number;
  minNorthingUTM: number;
  latitudeElevation: number;
  longitudeElevation: number;
  elevationSite_Alert: string;
  slopeX_Alert: string;
  slopeY_Alert: string;
}

export interface ElevationPoint {
  elevation: number;
  latitude: number;
  longitude: number;
}

export interface ElevationSlopePoint {
  latitude: number;
  longitude: number;
  slope: number;
  type: string;
  arrowLatitude1: number;
  arrowLongitude1: number;
  arrowLatitude2: number;
  arrowLongitude2: number;
  arrowEasting1: number;
  arrowNorthing1: number;
  arrowEasting2: number;
  arrowNorthing2: number;
}

export interface IDesignProcessMeteoSecretData {
  name: string;
  needSecret: boolean;
  isAutomatic: boolean;
  hasBeenDeleted: boolean;
}

export type EpsgCode = {
  id: string;
  name: string;
  epsgCode: string;
  description: string;
};

export enum UploadCadFileCodeError {
  GENERIC = 'GENERIC',
  ODA_GENERIC = 'ODA_GENERIC',
  ODA_DISABLED = 'ODA_DISABLED',
}

export type UploadCadFileError = {
  file: string;
  error: UploadCadFileCodeError;
  message: string;
};

export type UploadCadFileResponse = {
  cadFileToGeoJSON: {
    geoJson: string;
    summary: { [layerId: string]: number };
    errors: UploadCadFileError[];
  };
};

export interface TopographyData {
  id: string;
  name: string;
  createdBy: CreatedBy;
  publishedDate: string;
  executionDate: string;
  duration: number;
  status: string;
  errorType: string;
  errorMessage: string;
  elevationSummary: ElevationSummary;
  elevationPoints?: ElevationPoint[];
  elevationSlopePoints?: ElevationSlopePoint[];
}

export interface ElevationSummary {
  id: string;
  elevationMaxSite: number;
  elevationMinSite: number;
  elevationSite: number;
  slopeXAvgSite: number;
  slopeXMaxSite: number;
  slopeYAvgSite: number;
  slopeYMaxSite: number;
  source: string;
  maxEastingUTM: number;
  minEastingUTM: number;
  maxNorthingUTM: number;
  minNorthingUTM: number;
  latitudeElevation: number;
  longitudeElevation: number;
  elevationSite_Alert: string;
  slopeX_Alert: string;
  slopeY_Alert: string;
}

export interface ElevationPoint {
  elevation: number;
  latitude: number;
  longitude: number;
}

export interface ElevationSlopePoint {
  latitude: number;
  longitude: number;
  slope: number;
  type: string;
  arrowLatitude1: number;
  arrowLongitude1: number;
  arrowLatitude2: number;
  arrowLongitude2: number;
  arrowEasting1: number;
  arrowNorthing1: number;
  arrowEasting2: number;
  arrowNorthing2: number;
}

export enum MpptsLvConfiguration {
  SINGLE_MPPT = 'SINGLE_MPPT',
  MULTI_MPPT = 'MULTI_MPPT',
  EACH_SIDE_TO_DIFFERENT_INVERTER = 'EACH_SIDE_TO_DIFFERENT_INVERTER',
}
