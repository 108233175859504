import React, { useState } from 'react';

import { useTheme } from 'styled-components';
import * as S from './validations-display.styled';
import {
  PrimaryActionTypes,
  useGetRectangleElement,
  useModes,
  useOnSelectMultipleRectanglesById,
  useValidationErrorStatus,
} from '../../../ilc-store';
import { Tabs, Typography, Notification } from '@ratedpower/components';
import { combineErrorsByType } from '../utils';
import { WarningNoFillSvg } from 'assets/icons';
import { IlcValidationError, SelectedElement } from '../../../ilc-types';
import { useTranslation } from 'react-i18next';
import { ErrorCodeGroup } from '../error-code-group';
import { CameraControls } from '@react-three/drei';
import { flyToArea } from '../../../ilc-utils/camera';

interface IValidationsDisplay {
  validationErrors: IlcValidationError[];
  areasWithError: { id: string; label: string }[];
  selectedTab: string;
  setSelectedTab: (tab: 'BLOCKER' | 'WARNING') => void;
  selectedAreaTab: string;
  setSelectedAreaTab: (tab: string) => void;
  controls: typeof CameraControls | undefined;
}

export function ValidationsDisplay({
  validationErrors,
  areasWithError,
  selectedTab,
  setSelectedTab,
  selectedAreaTab,
  setSelectedAreaTab,
  controls,
}: Readonly<IValidationsDisplay>) {
  const theme = useTheme();
  const { t } = useTranslation('ilc');

  const [expandedCodes, setExpandedCodes] = useState<IlcValidationError['code'][]>([]);
  const onSelectMultipleRectanglesById = useOnSelectMultipleRectanglesById();
  const action = useModes().mode;
  const selectedRectangles = action.type === PrimaryActionTypes.SELECT_RECTANGLES ? action.payload : [];
  const selectedRectanglesIds = selectedRectangles.map((rect) => rect.id);
  const validationErrorStatus = useValidationErrorStatus();
  const getRectangleElement = useGetRectangleElement();

  const blockerErrors = validationErrors.filter((error) => error.result === 'BLOCKER');
  const warningErrors = validationErrors.filter((error) => error.result === 'WARNING');

  const tabOptions = [
    {
      id: 'BLOCKER',
      label: t('validation-errors.blocker', { value: blockerErrors.length }),
      Icon: WarningNoFillSvg,
      iconColor: theme.v2.error.icon.default,
      disabled: blockerErrors.length === 0,
    },
    {
      id: 'WARNING',
      label: t('validation-errors.warning', { value: warningErrors.length }),
      Icon: WarningNoFillSvg,
      iconColor: theme.v2.warning.icon.default,
      disabled: warningErrors.length === 0,
    },
  ];

  const filteredErrors =
    selectedAreaTab === 'all'
      ? validationErrors.filter((error) => error.result === selectedTab)
      : validationErrors.filter((error) => error.areaId === selectedAreaTab && error.result === selectedTab);

  const combinedErrorsByType = combineErrorsByType(filteredErrors);

  const checkAreAllErrorsSelected = (errorIds: string[]): boolean => {
    return errorIds.every((id) => selectedRectanglesIds.includes(id));
  };

  const handleCheckboxChange = (errorIds: string[], type: IlcValidationError['type']) => {
    if (type === 'ROADS') return;
    const allAreSelected = checkAreAllErrorsSelected(errorIds);
    if (allAreSelected) {
      onSelectMultipleRectanglesById(errorIds);
      return;
    }
    const missingIds = errorIds.filter((id) => !selectedRectanglesIds.includes(id));
    onSelectMultipleRectanglesById(missingIds);
  };

  function getNotificationMessage(type: IlcValidationError['type']) {
    switch (type) {
      case 'ROADS': {
        return t('validations-instructions.roads');
      }
      case 'SETBACKS': {
        return t('validations-instructions.setbacks');
      }
      case 'STRUCTURES': {
        return t('validations-instructions.structures');
      }
      case 'POWER_STATIONS': {
        return t('validations-instructions.power-stations');
      }
    }
  }

  const handleClickItemTarget = (e, error: IlcValidationError) => {
    e.stopPropagation();
    const rectangleElement = getRectangleElement(error.id);
    if (!rectangleElement) return;
    flyToArea([rectangleElement.centroid], controls);
  };

  const handleClickGroupTarget = (e, errors: IlcValidationError[]) => {
    e.stopPropagation();
    const elements = errors.map((error) => getRectangleElement(error.id));
    const validElements = elements.reduce<SelectedElement[]>((acc, element) => {
      if (element !== null) {
        acc.push(element);
      }
      return acc;
    }, []);
    if (elements.length > 0) {
      const centerPoints = validElements.map((element) => element.centroid);
      flyToArea(centerPoints, controls);
    }
  };

  const handleToggleExpand = (code: IlcValidationError['code']) => {
    if (expandedCodes.includes(code)) {
      setExpandedCodes(expandedCodes.filter((c) => c !== code));
    } else {
      setExpandedCodes([...expandedCodes, code]);
    }
  };

  const areaTabOptions = [...areasWithError, { id: 'all', label: 'All' }];

  return (
    <S.ValidationErrorsDisplayBox>
      {validationErrorStatus === 'requires-update' && (
        <S.ValidationsWarningWrapper>
          <Notification
            title={t('validation-errors.validations-not-updated-title')}
            description={t('validation-errors.validations-not-updated-description')}
            status="warning"
            heightFitContent={true}
          />
        </S.ValidationsWarningWrapper>
      )}
      <S.TabsWrapper>
        <Tabs
          tabs={tabOptions}
          tabActive={selectedTab}
          onClickTab={(tabId) => setSelectedTab(tabId as 'BLOCKER' | 'WARNING')}
        />
      </S.TabsWrapper>
      <Tabs tabs={areaTabOptions} tabActive={selectedAreaTab} onClickTab={(tabId) => setSelectedAreaTab(tabId)} wrap />
      <S.ValidationErrorsDisplayContent>
        {combinedErrorsByType.map(({ type, combinedErrors }) => {
          return (
            <S.ValidationErrorsTypes key={type}>
              <Notification description={getNotificationMessage(type)} status="informative" hideIcon={true} />
              <Typography size="s" weight="bold">
                {t(`validation-errors.${type}`)}
              </Typography>
              <S.ValidationErrorsList>
                {combinedErrors.map(({ code, count, errors }) => {
                  const errorIds = errors.map((err) => err.id);
                  return (
                    <ErrorCodeGroup
                      key={code}
                      code={code}
                      expandedCodes={expandedCodes}
                      type={type}
                      errorIds={errorIds}
                      selectedTab={selectedTab}
                      count={count}
                      errors={errors}
                      selectedRectanglesIds={selectedRectanglesIds}
                      handleToggleExpand={handleToggleExpand}
                      handleCheckboxChange={handleCheckboxChange}
                      checkAreAllErrorsSelected={checkAreAllErrorsSelected}
                      handleClickGroupTarget={handleClickGroupTarget}
                      handleClickItemTarget={handleClickItemTarget}
                    />
                  );
                })}
              </S.ValidationErrorsList>
              <S.Separator />
            </S.ValidationErrorsTypes>
          );
        })}
      </S.ValidationErrorsDisplayContent>
    </S.ValidationErrorsDisplayBox>
  );
}
