import React, { useState } from 'react';
import { RPTable } from '@ratedpower/components';
import TopbarTeamContent from './topbar-team-content';
import { TeamContentContainer } from './team-management.styled';
import { useListTeamMembers } from '../hooks/use-list-team-members';
import UsersTabFiltersPanel from 'pages/users-management/users-tab/users-tab-filters-panel/users-tab-filters-panel';
import { IServerFilter } from 'types/filter';
import UserTeamsAssignedModal from '../components/user-teams-assigned-modal';
import UnassignMemberModal from '../components/unsasign-member-modal';
import { useTranslation } from 'react-i18next';
import { USERS_PER_PAGE } from 'utils/constants/pagination';
import { useLocation, useParams } from 'react-router-dom';
import QueryStatusWrapper from 'components/query-status-wrapper/query-status-wrapper';
import { EmptyState } from 'components/empty-state/empty-state';

const TeamMembers: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { teamId } = useParams<{ teamId: string }>();
  const [filterPanelOpened, setFilterPanelOpened] = useState(false);

  const routeState = location.state as { teamName: string };

  const handleApplyFilters = (filters: IServerFilter[]) => {
    setFilterPanelOpened(false);
    setFilters(filters);
  };

  const handleOpenFiltersPanel = (open: boolean) => () => setFilterPanelOpened(open);

  const {
    table,
    error,
    inputSearchProps,
    teamsAssignedModal,
    unassignMemberModal,
    paginationComponent,
    setFilters,
    loading,
    isEmptyData,
  } = useListTeamMembers({
    showCurrentTeamMembers: true,
    teamId: teamId ?? '',
    canUnassign: true,
    pageSize: USERS_PER_PAGE,
  });

  return (
    <TeamContentContainer>
      <TopbarTeamContent onSearch={inputSearchProps.onChange} onOpenFiltersPanel={handleOpenFiltersPanel(true)} />
      <UsersTabFiltersPanel
        id="team-members-filters"
        active={filterPanelOpened}
        onHide={handleOpenFiltersPanel(false)}
        onApplyFilters={handleApplyFilters}
      />
      <QueryStatusWrapper loading={loading} error={error}>
        <>
          {isEmptyData && !loading ? (
            <EmptyState
              title={t('user:teams.no-members-assigned')}
              subtitle={t('user:teams.no-members-assigned-description')}
            />
          ) : (
            <>
              {table && !error && (
                <RPTable table={table as any} zeroResultsFoundText={t('common:zero-results-found')} />
              )}
              {paginationComponent}
            </>
          )}
        </>
      </QueryStatusWrapper>
      {teamsAssignedModal.isOpen && (
        <UserTeamsAssignedModal
          title={t('user:teams.teams-assigned')}
          isActive={teamsAssignedModal.isOpen}
          userId={teamsAssignedModal.data}
          onClose={() => teamsAssignedModal.setModalOpen(false)}
        />
      )}
      {unassignMemberModal?.isOpen && (
        <UnassignMemberModal
          selectedTeamId={teamId ?? ''}
          description={t('user:teams.unassign-member-modal-description', {
            name: unassignMemberModal.data.userName,
            team: routeState?.teamName,
          })}
          selectedUserId={unassignMemberModal.data.userId}
          title={t('user:teams.remove-member-from-team')}
          isActive={unassignMemberModal?.isOpen}
          onClose={() => unassignMemberModal.setModalOpen(false)}
        />
      )}
    </TeamContentContainer>
  );
};

export default TeamMembers;
