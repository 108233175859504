import { useCustomQuery } from 'utils/hooks/use-custom-query';
import { useMemo, useEffect } from 'react';
import { GET_DESIGNS_FOR_PROJECT_HOME } from 'services/designs-gql';
import { GET_LAST_PROJECTS_WITH_FAVOURITES_DESIGNS_BY_USER } from 'services/projects-gql';
import { useApolloClient } from '@apollo/client';
import { DesignsDisplayQueryFilter, IBessDesign, IDesign } from 'types/design';
import { useDesignsActions } from 'pages/designs/use-designs-actions';
import { designCardDimensions } from 'pages/home/home-designs/home-project-item/home-projects-designs/home-design-item-content';

export const useHomeProjectsDesigns = (projectId: string, isFavorite: boolean, containerWidth: number | null) => {
  const numCards = containerWidth ? Math.floor(containerWidth / designCardDimensions.width) : -1;
  const favoriteFilter = isFavorite ? DesignsDisplayQueryFilter.FAVOURITE : DesignsDisplayQueryFilter.PRIVATE;

  const {
    data: simulations,
    loading,
    error,
    paginationComponent,
    refetch,
  } = useCustomQuery(
    GET_DESIGNS_FOR_PROJECT_HOME,
    {
      variables: {
        pagination: {
          page: 0,
          size: numCards,
        },
        projectId,
        sorting: [
          {
            property: 'publishedDate',
            direction: 'DESC',
          },
        ],
        filters: [
          {
            field: favoriteFilter,
            operator: 'IN',
            values: [],
          },
          {
            field: 'STORE_STATUS',
            operator: 'IN',
            values: ['SAVED'],
          },
        ],
      },
      skip: numCards === -1,
      fetchPolicy: 'network-only',
    },
    false,
    true
  );

  const designActions = useDesignsActions(refetch, projectId);

  const designs = useMemo(() => {
    if (simulations?.designsHomePage) {
      const newData: IDesign[] | IBessDesign[] = simulations?.designsHomePage.content
        .filter((item: any) => !!item)
        .map((item: any) => {
          return { ...item, active: false };
        });
      return newData;
    } else {
      return [];
    }
  }, [simulations?.designsHomePage]);

  const apolloClient = useApolloClient();

  useEffect(() => {
    const designs = simulations?.designsHomePage;
    if (designs?.content?.length === 0 && designs?.pageInfo?.pageNumber === 0 && isFavorite) {
      apolloClient.refetchQueries({
        include: [GET_LAST_PROJECTS_WITH_FAVOURITES_DESIGNS_BY_USER],
      });
    }
  }, [simulations?.designsHomePage]);

  return {
    data: designs,
    loading,
    error,
    paginationComponent,
    designActions,
  };
};
