import React, { useEffect, useState } from 'react';
import { InputText, Checkbox, LabelWrapper, Typography, Button } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { useCompanyActivityState } from '../company-activity-state';
import { useMutation } from '@apollo/client';
import { GET_SUPPORT_COMPANY, UPDATE_SAML_CONFIGURATION } from 'services/support-gql';
import { useToasts } from 'utils/hooks/use-toasts';
import { CompanyFormSection, EditButton, FormActions, FormSectionHeadline } from './company-info-tab.styled';

const CompanySamlForm: React.FC = () => {
  const { t } = useTranslation();
  const { addSuccessToast } = useToasts();
  const { contextCompany, companyId } = useCompanyActivityState();
  const [updateSamlConfiguration] = useMutation(UPDATE_SAML_CONFIGURATION, {
    refetchQueries: [GET_SUPPORT_COMPANY],
    onCompleted: (data) => {
      if (data) {
        setEdition(false);
        addSuccessToast(t('support:update-company-success'));
      }
    },
  });

  const [edition, setEdition] = useState(false);
  const [federatedLoginChecked, setFederatedLoginChecked] = useState(contextCompany?.haveFederatedLogin || false);
  const [externalProviderName, setExternalProviderName] = useState(contextCompany?.externalProviderName ?? '');

  function handleSaveSAMLInputs() {
    updateSamlConfiguration({
      variables: {
        companyUpdateFederatedLoginDTO: {
          id: companyId,
          externalProviderName,
          federatedLoginEnabled: federatedLoginChecked,
        },
      },
    });
  }

  useEffect(() => {
    setFederatedLoginChecked(!!contextCompany?.haveFederatedLogin);
  }, [contextCompany]);

  return (
    <CompanyFormSection>
      <FormSectionHeadline>
        <Typography size="l" weight="bold">
          SAML
        </Typography>
        <EditButton variant="link" textUnderlined text={t('action:edit')} onClick={() => setEdition(true)} />
      </FormSectionHeadline>
      <LabelWrapper title={t('company:external-provider-name')} size="s" alignItems="center">
        <InputText
          name="externalProviderName"
          value={externalProviderName}
          dataTest="external-provider-name"
          onChange={(newString) => setExternalProviderName(newString)}
          readOnly={!edition}
          size="s"
        />
      </LabelWrapper>
      <LabelWrapper title={t('company:enable-federated-login')} size="s" alignItems="center">
        <Checkbox
          name="federatedLoginEnabled"
          checked={federatedLoginChecked}
          onChange={() => setFederatedLoginChecked((newValue) => !newValue)}
          disabled={!edition}
        />
      </LabelWrapper>
      {edition && (
        <FormActions>
          <Button variant="ghost" text={t('action:discard')} onClick={() => setEdition(false)} fullWidth />
          <Button variant="accent" text={t('action:save')} onClick={handleSaveSAMLInputs} fullWidth />
        </FormActions>
      )}
    </CompanyFormSection>
  );
};

export default CompanySamlForm;
