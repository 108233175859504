import React from 'react';
import Can from 'access-control/can';
import { Link } from 'react-router-dom';
import { Badge, ConditionalWrapper, Separator, SVGWrapper, Tooltip, Typography } from '@ratedpower/components';
import { Permission } from 'access-control/permissions';
import { ListItem } from './sidebar-list-item.styled';
import { useTranslation } from 'react-i18next';
import { IndexIds } from 'index.ids';

interface IProps {
  perform?: Permission | Permission[];
  pathTo: string;
  isPathActive: (path: any) => boolean;
  handleClickItem: (path: any) => void;
  sidebarCollapsed: boolean;
  hasSeparator?: boolean;
  description: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  isNew?: boolean;
  dataTest?: string;
}

export const SidebarListItemContent: React.FC<Omit<IProps, 'perform'>> = ({
  pathTo,
  isPathActive,
  handleClickItem,
  sidebarCollapsed,
  description,
  hasSeparator,
  Icon,
  isNew = false,
  dataTest,
}) => {
  const { t } = useTranslation();
  const active = isPathActive(pathTo);

  return (
    <>
      <Tooltip
        description={sidebarCollapsed ? description : sidebarCollapsed}
        className={IndexIds.Homepage.SideBarIds.Icon}
      >
        <Link to={pathTo}>
          <ListItem
            data-testid={dataTest}
            $active={active}
            onClick={() => handleClickItem(pathTo)}
            $sidebarCollapsed={sidebarCollapsed}
          >
            <ConditionalWrapper
              condition={!sidebarCollapsed && isNew}
              wrapper={(children) => (
                <Badge size="m" text={t('new')}>
                  {children}
                </Badge>
              )}
            >
              <SVGWrapper icon={Icon} size="m" badgeProps={sidebarCollapsed && isNew ? { size: 'xs' } : undefined} />
              {!sidebarCollapsed && (
                <Typography category="display" size="m" weight={active ? 'bold' : 'regular'} color="inherit">
                  {description}
                </Typography>
              )}
            </ConditionalWrapper>
          </ListItem>
        </Link>
      </Tooltip>
      {hasSeparator && <Separator />}
    </>
  );
};

const SidebarListItem: React.FC<IProps> = ({ perform, ...rest }) => {
  if (perform) {
    return (
      <Can perform={perform}>
        <SidebarListItemContent {...rest} />
      </Can>
    );
  }

  return <SidebarListItemContent {...rest} />;
};

export default SidebarListItem;
