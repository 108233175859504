import React, { useState } from 'react';
import * as S from './top-toolbar.styled';
import { ChevronDownSvg } from 'assets/icons';
import { Button, Menu, Tabs } from '@ratedpower/components';
import {
  PrimaryActionTypes,
  SecondaryActionTypes,
  TopToolbarTab,
  useAddLineBetweenNodesMode,
  useAddMultipleStructures,
  useAddNodeToClosestMode,
  useAddNodeToExistingNodeMode,
  useAddStructuresMode,
  useAlignSelectedStructureWithSetbacks,
  useAlignUniformly,
  useCallValidation,
  useDesignInputs,
  useModes,
  useMoveMultipleElements,
  useOpenModal,
  useRemoveElements,
  useResetMode,
  useSelectReferenceElementForRoadsMode,
  useSetMode,
  useSetSecondaryMode,
  useSetTopToolbarTab,
  useTopToolbarTab,
  useAlignSelectedStructureWithRoads,
} from '../../ilc-store';
import { StructureMovementInputs } from '../structure-movement-inputs';
import { useTranslation } from 'react-i18next';
import { track } from 'rudderstack/utils';
import { LayoutEditorTopToolbarActions, RudderstackEvent } from 'rudderstack/types';
import { filterStructuresAndOffset, getPerpendicularAngle } from '../../ilc-utils/geometry';
import { AlignToLineOption } from '../../ilc-types';

function TopToolbar() {
  const { t } = useTranslation('ilc');
  const topToolBar = useTopToolbarTab();
  const setTopToolBar = useSetTopToolbarTab();
  const addStructuresMode = useAddStructuresMode();
  const setSecondaryMode = useSetSecondaryMode();
  const action = useModes().mode;
  const resetActions = useResetMode();
  const removeElements = useRemoveElements();
  const callValidation = useCallValidation();
  const moveMultipleElements = useMoveMultipleElements();
  const setMode = useSetMode();
  const openModal = useOpenModal();
  const designInputs = useDesignInputs();
  const someSelectedStructures =
    action.type === PrimaryActionTypes.SELECT_RECTANGLES && action.payload.some((item) => item.type === 'STRUCTURES');
  const someSelectedPowerStations =
    action.type === PrimaryActionTypes.SELECT_RECTANGLES &&
    action.payload.some((item) => item.type === 'POWER_STATIONS');
  const selectedPowerStations =
    action.type === PrimaryActionTypes.SELECT_RECTANGLES &&
    action.payload.every((item) => item.type === 'POWER_STATIONS')
      ? action.payload
      : null;
  const structureAngle = selectedPowerStations
    ? designInputs?.areaParametricInputs[selectedPowerStations[0].areaKey].structureAngle
    : null;

  type MenuState = {
    anchorEl: HTMLElement | null;
    menu:
      | 'add-structure'
      | 'add-road-vertex'
      | 'remove-road-vertex'
      | 'align-with-setbacks'
      | 'rotate-power-station'
      | null;
  };

  const [menuState, setMenuState] = useState<MenuState>({ anchorEl: null, menu: null });
  const handleCloseMenu = () => setMenuState({ menu: null, anchorEl: null });

  const handleOpenMenu = (e: React.MouseEvent, menu: MenuState['menu']) =>
    setMenuState({
      anchorEl: e.currentTarget as HTMLElement,
      menu,
    });

  const addNodeToClosestMode = useAddNodeToClosestMode();
  const addNodeToOtherNodeMode = useAddNodeToExistingNodeMode();
  const addLineBetweenNodesMode = useAddLineBetweenNodesMode();
  const alignSelectedStructureWithSetbacks = useAlignSelectedStructureWithSetbacks();
  const alignSelectedStructureWithRoads = useAlignSelectedStructureWithRoads();
  const selectReferenceElementForRoadsMode = useSelectReferenceElementForRoadsMode();
  const alignUniformly = useAlignUniformly();
  const addMultipleStructures = useAddMultipleStructures();

  const deleteElements = async () => {
    await removeElements();
    resetActions();
    callValidation();
  };

  const tabOptions = [
    { id: 'structures', label: t('tabs.structures') },
    { id: 'roads', label: t('tabs.roads') },
    { id: 'power-stations', label: t('tabs.power-stations') },
    { id: 'all', label: t('tabs.manage-multiple') },
  ];

  const roadVertexOptions = [
    {
      label: t('actions.connect-vertices'),
      onClick: () => addLineBetweenNodesMode(null),
    },
    {
      label: t('actions.to-closest-vertex'),
      onClick: () => addNodeToClosestMode(),
    },
    {
      label: t('actions.to-other-vertex'),
      onClick: () => addNodeToOtherNodeMode(null),
    },
  ];

  const removeRoadVertexOptions = [
    {
      label: t('actions.keep-connection'),
      onClick: () => setMode(PrimaryActionTypes.DELETE_NODE_AND_KEEP_CONNECTIONS),
    },
    {
      label: t('actions.remove-connection'),
      onClick: () => setMode(PrimaryActionTypes.DELETE_NODE_AND_CONNECTING_EDGES),
    },
  ];

  const designInputsAvailable = designInputs !== null;

  const addStructureOptions = designInputsAvailable
    ? designInputs.structureParametricInputs.map((parametricInput) => {
        return {
          label: `Length: ${parametricInput.length}, Width: ${parametricInput.width}, Strings: ${parametricInput.strings}`,
          onClick: () => addStructuresMode({ selectedStructureOption: parametricInput }),
        };
      })
    : [];

  const getEventFromTab = () => {
    switch (topToolBar) {
      case 'structures':
        return RudderstackEvent.PV_EDITED_TOPBAR_STRUCTURES;
      case 'roads':
        return RudderstackEvent.PV_EDITED_TOPBAR_ROADS;
      case 'power-stations':
        return RudderstackEvent.PV_EDITED_TOPBAR_POWERSTATIONS;
      case 'all':
        return RudderstackEvent.PV_EDITED_TOPBAR_MANAGE_ALL;
    }
  };

  const rudderStackEvent = getEventFromTab();

  const handleAlignToSetbacks = (alignmentOption: AlignToLineOption) => {
    track(rudderStackEvent, {
      action: LayoutEditorTopToolbarActions.ALIGN_TO_SETBACKS,
      alignmentOption,
    });
    alignSelectedStructureWithSetbacks(alignmentOption);
  };

  const handleAlignToRoads = (alignmentOption: AlignToLineOption) => {
    track(rudderStackEvent, {
      action: LayoutEditorTopToolbarActions.ALIGN_TO_ROADS,
      alignmentOption,
    });
    alignSelectedStructureWithRoads(alignmentOption);
  };

  const alignToLineOptions = [
    {
      label: t('actions.align-to-setbacks'),
      items: [
        {
          label: t('actions.align-in-parallel'),
          onClick: () => handleAlignToSetbacks('snap-in-group'),
        },
        {
          label: t('actions.align-closest'),
          onClick: () => handleAlignToSetbacks('snap-to-row'),
        },
      ],
    },
    {
      label: t('actions.align-to-road'),
      items: [
        {
          label: t('actions.align-in-parallel'),
          onClick: () => handleAlignToRoads('snap-in-group'),
        },
        {
          label: t('actions.align-closest'),
          onClick: () => handleAlignToRoads('snap-to-row'),
        },
      ],
    },
  ];

  const powerStationRotateOptions = [
    {
      label: t('actions.parallel-to-structure'),
      onClick: async () => {
        if (!selectedPowerStations || !structureAngle) return;
        const updatedPowerStations = selectedPowerStations.map((ps) => {
          return {
            ...ps,
            angle: structureAngle,
          };
        });
        await moveMultipleElements(updatedPowerStations);
        resetActions();
      },
    },
    {
      label: t('actions.perpendicular-to-structure'),
      onClick: async () => {
        if (!selectedPowerStations || !structureAngle) return;
        const updatedPowerStations = selectedPowerStations.map((ps) => {
          return {
            ...ps,
            angle: getPerpendicularAngle(structureAngle),
          };
        });
        await moveMultipleElements(updatedPowerStations);
        resetActions();
      },
    },
    {
      label: t('actions.custom-angle'),
      onClick: () => {
        if (!selectedPowerStations || !structureAngle) return;
        openModal({ modalName: 'custom-ps-rotation', data: selectedPowerStations });
      },
    },
  ];

  const handleDuplicateStructures = () => {
    if (someSelectedStructures) {
      const structuresToCopy = action.payload;
      const { newStructures, originalIds } = filterStructuresAndOffset(structuresToCopy);
      addMultipleStructures(newStructures, originalIds);
    }
  };

  const getMenuOptions = () => {
    switch (menuState.menu) {
      case 'add-structure':
        return addStructureOptions;
      case 'align-with-setbacks':
        return alignToLineOptions;
      case 'add-road-vertex':
        return roadVertexOptions;
      case 'remove-road-vertex':
        return removeRoadVertexOptions;
      case 'rotate-power-station':
        return powerStationRotateOptions;
      default:
        return [];
    }
  };

  return (
    <S.TopToolbarWrapper id="layout-editor-top-toolbar">
      <Menu onClose={handleCloseMenu} anchorEl={menuState.anchorEl} items={getMenuOptions()} />
      <S.TopToolbarTabRow>
        <Tabs onClickTab={(tab) => setTopToolBar(tab as TopToolbarTab)} tabs={tabOptions} tabActive={topToolBar} />
      </S.TopToolbarTabRow>
      <S.TopToolbarButtonsRow>
        {topToolBar === 'structures' ? (
          <>
            <Button
              text={t('actions.add')}
              variant="ghost"
              Icon={ChevronDownSvg}
              iconPosition="right"
              onClick={(e) => {
                track(RudderstackEvent.PV_EDITED_TOPBAR_STRUCTURES, {
                  action: LayoutEditorTopToolbarActions.ADD,
                });
                handleOpenMenu(e, 'add-structure');
              }}
            />
            <S.Divider />
            <Button
              text={t('actions.duplicate')}
              variant="ghost"
              onClick={() => {
                track(RudderstackEvent.PV_EDITED_TOPBAR_STRUCTURES, {
                  action: LayoutEditorTopToolbarActions.DUPLICATE,
                });
                handleDuplicateStructures();
              }}
              disabled={!someSelectedStructures}
            />
            <S.Divider />

            <Button
              text={t('actions.align-to-line')}
              variant="ghost"
              Icon={ChevronDownSvg}
              iconPosition="right"
              disabled={!someSelectedStructures}
              onClick={(e) => {
                handleOpenMenu(e, 'align-with-setbacks');
              }}
            />
            <S.Divider />
            <Button
              text={t('actions.align-to-reference')}
              variant="ghost"
              onClick={() => {
                track(RudderstackEvent.PV_EDITED_TOPBAR_STRUCTURES, {
                  action: LayoutEditorTopToolbarActions.ALIGN_TO_REFERENCE,
                });
                setSecondaryMode({
                  type: SecondaryActionTypes.SELECT_REFERENCE,
                  payload: { element: null, referencePoint: null },
                });
              }}
              disabled={!someSelectedStructures}
            />
            <S.Divider />
            <Button
              text={t('actions.align-uniformly')}
              variant="ghost"
              disabled={!someSelectedStructures}
              onClick={() => {
                track(RudderstackEvent.PV_EDITED_TOPBAR_STRUCTURES, {
                  action: LayoutEditorTopToolbarActions.ALIGN_UNIFORMLY,
                });
                alignUniformly();
              }}
            />
            <S.Divider />
            <Button
              text={t('actions.edit-mv-group')}
              variant="ghost"
              onClick={() => {
                track(RudderstackEvent.PV_EDITED_TOPBAR_STRUCTURES, {
                  action: LayoutEditorTopToolbarActions.EDIT_MV_GROUP,
                });
                setSecondaryMode({ type: SecondaryActionTypes.SELECT_PS_FOR_STRUCTURES });
              }}
              disabled={!someSelectedStructures}
            />
            <S.Divider />
            <Button
              text={t('actions.delete')}
              variant="ghost"
              onClick={() => {
                track(RudderstackEvent.PV_EDITED_TOPBAR_STRUCTURES, {
                  action: LayoutEditorTopToolbarActions.DELETE,
                });
                deleteElements();
              }}
              disabled={!someSelectedStructures}
            />
            <S.Divider />
            <StructureMovementInputs disabled={!someSelectedStructures} rudderStackEvent={rudderStackEvent} />
          </>
        ) : topToolBar === 'roads' ? (
          <>
            <Button
              text={t('actions.draw-line')}
              variant="ghost"
              Icon={ChevronDownSvg}
              iconPosition="right"
              onClick={(e) => {
                track(RudderstackEvent.PV_EDITED_TOPBAR_ROADS, {
                  action: LayoutEditorTopToolbarActions.DRAW_LINE,
                });
                handleOpenMenu(e, 'add-road-vertex');
              }}
            />

            <S.Divider />

            <Button
              text={t('actions.delete-vertex')}
              variant="ghost"
              Icon={ChevronDownSvg}
              iconPosition="right"
              onClick={(e) => {
                track(RudderstackEvent.PV_EDITED_TOPBAR_ROADS, {
                  action: LayoutEditorTopToolbarActions.DELETE_VERTEX,
                });
                handleOpenMenu(e, 'remove-road-vertex');
              }}
            />

            <S.Divider />
            <Button
              text={t('actions.align-to-reference')}
              variant="ghost"
              onClick={() => {
                track(RudderstackEvent.PV_EDITED_TOPBAR_ROADS, {
                  action: LayoutEditorTopToolbarActions.ALIGN_TO_REFERENCE,
                });
                selectReferenceElementForRoadsMode(null);
              }}
            />
          </>
        ) : topToolBar === 'power-stations' ? (
          <>
            <Button
              text={t('actions.rotate')}
              variant="ghost"
              disabled={selectedPowerStations === null}
              Icon={ChevronDownSvg}
              iconPosition="right"
              onClick={(e) => {
                track(RudderstackEvent.PV_EDITED_TOPBAR_POWERSTATIONS, {
                  action: LayoutEditorTopToolbarActions.ROTATE,
                });
                handleOpenMenu(e, 'rotate-power-station');
              }}
            />
            <S.Divider />
            <Button
              text={t('actions.align-to-line')}
              variant="ghost"
              Icon={ChevronDownSvg}
              iconPosition="right"
              disabled={!someSelectedPowerStations}
              onClick={(e) => {
                handleOpenMenu(e, 'align-with-setbacks');
              }}
            />

            <S.Divider />
            <Button
              text={t('actions.align-to-reference')}
              variant="ghost"
              onClick={() => {
                track(RudderstackEvent.PV_EDITED_TOPBAR_POWERSTATIONS, {
                  action: LayoutEditorTopToolbarActions.ALIGN_TO_REFERENCE,
                });
                setSecondaryMode({
                  type: SecondaryActionTypes.SELECT_REFERENCE,
                  payload: { element: null, referencePoint: null },
                });
              }}
              disabled={!someSelectedPowerStations}
            />
            <S.Divider />
            <StructureMovementInputs disabled={!someSelectedPowerStations} rudderStackEvent={rudderStackEvent} />
          </>
        ) : (
          <>
            <Button
              text={t('actions.align-to-line')}
              variant="ghost"
              Icon={ChevronDownSvg}
              iconPosition="right"
              disabled={!someSelectedStructures}
              onClick={(e) => {
                handleOpenMenu(e, 'align-with-setbacks');
              }}
            />
            <S.Divider />
            <Button
              text={t('actions.align-uniformly')}
              variant="ghost"
              disabled={!someSelectedStructures}
              onClick={alignUniformly}
            />
            <S.Divider />
            <Button
              text={t('actions.align-to-reference')}
              variant="ghost"
              onClick={() =>
                setSecondaryMode({
                  type: SecondaryActionTypes.SELECT_REFERENCE,
                  payload: { element: null, referencePoint: null },
                })
              }
              disabled={!someSelectedStructures}
            />
            <S.Divider />
            <StructureMovementInputs disabled={!someSelectedStructures} rudderStackEvent={rudderStackEvent} />
          </>
        )}
      </S.TopToolbarButtonsRow>
    </S.TopToolbarWrapper>
  );
}

export { TopToolbar };
