import React from 'react';
import * as S from './projects-files-tab.styled';
import { useProjectsAndFiles } from './use-projects-files';
import { useTranslation } from 'react-i18next';
import { RatedPowerLoader, RPTable, Typography } from '@ratedpower/components';
import { useTheme } from 'styled-components';
import CreateProjectModal from 'pages/teams/components/create-project-modal';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data';
import { ContentTopBar } from 'components/content-top-bar';
import { Permission } from 'access-control/permissions';
import { useAuthorization } from 'access-control/can';
import ProjectsFilterPanel from 'components/filters/projects-filter-panel/projects-filter-panel';

const ProjectsFilesTab: React.FC = () => {
  const {
    inputSearchProps,
    newProjectFormModal,
    query: { error, loading, paginationComponent },
    table,
    projectFilterProps: { setFiltersPanelOpened, applyFilters, setServerFilters, filtersPanelOpened },
  } = useProjectsAndFiles();
  const { isAuthorized } = useAuthorization();

  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <S.PageContainer>
      <S.TopBarContainer>
        <S.TopbarSections>
          <Typography size="l" color={theme.gray_darker}>
            {t('support:all-projects')}
          </Typography>
        </S.TopbarSections>
        <ContentTopBar
          filterOnClick={() => setFiltersPanelOpened(true)}
          searchOnChange={inputSearchProps.onChange}
          hideFilterButton={!isAuthorized(Permission.TEAM_SUPPORT_READ)}
        />
      </S.TopBarContainer>
      <S.TableContainer>
        {loading ? (
          <RatedPowerLoader />
        ) : error ? (
          <ErrorRetrievingData />
        ) : (
          <>
            <RPTable table={table as any} zeroResultsFoundText={t('common:zero-results-found')} />
            {paginationComponent}
          </>
        )}
      </S.TableContainer>
      {isAuthorized(Permission.TEAM_SUPPORT_READ) && (
        <ProjectsFilterPanel
          isOpen={filtersPanelOpened}
          onApply={applyFilters}
          onClear={() => {
            setServerFilters([]);
            applyFilters();
          }}
          onClose={() => setFiltersPanelOpened(false)}
          showStatusFilter
        />
      )}
      {newProjectFormModal.isOpen && (
        <CreateProjectModal
          addPublicOption
          isSupport
          isActive={newProjectFormModal.isOpen}
          title={t('user:teams.create-new-project')}
          disableClickOutside
          onClose={() => newProjectFormModal.setModalOpen(false)}
        />
      )}
    </S.PageContainer>
  );
};

export default ProjectsFilesTab;
