import React, { useEffect, useMemo, useState } from 'react';
import * as S from './teams-selector.styled';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { ITeam } from 'types/teams';
import { ALL_TEAMS_OPTION, OTHER_TEAMS_OPTION, PUBLIC_TEAMS_OPTION } from 'utils/constants/users';
import { useTranslation } from 'react-i18next';
import { IOption } from 'types/common';
import { Dropdown } from '@ratedpower/components';

export interface IProps {
  teams: ITeam[];
  onChangeTeam: (teamId: string) => void;
  padding?: string;
  addPublicOption?: boolean;
  addOthersOption?: boolean;
  teamSelected?: string | null;
}

const TeamsSelector: React.FC<IProps> = ({
  teams = [],
  onChangeTeam,
  padding,
  addOthersOption,
  addPublicOption,
  teamSelected,
}) => {
  const { t } = useTranslation();
  const { isAuthorized } = useAuthorization();
  const [teamsOptions, setTeamsOptions] = useState<IOption[]>([]);
  const parsedTeamsOptions: IOption[] = useMemo(
    () => teams.map((team) => ({ label: team.name, value: team.id })),
    [teamsOptions]
  );

  useEffect(() => {
    const currentTeams = teams ? parsedTeamsOptions : [];
    setTeamsOptions([...getFirstTeamOptions(), ...currentTeams]);
  }, [teams]);

  function getFirstTeamOptions() {
    return [
      ALL_TEAMS_OPTION(t),
      ...(addOthersOption && isAuthorized(Permission.TEAM_READ_FROM_USER_TEAM) ? [OTHER_TEAMS_OPTION(t)] : []),
      ...(addPublicOption ? [PUBLIC_TEAMS_OPTION(t)] : []),
    ];
  }

  const companyHasTeams = !!teams?.length;

  const handleChangeTeam = (optionSelected: IOption[]) => {
    onChangeTeam(optionSelected[0].value);
  };

  const handleSearchTeam = (search: string) => {
    const filteredTeams = [...getFirstTeamOptions(), ...parsedTeamsOptions].filter((team) =>
      team.label.toLowerCase().includes(search.toLowerCase())
    );
    setTeamsOptions(filteredTeams);
  };
  const selectedOption = teamsOptions.find((option) => option.value === teamSelected) ?? ALL_TEAMS_OPTION(t);

  return (
    <S.TeamsContainer $visible={companyHasTeams} $padding={padding}>
      <Dropdown
        options={teamsOptions}
        onChange={handleChangeTeam}
        value={[selectedOption]}
        onSearch={handleSearchTeam}
      />
    </S.TeamsContainer>
  );
};

export default TeamsSelector;
