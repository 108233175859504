import { useEffect, useState } from 'react';
import { SelectedElement } from '../ilc-types';
import { useAddMultipleStructures } from '../ilc-store';
import { filterStructuresAndOffset } from '../ilc-utils/geometry';
import { ToastType, useToasts } from 'utils/hooks/use-toasts';

export function useClipboard(selectedElements: SelectedElement[]) {
  const [copiedRectangles, setCopiedRectangles] = useState<SelectedElement[]>([]);
  const addMultipleStructures = useAddMultipleStructures();
  const { addToast } = useToasts();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey || event.metaKey) {
        if (event.key === 'c' && selectedElements.length > 0) {
          setCopiedRectangles(selectedElements);
          addToast('top-center', 'Copied to clipboard', ToastType.DEFAULT, 2000);
        }
        if (event.key === 'v' && copiedRectangles.length > 0) {
          const { newStructures, originalIds } = filterStructuresAndOffset(copiedRectangles);
          addMultipleStructures(newStructures, originalIds);
          setCopiedRectangles([]);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [copiedRectangles, selectedElements]);

  return null;
}
