import React, { useRef } from 'react';
import ProjectsFilterPanel from 'components/filters/projects-filter-panel/projects-filter-panel';
import { RPTable } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import CreateProjectModal from '../components/create-project-modal';
import TopbarTeamContent from './topbar-team-content';
import { useListTeamProjects } from '../hooks/use-list-team-projects';
import UnassignProjectModal from '../components/unassign-project-modal';
import { TeamContentContainer } from './team-management.styled';
import { useTeamsProvider } from '../teams-data';
import { TEAM_PROJECTS_PAGE_SIZE } from 'utils/teams';
import { useLocation, useParams } from 'react-router-dom';
import QueryStatusWrapper from 'components/query-status-wrapper/query-status-wrapper';
import { EmptyState } from 'components/empty-state/empty-state';

const TeamProjects: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { createProjectModal } = useTeamsProvider();
  const selectedTeamId = useRef<string | undefined>('');
  const { id: companyId, teamId } = useParams<{ id: string; teamId: string }>();
  const routeState = location.state as { teamName: string };

  const {
    table,
    error,
    isEmptyData,
    inputSearchProps,
    projectFilterPanelProps: { applyFilters, filtersPanelOpened, setFiltersPanelOpened, setServerFilters },
    unassignProjectModal,
    paginationComponent,
    loading,
  } = useListTeamProjects(TEAM_PROJECTS_PAGE_SIZE, teamId);

  const handleCreateProjectModal = (open: boolean) => () => {
    selectedTeamId.current = open ? teamId : '';
    createProjectModal.setModalOpen(open);
  };

  return (
    <TeamContentContainer>
      <TopbarTeamContent onSearch={inputSearchProps.onChange} onOpenFiltersPanel={() => setFiltersPanelOpened(true)} />
      <QueryStatusWrapper loading={loading} error={error}>
        <>
          {isEmptyData && !loading ? (
            <EmptyState
              title={t('user:teams.empty-projects-description')}
              subtitle={t('user:teams.empty-projects-description-text')}
            />
          ) : (
            table && !error && <RPTable table={table as any} zeroResultsFoundText={t('common:zero-results-found')} />
          )}
        </>
      </QueryStatusWrapper>
      {paginationComponent}
      <ProjectsFilterPanel
        isOpen={filtersPanelOpened}
        onApply={applyFilters}
        onClear={() => {
          setServerFilters([]);
          applyFilters();
        }}
        onClose={() => setFiltersPanelOpened(false)}
        showTeamsFilter={false}
      />
      {createProjectModal.isOpen && (
        <CreateProjectModal
          addPublicOption={false}
          selectedTeamId={selectedTeamId.current ?? teamId}
          isActive={createProjectModal.isOpen}
          title={
            selectedTeamId.current
              ? t('user:teams.create-new-project-in', { team: routeState?.teamName })
              : t('user:teams.create-new-project')
          }
          isSupport={!!companyId}
          disableClickOutside
          onClose={handleCreateProjectModal(false)}
        />
      )}

      {unassignProjectModal?.isOpen && (
        <UnassignProjectModal
          selectedTeamId={teamId ?? ''}
          description={t('user:teams.unassign-project-modal-description', {
            project: unassignProjectModal.data.projectName,
            team: routeState?.teamName,
          })}
          selectedProjectId={unassignProjectModal.data.projectId}
          title={t('user:teams.remove-project-from-team')}
          isActive={unassignProjectModal?.isOpen}
          onClose={() => unassignProjectModal.setModalOpen(false)}
        />
      )}
    </TeamContentContainer>
  );
};

export default TeamProjects;
