import { gql } from '@apollo/client';

const fragments = {
  crs: gql`
    fragment Crs on CRSEpsgCodeDTO {
      basicEpsgCodeInfo {
        id
        epsgCode
        name
        description
        unit
        multiplier
      }
      distanceToSite
    }
  `,
  inputTemplate: gql`
    fragment InputTemplate on InputTemplateDTO {
      id
      name
      company {
        name
      }
      team {
        name
      }
      createdBy {
        id
        name
        surname
      }
      lastModifiedBy {
        name
        surname
      }
      publishedDate
      modifiedDate
      notes
    }
  `,
  iscResponse: gql`
    fragment ISCResponse on ISCResponse {
      geoJson
      errors {
        file
        error
        message
      }
    }
  `,
};

export const UPLOAD_HORIZON_FILE = gql`
  mutation UploadHorizonFile($form: HorizonUploadDTO!, $file: Upload!) {
    uploadHorizonFile(form: $form, file: $file) {
      id
    }
  }
`;

export const UPLOAD_ENERGY_PRICES_FILE = gql`
  mutation UploadEnergyPricesFile($file: Upload!, $form: EnergyPriceUploadDTO!) {
    uploadEnergyPriceFile(form: $form, file: $file) {
      id
    }
  }
`;

export const UPLOAD_ZIPPED_SHAPEFILE = gql`
  mutation ShapefileToGeoJSON($zip: Upload!) {
    result: shapefileToGeoJSON(zip: $zip) {
      ...ISCResponse
    }
  }
  ${fragments.iscResponse}
`;

export const SHAPEFILE_TO_GeoJSON_FROM_URI = gql`
  mutation ShapefileToGeoJSONFromUri($uri: String!) {
    result: shapefileToGeoJSONFromUri(uri: $uri) {
      ...ISCResponse
    }
  }
  ${fragments.iscResponse}
`;

export const CAD_FILE_TO_GEO_JSON = gql`
  mutation CadFileToGeoJSON($epsgCode: String!, $zip: Upload!) {
    cadFileToGeoJSON(epsgCode: $epsgCode, zip: $zip) {
      ...ISCResponse
      summary
    }
  }
  ${fragments.iscResponse}
`;

export const CAD_FILE_INFO = gql`
  query CadFileInfo($zip: Upload!) {
    cadFileInfo(zip: $zip) {
      epsgCode {
        id
        epsgCode
        name
        description
      }
      errors {
        file
        error
        message
      }
    }
  }
`;

export const SEARCH_EPSG_CODE = gql`
  query SearchEpsgCode($pagination: PaginationForm!, $sorting: [SortOrder]!, $search: String!) {
    result: searchEpsgCode(pagination: $pagination, sorting: $sorting, search: $search) {
      content {
        id
        epsgCode
        name
        description
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
`;

export const GET_SIMULATION_CURRENCY = gql`
  query SimulationCurrency($id: ID!) {
    design: simulation(id: $id) {
      outputs: outputsSimulation {
        capexResult: billOfQuantitiesResult {
          currency
        }
      }
    }
  }
`;

export const GET_CRS = gql`
  query FindByIdForCRS($id: ID!, $latSiteCenter: Float!, $lonSiteCenter: Float!) {
    findByIdForCRS(id: $id, latSiteCenter: $latSiteCenter, lonSiteCenter: $lonSiteCenter) {
      ...Crs
    }
  }
  ${fragments.crs}
`;

export const GET_CRS_LIST = gql`
  query FindCloserEpsgCodes(
    $pagination: PaginationForm!
    $filters: [EpsgCodeFilterEntry]
    $search: [String]
    $latSiteCenter: Float!
    $lonSiteCenter: Float!
    $originEpsgCode: Int!
  ) {
    result: findCloserEpsgCodes(
      pagination: $pagination
      filters: $filters
      search: $search
      latSiteCenter: $latSiteCenter
      lonSiteCenter: $lonSiteCenter
      originEpsgCode: $originEpsgCode
    ) {
      content {
        ...Crs
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
  ${fragments.crs}
`;

export const GET_INPUT_TEMPLATES = gql`
  query GetInputTemplates(
    $teamId: ID
    $companyId: ID!
    $search: [String]
    $filters: [InputTemplateFilterEntry]
    $isIncludedRatedPower: Boolean!
    $pagination: PaginationForm!
  ) {
    result: getInputTemplates(
      teamId: $teamId
      companyId: $companyId
      search: $search
      filters: $filters
      isIncludedRatedPower: $isIncludedRatedPower
      pagination: $pagination
    ) {
      content {
        ...InputTemplate
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
  ${fragments.inputTemplate}
`;

export const UPDATE_INPUT_TEMPLATE = gql`
  mutation UpdateInputTemplate($updateInputTemplateDTO: UpdateInputTemplateDTO!) {
    updateInputTemplate(updateInputTemplateDTO: $updateInputTemplateDTO) {
      ...InputTemplate
    }
  }
  ${fragments.inputTemplate}
`;

export const DELETE_INPUT_TEMPLATE = gql`
  mutation DeleteInputTemplate($inputTemplateId: ID!) {
    deleteInputTemplate(inputTemplateId: $inputTemplateId)
  }
`;

export const GET_INPUT_TEMPLATE_CREATORS = gql`
  query CompanyInputTemplateCreators($companyId: ID, $pagination: PaginationForm!, $search: String) {
    result: companyInputTemplateCreators(companyId: $companyId, pagination: $pagination, search: $search) {
      content {
        name
        id
      }
      pageInfo {
        pageNumber
        totalPages
      }
    }
  }
`;
