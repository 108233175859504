import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { GET_INPUT_TEMPLATES, UPDATE_INPUT_TEMPLATE } from 'services/design-process-gql';
import { useToasts } from 'utils/hooks/use-toasts';

const useUpdateInputTemplate = () => {
  const { t } = useTranslation();
  const { addSuccessToast, addErrorToast } = useToasts();

  const [updateInputTemplate, { loading }] = useMutation(UPDATE_INPUT_TEMPLATE, {
    refetchQueries: [GET_INPUT_TEMPLATES],
    onCompleted: (data) => {
      addSuccessToast(t('design:input-template-update-success', { inputTemplateName: data.updateInputTemplate.name }));
    },
    onError: () => {
      addErrorToast(t('errors:input-template-update-error'));
    },
  });

  return { updateInputTemplate, loading };
};

export { useUpdateInputTemplate };
