import styled from 'styled-components';

export const DesignItemDetailsContainer = styled.div`
  display: flex;
  padding: ${({ theme }) => `${theme.v2.spacing.m} ${theme.v2.spacing.xl}`};
  justify-content: space-between;

  .section-header {
    width: 100%;
    text-align: left;
    text-transform: capitalize;
  }
`;

export const DesignItemDetailsSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.v2.spacing.xxs};
`;

export const DesignItemDetailsRow = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.v2.spacing.xs};

  > * {
    min-width: 33%;
  }
`;
