import DateFilter from 'components/filters/date-filter/date-filter';
import UsersFilter from './users-filter';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxGroup, FilterSidebar, FilterSidebarItem } from '@ratedpower/components';
import { DateRange, IServerFilter } from 'types/filter';
import { IActivityTabFilters } from 'types/support.types';
import { activityTabInitialFilters, alertSeverityTitles, entityTypeTitles } from 'utils/constants/support';
import TeamsFilter from 'components/filters/teams-filter';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';

interface IProps {
  isOpened: boolean;
  onApply: (currentFilters: IServerFilter[]) => void;
  onClose: () => void;
}

const ActivityTabFiltersPanel: React.FC<IProps> = ({ isOpened, onApply, onClose }) => {
  const { t } = useTranslation();
  const { isAuthorized } = useAuthorization();
  const [currentFilters, setCurrentFilters] = useState<IActivityTabFilters>(activityTabInitialFilters);

  const handleApply = () => {
    const filters: IServerFilter[] = [];
    Object.entries(currentFilters).forEach(([key, values]) => {
      if (key === 'alertTypes') {
        filters.push({ field: 'ALERT_TYPE', operator: 'IN', values });
      }
      if (key === 'users' && values) {
        filters.push({ field: 'USER_ID', operator: 'IN', values });
      }
      if (key === 'entityTypes') {
        filters.push({ field: 'ENTITY_TYPE', operator: 'IN', values });
      }
      if (key === 'dateRange') {
        filters.push({ field: 'PUBLISHED_DATE', operator: 'GREATER_OR_EQUAL', values: [new Date(values.from)] });
        filters.push({ field: 'PUBLISHED_DATE', operator: 'LESS_OR_EQUAL', values: [new Date(values.to)] });
      }
      if (key === 'teamIds') {
        filters.push({ field: 'TEAM_ID', operator: 'IN', values });
      }
    });
    onApply(filters);
  };

  const onToggleFilter = (element: string, checked: boolean, objectKey: keyof IActivityTabFilters) => {
    if (checked) {
      setCurrentFilters({
        ...currentFilters,
        [objectKey]: [...currentFilters[objectKey], element],
      });
      return;
    }
    const index = currentFilters[objectKey].findIndex((current: string) => current === element);
    currentFilters[objectKey].splice(index, 1);
    setCurrentFilters({
      ...currentFilters,
      [objectKey]: [...currentFilters[objectKey]],
    });
  };

  const onClear = () => {
    setCurrentFilters(activityTabInitialFilters);
  };

  return (
    <FilterSidebar
      onApply={handleApply}
      onClose={onClose}
      onClear={onClear}
      isOpen={isOpened}
      onApplyTitle={t('action:apply')}
      onClearTitle={t('action:clear')}
      sidebarTitle={t('filters.title', 'Filters')}
    >
      <FilterSidebarItem title={t('support:filters.type-of-alert')} hasBorderBottom>
        <CheckboxGroup
          direction="column"
          id="type-of-alerts"
          onChange={(newOption) => {
            onToggleFilter(newOption.value, newOption.checked, 'alertTypes');
          }}
          options={Object.entries(alertSeverityTitles).map(([typeOfAlert, title]) => ({
            checked: currentFilters.alertTypes.includes(typeOfAlert),
            label: t(title),
            value: typeOfAlert,
          }))}
        />
      </FilterSidebarItem>
      <FilterSidebarItem title={t('filters.time')} hasBorderBottom>
        <DateFilter
          onChangeDateRange={(newDateRange: DateRange) => {
            setCurrentFilters({
              ...currentFilters,
              dateRange: {
                from: newDateRange.from,
                to: newDateRange.to,
              },
            });
          }}
          currentDateRange={currentFilters.dateRange}
        />
      </FilterSidebarItem>
      <FilterSidebarItem title={t('support:filters.entity-type')} hasBorderBottom>
        <CheckboxGroup
          direction="column"
          id="entity-types"
          onChange={(newOption) => {
            onToggleFilter(newOption.value, newOption.checked, 'entityTypes');
          }}
          options={Object.entries(entityTypeTitles).map(([typeOfEntity, title]) => ({
            checked: currentFilters.entityTypes.includes(typeOfEntity),
            label: t(title),
            value: typeOfEntity,
          }))}
        />
      </FilterSidebarItem>
      {isAuthorized(Permission.TEAM_SUPPORT_READ) && (
        <TeamsFilter
          teamsChecked={currentFilters.teamIds}
          onChangeTeams={(newOption) => {
            onToggleFilter(newOption.value, newOption.checked, 'teamIds');
          }}
        />
      )}
      {isAuthorized(Permission.USER_SUPPORT_READ) && (
        <FilterSidebarItem title={t('company:users')} maxHeightContent="100%">
          <UsersFilter
            onChangeUsers={(users: string[]) => {
              setCurrentFilters({
                ...currentFilters,
                users,
              });
            }}
          />
        </FilterSidebarItem>
      )}
    </FilterSidebar>
  );
};

export default ActivityTabFiltersPanel;
