import React from 'react';
import * as S from './designs-table.styled';
import {
  Button,
  Checkbox,
  ConditionalWrapper,
  EditableInput,
  IOption,
  ISort,
  PopupWrapper,
  SortableHeader,
  StatusSVGWrapper,
  Tooltip,
  Typography,
} from '@ratedpower/components';
import { createColumnHelper } from '@tanstack/react-table';
import { DESIGN_ACTIONS as action } from 'utils/constants/designs';
import { IBessDesign, IDesign, SimulationSecondaryCharacteristicsTag } from 'types/design';
import {
  FavouriteSvg,
  FavouriteOutlineSvg,
  PenSquareSvg,
  ChevronUpSvg,
  ChevronDownSvg,
  CloneSvg,
  DotsSvg,
  FolderExportSvg,
  FolderImportSvg,
  TrashSvg,
} from 'assets/icons';
import { useTranslation } from 'react-i18next';
import { useCanEditDesigns } from 'utils/hooks/use-can-edit-designs';
import { hasValidCharacters, PROJECT_DESIGN_UNALLOWED_CHARS } from 'utils/validation';
import { CustomTag, DesignColumn } from '../design-column.styled';
import {
  getAccessToIlcIsDisabled,
  getAccessToIlcMessage,
  getDesignIsILC,
  getPrimaryTagText,
  getSecondaryTagText,
  selectedPrimaryTagColor,
  selectedSecondaryTagColor,
} from 'utils/design-tags';
import { useHybridBessSku } from 'utils/hooks/sku/use-hybrid-bess-sku';
import { useIlcSku } from 'utils/hooks/sku/use-ilc-sku';
import { useStandaloneBessSku } from 'utils/hooks/sku/use-standalone-bess-sku';
import { useCanCloneDesigns } from 'utils/hooks/use-can-clone-designs';
import { isPvDesign } from 'utils/design-type-functions';
import { useConverter } from 'utils/conversor';
import { designAttributes } from 'utils/constants/design-attributes';
import { bessDesignAttributes } from 'utils/constants/bess-design-attributes';
import { getDynamicDate } from 'utils/date';
import { isSimulationInProgress } from 'utils/constants/status';
import { DESIGNS_STATUS } from 'utils/constants/tabs';
import { usePlanIsExpired } from 'utils/hooks/use-plan-is-expired';
import { SUPPORT_EMAIL } from 'utils/constants/contact';
import Skeleton from 'react-loading-skeleton';

interface IUseDesignTableColumnsOptions {
  rowsSelected: (IDesign | IBessDesign)[];
  onPerform: (action: string, design: IDesign | IBessDesign) => void;
  onEditDesignName: (id: string, newName: string) => void;
  sortBy: ISort;
  onSort: React.Dispatch<React.SetStateAction<ISort>>;
}

export const useDesignsTableColumns = (options: IUseDesignTableColumnsOptions) => {
  const columnHelper = createColumnHelper<IDesign | IBessDesign>();
  const { getValueOf } = useConverter();

  const { t } = useTranslation();
  const { canEditDesign } = useCanEditDesigns();
  const canCloneDesigns = useCanCloneDesigns();
  const planIsExpired = usePlanIsExpired();

  const { restrictStandaloneBessSku } = useStandaloneBessSku();
  const { restrictHybridBessSku } = useHybridBessSku();
  const { showIlcButtonByPrimaryTag, restrictIlcSku } = useIlcSku();

  const { rowsSelected, onPerform, onEditDesignName, sortBy, onSort } = options;

  const handleCheckBox = (design: IDesign | IBessDesign) => (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    onPerform(action.select, design);
  };

  const handleFavourite = (design: IDesign | IBessDesign) => (e: React.MouseEvent) => {
    e.stopPropagation();
    onPerform(action.favorite, design);
  };

  const handleGetHelp = (): void => {
    window.location.href = `mailto:${SUPPORT_EMAIL}`;
  };

  const handleIlc = (design: IDesign | IBessDesign) => (e: React.MouseEvent) => {
    e.stopPropagation();
    onPerform(action.ilc, design);
  };

  const handleClone = (design: IDesign | IBessDesign) => (e: React.MouseEvent) => {
    e.stopPropagation();
    onPerform(action.clone, design);
  };

  const designIsError = (design: IDesign | IBessDesign) =>
    design.simulationResult !== 'OK' && design.simulationResult !== 'IN_PROGRESS';

  const designIsInProgress = (design: IDesign | IBessDesign) => design.simulationResult === 'IN_PROGRESS';

  return [
    columnHelper.accessor('id', {
      header: '',
      cell: (info) => {
        const designId = info.row.original.id;
        const selected = rowsSelected.some((element) => element.id === designId);
        if (designIsInProgress(info.row.original)) {
          return <Skeleton />;
        }
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Checkbox id={designId} checked={selected} onChange={handleCheckBox(info.row.original)} />
          </div>
        );
      },
    }),
    columnHelper.accessor('isFavorite', {
      header: '',
      cell: (info) => {
        const isFavorite = info.getValue();
        if (designIsInProgress(info.row.original)) {
          return <Skeleton />;
        }
        return (
          <StatusSVGWrapper
            onClick={handleFavourite(info.row.original)}
            active={isFavorite}
            icon={isFavorite ? FavouriteSvg : FavouriteOutlineSvg}
            size="m"
          />
        );
      },
    }),
    columnHelper.accessor('name', {
      header: () => t('name'),
      cell: (info) => {
        const designName = info.getValue();
        const { number } = info.row.original;
        const canEditThisDesign = canEditDesign(info.row.original.id, info.row.original.projectTeamId);
        if (designIsInProgress(info.row.original)) {
          return <Skeleton />;
        }

        if (canEditThisDesign) {
          return (
            <S.DesignNameWrapper>
              <Typography>{number}.</Typography>
              <EditableInput
                validationFunction={hasValidCharacters(PROJECT_DESIGN_UNALLOWED_CHARS)}
                value={designName}
                onEditName={(newName: string) => onEditDesignName(info.row.original.id, newName)}
              />
            </S.DesignNameWrapper>
          );
        }
        return (
          <ConditionalWrapper
            condition={designName.length > 40}
            wrapper={(children) => <Tooltip description={designName}>{children}</Tooltip>}
          >
            <Typography nowrap>{designName}</Typography>
          </ConditionalWrapper>
        );
      },
    }),
    columnHelper.accessor('primaryTag', {
      header: t('design:design'),
      cell: (info) => {
        if (designIsInProgress(info.row.original)) {
          return <Skeleton />;
        }
        const design = info.row.original;
        const tagText: string = t(getPrimaryTagText(design));
        const isRestrictedIlc = restrictIlcSku(design) || !canCloneDesigns;
        const isRestrictedStandaloneBessDesign = restrictStandaloneBessSku(design);
        const isRestrictedHybridBessDesign = restrictHybridBessSku(design);
        const isDisabledColor = isRestrictedIlc || isRestrictedStandaloneBessDesign || isRestrictedHybridBessDesign;

        return <CustomTag color={selectedPrimaryTagColor(design, isDisabledColor)}>{tagText}</CustomTag>;
      },
    }),
    columnHelper.accessor('secondaryTag', {
      header: '',
      cell: (info) => {
        if (designIsInProgress(info.row.original)) {
          return <Skeleton />;
        }
        if (info.getValue() !== SimulationSecondaryCharacteristicsTag.EDITED) return <>-</>;
        const design = info.row.original;
        const secondaryTagText: string = t(getSecondaryTagText(design));
        const isRestrictedIlc = restrictIlcSku(design) || !canCloneDesigns;
        return <CustomTag color={selectedSecondaryTagColor(design, isRestrictedIlc)}>{secondaryTagText}</CustomTag>;
      },
    }),
    columnHelper.accessor('outputs.ratedPower', {
      header: t('design:power'),
      cell: (info) => {
        const design = info.row.original;
        if (designIsInProgress(design)) {
          return <Skeleton />;
        }
        if (designIsError(design)) return '-';
        return (
          <Typography>
            {isPvDesign(design)
              ? getValueOf(designAttributes.power.ratedPower, design)
              : getValueOf(bessDesignAttributes.bess.energyCapacity, design)}
          </Typography>
        );
      },
    }),
    columnHelper.accessor('outputs.peakPower', {
      header: '',
      cell: (info) => {
        const design = info.row.original;
        if (designIsInProgress(design)) {
          return <Skeleton />;
        }
        if (designIsError(design)) return '-';
        return (
          <Typography>
            {isPvDesign(design)
              ? getValueOf(designAttributes.power.peakPower, design)
              : getValueOf(bessDesignAttributes.bess.activePowerAc, design)}
          </Typography>
        );
      },
    }),
    columnHelper.accessor('outputs.specificProduction', {
      header: t('design:energy'),
      cell: (info) => {
        const design = info.row.original;
        if (designIsInProgress(design)) {
          return <Skeleton />;
        }
        if (designIsError(design)) return '-';
        return (
          <Typography>
            {isPvDesign(design) ? getValueOf(designAttributes.energy.specificProduction, design) : '-'}
          </Typography>
        );
      },
    }),
    columnHelper.accessor('outputs.gcr', {
      header: t('design-process:equipment-tab.gcr'),
      cell: (info) => {
        const design = info.row.original;
        if (designIsInProgress(design)) {
          return <Skeleton />;
        }
        if (designIsError(design)) return '-';
        return <Typography>{isPvDesign(design) ? getValueOf(designAttributes.equipment.gcr, design) : '-'}</Typography>;
      },
    }),
    columnHelper.accessor('publishedDate', {
      header: () => (
        <SortableHeader selector={{ label: t('date'), code: 'publishedDate' }} sortedBy={sortBy} onSort={onSort} />
      ),
      cell: (info) => {
        if (designIsInProgress(info.row.original)) {
          return <Skeleton />;
        }
        return (
          <Typography>
            {t('design:design-date', {
              dateFormat: getDynamicDate(info.getValue()),
            })}
          </Typography>
        );
      },
    }),
    columnHelper.accessor('createdBy', {
      header: '',
      cell: (info) => {
        const design = info.row.original;
        if (designIsInProgress(design)) {
          return <Skeleton />;
        }
        const isSimulationDesignInProgress: boolean = isSimulationInProgress(design.simulationResult);
        const designWithError: boolean = !isSimulationDesignInProgress && design.simulationResult !== 'OK';

        const designIsIlc = getDesignIsILC(design.secondaryTag);
        const isRestrictedStandaloneBessDesign = restrictStandaloneBessSku(design);
        const isRestrictedHybridBessDesign = restrictHybridBessSku(design);

        const disableClone =
          !canCloneDesigns ||
          isRestrictedStandaloneBessDesign ||
          isRestrictedHybridBessDesign ||
          designIsIlc ||
          planIsExpired;

        const showFavouriteOnly = isRestrictedStandaloneBessDesign || isRestrictedHybridBessDesign;
        const disableAccessToIlc = getAccessToIlcIsDisabled(design) || !canCloneDesigns;

        const getCloneTooltip = () => {
          if (designIsIlc) {
            return t('design:ilc-cannot-clone');
          }
          if (disableClone) {
            return t('action:readonly');
          }
          return t('action:clone');
        };

        const getArchiveOption = (status: string) => {
          return status === DESIGNS_STATUS.archived
            ? {
                labelIcon: FolderExportSvg,
                label: t('action:unarchive'),
                value: action.unarchive,
              }
            : {
                labelIcon: FolderImportSvg,
                label: t('action:archive'),
                value: action.archive,
              };
        };

        const archiveOption = getArchiveOption(design.status);
        const deleteOption = {
          labelIcon: TrashSvg,
          label: t('action:delete'),
          value: action.delete,
        };
        const favouriteOption = design.isFavorite
          ? {
              labelIcon: FavouriteOutlineSvg,
              label: t('action:unmark-favorite'),
              value: 'favorite',
              dataTest: 'unmark-favorite-btn',
            }
          : {
              labelIcon: FavouriteSvg,
              label: t('action:mark-favorite'),
              value: 'favorite',
              dataTest: 'favorite-btn',
            };

        const options: IOption[] = showFavouriteOnly
          ? [favouriteOption]
          : [archiveOption, deleteOption, favouriteOption];

        const canEditThisDesign = canEditDesign(design.createdBy.id, design.projectTeamId);
        const designShowDetails = info.row.getIsExpanded();

        const handleToggleExpand = (e: React.MouseEvent) => {
          e.stopPropagation();
          info.row.toggleExpanded(!designShowDetails);
        };

        return (
          <DesignColumn>
            {!designWithError ? (
              <StatusSVGWrapper
                icon={designShowDetails ? ChevronUpSvg : ChevronDownSvg}
                tooltip={{
                  text: t('action:show-detail'),
                  place: 'bottom',
                }}
                size="m"
                onClick={handleToggleExpand}
                active={false}
              />
            ) : (
              <Button onClick={handleGetHelp} text={t('design:get-help')} variant="link" textUnderlined />
            )}
            {showIlcButtonByPrimaryTag(design) && (
              <StatusSVGWrapper
                icon={PenSquareSvg}
                tooltip={{
                  text: t(getAccessToIlcMessage(design.layoutEditorTag)),
                  place: 'bottom',
                }}
                size="m"
                onClick={handleIlc(design)}
                active={!canCloneDesigns || planIsExpired}
                disabled={disableAccessToIlc}
              />
            )}
            <StatusSVGWrapper
              icon={CloneSvg}
              tooltip={{
                text: getCloneTooltip(),
                place: 'bottom',
              }}
              size="m"
              onClick={handleClone(design)}
              active={false}
              disabled={disableClone}
            />

            {canEditThisDesign && (
              <PopupWrapper
                popupPosition="leftCenter"
                onClickOption={(selectedOption: IOption) => onPerform(selectedOption.value, design)}
                options={options}
                dataTest="more-btn"
              >
                <StatusSVGWrapper
                  active={false}
                  icon={DotsSvg}
                  tooltip={{ text: t('action:more'), place: 'bottom' }}
                  size="m"
                />
              </PopupWrapper>
            )}
          </DesignColumn>
        );
      },
    }),
  ];
};
