import { ChartTypeRegistry } from 'chart.js';
import { DateRange } from './filter';
import { ICompanyActivityStatus } from 'utils/constants/simulations';
import { SIMULATION_CATEGORY, SIMULATION_TYPE } from './design';

export interface IChartResponse {
  chart: IChart;
}
export interface IChart {
  type: string;
  data: IChartData;
  options: IChartOptions;
  pageInfo?: { pageNumber: number; totalPages: number };
  tooltipMessage?: string;
}

export interface IChartData {
  labels: string[];
  datasets: IDataSet[];
  mapDatasets: IMapDataset[];
}

export interface IMapDataset {
  label: string;
  countryCodes: string[];
  color?: string;
}

export interface IDataSet {
  label: string;
  data: number[];
  yAxisID?: string;
  grouped?: boolean;
  type: keyof ChartTypeRegistry;
  fill?: boolean;
  backgroundColor?: string;
  borderColor?: string;
}

export interface ISimpleDataSet {
  data: number[];
  backgroundColor: string[];
}

export enum TimePeriod {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}
export interface IStatisticsFilters {
  companyIds: string[];
  companyCountries: string[];
  countries: string[];
  timePeriod?: TimePeriod;
  isIncludedRatedPower: boolean;
  dateRange: DateRange;
  simulationCategories: SIMULATION_CATEGORY[];
}
export interface IChartParameters {
  chartId: string;
  filters?: IStatisticsFilters;
}
export interface IChartOptions {
  indexAxis?: 'x' | 'y';
  scale?: IScale;
  scales: any;
  title?: string;
  plugins: any;
  responsive?: boolean;
  tooltipMessage?: string;
  maintainAspectRatio?: boolean;
}

interface IScale {
  xAxes: IXAxis[];
  yAxes: IYAxis[];
}

export interface IXAxis {
  id: string;
  title?: string;
  stacked?: boolean;
  stepSize?: number;
  max?: number;
}

export interface IYAxis {
  id: string;
  title: string;
  position: AxisPosition;
  stacked?: boolean;
}

export interface ITabSection {
  section: string;
  subsections: [ITabSubsection];
  availableCharts: IAvailableChart[];
}

export interface ITabSubsection {
  subsection: string;
  availableCharts: IAvailableChart[];
}

export interface ITabSummary {
  entries: ISummaryKeyValue[];
}

export interface ISummaryKeyValue {
  key: string;
  value: number;
  unit?: string;
  info?: string;
}

export interface IAvailableChart {
  chartId: string;
  size: 'SMALL' | 'MEDIUM' | 'LARGE';
}

export type AxisPosition = 'left' | 'right';
export type ChartType = 'bar' | 'line' | 'pie';

export interface IMapDataset {
  label: string;
  countryCodes: string[];
  visible?: boolean;
}

export interface IStatisticsCompanyDTO {
  id: string;
  name: string;
}
export interface IStatisticsProjectDesignDTO {
  name: string;
  id: string;
  status: string;
}
export interface IStatisticsProjectDTO {
  company: IStatisticsCompanyDTO;
  name: string;
  id: string;
  simulationCount: number;
  lastDesign?: IStatisticsProjectDesignDTO;
}

export interface IBasicListItem {
  id: string;
  projectCompanyName: string;
  projectName: string;
  projectId: string;
}

export interface ISimulationStatisticsListItem extends IBasicListItem {
  simulationType: SIMULATION_TYPE;
  name: string;
  companiesActivityStatus: ICompanyActivityStatus;
  allowedActions: string[];
  companiesActivitySimulationResult: string;
  publishedDate: string;
  duration: number;
  peakPower: number;
  siteNetArea: number;
  pr: number;
  siteId: string;
  siteName: string;
  siteFileFormat: string;
  simulationCategory: SIMULATION_CATEGORY;
}

export interface IProjectStatisticsListItem {
  company: { name: string };
  name: string;
  id: string;
  projectRatedPowerAvg: number;
  projectPeakPowerAvg: number;
  numDesigns: number;
  lastDesign: { name: string; id: string; status: string };
}

export interface IUserStatisticsListItem {
  companyName: string;
  email: string;
  nameSurname: string;
  lastLogin: string;
  status: string;
  codeLanguage: string;
  countProjects: number;
  countSites: number;
  countSitesWithError: number;
  countSimulations: number;
  countSimulationsWithError: number;
}

export interface IElevationStatisticsListItem extends IBasicListItem {
  name: string;
  status: string;
  errorType: string;
  publishedDate: string;
  duration: number;
}

export interface ISiteStatisticsListItem extends IElevationStatisticsListItem {
  fileFormat: string;
}

export interface IEstimatePowerStatisticsListItem extends IBasicListItem {
  status: string;
  publishedDate: string;
  duration: number;
  estimatePowerResult: string;
}

export interface IMeteoStatisticsListItem extends IBasicListItem {
  companyName: string;
  name: string;
  source: string;
  status: string;
  publishedDate: string;
  duration: number;
  errorType: string;
  originalAvailable: string;
}

export interface IErrorStatisticsListItem extends IBasicListItem {
  id: string;
  company: { id: string; name: string };
  createdBy: { email: string };
  publishedDate: string;
  action: string;
  errorCode: string;
  errorMessage: string;
}
