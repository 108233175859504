import styled from 'styled-components';

export const CompanyResults = styled.div`
  overflow-y: auto;
  height: 180px;
  padding-left: 5px;
  padding-top: 5px;
  max-height: 250px;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.gray_lighter};
`;

export const CompanyItem = styled.div`
  display: flex;
  font-size: 14px;
  margin-bottom: 8px;

  .company-name {
    cursor: pointer;
  }

  .checkbox-label {
    margin-right: 5px;

    &::after {
      height: 30px;
      width: 30px;
    }
  }
`;

export const Container = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;

  .clear {
    font-size: 14px;
    float: right;
    margin-top: 10px;
  }

  .topbar-input-search {
    margin-bottom: 15px;
  }
`;
