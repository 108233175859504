import React from 'react';
import * as S from './roles-tab.styled';
import { useUsersManagementContext } from '../users-management-data';
import PermissionList from './permission-list';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import TopbarRoles from './topbar-roles-tab';
import { usersManagementTabs } from 'utils/constants/users';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import QueryStatusWrapper from 'components/query-status-wrapper/query-status-wrapper';
import { RPTable } from '@ratedpower/components';
import { IRoleListItem } from 'types/roles';

const RolesTab: React.FC = () => {
  const { t } = useTranslation();
  const {
    useRolesObject: { loading, rolesError, table, currentPage, paginationComponent },
  } = useUsersManagementContext();

  const navigate = useNavigate();
  const { isAuthorized } = useAuthorization();
  const canSeePermissions = isAuthorized(Permission.PERMISSION_SUPPORT_READ);

  const navigateToTab = (tab: string) => {
    navigate(`/users-management/${tab.toLowerCase()}`);
  };

  const canCollapse = (role: IRoleListItem) => !role.editable;

  return (
    <S.RolesTabWrapper>
      <TopbarRoles activeTab={usersManagementTabs[2].id} onClickTab={navigateToTab} />
      <S.MainView>
        <QueryStatusWrapper loading={loading} error={rolesError}>
          <RPTable
            table={table as any}
            canCollapse={canCollapse}
            pageNumber={currentPage}
            collapsibleComponent={canSeePermissions ? <PermissionList /> : <></>}
            zeroResultsFoundText={t('common:zero-results-found')}
          />
          {paginationComponent}
        </QueryStatusWrapper>
      </S.MainView>
    </S.RolesTabWrapper>
  );
};

export default RolesTab;
