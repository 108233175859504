import React, { useState } from 'react';
import { InputNumber, Modal } from '@ratedpower/components';
import { CustomRotationModalState, useCloseModal, useMoveMultipleElements, useResetMode } from '../../ilc-store';
import { useTranslation } from 'react-i18next';
import { degreesToRadians } from '../../ilc-utils/geometry';

interface ICustomRotationModal {
  modalState: CustomRotationModalState;
}

const CustomRotationModal: React.FC<ICustomRotationModal> = ({ modalState }) => {
  const { t } = useTranslation('ilc');
  const [customAngle, setCustomAngle] = useState('0');
  const moveMultipleElements = useMoveMultipleElements();
  const resetActions = useResetMode();

  const closeModal = useCloseModal();

  const handleSave = async () => {
    if (customAngle) {
      const inRadians = degreesToRadians(parseFloat(customAngle));
      const updatedItems = modalState.data.map((item) => {
        return {
          ...item,
          angle: item.angle + inRadians,
        };
      });
      await moveMultipleElements(updatedItems);
      closeModal();
      resetActions();
    }
  };

  return (
    <Modal
      id="custom-rotation-modal"
      isActive
      title={t('actions.rotates-counter-clockwise')}
      size="s"
      onClose={closeModal}
      buttons={{
        primary: {
          text: t('actions.rotate'),
          onClick: () => {
            handleSave();
          },
          type: 'button',
        },
        secondary: {
          text: t('actions.cancel'),
          onClick: closeModal,
        },
      }}
    >
      <InputNumber
        suffix="deg"
        onChange={(value) => setCustomAngle(value)}
        size="s"
        value={customAngle}
        textAlign="right"
      />
    </Modal>
  );
};

export { CustomRotationModal };
