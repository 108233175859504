/* eslint-disable max-len */
import React from 'react';
import {
  DesignItemDetailsContainer,
  DesignItemDetailsRow,
  DesignItemDetailsSectionWrapper,
} from './design-item-details.styled';
import { IDesign } from 'types/design';
import { useConverter } from 'utils/conversor';
import { designAttributes } from 'utils/constants/design-attributes';
import { useTranslation } from 'react-i18next';
import { Typography } from '@ratedpower/components';

interface IProps {
  item: IDesign;
}

const DesignItemDetails: React.FC<IProps> = ({ item }) => {
  const { inverter1, structure, module } = item.inputs || {};
  const { getValueOf } = useConverter();
  const { t } = useTranslation();

  if (!item.outputs) return null;

  return (
    <DesignItemDetailsContainer>
      <DesignItemDetailsSectionWrapper>
        <Typography nowrap className="section-header" weight={'bold'}>
          {t('design:power')}
        </Typography>
        <DesignItemDetailsRow>
          <Typography nowrap>{getValueOf(designAttributes.power.ratedPower, item)}</Typography>
          <Typography nowrap>{getValueOf(designAttributes.power.peakPower, item)}</Typography>
          <Typography nowrap>
            {getValueOf(designAttributes.power.ratioDcAc, item)} <span>DC/AC</span>
          </Typography>
        </DesignItemDetailsRow>
      </DesignItemDetailsSectionWrapper>
      <DesignItemDetailsSectionWrapper>
        <Typography nowrap className="section-header" weight={'bold'}>
          {t('design:energy')}
        </Typography>
        <DesignItemDetailsRow>
          <Typography nowrap>{getValueOf(designAttributes.energy.specificProduction, item)}</Typography>
          <Typography nowrap>{getValueOf(designAttributes.energy.eneryYield, item)}</Typography>
          <Typography nowrap>{getValueOf(designAttributes.energy.plantPR, item)}</Typography>
        </DesignItemDetailsRow>
      </DesignItemDetailsSectionWrapper>
      <DesignItemDetailsSectionWrapper>
        <Typography nowrap className="section-header" weight={'bold'}>
          {t('design-process:site')}
        </Typography>
        <DesignItemDetailsRow>
          <Typography nowrap>{t(`design:${designAttributes.site.availableArea.label}`)}</Typography>
          <Typography nowrap>{getValueOf(designAttributes.site.availableArea, item)}</Typography>
        </DesignItemDetailsRow>
        <DesignItemDetailsRow>
          <Typography nowrap>{t(`design:${designAttributes.site.suitableArea.label}`)}</Typography>
          <Typography nowrap>{getValueOf(designAttributes.site.suitableArea, item)}</Typography>
        </DesignItemDetailsRow>
        <DesignItemDetailsRow>
          <Typography nowrap>{t(`design:${designAttributes.site.fenceArea.label}`)}</Typography>
          <Typography nowrap>{getValueOf(designAttributes.site.fenceArea, item)}</Typography>
        </DesignItemDetailsRow>
        <DesignItemDetailsRow>
          <Typography nowrap>{getValueOf(designAttributes.meteo.source, item)}</Typography>
          <Typography nowrap>{getValueOf(designAttributes.meteo.yearGhi, item)}</Typography>
          <Typography nowrap>{getValueOf(designAttributes.meteo.avgTemp, item)}</Typography>
        </DesignItemDetailsRow>
      </DesignItemDetailsSectionWrapper>
      <DesignItemDetailsSectionWrapper>
        <Typography nowrap className="section-header" weight={'bold'}>
          {t('equipment')}
        </Typography>
        <DesignItemDetailsRow>
          <Typography nowrap>{`${module.manufacturer}`}</Typography>
        </DesignItemDetailsRow>
        <DesignItemDetailsRow>
          <Typography nowrap>{getValueOf(designAttributes.equipment.modulePower, item)}</Typography>
        </DesignItemDetailsRow>
        <DesignItemDetailsRow>
          <Typography nowrap>{`${inverter1.manufacturer} ${module.model}`}</Typography>
        </DesignItemDetailsRow>
        <DesignItemDetailsRow>
          <Typography nowrap>{getValueOf(designAttributes.equipment.inverter1Power, item)}</Typography>
        </DesignItemDetailsRow>
        <DesignItemDetailsRow>
          <Typography nowrap>
            {`${structure.manufacturer} ${structure.model} - ${structure.positionNumber}${structure.positionLetter}`}
          </Typography>
        </DesignItemDetailsRow>
        <DesignItemDetailsRow>
          <Typography nowrap>
            {getValueOf(designAttributes.equipment.modulesPerString, item)} <span>modules/string</span>
          </Typography>
        </DesignItemDetailsRow>
        <DesignItemDetailsRow>
          <Typography nowrap>
            {getValueOf(designAttributes.equipment.tiltAngle, item)} <span>Tilt</span>
          </Typography>
        </DesignItemDetailsRow>
        <DesignItemDetailsRow>
          <Typography nowrap>
            {getValueOf(designAttributes.equipment.pitchDistance, item)} <span>Pitch</span>
          </Typography>
        </DesignItemDetailsRow>
        <DesignItemDetailsRow>
          <Typography nowrap>
            {getValueOf(designAttributes.equipment.gcr, item)} <span>GCR</span>
          </Typography>
        </DesignItemDetailsRow>
        <DesignItemDetailsRow>
          <Typography nowrap>{t('design:lvConfig')}</Typography>
          <Typography nowrap>{getValueOf(designAttributes.equipment.lvConfig, item)}</Typography>
        </DesignItemDetailsRow>
      </DesignItemDetailsSectionWrapper>
    </DesignItemDetailsContainer>
  );
};

export default DesignItemDetails;
