import { Point, StructureElement, StructureParametricInputs } from '../ilc-types';
import { v4 as uuidv4 } from 'uuid';

export interface StructureArgs {
  areaKey: string;
  angle: number;
  coordinates: Point;
  color: string;
  structureId?: string;
  psKey: string;
  selectedStructureOption: StructureParametricInputs;
}

export function createStructureElement(args: StructureArgs): StructureElement {
  return {
    areaKey: args.areaKey,
    angle: args.angle,
    centroid: { x: args.coordinates.x, y: args.coordinates.y },
    color: args.color,
    key: args.structureId ? args.structureId : uuidv4(),
    psKey: args.psKey,
    length: args.selectedStructureOption.length,
    width: args.selectedStructureOption.width,
    strings: args.selectedStructureOption.strings,
    type: 'STRUCTURES',
  };
}
