import React from 'react';
import { Dropdown, InputText } from '@ratedpower/components';
import { useSalesforceIds } from './use-salesforce-ids';
import { IsSalesforceApiAvailable } from 'utils/constants/support';

interface IProps {
  selectedId: string;
  onChange: (value: string) => void;
  textAlign?: 'left' | 'right';
}

const SalesforceIDInput: React.FC<IProps> = ({ selectedId, onChange, textAlign = 'left' }) => {
  const { data: salesforceOptions = [], onSearchSalesforceIds } = useSalesforceIds();

  const selectedSalesforceIdOption = salesforceOptions?.find((option) => option.value === selectedId) ?? {
    label: selectedId,
    value: selectedId,
  };

  if (!IsSalesforceApiAvailable) {
    return (
      <InputText
        name="salesforceId"
        value={selectedSalesforceIdOption.label ?? '-'}
        onChange={onChange}
        textAlign={textAlign}
        dataTest="salesforce-id"
      />
    );
  }

  return (
    <Dropdown
      name="salesforceId"
      options={salesforceOptions}
      value={selectedSalesforceIdOption ? [selectedSalesforceIdOption] : []}
      onChange={([selectedSalesforceId]) => {
        onChange(selectedSalesforceId.value);
      }}
      onSearch={onSearchSalesforceIds}
    />
  );
};

export default SalesforceIDInput;
