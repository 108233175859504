import styled, { css } from 'styled-components';

export const CapexTemplatesListContainer = styled.div<{ $showPublicTemplates: boolean }>`
  position: relative;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 270px));
  grid-auto-rows: 1fr;
  ${({ $showPublicTemplates }) =>
    $showPublicTemplates &&
    css`
      grid-auto-rows: 64px;
    `}
  row-gap: 40px;
  column-gap: 20px;
  padding: ${({ theme }) => `${theme.v2.spacing.l} ${theme.v2.spacing.l} 80px ${theme.v2.spacing.l}`};
`;
