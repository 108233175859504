import { AccountSvg, ConfigSvg, LogoutSvg, SubscriptionSvg } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { IUseModal, useModal } from 'utils/hooks/use-modal';
import { clearLocalStorage } from 'services/local-storage';
import { useAuth0 } from '@auth0/auth0-react';
import { getRedirectUri } from 'utils/redirect_uri';
import { IOption } from '@ratedpower/components';
import { track } from 'rudderstack/utils';
import { LogoutReason, RudderstackEvent } from 'rudderstack/types';

interface IUseTopbarMyAccount {
  options: IOption[];
  onClickOption: (selectedOption: IOption) => void;
  handleLogout: () => void;
  handleCancelLogout: () => void;
  modal: IUseModal;
}

export const useTopbarMyAccount = (): IUseTopbarMyAccount => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { logout } = useAuth0();

  const modal = useModal();

  const handleLogout = () => {
    clearLocalStorage();
    track(RudderstackEvent.LOGOUT, { reason: LogoutReason.LOGOUT_BUTTON_CLICKED });
    logout({ logoutParams: { returnTo: getRedirectUri() } });
  };

  const handleCancelLogout = () => {
    modal.setModalOpen(false);
  };

  const onClickOption = (selectedOption: IOption) => {
    if (selectedOption.value === 'logout') {
      modal.setModalOpen(true);
    } else {
      navigate(selectedOption.value);
    }
  };

  const defaultOptionsIconColor: string = theme.v2.icon.primary.default;
  const options: IOption[] = [
    {
      label: t('user:my-profile'),
      labelIcon: AccountSvg,
      value: '/profile',
      labelIconColor: defaultOptionsIconColor,
    },
    {
      label: t('user:my-preferences'),
      labelIcon: ConfigSvg,
      value: '/profile/preferences',
      labelIconColor: defaultOptionsIconColor,
    },
    {
      label: t('user:my-plan'),
      labelIcon: SubscriptionSvg,
      value: '/profile/plan',
      labelIconColor: defaultOptionsIconColor,
    },
    {
      value: 'logout',
      label: t('auth:logout'),
      labelIcon: LogoutSvg,
      labelIconColor: theme.v2.error.bg.contrast,
      labelColor: theme.v2.error.bg.contrast,
    },
  ];

  return { options, onClickOption, handleLogout, handleCancelLogout, modal };
};
