import React from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Tabs, TopbarWrapper } from '@ratedpower/components';
import { Helmet } from 'react-helmet';
import { useUsersManagementContext } from '../users-management-data';
import { useTheme } from 'styled-components';

interface ITopbarUsersManagement {
  onClickTab: (tabSelected: string) => void;
  activeTab: string;
}

const TopbarRoles: React.FC<ITopbarUsersManagement> = ({ activeTab, onClickTab }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { tabs, hasAccessToTeamsTab } = useUsersManagementContext();
  const pageTitle = hasAccessToTeamsTab ? 'user:users-management' : 'user:user-plural';

  return (
    <>
      <Helmet>
        <title>{t(pageTitle)}</title>
      </Helmet>
      <TopbarWrapper type="main" left={<Breadcrumb currentPage={{ name: t(pageTitle) }} />} />
      <TopbarWrapper
        padding={`${theme.v2.spacing.l} ${theme.v2.spacing.l} 0 ${theme.v2.spacing.l}`}
        type="secondary"
        left={
          <Tabs
            onClickTab={onClickTab}
            tabs={tabs.map((tab) => ({ ...tab, label: t(tab.label) }))}
            tabActive={activeTab}
          />
        }
        noBorder
      />
    </>
  );
};

export default TopbarRoles;
