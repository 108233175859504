import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as S from './single-power-station-kpis.styled';
import { Counter, LabelWrapper, Notification, StatusSVGWrapper, SVGWrapper, Typography } from '@ratedpower/components';
import { ChevronDownSvg, ChevronUpSvg, DotSvg, TargetSvg } from 'assets/icons';
import { Collapse } from 'react-collapse';
import {
  InverterElement,
  InverterPowerConfiguration,
  PowerStationKPIData,
} from 'pages/interactive-layout-creator/ilc-types';
import { CameraControls } from '@react-three/drei';
import { flyToArea } from 'pages/interactive-layout-creator/ilc-utils/camera';
import {
  useAddInverters,
  useGetRectangleElement,
  useRemoveInverters,
  useSimulationInputs,
} from 'pages/interactive-layout-creator/ilc-store';
import { useTranslation } from 'react-i18next';
import { outputAttributes } from 'utils/constants/design-result-attributes';
import { useConverter } from 'utils/conversor';
import { getInverterElement } from 'pages/interactive-layout-creator/ilc-utils/inverters';
import { MpptsLvConfiguration } from 'types/design-process';

interface ISinglePowerStationKpis {
  psData: PowerStationKPIData;
  controls: typeof CameraControls | undefined;
  primaryInverters: InverterElement[];
  secondaryInverters: InverterElement[];
}

function SinglePowerStationKpis({ psData, controls, primaryInverters, secondaryInverters }: ISinglePowerStationKpis) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();
  const { getValueOf } = useConverter();
  const getRectangleElement = useGetRectangleElement();
  const rectangleElement = getRectangleElement(psData.psKey);
  const isPowerStationWithDcBess = rectangleElement?.type === 'POWER_STATIONS' && rectangleElement.dcBess;
  const simulationInputs = useSimulationInputs();
  const simulationInverterType = simulationInputs?.inverterType;
  const defaultPowerStationDTO = simulationInputs?.powerStationSelectionDTO?.defaultPowerStationDTO;
  const areSecondaryInvertersEnabled =
    simulationInverterType === 'CENTRAL' && simulationInputs?.inverterCentralModel2Define;
  const step = simulationInputs?.mpptsLvConfiguration === MpptsLvConfiguration.EACH_SIDE_TO_DIFFERENT_INVERTER ? 2 : 1;

  const getTotalMaxInverters = (): number => {
    if (!defaultPowerStationDTO) return 0;
    if (defaultPowerStationDTO.numSecondaryInverter) {
      return defaultPowerStationDTO.numSecondaryInverter + defaultPowerStationDTO.numPrimaryInverter;
    }
    return defaultPowerStationDTO.numPrimaryInverter;
  };

  const getMaxInvertersPerType = (): number => {
    if (!defaultPowerStationDTO) return 0;
    if (defaultPowerStationDTO.numSecondaryInverter) {
      return Math.max(defaultPowerStationDTO.numSecondaryInverter, defaultPowerStationDTO.numPrimaryInverter);
    }
    return defaultPowerStationDTO.numPrimaryInverter;
  };

  const getTotalMinInverters = (): number => {
    return step;
  };

  const getMinimumByInverterType = (): number => {
    if (!defaultPowerStationDTO) return 0;
    if (simulationInverterType === 'STRING' && simulationInputs?.powerStationNumInvStringMinPerPsDefault) {
      return simulationInputs.powerStationNumInvStringMinPerPsDefault * step;
    }
    return 0;
  };

  const {
    ratedPower: ratedPowerAttribute,
    peakPower: peakPowerAttribute,
    ratioDcAc: ratioDcAcAttribute,
  } = outputAttributes(t);

  const removeInverters = useRemoveInverters();
  const addInverters = useAddInverters();

  const handleClickPowerStationTarget = () => {
    if (!rectangleElement) return;
    flyToArea([rectangleElement.centroid], controls);
  };

  const maxValue = areSecondaryInvertersEnabled ? getMaxInvertersPerType() : getTotalMaxInverters();
  const minValue = areSecondaryInvertersEnabled ? getMinimumByInverterType() : getTotalMinInverters();

  const getCombinedMaxReached = (): boolean => {
    const allInverters = [...primaryInverters, ...secondaryInverters];
    const maxInverters = getTotalMaxInverters();
    return allInverters.length >= maxInverters;
  };

  const getMaxReachedPerType = (inverters: InverterElement[]): boolean | null => {
    const maxPerType = getMaxInvertersPerType();
    return inverters.length >= maxPerType;
  };

  const getMaxReached = (inverters: InverterElement[]) => {
    return getMaxReachedPerType(inverters) || getCombinedMaxReached();
  };

  const getCombinedMinReached = (): boolean => {
    const allInverters = [...primaryInverters, ...secondaryInverters];
    const minInverters = getTotalMinInverters();
    return allInverters.length <= minInverters;
  };

  const getMinReachedPerType = (inverters: InverterElement[]): boolean | null => {
    const minPerType = getMinimumByInverterType();
    return inverters.length <= minPerType;
  };

  const getMinReached = (inverters: InverterElement[]) => {
    return getMinReachedPerType(inverters) || getCombinedMinReached();
  };

  const getInverterTemplate = (isPrimary: boolean): InverterElement => {
    return getInverterElement(isPrimary, psData.psKey, psData.areaKey, uuidv4(), '#00FFFFFF');
  };

  const powerStationElement = getRectangleElement(psData.psKey);
  const enablePrimaryDecrement = !getMinReached(primaryInverters) && !isPowerStationWithDcBess;
  const enablePrimaryIncrement = !getMaxReached(primaryInverters) && !isPowerStationWithDcBess;
  const enableSecondaryDecrement =
    areSecondaryInvertersEnabled && !getMinReached(secondaryInverters) && !isPowerStationWithDcBess;
  const enableSecondaryIncrement =
    areSecondaryInvertersEnabled && !getMaxReached(secondaryInverters) && !isPowerStationWithDcBess;

  const powerConfiguration: InverterPowerConfiguration = {
    inverterCentralModel1Power: simulationInputs?.inverterCentralModel1Power ?? 0,
    inverterCentralModel2Power: simulationInputs?.inverterCentralModel2Power ?? 0,
    inverterStringModelPower: simulationInputs?.inverterStringModelPower ?? 0,
    inverterType: simulationInverterType ?? 'STRING',
    electricalCosPhiInverters: simulationInputs?.electricalCosPhiInverters ?? 1,
  };

  const decreaseInverters = (enableDecrement: boolean | undefined, inverters: InverterElement[], step: 1 | 2) => {
    if (enableDecrement) {
      if (step === 1 && inverters.length >= 1) {
        removeInverters([inverters[0]], powerConfiguration);
      } else if (step === 2 && inverters.length >= 2) {
        removeInverters(inverters.slice(0, 2), powerConfiguration);
      }
    }
  };

  const decreasePrimaryInverters = () => {
    decreaseInverters(enablePrimaryDecrement, primaryInverters, step);
  };

  const increasePrimaryInverters = () => {
    if (enablePrimaryIncrement) {
      const inverters =
        step === 1 ? [getInverterTemplate(true)] : [getInverterTemplate(true), getInverterTemplate(true)];
      addInverters(inverters, powerConfiguration);
    }
  };

  const decreaseSecondaryInverters = () => {
    decreaseInverters(enableSecondaryDecrement, secondaryInverters, step);
  };

  const increaseSecondaryInverters = () => {
    if (enableSecondaryIncrement) {
      const inverters =
        step === 1 ? [getInverterTemplate(false)] : [getInverterTemplate(false), getInverterTemplate(false)];
      addInverters(inverters, powerConfiguration);
    }
  };

  return (
    <>
      <S.PowerStationBlock key={psData.psKey}>
        <S.PowerStationInfo>
          <StatusSVGWrapper
            icon={isExpanded ? ChevronUpSvg : ChevronDownSvg}
            onClick={() => setIsExpanded(!isExpanded)}
            size="s"
            active={false}
          />
          <SVGWrapper size="s" icon={DotSvg} color={powerStationElement?.color} />
          <Typography weight="bold">{psData.psName}</Typography>
          <Typography>
            {t('ilc:results.ilc-dc-ac-ratio', {
              dcAcRatio: getValueOf(ratioDcAcAttribute, {
                ratioDcAc: psData.powerKpis.dcAcRatio,
              }),
            })}
          </Typography>
          <StatusSVGWrapper icon={TargetSvg} onClick={handleClickPowerStationTarget} size="s" active={true} />
        </S.PowerStationInfo>
        <S.RatedAndPeakPower>
          <Typography>{getValueOf(ratedPowerAttribute, { ratedPower: psData.powerKpis.ratedPower })}</Typography>
          <Typography>{getValueOf(peakPowerAttribute, { peakPower: psData.powerKpis.peakDcPower })}</Typography>
        </S.RatedAndPeakPower>
        <Collapse isOpened={isExpanded}>
          <S.CollapseContent>
            <>
              <S.CounterWrapper>
                <LabelWrapper title={t('equipment:primary-inverter')} size="s" disabled={isPowerStationWithDcBess}>
                  <Counter
                    onClickMinus={decreasePrimaryInverters}
                    value={primaryInverters.length}
                    onClickPlus={increasePrimaryInverters}
                    disableMinus={!enablePrimaryDecrement}
                    disablePlus={!enablePrimaryIncrement}
                  />
                </LabelWrapper>
                {!isPowerStationWithDcBess && (
                  <S.HelperText>
                    {t('design-process:electrical-tab.min-max-boxes', { min: minValue, max: maxValue })}
                  </S.HelperText>
                )}
              </S.CounterWrapper>
              {areSecondaryInvertersEnabled && (
                <S.CounterWrapper>
                  <LabelWrapper title={t('equipment:secondary-inverter')} size="s" disabled={isPowerStationWithDcBess}>
                    <Counter
                      onClickMinus={decreaseSecondaryInverters}
                      value={secondaryInverters.length}
                      onClickPlus={increaseSecondaryInverters}
                      disableMinus={!enableSecondaryDecrement}
                      disablePlus={!enableSecondaryIncrement}
                    />
                  </LabelWrapper>
                  {!isPowerStationWithDcBess && (
                    <S.HelperText>
                      {t('design-process:electrical-tab.min-max-boxes', { min: minValue, max: maxValue })}
                    </S.HelperText>
                  )}
                </S.CounterWrapper>
              )}
              {areSecondaryInvertersEnabled && getCombinedMaxReached() && (
                <Notification
                  description={
                    <Typography size="s">
                      {t('ilc:results.ilc-inverters-max-reached', { max: getTotalMaxInverters() })}
                    </Typography>
                  }
                  hideCloseButton={true}
                  status="informative"
                />
              )}
              {isPowerStationWithDcBess && (
                <Notification
                  description={<Typography size="s">{t('ilc:results.ilc-inverters-disabled-for-dc-bess')}</Typography>}
                  hideCloseButton={true}
                  status="informative"
                />
              )}
            </>
          </S.CollapseContent>
        </Collapse>
      </S.PowerStationBlock>
      <S.Divider />
    </>
  );
}

export { SinglePowerStationKpis };
