import React, { PropsWithChildren } from 'react';
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import { onError } from '@apollo/client/link/error';
import { ErrorType } from 'components/error-components/error-notifier';
import { createUploadLink } from 'apollo-upload-client';
import { getApiUrl } from 'utils/api_url';
import { getRedirectUri } from 'utils/redirect_uri';
import { LogoutReason, RudderstackEvent } from 'rudderstack/types';
import { track } from 'rudderstack/utils';

interface IProps {
  showError: (error?: string) => void;
}

const ApolloAuthWrapper: React.FC<PropsWithChildren<IProps>> = ({ children, showError }) => {
  const { getAccessTokenSilently, logout } = useAuth0();

  const authLink = setContext(async (_, { headers }) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: { audience: process.env.REACT_APP_AUTH0_AUDIENCE },
    });
    return {
      headers: {
        ...headers,
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  });

  const httpLink = createUploadLink({
    uri: `${getApiUrl()}/graphql`,
  });

  const errorLink = (showError: (error?: string) => void) =>
    onError(({ graphQLErrors, networkError, response, operation }: any) => {
      if (networkError) {
        const unAuthorized = networkError.statusCode === 401;
        if (process.env.NODE_ENV !== 'production') {
          console.log(`[Network error]: ${networkError}`);
          console.log('operation:', operation);
        }
        if (unAuthorized) {
          // remove cached token on 401 from the server
          track(RudderstackEvent.LOGOUT, { reason: LogoutReason.INVALID_SESSION });
          logout({ logoutParams: { returnTo: getRedirectUri() } });
        }
        if (typeof window !== 'undefined' && !window.navigator.onLine) {
          showError(ErrorType.NO_INTERNET);
        } else {
          console.log(`[${ErrorType.UNKNOWN_NETWORK_ERROR} error]: ${networkError}`);
        }
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, path, extensions }: any) => {
          if (extensions.errorType) {
            if (process.env.NODE_ENV !== 'production') {
              console.log(
                `[GraphQL ${extensions.errorType ? extensions.errorType : ''} error]: ${message} ${
                  extensions.debugInfo
                    ? '-> ' + extensions.debugInfo.messageError.replace('com.ratedpower.pvdesign2.', '')
                    : ''
                } ${path ? ', Mutation or Part of the query failing: ' + path : ''}`
              );
              console.log('operation:', operation);
            }
            if (message === 'AccessDeniedException') {
              showError(ErrorType.FORBIDDEN);
              return;
            }
            if (!response || !response.data) {
              showError(ErrorType.GENERAL);
              console.log(
                `[GraphQL ${extensions.errorType ? extensions.errorType : ''} error]: ${message} ${
                  extensions.debugInfo
                    ? '-> ' + extensions.debugInfo.messageError.replace('com.ratedpower.pvdesign2.', '')
                    : ''
                }`
              );
            }
          } else {
            if (process.env.NODE_ENV !== 'production') {
              //error thrown before our graphql interceptor (development error only)
              console.log(
                `[GraphQL ${extensions && extensions.classification ? extensions.classification : 'error'}]: ${message}`
              );
            }
          }
        });
      }
    });

  const createApolloClient = () => {
    if (showError) {
      return new ApolloClient({
        link: ApolloLink.from([errorLink(showError), authLink.concat(httpLink as any)]),
        cache: new InMemoryCache(),
        connectToDevTools: process.env.NODE_ENV === 'development',
      });
    }
    return new ApolloClient({
      link: authLink.concat(httpLink as any),
      cache: new InMemoryCache(),
      connectToDevTools: process.env.NODE_ENV === 'development',
    });
  };

  return <ApolloProvider client={createApolloClient()}>{children}</ApolloProvider>;
};

export default ApolloAuthWrapper;
