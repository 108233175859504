import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { UPDATE_DESIGNS, UPDATE_DESIGN_NAME, TOGGLE_FAVORITE_DESIGN } from 'services/designs-gql';
import { IBessDesign, IDesign, SIMULATION_CATEGORY, SimulationPrimaryCharacteristicsTag } from 'types/design';
import { IUseModal, useModal } from 'utils/hooks/use-modal';
import { FetchResult, MutationFunctionOptions, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import {
  getRudderstackCloneDesignEventLocation,
  getRudderstackDesignCategory,
  getRudderstackFavoriteDesignEventLocation,
} from 'utils/constants/rudderstack';
import { track } from 'rudderstack/utils';
import { LayoutEditorButtonSource, RudderstackEvent } from 'rudderstack/types';
import { buildDesignProcessUrl } from 'utils/navigation/navigation';
import { nanoid } from 'nanoid';

export interface IDesignActions {
  handleClone: (item: IDesign | IBessDesign) => Promise<void>;
  editNotesModal: IUseModal;
  viewNotesModal: IUseModal;
  handleModalEditNotes: (item: IDesign | IBessDesign) => void;
  handleModalViewNotes: (notes: string) => void;
  handleEditNotes: () => void;
  handleSwitchFavorite: (item: IDesign | IBessDesign) => Promise<void>;
  handleEditDesignName: (id: string, newName: string) => void;
  updateDesigns: (options?: MutationFunctionOptions) => Promise<FetchResult<any>>;
  handleIlc: (item: IDesign | IBessDesign) => Promise<void>;
  itemToEdit: IDesign | IBessDesign | null;
}

export const useDesignsActions = (refetch: () => void, projectId: string): IDesignActions => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [updateDesigns] = useMutation(UPDATE_DESIGNS, {
    onCompleted: () => refetch(),
  });
  const [toggleFavoriteDesign] = useMutation(TOGGLE_FAVORITE_DESIGN, {
    onCompleted: () => refetch(),
  });
  const [itemToEdit, setItemToEdit] = useState<IDesign | IBessDesign | null>(null);

  const handleSwitchFavorite = async (item: IDesign | IBessDesign) => {
    const { id, isFavorite } = item;
    toggleFavoriteDesign({ variables: { designId: id } });
    const location = getRudderstackFavoriteDesignEventLocation(pathname);
    if (location && !isFavorite)
      track(RudderstackEvent.DESIGN_MARKED_FAVORITE, {
        location,
        design_category: getRudderstackDesignCategory(item.primaryTag, item.secondaryTag),
      });
  };

  const [updateDesignName] = useMutation(UPDATE_DESIGN_NAME, {
    onCompleted: () => refetch(),
  });

  const { subscriptionPlan } = useSelector((state: RootState) => state.currentUser);
  const canClone = !!subscriptionPlan && !subscriptionPlan.subscriptionPlanExpired;

  const handleClone = async (item: IDesign | IBessDesign) => {
    if (!canClone) return;
    const { id } = item;
    const location = getRudderstackCloneDesignEventLocation(pathname);
    if (location) track(RudderstackEvent.DESIGN_CLONE, { location });

    const simulationCategory =
      item.primaryTag === SimulationPrimaryCharacteristicsTag.BESS
        ? SIMULATION_CATEGORY.STANDALONE_BATTERY
        : SIMULATION_CATEGORY.PV;

    navigate(buildDesignProcessUrl(projectId, id, simulationCategory, false));
  };

  const editNotesModal = useModal();
  const viewNotesModal = useModal();

  const handleModalEditNotes = (item: IDesign | IBessDesign) => {
    editNotesModal.setModalOpen(true);
    setItemToEdit(item);
  };
  const handleModalViewNotes = (notes: string) => {
    viewNotesModal.setModalOpen(true);
    viewNotesModal.setData(notes);
  };

  const handleEditNotes = () => {
    editNotesModal.setModalOpen(false);
    setItemToEdit(null);
    refetch();
  };

  const handleEditDesignName = (id: string, newName: string) => {
    updateDesignName({ variables: { id, name: newName } });
  };

  const handleIlc = async (item: IDesign | IBessDesign) => {
    if (!canClone) return;
    const { id } = item;
    track(RudderstackEvent.EDIT_LAYOUT_CLICK_BUTTON, { source: LayoutEditorButtonSource.DESIGN_CARD });
    navigate(`/projects/${projectId}/interactive-layout-webgl/?designId=${id}&sessionId=${nanoid()}`);
  };

  return {
    itemToEdit,
    editNotesModal,
    viewNotesModal,
    handleSwitchFavorite,
    handleClone,
    handleModalEditNotes,
    handleModalViewNotes,
    handleEditNotes,
    handleEditDesignName,
    updateDesigns,
    handleIlc,
  };
};
