import { gql } from '@apollo/client';

const fragments = {
  project: gql`
    fragment ProjectItem on ProjectDTO {
      id
      name
      status
      isFavorite: favourite
      publishedDate
      createdBy {
        id
        name
        surname
      }
      numDesigns
      country
      region
      city
      latitude: latitudeProject
      longitude: longitudeProject
      teamId
      teamName
    }
  `,
};

export const GET_PROJECT_INFO = gql`
  query GetProject($id: ID!) {
    project(id: $id) {
      name
      teamId
      company {
        id
        name
      }
      createdBy {
        id
      }
      lat: latitudeProject
      lng: longitudeProject
      maxDistance: maxSiteToProjectDistance
      uploadedAnySite
    }
  }
`;

export const GET_USER_PROJECTS = gql`
  query UserProjects(
    $pagination: PaginationForm!
    $sorting: [SortOrder]!
    $filters: [ProjectFilterEntry]
    $search: [String]
    $isFavorite: Boolean!
    $teamId: String
  ) {
    result: userProjects(
      pagination: $pagination
      sorting: $sorting
      filters: $filters
      search: $search
      isFavorite: $isFavorite
      teamId: $teamId
    ) {
      content {
        ...ProjectItem
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
  ${fragments.project}
`;

export const GET_LAST_PROJECTS_WITH_SAVED_SIMULATIONS = gql`
  query LastsProjectsWithSavedSimulations {
    lastsProjectsWithSavedSimulations {
      ...ProjectItem
      numDesignsCreatedByUser
    }
  }
  ${fragments.project}
`;

export const GET_LAST_PROJECTS_WITH_FAVOURITES_DESIGNS_BY_USER = gql`
  query LastProjectsWithFavouritesDesignsByUser {
    lastProjectsWithFavouritesDesignsByUser {
      ...ProjectItem
      numDesignsCreatedByUser
    }
  }
  ${fragments.project}
`;

export const CREATE_PROJECT = gql`
  mutation CreateProject($projectForm: ProjectCreateDTO!) {
    createProject(projectForm: $projectForm) {
      ...ProjectItem
    }
  }
  ${fragments.project}
`;

export const UPDATE_PROJECTS = gql`
  mutation UpdateProjects($projectForms: [ProjectUpdateDTO]!) {
    updateProjects(projectForms: $projectForms) {
      ...ProjectItem
    }
  }
  ${fragments.project}
`;

export const UPDATE_PROJECT_NAME = gql`
  mutation UpdateProjectName($id: ID!, $name: String!) {
    updateProjectName(id: $id, name: $name) {
      id
    }
  }
`;

export const TOGGLE_FAVORITE_PROJECT = gql`
  mutation toggleFavoriteProject($projectId: ID!) {
    toggleFavoriteProject(projectId: $projectId)
  }
`;

export const GET_PROJECT_CREATORS = gql`
  query getProjectCreators($pagination: PaginationForm!, $search: String) {
    result: companyProjectsCreators(pagination: $pagination, search: $search) {
      content {
        id
        name
        lastname: surname
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
`;

export const GET_PROJECT_COUNTRIES = gql`
  query getProjectCountries($pagination: PaginationForm!, $search: String) {
    result: companyProjectsCountries(pagination: $pagination, search: $search) {
      content
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
`;
