import {
  DrawableRectangle,
  IntersectionPoint,
  Line,
  SelectedElement,
  SelectedElementWithSlopeData,
} from '../ilc-types';
import { calculateIntersectionBetweenProjectedPointAndLine } from './snap-to-line';
import { convexHull, distance } from './geometry';

export const alignBySnapToLine = (
  line: Line,
  elementsGroups: Record<number, SelectedElementWithSlopeData[]>
): SelectedElement[] => {
  const elementsToUpdate: SelectedElement[] = [];
  for (const group of Object.values(elementsGroups)) {
    const angle = group[0].angle;
    const groupsPoints = group.map((element) => element.rectangle).flat();
    const intersectionPoints: IntersectionPoint[] = [];
    for (const point of groupsPoints) {
      const intersection = calculateIntersectionBetweenProjectedPointAndLine(point, angle, line);
      if (!intersection) break;
      const distanceA = distance(intersection, point);
      intersectionPoints.push({
        point: intersection,
        distance: distanceA,
        offSet: { x: intersection.x - point.x, y: intersection.y - point.y },
      });
    }
    intersectionPoints.sort((a: any, b: any) => a.distance - b.distance);
    if (intersectionPoints.length > 0 && intersectionPoints[0]) {
      const offset = intersectionPoints[0].offSet;
      group.forEach((element) => {
        elementsToUpdate.push({
          ...element,
          centroid: { x: element.centroid.x + offset.x, y: element.centroid.y + offset.y },
        });
      });
    }
  }
  return elementsToUpdate;
};

export const alignBySnapInGroup = (
  line: Line,
  selectedRectangles: DrawableRectangle[],
  selectedElements: SelectedElement[]
) => {
  const allPoints = selectedRectangles.map((rectangle) => rectangle.points).flat();
  const convexHullPoints = convexHull(allPoints);
  const intersectionPoints: any = [];
  const angle = selectedElements[0].angle;
  for (const point of convexHullPoints) {
    const intersection = calculateIntersectionBetweenProjectedPointAndLine(point, angle, line);
    if (intersection) {
      const distanceA = distance(intersection, point);
      intersectionPoints.push({
        point: intersection,
        distance: distanceA,
        offSet: { x: intersection.x - point.x, y: intersection.y - point.y },
      });
    }
  }
  intersectionPoints.sort((a: any, b: any) => a.distance - b.distance);
  const offSet = intersectionPoints[0].offSet;
  return selectedElements.map((element) => {
    return {
      ...element,
      centroid: { x: element.centroid.x + offSet.x, y: element.centroid.y + offSet.y },
    };
  });
};
