import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import React, { useEffect, useState } from 'react';
import { GET_ROLES } from 'services/roles-gql';
import { IServerFilter, ISort } from 'types/filter';
import { IRoleListItem, ROLE_TYPE } from 'types/roles';
import { IUseRolesObject, IUserRoleSummary } from 'types/users';
import { useCustomQuery } from 'utils/hooks/use-custom-query';
import { useModal } from 'utils/hooks/use-modal';
import { useSearch } from 'utils/hooks/use-search';
import { getSortDirection } from 'utils/sort';
import { useGetRolesTableColumns } from '../../table-columns/roles-table-columns';
import { getCoreRowModel, getExpandedRowModel } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { SortableHeader } from '@ratedpower/components';
import { useEditableRPTable } from 'utils/hooks/use-editable-rptable';

export const useRoles = (userRoleSummary: IUserRoleSummary[]): IUseRolesObject => {
  const deleteRoleModal = useModal();
  const { t } = useTranslation();

  const [sortedBy, setSortedBy] = useState<ISort>({
    selector: {
      label: t('name'),
      code: 'name',
    },
    ascendent: true,
  });
  const [roles, setRoles] = useState<IRoleListItem[]>([]);
  const [mapSelectedRoleTypes, setSelectedRoleTypes] = useState<{ [id: string]: ROLE_TYPE }>();
  const [filters, setFilters] = useState<IServerFilter[]>([]);

  const { getSearchFilter, inputSearchProps } = useSearch();
  const { isAuthorized } = useAuthorization();

  const {
    data: fetchedData,
    loading,
    paginationComponent,
    currentPage,
    error: rolesError,
  } = useCustomQuery(GET_ROLES, {
    variables: {
      pagination: {
        page: 0,
        size: 10,
      },
      sorting: {
        property: sortedBy.selector.code,
        direction: getSortDirection(sortedBy.ascendent),
      },
      search: getSearchFilter(),
      filters,
    },
    skip: !isAuthorized(Permission.ROLE_SUPPORT_READ),
    fetchPolicy: 'network-only',
  });

  const sortableHeader = (label: string, code: string) => (
    <SortableHeader
      {...{ sortedBy, onSort: setSortedBy }}
      selector={{
        label: t(`${label}`),
        code,
      }}
    />
  );

  useEffect(() => {
    if (fetchedData?.result?.content && !loading) setRoles(fetchedData.result?.content);
  }, [fetchedData, loading]);

  useEffect(() => {
    if (roles.length) {
      setInitialRoleTypes();
    }
  }, [roles.length]);

  const setInitialRoleTypes = () => {
    const selectedRoleTypes: { [id: string]: ROLE_TYPE } = {};
    roles.forEach((role) => {
      selectedRoleTypes[role?.id as string] = role.type;
    });
    setSelectedRoleTypes(selectedRoleTypes);
  };

  const columns = useGetRolesTableColumns(sortableHeader, userRoleSummary);

  const table = useEditableRPTable(roles, columns, setRoles, {
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return {
    loading,
    rolesError,
    mapSelectedRoleTypes,
    table,
    roles,
    currentPage,
    setFilters,
    inputSearchProps,
    deleteRoleModal,
    paginationComponent: fetchedData && fetchedData.result?.pageInfo.totalPages > 1 ? paginationComponent : undefined,
  };
};
