import React from 'react';
import * as S from '../design-grid-item.styled';
import { DESIGNS_STATUS } from 'utils/constants/tabs';
import { IBessDesign, IDesign } from 'types/design';
import { useTranslation } from 'react-i18next';
import {
  CloneSvg,
  DotsSvg,
  FavouriteOutlineSvg,
  FavouriteSvg,
  FolderExportSvg,
  FolderImportSvg,
  PenSquareSvg,
  TrashSvg,
} from 'assets/icons';
import { Checkbox, EditableInput, IOption, PopupWrapper, StatusSVGWrapper, Typography } from '@ratedpower/components';
import { handleValidateDesignName } from 'utils/text/text.utils';
import { usePlanIsExpired } from 'utils/hooks/use-plan-is-expired';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { IndexIds } from 'index.ids';
import { getAccessToIlcIsDisabled, getAccessToIlcMessage, getDesignIsILC } from 'utils/design-tags';
import { useHybridBessSku } from 'utils/hooks/sku/use-hybrid-bess-sku';
import { useStandaloneBessSku } from 'utils/hooks/sku/use-standalone-bess-sku';
import { useIlcSku } from 'utils/hooks/sku/use-ilc-sku';
import { track } from 'rudderstack/utils';
import { LayoutEditorButtonSource, RudderstackEvent } from 'rudderstack/types';

interface IProps {
  item: IDesign | IBessDesign;
  handleSelect?: () => void;
  handleClone?: (e: React.MouseEvent) => void;
  handleDelete?: () => void;
  handleUnarchive?: () => void;
  handleArchive?: () => void;
  handleEditName?: (newName: string) => void;
  handleFavorite?: () => void;
  selected?: boolean;
  canEditDesign?: boolean;
  canCloneDesign?: boolean;
}

const DesignGridItemHeader: React.FC<IProps> = ({
  item,
  selected = false,
  canEditDesign,
  canCloneDesign,
  handleSelect = () => ({}),
  handleClone = () => ({}),
  handleDelete = () => ({}),
  handleUnarchive = () => ({}),
  handleArchive = () => ({}),
  handleFavorite = () => ({}),
  handleEditName,
}) => {
  const { t } = useTranslation();
  const planIsExpired = usePlanIsExpired();
  const { restrictStandaloneBessSku } = useStandaloneBessSku();
  const { restrictHybridBessSku } = useHybridBessSku();
  const { showIlcButtonByPrimaryTag } = useIlcSku();

  const optionArchive = {
    labelIcon: FolderExportSvg,
    label: t('action:archive'),
    value: 'archive',
    dataTest: 'archive-btn',
  };
  const optionUnArchive = {
    labelIcon: FolderImportSvg,
    label: t('action:unarchive'),
    value: 'unarchive',
    dataTest: 'unarchive-btn',
  };
  const optionDelete = {
    labelIcon: TrashSvg,
    label: t('action:delete'),
    value: 'delete',
    dataTest: IndexIds.Designs.DesignDeleteBtn,
  };
  const optionFavorite = {
    labelIcon: FavouriteSvg,
    label: t('action:mark-favorite'),
    value: 'favorite',
    dataTest: 'favorite-btn',
  };
  const optionUnmarkFavorite = {
    labelIcon: FavouriteOutlineSvg,
    label: t('action:unmark-favorite'),
    value: 'favorite',
    dataTest: 'unmark-favorite-btn',
  };

  const optionMethods = {
    [optionArchive.value]: handleArchive,
    [optionUnArchive.value]: handleUnarchive,
    [optionDelete.value]: handleDelete,
    [optionFavorite.value]: handleFavorite,
    [optionUnmarkFavorite.value]: handleFavorite,
  };
  const { id: projectId } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const designIsIlc = getDesignIsILC(item.secondaryTag);
  const disableAccessToIlc = getAccessToIlcIsDisabled(item) || !canCloneDesign;

  const handleWebGL = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    track(RudderstackEvent.EDIT_LAYOUT_CLICK_BUTTON, { source: LayoutEditorButtonSource.DESIGN_CARD });
    const { id } = item;
    navigate(`/projects/${projectId}/interactive-layout-webgl/?designId=${id}&sessionId=${nanoid()}`);
  };

  const getOptions = (): IOption[] => {
    const optionsArray = [item.isFavorite ? optionUnmarkFavorite : optionFavorite];

    if (canEditDesign && !restrictStandaloneBessSku(item) && !restrictHybridBessSku(item)) {
      optionsArray.unshift(item.status === DESIGNS_STATUS.archived ? optionUnArchive : optionArchive, optionDelete);
    }
    return optionsArray;
  };

  const handleOptionOnClick = (selectedOption: IOption) => {
    optionMethods[selectedOption.value]();
  };

  const onHandleEditName = (value: string): void => {
    if (!handleEditName || item.name.trim().length === 0) return;
    handleEditName(value);
  };

  const cloneDisabled =
    !canCloneDesign || restrictStandaloneBessSku(item) || restrictHybridBessSku(item) || planIsExpired || designIsIlc;

  const getIlcTooltip = () => {
    if (!canCloneDesign) {
      return t('action:readonly');
    }
    return t(getAccessToIlcMessage(item.layoutEditorTag));
  };

  const getCloneTooltip = () => {
    if (designIsIlc) {
      return t('design:ilc-cannot-clone');
    }
    if (cloneDisabled) {
      return t('action:readonly');
    }
    return t('action:clone');
  };

  return (
    <S.CardHeader>
      <div
        data-testid="checkbox"
        className="checkbox"
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.key === 'Enter' && e.stopPropagation()}
      >
        <Checkbox id={item.id} checked={selected} onChange={handleSelect} />
      </div>
      <div className="header">
        <div className="editable-header">
          {canEditDesign && handleEditName ? (
            <>
              <Typography>{item.number}.</Typography>
              <EditableInput
                validationFunction={handleValidateDesignName}
                value={item.name}
                onEditName={onHandleEditName}
                className="editable-input"
              />
            </>
          ) : (
            <Typography className="design-no-editable" nowrap>
              {item.number}. {item.name}
            </Typography>
          )}
        </div>
      </div>
      <S.IconsWrapper onClick={(e) => e.stopPropagation()}>
        {showIlcButtonByPrimaryTag(item) && (
          <StatusSVGWrapper
            disabled={disableAccessToIlc}
            active={false}
            tooltip={{
              text: getIlcTooltip(),
              place: 'bottom',
            }}
            onClick={handleWebGL}
            icon={PenSquareSvg}
            data-testid="ilc-btn"
            size={'s'}
          />
        )}

        <StatusSVGWrapper
          disabled={cloneDisabled}
          active={false}
          tooltip={{ text: getCloneTooltip(), place: 'bottom' }}
          onClick={handleClone}
          icon={CloneSvg}
          data-testid="clone-btn"
          size={'s'}
        />

        <PopupWrapper
          popupPosition="leftCenter"
          onClickOption={handleOptionOnClick}
          options={getOptions()}
          dataTest={IndexIds.Designs.MoreOptions}
        >
          <StatusSVGWrapper
            active={false}
            icon={DotsSvg}
            tooltip={{ text: t('action:more'), place: 'bottom' }}
            size="s"
          />
        </PopupWrapper>
      </S.IconsWrapper>
    </S.CardHeader>
  );
};

export default DesignGridItemHeader;
