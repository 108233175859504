import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useModal } from './use-modal';
import { useAuth0 } from '@auth0/auth0-react';
import { clearLocalStorage } from 'services/local-storage';
import { getRedirectUri } from 'utils/redirect_uri';
import { LogoutReason, RudderstackEvent } from 'rudderstack/types';
import { track } from 'rudderstack/utils';

const useSessionExpirationModal = () => {
  const { logout } = useAuth0();
  const sessionExpirationModal = useModal();
  const warningModalCountDown = 1000 * 30; //30 seconds
  const [loading, setLoading] = useState(false);

  const onIdle = async () => {
    setLoading(true);
    track(RudderstackEvent.LOGOUT, { reason: LogoutReason.INACTIVITY });
    logout({ logoutParams: { returnTo: getRedirectUri() } });
    clearLocalStorage();
    setLoading(false);
  };

  const onPrompt = () => {
    sessionExpirationModal.setModalOpen(true);
  };

  /*
   ** https://idletimer.dev/docs/features/idle-detection
   */
  const { activate } = useIdleTimer({
    onPrompt,
    onIdle,
    timeout: 1000 * 60 * 240, // 4 hours to open expiration modal
    promptBeforeIdle: warningModalCountDown,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    eventsThrottle: 1000,
    element: document,
    startOnMount: true,
    stopOnIdle: true,
    crossTab: true,
  });

  async function extendUserSession() {
    activate();
    sessionExpirationModal.setModalOpen(false);
  }

  return {
    loading,
    extendUserSession,
    endUserSession: onIdle,
    showExpirationModal: sessionExpirationModal.isOpen,
    closeExpirationModal: () => sessionExpirationModal.setModalOpen(false),
    timeRemaining: warningModalCountDown,
  };
};

export default useSessionExpirationModal;
