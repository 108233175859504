import { Area, DesignInputs, IlcValidationError } from '../ilc-types';

export function getAreasWithError(
  validationErrors: IlcValidationError[],
  resultType: IlcValidationError['result'],
  areaParametricInputs: DesignInputs['areaParametricInputs'] | null
): Area[] {
  return validationErrors
    .filter((error) => error.result === resultType)
    .reduce((acc: string[], item) => {
      if (!acc.includes(item.areaId)) {
        acc.push(item.areaId);
      }
      return acc;
    }, [])
    .map((id, index) => {
      const areaName = cleanAreaName(areaParametricInputs?.[id].areaName ?? `area-${index + 1}`);
      return { id, label: areaName };
    });
}

export function cleanAreaName(name: string): string {
  return name.replace(/Polygon/i, '');
}
