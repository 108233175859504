import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { useState } from 'react';
import { GET_INPUT_TEMPLATE_CREATORS } from 'services/design-process-gql';
import { useCustomQuery } from 'utils/hooks/use-custom-query';

export const useInputTemplatesCreators = (companyId?: string | null) => {
  const [searchBy, setSearchBy] = useState<string | string[]>('');
  const { isAuthorized } = useAuthorization();

  const { data, error, paginationComponent } = useCustomQuery(
    GET_INPUT_TEMPLATE_CREATORS,
    {
      variables: {
        pagination: {
          page: 0,
          size: 10,
        },
        companyId,
        search: searchBy,
      },
      skip: companyId === null && !isAuthorized(Permission.FINANCIAL_SUPPORT_WRITE),
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    },
    true
  );

  return {
    data: data?.result.content || [],
    error,
    paginationComponent,
    setSearchBy,
  };
};
