import React, { useEffect, useState } from 'react';
import * as S from './design-comparison.styled';
import ComparisonTable from './comparison-table/comparison-table';
import ComparisonAttributesPanel from './attributes-panel/attributes-panel';
import { useModal } from 'utils/hooks/use-modal';
import ModalAddDesigns from './modal-add-designs/modal-add-designs';
import TopbarDesignComparison from './topbar-design-comparison';
import useSidebar from 'utils/hooks/use-sidebar';
import { useTranslation } from 'react-i18next';
import ComparisonCharts from './comparison-charts/comparison-charts';
import useComparisonActiveFilters from './attributes-panel/use-comparison-active-filters';
import {
  defaultChartFilters,
  defaultDesignComparisonAttributes as defaultTableFilters,
  DesignComparisonChartFilters as allChartFilters,
  DesignComparisonTableFilters as allTableFilters,
} from 'utils/constants/design-comparison';
import { deepSearch } from 'utils/objects';
import { Button, ISegmentedControlItem, SegmentedControl } from '@ratedpower/components';
import { ComparisonView, IComparisonFilter, IComparisonFilterGroup } from 'types/design-comparison.types';
import { FilterSvg } from 'assets/icons';
import { useAuthorization } from 'access-control/can';
import ErrorForbidden from 'pages/error/error-forbidden/error-forbidden';

const DesignComparison: React.FC = () => {
  const { t } = useTranslation();
  const [saveFiltersConfiguration, setSaveFiltersConfiguration] = useState(
    localStorage.getItem('tableFilters') ? true : false
  );
  const [saveChartFiltersConfiguration, setSaveChartFiltersConfiguration] = useState(
    localStorage.getItem('chartFilters') ? true : false
  );

  useSidebar({ collapsed: true });
  const { isAuthorizedToPermissionSuffix } = useAuthorization();

  const [viewShown, setViewShown] = useState<ComparisonView>('table');
  const [showAttributesPanel, setShowAttributesPanel] = useState(false);

  const addDesignsModal = useModal();

  useEffect(() => {
    if (!saveFiltersConfiguration) {
      localStorage.removeItem('tableFilters');
    }
    if (saveFiltersConfiguration) {
      if (localStorage.getItem('tableFilters')) return;
      localStorage.setItem(
        'tableFilters',
        JSON.stringify(tableFilters.activeFilters.map((attribute) => attribute.label))
      );
    }
  }, [saveFiltersConfiguration]);

  useEffect(() => {
    if (!saveChartFiltersConfiguration) {
      localStorage.removeItem('chartFilters');
    }
    if (saveChartFiltersConfiguration) {
      if (localStorage.getItem('chartFilters')) return;
      localStorage.setItem(
        'chartFilters',
        JSON.stringify(chartFilters.activeFilters.map((attribute) => attribute.label))
      );
    }
  }, [saveChartFiltersConfiguration]);

  // Get saved table filters
  const savedTableFilters = localStorage.getItem('tableFilters');
  const parsedTableFilters = savedTableFilters && JSON.parse(savedTableFilters);
  const availableTableFilters: IComparisonFilter[] = savedTableFilters
    ? parsedTableFilters
        .map((attribute: any) => deepSearch(allTableFilters, 'key', (k: any, v: any) => v === attribute))
        .filter((attribute: any) => !!attribute)
    : defaultTableFilters;

  // Get saved chart filters
  const savedChartFilters = localStorage.getItem('chartFilters');
  const parsedChartFilters = savedChartFilters && JSON.parse(savedChartFilters);
  const availableChartFilters: IComparisonFilter[] = savedChartFilters
    ? parsedChartFilters.map((attribute: any) =>
        deepSearch(allChartFilters, 'key', (k: any, v: any) => v === attribute)
      )
    : defaultChartFilters[0].filters;
  // Filters
  const tableFilters: {
    availableFilters: IComparisonFilterGroup[];
    activeFilters: IComparisonFilter[];
    updateActiveFilters: React.Dispatch<React.SetStateAction<IComparisonFilter[]>>;
  } = useComparisonActiveFilters(allTableFilters, availableTableFilters);
  const chartFilters: {
    availableFilters: IComparisonFilterGroup[];
    activeFilters: IComparisonFilter[];
    updateActiveFilters: React.Dispatch<React.SetStateAction<IComparisonFilter[]>>;
  } = useComparisonActiveFilters(allChartFilters, availableChartFilters);

  useEffect(() => {
    if (saveFiltersConfiguration) {
      localStorage.setItem(
        'tableFilters',
        JSON.stringify(tableFilters.activeFilters.map((attribute) => attribute.key))
      );
    }
  }, [tableFilters.activeFilters, saveFiltersConfiguration]);

  useEffect(() => {
    if (saveChartFiltersConfiguration) {
      localStorage.setItem(
        'chartFilters',
        JSON.stringify(chartFilters.activeFilters.map((attribute) => attribute.key))
      );
    }
  }, [chartFilters.activeFilters, saveChartFiltersConfiguration]);

  const switchItems: ISegmentedControlItem[] = [
    {
      label: t('datatable'),
      value: 'table',
      width: '147px',
    },
    {
      label: t('charts'),
      value: 'chart',
      width: '147px',
    },
  ];

  if (!isAuthorizedToPermissionSuffix('SIMULATION')) {
    return <ErrorForbidden />;
  }

  const isViewTable = viewShown === 'table';
  const activeFilters = isViewTable ? tableFilters.activeFilters : chartFilters.activeFilters;

  return (
    <S.DesignComparisonContainer>
      <TopbarDesignComparison
        onAddDesign={() => addDesignsModal.setModalOpen(true)}
        viewShown={viewShown}
        activeFilters={activeFilters}
      />
      <S.ComparisonContent>
        <S.ComparisonSubTab>
          <SegmentedControl
            items={switchItems}
            selectedValue={viewShown}
            onChange={(selectedItem) => {
              setViewShown(selectedItem.value as ComparisonView);
            }}
          />
          <S.ComparisonTopRightButtons>
            <Button
              variant="ghost"
              text={String(t('filters.title') + ' (' + activeFilters.length + ')')}
              Icon={() => <FilterSvg />}
              onClick={() => setShowAttributesPanel(true)}
            />
          </S.ComparisonTopRightButtons>
        </S.ComparisonSubTab>
        <ComparisonAttributesPanel
          {...(isViewTable ? { ...tableFilters } : { ...chartFilters })}
          saveFiltersConfiguration={isViewTable ? saveFiltersConfiguration : saveChartFiltersConfiguration}
          setSaveFiltersConfiguration={isViewTable ? setSaveFiltersConfiguration : setSaveChartFiltersConfiguration}
          viewShown={viewShown}
          active={showAttributesPanel}
          hideAttributesPanel={() => setShowAttributesPanel(false)}
        />
        <S.ComparisonDesignsPanel className={isViewTable ? '' : 'hidden'}>
          <ComparisonTable updateActiveFilters={tableFilters.updateActiveFilters} activeAttributes={activeFilters} />
        </S.ComparisonDesignsPanel>

        {!isViewTable && (
          <S.ComparisonDesignsPanel>
            <ComparisonCharts activeAttributes={activeFilters} onAddDesign={() => addDesignsModal.setModalOpen(true)} />
          </S.ComparisonDesignsPanel>
        )}
      </S.ComparisonContent>

      {addDesignsModal.isOpen && (
        <ModalAddDesigns
          isActive={addDesignsModal.isOpen}
          size="l"
          onClose={() => addDesignsModal.setModalOpen(false)}
        />
      )}
    </S.DesignComparisonContainer>
  );
};

export default DesignComparison;
