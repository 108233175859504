import React, { useState } from 'react';
import { LabelWrapper, Typography, Button, Dropdown } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { useCompanyActivityState } from '../../company-activity-state';
import { useMutation } from '@apollo/client';
import { COMPANY_UPDATE_ALERT, GET_SUPPORT_COMPANY } from 'services/support-gql';
import { useToasts } from 'utils/hooks/use-toasts';
import { EditButton, FormActions, FormSectionHeadline } from '../company-info-tab.styled';
import { CompanyWarningMessage } from 'types/company.types';
import { ISelectorOptions } from 'types/selector.types';
import { arrayToSelectorOptions, getOptionForValue } from 'utils/selector';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';

const AlertsForm: React.FC = () => {
  const { t } = useTranslation();
  const { isAuthorized } = useAuthorization();
  const { addSuccessToast } = useToasts();
  const { contextCompany, companyId } = useCompanyActivityState();
  const [edition, setEdition] = useState(false);
  const companyWarnings: ISelectorOptions[] = arrayToSelectorOptions(Object.values(CompanyWarningMessage));

  const [selectedWarning, setSelectedWarning] = useState(
    contextCompany?.companyWarningMessage ?? CompanyWarningMessage.NO_WARNING
  );

  const selectedWarningOption = getOptionForValue(selectedWarning, companyWarnings);

  const [companyUpdateAlert] = useMutation(COMPANY_UPDATE_ALERT, {
    refetchQueries: [GET_SUPPORT_COMPANY],
    onCompleted: (data) => {
      if (data) {
        setEdition(false);
        addSuccessToast(t('support:update-company-success'));
      }
    },
  });

  const editCompanyWarning = () => {
    companyUpdateAlert({
      variables: {
        companyUpdateAlertDTO: { id: companyId, companyWarningMessage: selectedWarning },
      },
    });
  };

  return (
    <>
      <FormSectionHeadline>
        <Typography size="l" weight="bold">
          {t('support:alerts')}
        </Typography>
        {isAuthorized(Permission.COMPANY_SUPPORT_WRITE) && (
          <EditButton text={t('action:edit')} onClick={() => setEdition(true)} variant="link" textUnderlined />
        )}
      </FormSectionHeadline>
      <LabelWrapper title={t('support:company-tab.warning-message')} size="s" alignItems="center">
        <Dropdown
          name="companyWarningMessage"
          value={selectedWarningOption ? [selectedWarningOption] : []}
          options={companyWarnings}
          onChange={([selectedWarning]) => setSelectedWarning(selectedWarning.value)}
          readonly={!edition}
          size="s"
        />
      </LabelWrapper>
      {edition && (
        <FormActions>
          <Button variant="ghost" text={t('action:discard')} onClick={() => setEdition(false)} fullWidth />
          <Button variant="accent" text={t('action:save')} onClick={editCompanyWarning} fullWidth />
        </FormActions>
      )}
    </>
  );
};

export default AlertsForm;
