import React from 'react';
import * as S from './designs.styled';
import ComparissonBar from './comparisson-bar/comparisson-bar';
import DesignsGrid from './grid/designs-grid';
import DesignsList from './table/designs-table';
import DesignsSubBar from './designs-sub-bar/designs-sub-bar';
import DesignsTopbar from './designs-topbar/designs-topbar';
import ModalEditNotes from './modal-edit-notes/modal-edit-notes';
import NoResultsFilter from 'components/no-results-filter/no-results-filter';
import classNames from 'classnames';
import useSubscriptionPlanBanner from 'utils/hooks/use-subscription-plan-banner';
import { CheckboxGroup, FilterSidebar, FilterSidebarItem, Modal, Typography } from '@ratedpower/components';
import { DESIGNS_STATUS } from 'utils/constants/tabs';
import { useDesigns } from './use-designs';
import { useTranslation } from 'react-i18next';
import { views } from 'utils/constants/top-bar';
import { Permission } from 'access-control/permissions';
import { useAuthorization } from 'access-control/can';
import ZeroDesignsCreated from 'pages/designs/zero-designs-created/zero-designs-created';
import ErrorForbidden from 'pages/error/error-forbidden/error-forbidden';
import QueryStatusWrapper from 'components/query-status-wrapper/query-status-wrapper';
import { IndexIds } from 'index.ids';

const Designs: React.FC = () => {
  const { isAuthorized, isAuthorizedToPermissionSuffix } = useAuthorization();
  const hasLicenseBanner = useSubscriptionPlanBanner();
  const { t } = useTranslation();
  const {
    changeView,
    handleArchive,
    handleChangeTab,
    handleCompare,
    handleDelete,
    handleModalDelete,
    handlePerform,
    handleSelectAll,
    handleUnarchive,
    setSortBy,
    setViewDetailed,
    startDesignProcess,
    startStandaloneBess,
    toggleOverlay,
    handleEditNotes,
    handleEditDesignName,
    itemToEdit,
    deleteModal,
    designs,
    editNotesModal,
    viewNotesModal,
    error,
    inputSearchProps,
    itemsToDelete,
    loading,
    isInitialLoading,
    overlayProps,
    paginationComponent,
    rowsSelected,
    selectedTab,
    selectedView,
    sortBy,
    viewDetailed,
    zeroDesignsCreated,
    projectName,
    projectId,
  } = useDesigns();
  const designViewProps = {
    designs,
    viewDetailed,
    rowsSelected,
    zeroDesignsCreated,
    onPerform: handlePerform,
    onEditDesignName: handleEditDesignName,
  };

  function handleSort(): void {
    const newSort = { ...sortBy, ascendent: !sortBy.ascendent };
    setSortBy(newSort);
  }

  function areFiltersSelected(): boolean {
    let filtersSelected = false;
    overlayProps.filters.forEach((filter) => {
      filter.options.forEach((option) => {
        if (option.checked) filtersSelected = true;
      });
    });
    return filtersSelected;
  }

  const hasRunDesignPermissions =
    isAuthorized(Permission.SIMULATION_SUPPORT_CREATE) ||
    isAuthorized(Permission.SIMULATION_CREATE_IN_USER_COMPANY) ||
    isAuthorized(Permission.SIMULATION_CREATE_IN_USER_TEAM_AND_PUBLIC);

  const handleNoResults = () => {
    if (areFiltersSelected()) return <NoResultsFilter onClick={overlayProps.onClear} />;
    return <ZeroDesignsCreated selectedTab={selectedTab} hasRunDesignPermissions={hasRunDesignPermissions} />;
  };

  const handleArchiveAction = (): void => {
    if (designs.length && designs[0].status === DESIGNS_STATUS.archived) {
      handleUnarchive(rowsSelected);
    } else {
      handleArchive(rowsSelected);
    }
  };

  if (!isAuthorizedToPermissionSuffix('SIMULATION')) {
    return <ErrorForbidden />;
  }

  return (
    <S.DesignsContainer $hasBanner={hasLicenseBanner}>
      <DesignsTopbar
        projectId={projectId ?? ''}
        selectedView={selectedView}
        changeView={changeView}
        startDesignProcess={startDesignProcess}
        startStandaloneBess={startStandaloneBess}
        title={projectName}
        loading={loading}
      />
      <S.DesignsContent>
        <DesignsSubBar
          selectedTab={selectedTab}
          onFilterClick={() => toggleOverlay()}
          onChangeTab={handleChangeTab}
          inputSearchProps={inputSearchProps}
        />
        {!error && designs.length !== 0 && (
          <ComparissonBar
            selectedDesigns={rowsSelected}
            viewDetailed={viewDetailed}
            onClickViewDetailed={() => setViewDetailed(!viewDetailed)}
            toggleAll={handleSelectAll}
            handleCompare={handleCompare}
            handleDelete={() => handleModalDelete(rowsSelected)}
            handleArchive={handleArchiveAction}
            hideSortingByDate={selectedView === views.LIST}
            sortBy={sortBy}
            onSort={handleSort}
          />
        )}
        <div className="data-view">
          <div
            className={classNames('data-list-scrolleable', {
              error: !!error,
            })}
          >
            <QueryStatusWrapper loading={isInitialLoading} error={error}>
              {!isInitialLoading && designs.length === 0 ? (
                handleNoResults()
              ) : selectedView === views.LIST ? (
                <DesignsList {...designViewProps} sortBy={sortBy} onSort={handleSort} />
              ) : (
                <DesignsGrid {...designViewProps} />
              )}
            </QueryStatusWrapper>
          </div>
        </div>
      </S.DesignsContent>

      {paginationComponent}

      {/* MODALS */}
      {editNotesModal.isOpen && itemToEdit && (
        <ModalEditNotes
          isActive={editNotesModal.isOpen}
          size="s"
          title={t('design:edit-notes')}
          item={itemToEdit}
          onClose={() => editNotesModal.setModalOpen(false)}
          onSubmit={() => handleEditNotes()}
        />
      )}
      {viewNotesModal.isOpen && (
        <Modal
          title={t('design:view-note')}
          isActive={viewNotesModal.isOpen}
          onClose={() => viewNotesModal.setModalOpen(false)}
        >
          <Typography>{viewNotesModal.data || t('design:no-notes')}</Typography>
        </Modal>
      )}
      {itemsToDelete && itemsToDelete.length > 0 && (
        <Modal
          status="error"
          isActive={deleteModal.isOpen}
          size="s"
          title={
            itemsToDelete.length === 1
              ? t('design:delete-single-design-title', { designName: itemsToDelete[0].name })
              : t('design:delete-multiple-design-title', { num: itemsToDelete.length })
          }
          buttons={{
            primary: {
              text: t('action:delete'),
              onClick: () => {
                handleDelete(Array.isArray(itemsToDelete) ? itemsToDelete : [itemsToDelete]);
              },
              dataTest: IndexIds.Designs.ModalDeleteConfirmBtn,
            },
            secondary: {
              text: t('action:cancel'),
              onClick: () => deleteModal.setModalOpen(false),
            },
          }}
          onClose={() => deleteModal.setModalOpen(false)}
        >
          <Typography>{t('design:action-undone-warning')}</Typography>
        </Modal>
      )}
      <FilterSidebar
        isOpen={overlayProps.isOpen}
        onApply={overlayProps.onApply}
        onClear={overlayProps.onClear}
        onClose={toggleOverlay}
        onApplyTitle={t('action:apply')}
        onClearTitle={t('action:clear')}
        sidebarTitle={t('filters.title', 'Filters')}
      >
        <>
          {overlayProps.filters.map((filter) => (
            <FilterSidebarItem key={filter.title} title={filter.title} hasBorderBottom={true}>
              <CheckboxGroup
                direction="column"
                id={filter.code}
                onChange={(newOption) => {
                  overlayProps.onSelect(filter.code, newOption.value, newOption.checked);
                }}
                options={filter.options.map((option) => ({
                  checked: option.checked,
                  label: option.label,
                  value: option.code,
                }))}
              />
            </FilterSidebarItem>
          ))}
        </>
      </FilterSidebar>
    </S.DesignsContainer>
  );
};

export default Designs;
