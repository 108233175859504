import React, { useEffect, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TourProvider } from '@reactour/tour';

import {
  PrimaryActionTypes,
  useActionHistory,
  useAddNodeAndEdgeToOtherNode,
  useAddNodeToClosestNode,
  useAddNodeToExistingNodeMode,
  useAvailableAreas,
  useBatteryAreas,
  useBbox,
  useDetectIfClickIsInsideAvailableArea,
  useGenerateIlcDesign,
  useGetRoadOfAvailableArea,
  useModalState,
  useModes,
  usePointerType,
  useRectangleElements,
  useRemoveElements,
  useReset,
  useResetMode,
  useResetModeByStep,
  useRestrictedAreas,
  useRoads,
  useSessionActions,
  useSetActiveArea,
  useSubstationAreas,
  useSwitchingStationsAreas,
} from './ilc-store';
import { CameraControls, PerspectiveCamera } from '@react-three/drei';
import { UtmBoxIn, UtmBoxOut } from './webgl-components/utm-box';
import { BottomToolbarIn, BottomToolbarOut } from './components/bottom-toolbar';
import GraphLinesController from './webgl-components/interactive-graph-lines';
import StructuresInstancedMesh from './webgl-components/structures-instanced-mesh';
import SelectedElementsHOC from './webgl-components/selected-elements';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';
import { useKeyboardShortcut } from 'utils/hooks/use-keyboard-shortcut';
import { TopBar } from './components/topbar';
import { TopToolbar } from './components/top-toolbar';
import { ReferenceForRoads } from './webgl-components/reference-for-roads';
import { getWorldCoords } from './hooks/use-world-coords';
import { ReferenceStructureForAdd } from './webgl-components/reference-structure-for-add';
import { CustomRotationModal } from './components/custom-rotation-modal';
import { UserFeedbackModal } from './components/user-feedback-modal';
import { PolygonWithoutGeometry } from './components/polygon';
import { useILCLayoutElements } from './use-ilc-layout-elements-zustand';
import { RightSideBar } from './components/right-side-bar';
import { getPointerFromEvent, pointIsWithinAvailableArea } from './ilc-utils/geometry';
import { useToasts } from 'utils/hooks/use-toasts';
import { useTranslation } from 'react-i18next';
import { InstructionsBox } from './components/instructions-box';
import { TourPopover } from './components/layout-editor-tour/tour-popover';
import { steps } from './components/layout-editor-tour/tour-steps';
import LayoutEditorTour from './components/layout-editor-tour/layout-editor-tour';
import { PowerStationFilterPanel } from './components/kpis-display/power-station-filter-panel';
import { track } from 'rudderstack/utils';
import { RudderstackEvent } from 'rudderstack/types';
import { CartesianAxis } from './webgl-components/cartesian-axis';
import { UnsavedLayoutChangesModal } from './components/unsaved-layout-changes-modal';
import { centerAroundBbox } from './ilc-utils/camera';

const InteractiveLayoutCreator = () => {
  const cameraRef = useRef<any>();
  const generateIlcDesign = useGenerateIlcDesign();
  const navigate = useNavigate();
  const resetActions = useResetMode();
  const resetActionsByStep = useResetModeByStep();
  const addNodeToOtherNode = useAddNodeAndEdgeToOtherNode();
  const pointerType = usePointerType();
  const modalState = useModalState();
  const flags = useFlags();
  const degreesInRadians360 = Math.PI * 2;
  const { addErrorToast } = useToasts();
  const { t } = useTranslation('ilc');
  const showDevElements = { cartesianAxis: false };
  const sessionActions = useSessionActions();
  const actionHistory = useActionHistory();
  const currentActions = actionHistory.past;
  const persistedSessionActions = sessionActions ?? [];
  const someActionsApplied = [...persistedSessionActions, ...currentActions].length > 0;

  const { projectId } = useParams<{ projectId: string }>();

  const projectResultsUrl = `/projects/${projectId}/design-process/`;

  const handleRedirectToResults = (designId: string) => {
    if (!projectId) return;
    navigate(`${projectResultsUrl}${designId}`);
  };

  const addNodeToOtherNodeMode = useAddNodeToExistingNodeMode();

  const handleGenerateDesign = async () => {
    const result = await generateIlcDesign();
    if (result.error !== null) {
      addErrorToast(t(`errors.${result.error}`), 'top-center');
      return;
    }
    track(RudderstackEvent.PV_EDITED_GENERATE);
    handleRedirectToResults(result.data.simulationId);
  };

  useILCLayoutElements();

  const action = useModes().mode;
  const controls = useRef<typeof CameraControls>();

  useKeyboardShortcut(['escape'], resetActionsByStep);

  const rectangleElements = useRectangleElements();
  const availableAreas = useAvailableAreas();
  const substationAreas = useSubstationAreas();
  const reset = useReset();
  const setActiveArea = useSetActiveArea();

  const roads = useRoads();

  const removeElements = useRemoveElements();

  const deleteElements = () => {
    if (document.activeElement?.tagName === 'BODY') {
      removeElements();
      resetActions();
    }
  };

  useKeyboardShortcut(['delete'], deleteElements);

  const restrictedAreas = useRestrictedAreas();
  const switchingStationAreas = useSwitchingStationsAreas();
  const batteryAreas = useBatteryAreas();

  const bbox = useBbox();

  const addNodeToClosest = useAddNodeToClosestNode();
  const detectIfClickIsInsideAvailableArea = useDetectIfClickIsInsideAvailableArea();
  const getRoadOfAvailableArea = useGetRoadOfAvailableArea();

  const handlePointerDownOnArea = (event) => {
    const intersectingObject = event.intersections[0]?.object;
    const clickingOnAreaDirectly = intersectingObject.userId === event.object.userId;
    if (!clickingOnAreaDirectly) return;
    if (event.shiftKey) return;
    if (event.buttons === 2) return;
    const clickPosition = getWorldCoords(
      getPointerFromEvent(event, {
        width: window.innerWidth,
        height: window.innerHeight,
      }),
      cameraRef.current,
      'XZ'
    );

    if (action.type === PrimaryActionTypes.ADD_NEW_VERTEX_TO_EXISTING_VERTEX && action.payload?.connectedNode) {
      if (!availableAreas) return;
      const availableAreaOfInitialPoint = availableAreas[action.payload.connectedNode.initialNodeAreaId];
      const newPoint = { x: clickPosition.x, y: clickPosition.y };
      const pointIsInsideAvailableArea = pointIsWithinAvailableArea(newPoint, availableAreaOfInitialPoint);
      if (!pointIsInsideAvailableArea) {
        addErrorToast(t('errors.connect-to-node-different-area'), 'top-center');
        return;
      }
      const newNodeId = addNodeToOtherNode(
        newPoint,
        action.payload.connectedNode.initialNodeId,
        action.payload.connectedNode.roadId
      );
      if (newNodeId) {
        addNodeToOtherNodeMode({
          connectedNode: {
            initialNodeId: newNodeId,
            roadId: action.payload.connectedNode.roadId,
            initialNodeAreaId: action.payload.connectedNode.initialNodeAreaId,
          },
        });
      }
    }

    if (action.type === PrimaryActionTypes.ADD_NODE_TO_CLOSEST) {
      const areaId = detectIfClickIsInsideAvailableArea({ x: clickPosition.x, y: clickPosition.y });
      if (areaId) {
        const roadId = getRoadOfAvailableArea(areaId);
        if (roadId) {
          addNodeToClosest({ x: clickPosition.x, y: clickPosition.y }, roadId);
        }
      }
    }
  };

  useEffect(() => {
    return () => reset();
  }, []);

  useEffect(() => {
    if (bbox && controls) centerAroundBbox(bbox, controls.current);
  }, [bbox, controls]);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const willRedirectToResults = nextLocation.pathname.includes(projectResultsUrl);
    if (willRedirectToResults) return false;
    return someActionsApplied && currentLocation.pathname !== nextLocation.pathname;
  });

  if (!flags.skuIlc) return <div style={{ padding: '24px' }}>Layout editor not enabled</div>;

  return (
    <TourProvider steps={steps(t)} ContentComponent={TourPopover}>
      <LayoutEditorTour />
      <TopBar handleGenerateDesign={handleGenerateDesign} />
      <TopToolbar />
      <RightSideBar controls={controls.current} />
      {modalState && modalState.modalName === 'custom-ps-rotation' && <CustomRotationModal modalState={modalState} />}
      {modalState && modalState.modalName === 'user-feedback-modal' && <UserFeedbackModal />}
      {blocker.state === 'blocked' && <UnsavedLayoutChangesModal blocker={blocker} />}
      <Canvas
        style={{
          cursor: pointerType === 'MOVE' ? 'grab' : undefined,
          backgroundColor: '#F5F5F5',
        }}
        gl={{ pixelRatio: 2 }}
        dpr={2}
      >
        <PerspectiveCamera ref={cameraRef} fov={60} far={20000} near={1} makeDefault />

        <CameraControls
          makeDefault
          mouseButtons={{ left: pointerType === 'SELECT' ? 0 : 2, right: 2, wheel: 8, middle: 2 }}
          maxAzimuthAngle={degreesInRadians360}
          minPolarAngle={-Infinity}
          maxPolarAngle={Infinity}
          ref={controls}
          dollyToCursor={true}
          minDistance={50}
        />

        {availableAreas &&
          Object.keys(availableAreas).length > 0 &&
          Object.keys(availableAreas).map((key, index) => (
            <PolygonWithoutGeometry
              renderOrder={index}
              key={key}
              userId={key}
              color={availableAreas[key].color}
              areaPolygon={availableAreas[key].exteriorRing}
              innerRings={availableAreas[key].interiorRings}
              type={availableAreas[key].type}
              yPosition={1}
              onPointerEnter={() => {
                setActiveArea(availableAreas[key]);
              }}
              onPointerLeave={() => setActiveArea(null)}
              onPointerDown={handlePointerDownOnArea}
            />
          ))}

        {restrictedAreas &&
          Object.keys(restrictedAreas).length > 0 &&
          Object.keys(restrictedAreas).map((key, index) => (
            <PolygonWithoutGeometry
              renderOrder={index}
              key={key}
              userId={key}
              color={restrictedAreas[key].color}
              areaPolygon={restrictedAreas[key].exteriorRing}
              innerRings={restrictedAreas[key].interiorRings}
              type={restrictedAreas[key].type}
              yPosition={2}
            />
          ))}

        {substationAreas &&
          Object.keys(substationAreas).length > 0 &&
          Object.keys(substationAreas).map((key, index) => (
            <PolygonWithoutGeometry
              renderOrder={index}
              key={key}
              userId={key}
              color={substationAreas[key].color}
              areaPolygon={substationAreas[key].exteriorRing}
              innerRings={substationAreas[key].interiorRings}
              type={substationAreas[key].type}
              yPosition={3}
            />
          ))}

        {switchingStationAreas &&
          Object.keys(switchingStationAreas).length > 0 &&
          Object.keys(switchingStationAreas).map((key, index) => (
            <PolygonWithoutGeometry
              renderOrder={index}
              key={key}
              userId={key}
              color={switchingStationAreas[key].color}
              areaPolygon={switchingStationAreas[key].exteriorRing}
              innerRings={switchingStationAreas[key].interiorRings}
              type={switchingStationAreas[key].type}
              yPosition={3}
            />
          ))}

        {batteryAreas &&
          Object.keys(batteryAreas).length > 0 &&
          Object.keys(batteryAreas).map((key, index) => (
            <PolygonWithoutGeometry
              renderOrder={index}
              key={key}
              userId={key}
              color={batteryAreas[key].color}
              areaPolygon={batteryAreas[key].exteriorRing}
              innerRings={batteryAreas[key].interiorRings}
              type={batteryAreas[key].type}
              yPosition={3}
            />
          ))}

        {rectangleElements && <StructuresInstancedMesh elements={rectangleElements} />}
        <SelectedElementsHOC />
        <ReferenceForRoads />
        <ReferenceStructureForAdd />
        {roads && <GraphLinesController nodesGraph={roads} />}
        {showDevElements.cartesianAxis && <CartesianAxis />}

        <UtmBoxIn />
        <BottomToolbarIn />
      </Canvas>
      <UtmBoxOut />
      <BottomToolbarOut />
      <InstructionsBox />
      <PowerStationFilterPanel />
    </TourProvider>
  );
};

export default InteractiveLayoutCreator;
