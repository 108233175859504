import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterSidebar, FilterSidebarItem, RadioOption, RadioGroupOptionList } from '@ratedpower/components';
import { DateRange, ISearchListItem } from 'types/filter';
import CheckboxListFilter from 'components/filters/checkbox-list-filter/checkbox-list-filter';
import DateFilter from 'components/filters/date-filter/date-filter';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { companyFilters } from 'utils/constants/filters';
import { useCompanySearch } from 'utils/hooks/use-company-search';
import { toDayEnd } from 'utils/date';
import { COMPANY_SEARCH_MANUALLY } from 'utils/constants/statistics';
import { IInputTemplatesFilters, IInputTemplatesServerFilter } from 'types/input-templates';
import { initialInputTemplatesFilters, mapInputTemplatesToServerFilters } from './input-templates-constants';
import { useInputTemplatesCreators } from './hooks/use-input-templates-creators';

export interface IInputTemplatesFiltersPanel {
  id: string;
  active: boolean;
  companyId: string;
  onHide: () => void;
  onApplyFilters: (filters: IInputTemplatesServerFilter) => void;
  showCompanyFilters: boolean;
}

const InputTemplatesFiltersPanel: React.FC<IInputTemplatesFiltersPanel> = ({
  id,
  active,
  companyId = '',
  showCompanyFilters,
  onHide,
  onApplyFilters,
}) => {
  const { t } = useTranslation();
  const { isAuthorized } = useAuthorization();
  const [currentFilters, setCurrentFilters] = useState<IInputTemplatesFilters>(initialInputTemplatesFilters);
  const [showCompanySearch, setShowCompanySearch] = useState(true);
  const currentFiltersRef = useRef<IInputTemplatesFilters>(initialInputTemplatesFilters);

  const {
    data: creators,
    error: errorCreators,
    paginationComponent: creatorsPagination,
    setSearchBy: setSearchByCreators,
  } = useInputTemplatesCreators(companyId);
  const {
    data: companies,
    error,
    paginationComponent: companiesPagination,
    setSearchBy: setSearchByCompanies,
  } = useCompanySearch();

  useEffect(() => {
    if (!(companyId && !currentFilters.companyIds.length)) return;

    setCurrentFilters((filters) => ({ ...filters, companyIds: [companyId] }));
  }, [companyId]);

  const handleApply = () => {
    const newFilters = JSON.parse(JSON.stringify(mapInputTemplatesToServerFilters(currentFilters)));
    onApplyFilters({
      filters: newFilters,
      isIncludedRatedPower: currentFilters.isIncludedRatedPower,
    });

    currentFiltersRef.current = currentFilters;
    onHide();
  };

  const handleReset = () => {
    setCurrentFilters({
      companyIds: [companyId],
      dateRange: {
        from: initialInputTemplatesFilters.dateRange.from,
        to: toDayEnd(new Date()).toISOString(),
      },
      creators: [],
      isIncludedRatedPower: true,
    });
  };

  const canSeeCompanyFilters = showCompanyFilters && isAuthorized(Permission.INPUT_TEMPLATE_SUPPORT_WRITE);

  return (
    <FilterSidebar
      onClose={onHide}
      isOpen={active}
      onApply={handleApply}
      onClear={handleReset}
      onApplyTitle={t('action:apply')}
      onClearTitle={t('action:clear')}
      sidebarTitle={t('filters.title', 'Filters')}
    >
      <FilterSidebarItem title={t('filters.date-range-creation')} hasBorderBottom>
        <DateFilter
          id={id}
          onChangeDateRange={(newDateRange: DateRange) => {
            setCurrentFilters((filters) => ({
              ...filters,
              dateRange: newDateRange,
            }));
          }}
          currentDateRange={currentFilters.dateRange}
        />
      </FilterSidebarItem>

      {canSeeCompanyFilters && (
        <FilterSidebarItem title={t('company')} hasBorderBottom maxHeightContent="none">
          <RadioGroupOptionList $direction="column">
            {companyFilters.map((option, key) => (
              <RadioOption
                key={option.label}
                info={key === 1 ? t('filters.all-companies-rated-power-inc-info') : ''}
                option={{ label: t(`${option.label}`), value: '' }}
                checked={currentFilters.isIncludedRatedPower === option.value && !showCompanySearch}
                onChange={() => {
                  setCurrentFilters({
                    ...currentFilters,
                    companyIds: [],
                    isIncludedRatedPower: option.value,
                  });
                  setShowCompanySearch(false);
                }}
              />
            ))}
            <RadioOption
              option={{ label: t(COMPANY_SEARCH_MANUALLY), value: t(COMPANY_SEARCH_MANUALLY) }}
              onChange={() => {
                setCurrentFilters({
                  ...currentFilters,
                  companyIds: companyId ? [...currentFilters.companyIds, companyId] : currentFilters.companyIds,
                  isIncludedRatedPower: true,
                });
                setShowCompanySearch(true);
              }}
              checked={showCompanySearch}
            />
          </RadioGroupOptionList>
          {showCompanySearch && companies && (
            <CheckboxListFilter
              items={companies.map((company: ISearchListItem) =>
                currentFilters.companyIds.includes(company.id) ? { ...company, checked: true } : company
              )}
              onToggleItem={(companyIds: string[]) => {
                setCurrentFilters({ ...currentFilters, companyIds });
              }}
              onSearch={setSearchByCompanies}
              paginationComponent={companiesPagination}
              error={error}
            />
          )}
        </FilterSidebarItem>
      )}
      <FilterSidebarItem title={t('filters.creator')} maxHeightContent="none">
        <CheckboxListFilter
          id={`creators-${id}`}
          error={errorCreators}
          items={creators.map((creator: ISearchListItem) =>
            currentFilters.creators.includes(creator.id) ? { ...creator, checked: true } : creator
          )}
          onToggleItem={(selectedCreators: string[]) =>
            setCurrentFilters({ ...currentFilters, creators: selectedCreators })
          }
          onSearch={setSearchByCreators}
          paginationComponent={creatorsPagination}
        />
      </FilterSidebarItem>
    </FilterSidebar>
  );
};

export { InputTemplatesFiltersPanel };
