import { GET_PROJECT_CREATORS } from 'services/projects-gql';
import { useCustomQuery } from 'utils/hooks/use-custom-query';
import { ISearchProps, useSearch } from 'utils/hooks/use-search';

export interface IUseGetProjectCreatos {
  fetchedCreators: any;
  unknownCreators: IProjectCreator[];
  loadingCreators: boolean;
  inputSearchProps: ISearchProps;
  paginationComponent: any;
}

export interface IProjectCreator {
  name: string;
  id: string;
  lastname: string;
}

export const useGetProjectCreatos = (): IUseGetProjectCreatos => {
  const { getSearchFilter, inputSearchProps } = useSearch();
  const {
    loading,
    data: fetchedCreators,
    paginationComponent,
  } = useCustomQuery(
    GET_PROJECT_CREATORS,
    {
      variables: {
        search: getSearchFilter()?.[0],
        pagination: {
          page: 0,
          size: 12,
        },
      },
      fetchPolicy: 'network-only',
    },
    true
  );

  function getCreators() {
    if (fetchedCreators) {
      return fetchedCreators.result.content
        .filter((user: IProjectCreator) => user.name === '-' && user.lastname === '-')
        .map((user: IProjectCreator) => user.id);
    }
    return [''];
  }

  return {
    fetchedCreators: fetchedCreators?.result.content || [],
    unknownCreators: getCreators(),
    loadingCreators: loading,
    paginationComponent,
    inputSearchProps,
  };
};
