import saveAs from 'file-saver';
import rudderstack from 'rudderstack/utils';
import { RudderstackEvent, ComparisonExportType } from 'rudderstack/types';
import { getApiUrl } from 'utils/api_url';
import { useRestClient } from 'utils/hooks/use-rest-client';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ComparisonView, IComparisonFilter } from 'types/design-comparison.types';
import { DesignComparisonTableFilters as allTableFilters } from 'utils/constants/design-comparison';
import { useDesignComparisonData } from './design-comparison-state';
import { useTranslation } from 'react-i18next';

export const useExportDesignComparison = (viewShown: ComparisonView, activeFilters: IComparisonFilter[]) => {
  const { t } = useTranslation();

  const profile = useSelector((state: RootState) => state.currentUser.profile);
  const { selectedDesigns, project } = useDesignComparisonData();

  const { post } = useRestClient();

  const disableExportComparisonCondition =
    process.env.NODE_ENV === 'production' && !!project.companyId && project.companyId !== profile?.companyId;

  const handleSendIds = async () => {
    if (viewShown === 'chart') {
      rudderstack.track(RudderstackEvent.COMPARISON_EXPORTED, { export_type: ComparisonExportType.CHART });
      const url = `${getApiUrl()}/simulations/comparison-excel-chart`;

      const body = {
        ids: selectedDesigns,
        charts: activeFilters.map((chartAttribute) => chartAttribute.label.split('comparison-charts.')[1]),
        translates: getTranslates(),
      };

      const response = await post(url, body, { responseType: 'blob' });
      if (response.data) {
        const blob = new Blob([response.data]);
        saveAs(blob, 'comparison_charts.xlsx');
      }
    } else {
      rudderstack.track(RudderstackEvent.COMPARISON_EXPORTED, { export_type: ComparisonExportType.TABLE });

      const url = `${getApiUrl()}/simulations/comparison-excel`;

      const body = {
        ids: selectedDesigns,
        fields: activeFilters.map((tableAttribute) => tableAttribute.label),
        translates: getTranslates(),
      };

      const response = await post(url, body, { responseType: 'blob' });
      if (response.data) {
        const blob = new Blob([response.data]);
        saveAs(blob, 'comparison.xlsx');
      }
    }
  };

  const getTranslates = () => {
    const tableTranslates: { field: string; translate: string }[] = [];
    allTableFilters.forEach((table) => {
      table.filters.forEach((filter) =>
        tableTranslates.push({
          field: filter.label,
          translate: t(`design:${filter.label}`),
        })
      );
    });

    const extraTranslates = [
      {
        field: 'design',
        translate: t('design:design'),
      },
      {
        field: 'structurePitchMax',
        translate: t('design:structurePitchMax'),
      },
      {
        field: 'structurePitchMin',
        translate: t('design:structurePitchMin'),
      },
      {
        field: 'gcr_min',
        translate: t('design:gcr_min'),
      },
      {
        field: 'gcr_max',
        translate: t('design:gcr_max'),
      },
      {
        field: 'currency',
        translate: t('design:currency'),
      },
      {
        field: 'energyMonthly',
        translate: t('design:comparison-charts.monthly-energy'),
      },
      {
        field: 'monthly',
        translate: t('common:monthly'),
      },
      {
        field: 'month.1',
        translate: t('common:month-1'),
      },
      {
        field: 'month.2',
        translate: t('common:month-2'),
      },
      {
        field: 'month.3',
        translate: t('common:month-3'),
      },
      {
        field: 'month.4',
        translate: t('common:month-4'),
      },
      {
        field: 'month.5',
        translate: t('common:month-5'),
      },
      {
        field: 'month.6',
        translate: t('common:month-6'),
      },
      {
        field: 'month.7',
        translate: t('common:month-7'),
      },
      {
        field: 'month.8',
        translate: t('common:month-8'),
      },
      {
        field: 'month.9',
        translate: t('common:month-9'),
      },
      {
        field: 'month.10',
        translate: t('common:month-10'),
      },
      {
        field: 'month.11',
        translate: t('common:month-11'),
      },
      {
        field: 'month.12',
        translate: t('common:month-12'),
      },
    ];

    return [...tableTranslates, ...extraTranslates];
  };

  return {
    handleSendIds,
    disableExportComparisonCondition,
  };
};
