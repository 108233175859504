import DateFilter from 'components/filters/date-filter/date-filter';
import TeamsFilter from 'components/filters/teams-filter';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxGroup, FilterSidebar, FilterSidebarItem, ICheckboxOption } from '@ratedpower/components';
import { DateRange, IServerFilter } from 'types/filter';
import { IDesignsTabFilters } from 'types/support.types';
import { DESIGNS_STATUS_OPTIONS, SIMULATION_RESULTS_OPTIONS, designsTabInitialFilters } from 'utils/constants/support';
import UsersFilter from '../activity-tab/filters/users-filter';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';

interface IProps {
  isOpen: boolean;
  onApply: (filters: IServerFilter[]) => void;
  onClose: () => void;
  onClear: () => void;
}

const DesignsTabFilters = ({ isOpen, onApply, onClose, onClear }: IProps) => {
  const { t } = useTranslation();
  const { isAuthorized } = useAuthorization();
  const [currentFilters, setCurrentFilters] = useState<IDesignsTabFilters>(designsTabInitialFilters);

  const handleApply = () => {
    const filters: IServerFilter[] = [];
    Object.entries(currentFilters).forEach(([key, values]) => {
      if (key === 'status' && values.length) {
        filters.push({ field: 'SIMULATION_STATUS', operator: 'IN', values });
      }
      if (key === 'users' && values.length) {
        filters.push({ field: 'CREATED_BY', operator: 'IN', values });
      }
      if (key === 'simulationResults' && values.length) {
        filters.push({ field: 'SIMULATION_RESULT', operator: 'IN', values });
      }
      if (key === 'dateRange') {
        filters.push({ field: 'PUBLISHED_DATE', operator: 'GREATER_OR_EQUAL', values: [new Date(values.from)] });
        filters.push({ field: 'PUBLISHED_DATE', operator: 'LESS_OR_EQUAL', values: [new Date(values.to)] });
      }
      if (key === 'teamIds' && values.length) {
        filters.push({ field: 'TEAM_ID', operator: 'IN', values });
      }
    });
    onApply(JSON.parse(JSON.stringify(filters)));
  };

  const onToggleFilter = (element: string, checked: boolean, objectKey: keyof IDesignsTabFilters) => {
    if (checked) {
      setCurrentFilters({
        ...currentFilters,
        [objectKey]: [...currentFilters[objectKey], element],
      });
      return;
    }
    const index = currentFilters[objectKey].findIndex((current: string) => current === element);
    currentFilters[objectKey].splice(index, 1);
    setCurrentFilters({
      ...currentFilters,
      [objectKey]: [...currentFilters[objectKey]],
    });
  };

  const handleClear = () => {
    setCurrentFilters(designsTabInitialFilters);
    onClear();
  };

  return (
    <FilterSidebar
      onApply={handleApply}
      onClear={handleClear}
      isOpen={isOpen}
      onClose={onClose}
      onApplyTitle={t('action:apply')}
      onClearTitle={t('action:clear')}
      sidebarTitle={t('filters.title', 'Filters')}
    >
      <FilterSidebarItem hasBorderBottom title={t('support:designs-tab.status')}>
        <CheckboxGroup
          id="status-filter"
          direction="column"
          options={DESIGNS_STATUS_OPTIONS.map((option: ICheckboxOption) => ({
            ...option,
            checked: currentFilters.status.includes(option.value),
          }))}
          onChange={(newOption) => {
            onToggleFilter(newOption.value, newOption.checked, 'status');
          }}
        />
      </FilterSidebarItem>
      <FilterSidebarItem title={t('filters.time')} hasBorderBottom>
        <DateFilter
          onChangeDateRange={(newDateRange: DateRange) => {
            setCurrentFilters({
              ...currentFilters,
              dateRange: {
                from: newDateRange.from,
                to: newDateRange.to,
              },
            });
          }}
          currentDateRange={currentFilters.dateRange}
        />
      </FilterSidebarItem>
      <FilterSidebarItem hasBorderBottom title={t('support:designs-tab.simulation-result')}>
        <CheckboxGroup
          id="simulation-result-filter"
          direction="column"
          options={SIMULATION_RESULTS_OPTIONS.map((option: ICheckboxOption) => ({
            ...option,
            label: t(option.label as string),
            checked: currentFilters.simulationResults.includes(option.value),
          }))}
          onChange={(newOption) => {
            onToggleFilter(newOption.value, newOption.checked, 'simulationResults');
          }}
        />
      </FilterSidebarItem>
      {isAuthorized(Permission.TEAM_SUPPORT_READ) && (
        <TeamsFilter
          teamsChecked={currentFilters.teamIds}
          onChangeTeams={(newOption) => {
            onToggleFilter(newOption.value, newOption.checked, 'teamIds');
          }}
        />
      )}
      <FilterSidebarItem title={t('support:users')} maxHeightContent="100%">
        <UsersFilter
          onChangeUsers={(users: string[]) => setCurrentFilters((actualFilters) => ({ ...actualFilters, users }))}
        />
      </FilterSidebarItem>
    </FilterSidebar>
  );
};

export default DesignsTabFilters;
