import React from 'react';
import { useUsersManagementContext } from 'pages/users-management/users-management-data';
import UsersTabRoleSwitch from '../../../components/users-tab-role-switch';

const UsersManagementRoleSwitch: React.FC = () => {
  const {
    useUsersObject: { filters, userRoleSummary, userSummaryLoading, setFilters },
  } = useUsersManagementContext();

  const filtersWithoutRole = filters?.filter((serverFilter) => serverFilter.field !== 'ROLE');

  const onClickAll = () => {
    setFilters(filtersWithoutRole);
  };

  const onClickRole = (roleSelected: string) => {
    setFilters([...filtersWithoutRole, { field: 'ROLE', operator: 'IN', values: [roleSelected] }]);
  };

  return (
    <UsersTabRoleSwitch
      roleFilterSelected={filters?.find((filter) => filter.field === 'ROLE')}
      onClickRole={onClickRole}
      onClickAll={onClickAll}
      loading={userSummaryLoading}
      userRoleSummary={userRoleSummary || []}
    />
  );
};

export default UsersManagementRoleSwitch;
