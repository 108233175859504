import styled, { css } from 'styled-components';
import { Tag } from '@ratedpower/components';

export const centerGrid = (width: string) => css`
  justify-content: center;
  display: grid;
  width: ${width};
`;

export const DesignColumn = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.v2.spacing.m};
  justify-content: right;
`;

export const CustomTag = styled(Tag)`
  max-width: fit-content;
`;
