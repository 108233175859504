import {
  CapexCardContainer,
  CapexCardContent,
  CapexCardHeader,
  CardFooter,
  CardRow,
} from 'components/capex/capex-card/capex-card.styled';
import styled from 'styled-components';

export const InputTemplateContainer = styled(CapexCardContainer)<{ $cursorPointerOnHover: boolean }>`
  width: 100%;
  cursor: ${({ $cursorPointerOnHover }) => ($cursorPointerOnHover ? 'pointer' : 'initial')};
`;
export const InputTemplateHeader = styled(CapexCardHeader)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ theme }) => theme.v2.spacing.l} ${({ theme }) => theme.v2.spacing.m};
`;
export const InputTemplateContent = styled(CapexCardContent)``;
export const InputTemplateContentField = styled(CardRow)``;
export const InputTemplateFooter = styled(CardFooter)<{ $cursorPointerOnHover: boolean }>`
  cursor: ${({ $cursorPointerOnHover }) => ($cursorPointerOnHover ? 'pointer' : 'initial')};
`;
