import styled from 'styled-components';
import { Typography } from '@ratedpower/components';

export const PowerStationBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.v2.spacing.xxs};
`;

export const PowerStationInfo = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.v2.spacing.xs};
  align-items: center;

  p:first-of-type {
    flex-grow: 1;
  }
`;

export const RatedAndPeakPower = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.v2.spacing.xxs};

  p {
    span {
      font-size: ${({ theme }) => theme.v2.font.size.s};
      color: ${({ theme }) => theme.v2.text.secondary.default};
    }
  }
`;

export const CollapseContent = styled.div`
  gap: ${({ theme }) => theme.v2.spacing.xs};
`;

export const CounterWrapper = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.xs} 0;
  gap: ${({ theme }) => theme.v2.spacing.xxs};
`;

export const HelperText = styled(Typography)`
  color: ${({ theme }) => theme.v2.text.secondary.default};
  font-size: ${({ theme }) => theme.v2.font.size.s};
  text-align: right;
`;

export const Divider = styled.hr`
  width: 100%;
  margin: 0;
  height: 1px;
  background-color: ${({ theme }) => theme.v2.stroke.primary.default};
`;
