import { useCustomQuery } from 'utils/hooks/use-custom-query';
import { GET_INPUT_TEMPLATES } from 'services/design-process-gql';
import { useSearch } from 'utils/hooks/use-search';
import { useEffect, useState } from 'react';
import { InputTemplate, InputTemplateDto } from 'types/design-process';
import { getDateMonthDDYYYY, toDayEnd } from 'utils/date';
import { useTranslation } from 'react-i18next';
import { Permission } from 'access-control/permissions';
import { useAuthorization } from 'access-control/can';
import { RootState } from 'redux/root-reducer';
import { useSelector } from 'react-redux';
import { IOption } from '@ratedpower/components';
import { ITeam } from 'types/teams';
import { IInputTemplatesServerFilter } from 'types/input-templates';
import { initialInputTemplatesFilters } from '../input-templates-constants';
import { useListCompanyTeams } from 'pages/support/company-activity/activity-tab/hooks/use-list-company-teams';
import { operators } from 'services/operators';
import { fields } from 'services/fields';
import { useListTeams } from 'pages/teams/hooks/use-list-teams';

export const SAVE_INPUT_TEMPLATE_PERMISSIONS = [
  Permission.PROJECT_SUPPORT_CREATE,
  Permission.PROJECT_CREATE_IN_USER_COMPANY,
  Permission.PROJECT_CREATE_IN_USER_TEAM_AND_PUBLIC,
];
export const LOAD_INPUT_TEMPLATE_PERMISSIONS = [
  Permission.SIMULATION_SUPPORT_CREATE,
  Permission.SIMULATION_CREATE_IN_USER_COMPANY,
  Permission.SIMULATION_CREATE_IN_USER_TEAM_AND_PUBLIC,
];
export const VIEW_INPUT_TEMPLATE_PERMISSIONS = LOAD_INPUT_TEMPLATE_PERMISSIONS;
export const EDIT_DELETE_INPUT_TEMPLATE_PERMISSIONS = [
  Permission.INPUT_TEMPLATE_SUPPORT_WRITE,
  Permission.INPUT_TEMPLATE_WRITE_IN_USER_COMPANY,
  Permission.INPUT_TEMPLATE_WRITE_IN_USER_TEAM_ALL_AND_PUBLIC_IF_IS_CREATOR,
  Permission.INPUT_TEMPLATE_WRITE_IN_USER_TEAM_AND_PUBLIC_IF_IS_CREATOR,
];

type UseInputTemplateCommonProps = {
  isOtherCompany: boolean;
  projectTeamId?: string;
  companyId?: string;
  noFilters?: boolean;
};

export const useInputTemplatesCommon = ({
  isOtherCompany,
  projectTeamId,
  companyId,
  noFilters,
}: UseInputTemplateCommonProps) => {
  const [inputTemplatesData, setInputTemplatesData] = useState<InputTemplate[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<IOption | undefined>(undefined);
  const { t } = useTranslation();
  const { hasAtLeastOneValidAuthorization } = useAuthorization();
  const { getSearchFilter, inputSearchProps } = useSearch();
  const user = useSelector((state: RootState) => state.currentUser.profile);
  const [inputTemplatesServerFilter, setInputTemplatesServerFilter] = useState<IInputTemplatesServerFilter>({
    filters: [],
    isIncludedRatedPower: true,
  });

  const { teams: companyTeams } = useListCompanyTeams({
    serverFilters: [],
    companyId: companyId,
    skip: !isOtherCompany,
  });
  const { teams: teamsData } = useListTeams({ noCompanyId: true });
  const teamsResponse = companyTeams ?? teamsData ?? [];
  const teamOptions: IOption[] = [
    { label: t('user:teams.public-teams'), value: null },
    ...teamsResponse.map((team: ITeam) => ({
      label: team.name,
      value: team.id,
    })),
  ];

  useEffect(() => {
    setInputTemplatesServerFilter({
      isIncludedRatedPower: true,
      filters: [
        {
          field: fields.COMPANY_ID,
          operator: operators.IN,
          values: [companyId],
        },
        {
          field: fields.PUBLISHED_DATE,
          operator: operators.GREATER_OR_EQUAL,
          values: [initialInputTemplatesFilters.dateRange.from],
        },
        {
          field: fields.PUBLISHED_DATE,
          operator: operators.LESS_OR_EQUAL,
          values: [toDayEnd(new Date()).toISOString()],
        },
      ],
    });
  }, [companyId]);

  const projectTeam = teamOptions.find((team) => team.value === projectTeamId);
  const currentTeam = selectedTeam ?? projectTeam ?? teamOptions[0];
  const filters = noFilters ? [] : inputTemplatesServerFilter?.filters;
  const defaultNumberOfItems = 20;
  const inputTemplatesResponse = useCustomQuery(GET_INPUT_TEMPLATES, {
    variables: {
      teamId: currentTeam.value,
      companyId: companyId,
      search: getSearchFilter ? getSearchFilter() : '',
      filters,
      isIncludedRatedPower: inputTemplatesServerFilter?.isIncludedRatedPower ?? true,
      pagination: {
        page: 0,
        size: defaultNumberOfItems,
      },
    },
    fetchPolicy: 'network-only',
    skip: !companyId,
  });
  const { loading, data: fetchedData, error, currentPage, paginationComponent } = inputTemplatesResponse;

  const hasEditDeletePermissions = hasAtLeastOneValidAuthorization(EDIT_DELETE_INPUT_TEMPLATE_PERMISSIONS);
  const isRoleNameUser = user?.roleName === 'User';
  const isRoleNameTeamManager = user?.roleName === 'Team Manager';
  const userId = user?.id;
  useEffect(() => {
    if (!fetchedData) return;
    const { result: response } = fetchedData;
    if (!response?.content || !response?.pageInfo) return;
    const { content: fetchedInputTemplates } = response;
    const formattedInputTemplates: InputTemplate[] = fetchedInputTemplates.map((inputTemplate: InputTemplateDto) => {
      const isInputTemplateCreator = inputTemplate.createdBy.id === userId;
      const isNotPublicTeam = !!inputTemplate.team?.name;
      const canEditDelete = isRoleNameUser
        ? isInputTemplateCreator
        : isRoleNameTeamManager
        ? isNotPublicTeam || isInputTemplateCreator
        : hasEditDeletePermissions;

      return {
        ...inputTemplate,
        title: inputTemplate.name,
        body: [
          { label: t('created-by'), value: `${inputTemplate.createdBy.name} ${inputTemplate.createdBy.surname}` },
          { label: t('created-on'), value: getDateMonthDDYYYY(inputTemplate.publishedDate) },
          {
            label: t('last-modify-by'),
            value: `${inputTemplate.lastModifiedBy.name} ${inputTemplate.lastModifiedBy.surname}`,
          },
          { label: t('last-modify'), value: getDateMonthDDYYYY(inputTemplate.modifiedDate) },
          { label: t('user:teams.team'), value: inputTemplate.team?.name ?? t('user:teams.public-teams') },
        ],
        canEditDelete,
      };
    });
    setInputTemplatesData(formattedInputTemplates);
  }, [fetchedData, currentPage]);

  return {
    inputTemplatesData,
    currentTeam,
    setSelectedTeam,
    teamOptions,
    loading,
    error,
    paginationComponent,
    currentPage,
    inputSearchProps,
    setInputTemplatesServerFilter,
  };
};
