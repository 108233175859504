import React from 'react';
import * as S from './empty-state.styled';
import { Typography } from '@ratedpower/components';
import { ZeroDesignsSvg } from 'assets/icons';

interface IProps {
  title: string;
  subtitle: string;
  image?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const EmptyState: React.FC<IProps> = ({ image, title, subtitle }) => (
  <S.EmptyContentContainer>
    {image ? React.createElement(image) : <ZeroDesignsSvg />}
    <Typography weight="bold">{title}</Typography>
    <Typography size="s">{subtitle}</Typography>
  </S.EmptyContentContainer>
);
