import React, { useState } from 'react';
import * as S from './validation-errors-display.styled';
import {
  useUpdateSelectedResultTab,
  useUpdateSelectedAreaTab,
  useValidationErrorsState,
  useValidationErrorStatus,
} from '../../ilc-store';
import { Notification, SectionHeader, StatusSVGWrapper } from '@ratedpower/components';
import { ChevronDownSvg, ChevronUpSvg } from 'assets/icons';
import { Collapse } from 'react-collapse';
import { ValidationsDisplay } from './validations-display';
import { IlcValidationError } from '../../ilc-types';
import { CameraControls } from '@react-three/drei';
import { useTranslation } from 'react-i18next';
import { track } from 'rudderstack/utils';
import { RudderstackEvent } from 'rudderstack/types';

interface IValidationErrorsDisplay {
  validationErrors: IlcValidationError[];
  controls: typeof CameraControls | undefined;
}

function ValidationErrorsDisplay({ validationErrors, controls }: Readonly<IValidationErrorsDisplay>) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { selectedAreaTab, selectedResultTab, areasWithError } = useValidationErrorsState();
  const updateSelectedResultTab = useUpdateSelectedResultTab();
  const updateSelectedAreaTab = useUpdateSelectedAreaTab();
  const validationErrorStatus = useValidationErrorStatus();
  const { t } = useTranslation('ilc');

  const setSelectedTab = (result: 'BLOCKER' | 'WARNING') => updateSelectedResultTab(result);

  const setSelectedAreaTab = (tabId: string) => updateSelectedAreaTab(tabId);

  return (
    <S.ValidationErrorsDisplayWrapper>
      <SectionHeader
        title={t('validations-instructions.list-of-validations')}
        leftSideOptions={
          areasWithError.length > 0 ? (
            <StatusSVGWrapper
              icon={isCollapsed ? ChevronDownSvg : ChevronUpSvg}
              onClick={() => {
                setIsCollapsed(!isCollapsed);
                track(RudderstackEvent.PV_EDITED_VALIDATIONS_CLICK);
              }}
              size="m"
              active={false}
            />
          ) : null
        }
      />
      {validationErrorStatus === 'error' ? (
        <S.ValidationStatusWrapper>
          <Notification
            title="Error"
            description="Could not process the validation of the design"
            status="error"
            heightFitContent
            hideCloseButton
          />
        </S.ValidationStatusWrapper>
      ) : validationErrorStatus === 'success' ? (
        <S.ValidationStatusWrapper>
          <Notification
            title="Validation complete!"
            description="Your design has been validated with no errors."
            status="success"
            heightFitContent
            hideCloseButton
          />
        </S.ValidationStatusWrapper>
      ) : null}
      {areasWithError.length > 0 ? (
        <Collapse isOpened={!isCollapsed}>
          <ValidationsDisplay
            areasWithError={areasWithError}
            selectedTab={selectedResultTab}
            setSelectedTab={setSelectedTab}
            selectedAreaTab={selectedAreaTab}
            setSelectedAreaTab={setSelectedAreaTab}
            validationErrors={validationErrors}
            controls={controls}
          />
        </Collapse>
      ) : null}
    </S.ValidationErrorsDisplayWrapper>
  );
}

export { ValidationErrorsDisplay };
