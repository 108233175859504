import { fields } from 'services/fields';
import { operators } from 'services/operators';
import { IServerFilter } from 'types/filter';
import { IInputTemplatesFilters } from 'types/input-templates';
import { toDayEnd, toMidnight } from 'utils/date';

export const initialInputTemplatesFilters: IInputTemplatesFilters = {
  companyIds: [],
  dateRange: {
    from: toMidnight(new Date(new Date().setFullYear(new Date().getFullYear() - 4))).toISOString(),
    to: toDayEnd(new Date()).toISOString(),
  },
  creators: [],
  isIncludedRatedPower: true,
};

export const mapInputTemplatesToServerFilters = (inputTemplatesFilters: IInputTemplatesFilters): IServerFilter[] => {
  const filters: IServerFilter[] = [];
  Object.entries(inputTemplatesFilters).forEach(([key, value]) => {
    switch (key) {
      case 'companyIds':
        if (value.length) {
          filters.push({ field: fields.COMPANY_ID, operator: operators.IN, values: value });
        }
        break;
      case 'creators':
        if (value.length) {
          filters.push({ field: fields.CREATED_BY, operator: operators.IN, values: value });
        }
        break;
      case 'dateRange':
        if (value.from) {
          filters.push({ field: fields.PUBLISHED_DATE, operator: operators.GREATER_OR_EQUAL, values: value.from });
        }
        if (value.to) {
          filters.push({ field: fields.PUBLISHED_DATE, operator: operators.LESS_OR_EQUAL, values: value.to });
        }
        break;
    }
  });

  return filters;
};
