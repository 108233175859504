import React, { useState } from 'react';
import * as S from './input-templates.styled';
import TopBarInputTemplates from './topbar-input-templates';
import { useInputTemplatesContext } from './hooks/use-input-templates-context';
import { LoadTemplateModalContent } from 'pages/design-process/preferences-tab/load-template-modal/load-template-modal-content';
import { InputTemplatesFiltersPanel } from './input-templates-filters-panel';
import { TitleSize } from 'types/input-templates';

type IProps = {
  companyId: string;
  showCompanyFilters: boolean;
  titleSize: TitleSize;
};

const InputTemplates: React.FC<IProps> = ({ companyId, showCompanyFilters, titleSize }) => {
  const [filtersPanelOpened, setFiltersPanelOpened] = useState(false);

  const {
    useInputTemplatesObject: {
      inputTemplatesData,
      error,
      paginationComponent,
      handleEditTemplate,
      handleDeleteTemplate,
      setInputTemplatesServerFilter,
    },
  } = useInputTemplatesContext();

  return (
    <S.InputTemplatesContainer>
      <TopBarInputTemplates
        areFiltersOpened={filtersPanelOpened}
        onClickFilters={setFiltersPanelOpened}
        titleSize={titleSize}
      />
      <S.InputTemplatesCardsContainer $showPublicTemplates={false}>
        <LoadTemplateModalContent
          error={error}
          inputTemplatesData={inputTemplatesData}
          onEditTemplate={handleEditTemplate}
          onDeleteTemplate={handleDeleteTemplate}
        />
      </S.InputTemplatesCardsContainer>
      <InputTemplatesFiltersPanel
        id="main-input-templates-filters"
        active={filtersPanelOpened}
        companyId={companyId}
        onHide={() => setFiltersPanelOpened(false)}
        onApplyFilters={setInputTemplatesServerFilter}
        showCompanyFilters={showCompanyFilters}
      />
      <S.InputTemplatesPaginationWrapper>{paginationComponent}</S.InputTemplatesPaginationWrapper>
    </S.InputTemplatesContainer>
  );
};

export default InputTemplates;
