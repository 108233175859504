import { useState } from 'react';
import { getRangeFilters } from 'utils/filters';
import { IProjectsFilters, IUseProjectFilters } from 'types/projects';
import { projectFiltersTypes, ProjectServerFilter } from 'utils/constants/projects';

interface IServerFilter {
  field: string;
  operator: string;
  values: string[];
}

export const useProjectFilters = (): IUseProjectFilters => {
  const [serverFilters, setServerFilters] = useState<IServerFilter[]>([]);
  const [filtersPanelOpened, setFiltersPanelOpened] = useState(false);

  function applyFilters(filters?: IProjectsFilters) {
    const appliedFilters = filters ?? ({} as IProjectsFilters);
    const serverFiltersToSend: IServerFilter[] = [];

    Object.entries(appliedFilters).forEach(([key, value]) => {
      if (value.length === 0) return;
      if (key === projectFiltersTypes.numDesigns) {
        serverFiltersToSend.push(
          ...getRangeFilters({
            code: ProjectServerFilter[projectFiltersTypes.numDesigns],
            title: '',
            options: [value].map((filterValue: string) => ({ code: filterValue, label: '', checked: true })),
          })
        );
      }
      if (key === projectFiltersTypes.country) {
        serverFiltersToSend.push({
          field: ProjectServerFilter[projectFiltersTypes.country],
          operator: 'IN',
          values: value,
        });
      }
      if (key === projectFiltersTypes.creator) {
        serverFiltersToSend.push({
          field: ProjectServerFilter[projectFiltersTypes.creator],
          operator: 'IN',
          values: value,
        });
      }
      if (key === projectFiltersTypes.team) {
        serverFiltersToSend.push({
          field: ProjectServerFilter[projectFiltersTypes.team],
          operator: 'IN',
          values: value,
        });
      }
      if (key === projectFiltersTypes.status) {
        serverFiltersToSend.push({
          field: ProjectServerFilter[projectFiltersTypes.status],
          operator: 'IN',
          values: value,
        });
      }
    });
    setFiltersPanelOpened(false);
    setServerFilters(serverFiltersToSend);
  }

  return {
    applyFilters,
    serverFilters,
    filtersPanelOpened,
    setFiltersPanelOpened,
    setServerFilters,
  };
};
