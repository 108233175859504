import { ICapexFilters, ICapexTemplate } from 'types/capex';
import { IOption } from 'types/common';
import { stringLitArray } from '../types';
import { ITab } from '@ratedpower/components';
import { getDateMonthDDYYYY, getHourAndMinutesHHmm, toDayEnd, toMidnight } from 'utils/date';
import { IServerFilter } from 'types/filter';
import { FlagSvg, LayersSvg } from 'assets/icons';
import { IndexIds } from 'index.ids';

interface ICapexCardTemplate {
  name: string;
  pageSize: number;
}

type ICapexCardTemplates = Record<string, ICapexCardTemplate>;

export const CAPEX_TEMPLATES: ICapexCardTemplates = {
  public: {
    name: 'public',
    pageSize: 12,
  },
  private: {
    name: 'private',
    pageSize: 8,
  },
};

export const ENABLED_ENTRY_TEXT = 'ENABLED';

export const emptyMapEntry = {
  tagIndex: '',
  provisionalId: null,
  children: [],
  mapKey: null,
  name: 'capex:untitled',
  priceDefinition: {
    name: '',
    baseParam: [],
    paramMultiplier: 1,
    tax: 0,
    price: 0,
  },
  status: ENABLED_ENTRY_TEXT,
  visible: true,
};

export const emptyMapSection = {
  tagIndex: '',
  provisionalId: null,
  children: [],
  mapKey: null,
  name: 'capex:untitled',
  publishedDate: null,
  status: ENABLED_ENTRY_TEXT,
  visible: true,
};

export const pageTabs: ITab[] = [
  {
    id: CAPEX_TEMPLATES.public.name,
    label: 'capex:public',
    Icon: LayersSvg,
    dataTest: IndexIds.Capex.PublicTab,
  },
  {
    id: CAPEX_TEMPLATES.private.name,
    label: 'capex:private',
    Icon: FlagSvg,
    dataTest: IndexIds.Capex.PrivateTab,
  },
];

const capexEntryProperties = stringLitArray(['unit', 'price', 'tax', 'visible', 'baseParam', 'name']);
export type CapexEntryProperty = (typeof capexEntryProperties)[number];
export const PAGE_SIZE_DESIGN_PROCESS = 6;

export const PAGE_SIZE_CAPEX_PUBLIC = 50;
export const PAGE_SIZE_CAPEX_PRIVATE = 25;

export const initialCapexFilters: ICapexFilters = {
  companyIds: [],
  dateRange: {
    from: toMidnight(new Date(new Date().setFullYear(new Date().getFullYear() - 4))).toISOString(),
    to: toDayEnd(new Date()).toISOString(),
  },
  creators: [],
  currencies: [],
  isIncludedRatedPower: true,
};

export const dataToCapexTemplate = (template: any, currencyOptions?: IOption[]): ICapexTemplate | undefined => {
  if (!template) return;
  return {
    status: template.status,
    name: template.name,
    type: template.type || template.company ? 'private' : 'public',
    currency:
      currencyOptions?.find((item: IOption) => item.value === template.currency) ??
      ({ label: 'ERROR', value: 'ERROR' } || template.currency),
    id: template.id,
    createdBy: {
      name: template.createdBy?.name ?? 'Rated',
      surname: template.createdBy?.surname ?? 'Power',
    },
    folder: template.folder,
    countryTranslated: template.country?.translation,
    notes: template.notes,
    publishedDate: getDateMonthDDYYYY(template.publishedDate) + ', ' + getHourAndMinutesHHmm(template.publishedDate),
    entries: template?.entries,
    countryCode: template.country?.countryCode,
    countryDefault: template.countryDefault,
    originalTemplateId: template.originalTemplateId,
    modifiedDate: getDateMonthDDYYYY(template.modifiedDate) + ', ' + getHourAndMinutesHHmm(template.modifiedDate),
  };
};

export const mapCapexToServerFilters = (capexFilters: ICapexFilters): IServerFilter[] => {
  const filters: IServerFilter[] = [];
  Object.entries(capexFilters).forEach(([key, value]) => {
    if (key === 'companyIds' && value.length) {
      filters.push({ field: 'COMPANY_ID', operator: 'IN', values: value });
    }
    if (key === 'currencies' && value.length) {
      filters.push({ field: 'CURRENCY', operator: 'IN', values: value });
    }
    if (key === 'creators' && value.length) {
      filters.push({ field: 'CREATED_BY', operator: 'IN', values: value });
    }
    if (key === 'dateRange') {
      if (value.from) {
        filters.push({ field: 'PUBLISHED_DATE', operator: 'GREATER_OR_EQUAL', values: value.from });
      }
      if (value.to) {
        filters.push({ field: 'PUBLISHED_DATE', operator: 'LESS_OR_EQUAL', values: value.to });
      }
    }
  });

  return filters;
};

export const getCapexItems = (editableTemplate, t) => [
  {
    label: t('common:name'),
    value: editableTemplate?.name || editableTemplate?.countryTranslated || t('capex:rest-of-the-world'),
  },
  {
    label: t('capex:capex-folder'),
    value: editableTemplate?.folder?.label
      ? editableTemplate?.folder?.label
      : editableTemplate?.type === 'public'
      ? 'DEFAULT'
      : '-',
  },
  { label: t('capex:currency'), value: editableTemplate?.currency?.label ?? '' },
  { label: t('equipment:database'), value: editableTemplate?.type ?? '', isTag: true },
  {
    label: t('statistics:lists.user'),
    value: `${editableTemplate?.createdBy?.name ?? ''} ${editableTemplate?.createdBy?.surname ?? ''}`,
  },
  { label: t('common:created-on'), value: editableTemplate?.publishedDate ?? '' },
  { label: t('common:last-modify'), value: editableTemplate?.modifiedDate ?? '' },
  { label: t('common:notes'), value: editableTemplate?.notes ?? '-' },
];
