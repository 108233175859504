import { hasLessThanNChars, hasValidCharacters, PROJECT_DESIGN_UNALLOWED_CHARS } from '../validation';

export const getDesignNumberString = (loadIncrementalResults: any): string => {
  const designNumber = loadIncrementalResults?.number;

  return designNumber ? `${designNumber}. ` : '';
};

export const handleValidateDesignName = (value: string): boolean => {
  return hasLessThanNChars(255)(value) && hasValidCharacters(PROJECT_DESIGN_UNALLOWED_CHARS)(value);
};