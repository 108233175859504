import React from 'react';
import * as S from './design-comparison.styled';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useDesignComparisonData } from './design-comparison-state';
import { Breadcrumb, Button, ConditionalWrapper, Tooltip, TopbarWrapper } from '@ratedpower/components';
import { useNavigate } from 'react-router-dom';
import { DownloadSvg, PlusSvg } from 'assets/icons';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { ComparisonView, IComparisonFilter } from 'types/design-comparison.types';
import { useExportDesignComparison } from './use-export-design-comparison';

interface IProps {
  viewShown: ComparisonView;
  activeFilters: IComparisonFilter[];
  onAddDesign: () => void;
}

const TopbarDesignComparison: React.FC<IProps> = ({ onAddDesign, viewShown, activeFilters }) => {
  const { t } = useTranslation();
  const { isAuthorized } = useAuthorization();
  const navigate = useNavigate();
  const { project } = useDesignComparisonData();
  const { handleSendIds, disableExportComparisonCondition } = useExportDesignComparison(viewShown, activeFilters);

  return (
    <>
      <Helmet>
        <title>{t('design:comparison')}</title>
      </Helmet>
      <TopbarWrapper
        type="secondary"
        left={
          project.name && (
            <Breadcrumb
              prevPages={[
                { name: project.name, url: `/projects/${project.id}`, onClick: (url) => url && navigate(url) },
              ]}
              currentPage={{ name: t('design:comparison') }}
            />
          )
        }
        right={
          <S.ComparisonTopRightButtons>
            {isAuthorized(Permission.COMPARISON_DOWNLOAD) && (
              <ConditionalWrapper
                condition={disableExportComparisonCondition}
                wrapper={(children) => (
                  <Tooltip description={t('design:export-button-disabled-tooltip')}>{children}</Tooltip>
                )}
              >
                <Button
                  onClick={handleSendIds}
                  text={t('common:export')}
                  variant="accent"
                  Icon={DownloadSvg}
                  disabled={disableExportComparisonCondition}
                />
              </ConditionalWrapper>
            )}
            <Button text={t('design:add-to-compare-btn')} Icon={PlusSvg} onClick={onAddDesign} />
          </S.ComparisonTopRightButtons>
        }
      />
    </>
  );
};

export default TopbarDesignComparison;
