import { gql } from '@apollo/client';

export const GET_ROLES = gql`
  query Roles($pagination: PaginationForm!, $sorting: [SortOrder]!, $search: [String], $filters: [RoleFilterEntry]) {
    result: roles(pagination: $pagination, sorting: $sorting, search: $search, filters: $filters) {
      content {
        name
        permissions
      }
      pageInfo {
        totalPages
        pageNumber
      }
    }
  }
`;

export const GET_ROLES_BY_CURRENT_USER = gql`
  query RolesByCurrentUser {
    result: getRolesByCurrentUser {
      name
      type
    }
  }
`;

export const GET_USER_ROLE_SUMMARY = gql`
  query GetRoleSummary($filters: [UserFilterEntry], $teamId: String, $companyId: String!) {
    result: roleSummary(filters: $filters, teamId: $teamId, companyId: $companyId) {
      role
      roleName
      roleType
      userCounter
    }
  }
`;
