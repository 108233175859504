import { Permission } from 'access-control/permissions';

export interface IPermission {
  id: string;
  name: string;
  type?: string;
  publishedDate?: Date;
}

export type PermissionType =
  | 'READ'
  | 'WRITE'
  | 'DISABLE'
  | 'VIEW'
  | 'DOWNLOAD'
  | 'SAML'
  | 'DELETE'
  | 'SCORE'
  | 'CREATE'
  | 'RESEND';

export enum ROLE_STATUS {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum ROLE_TYPE {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export interface IRole {
  name: string;
  type: ROLE_TYPE;
  permissions: string[];
}

export interface IRoleListItem {
  id?: string;
  name: string;
  description: string;
  type: ROLE_TYPE;
  status?: ROLE_STATUS;
  editable?: boolean;
  permissions: string[];
}

export interface IPermissionType {
  text: string;
  value: Permission;
}
export interface IPermissionToShow {
  group: string;
  permissionType: IPermissionType[];
}

export interface IReadOnlyPermissionSection {
  sectionTitle: string;
  permissions: {
    title: string;
    titleColor?: string;
    points?: string[];
  }[];
}

export interface RoleConfig {
  texts?: string[];
  permissions?: Permission[];
}

export interface ActionConfig {
  title: string;
  roles: {
    [key in ReadOnlyRoles]?: RoleConfig;
  };
}

export interface FeatureConfig {
  title: string;
  actions: {
    [key: string]: ActionConfig;
  };
}

export interface ReadOnlyRolesConfig {
  [key: string]: FeatureConfig;
}

export enum ReadOnlyRolesConfigFeature {
  projects = 'projects',
  designs = 'designs',
  teams = 'teams',
  users = 'users',
  equipment = 'equipment',
  capex = 'capex',
  statistics = 'statistics',
  myCompany = 'myCompany',
}

export enum ReadOnlyRoles {
  admin = 'Admin',
  teamManager = 'Team manager',
  user = 'User',
  basicUser = 'Basic user',
  readOnly = 'Read-only',
}

export type ReadOnlyRolesPermissionMap = Map<ReadOnlyRoles, Set<Permission>>;
