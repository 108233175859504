import { useQuery } from '@apollo/client';
import { GET_ROLES_BY_CURRENT_USER } from 'services/roles-gql';

export const useRolesByCurrentUser = (skip?: boolean) => {
  const {
    data: rolesByCurrentUser,
    error,
    loading,
  } = useQuery(GET_ROLES_BY_CURRENT_USER, {
    fetchPolicy: 'cache-first',
    skip,
  });

  return {
    rolesByCurrentUser: rolesByCurrentUser ? rolesByCurrentUser.result : [],
    error,
    loading,
  };
};
