import { useQuery } from '@apollo/client';
import { IOption } from '@ratedpower/components';
import { useState } from 'react';
import { GET_SALESFORCE_IDS } from 'services/support-gql';
import { SalesforceSearchRecordDTO } from 'types/support.types';

interface IUseSalesforceIds {
  data: IOption[];
  error: boolean;
  onSearchSalesforceIds: (search: string) => void;
}

export const useSalesforceIds = (): IUseSalesforceIds => {
  const [search, setSearch] = useState('');
  // This salesforce API only works if the search string has at least 2 characters
  const { data, error } = useQuery(GET_SALESFORCE_IDS, { variables: { search }, skip: search?.length < 2 });

  const onSearchSalesforceIds = (search: string) => {
    setSearch(search);
  };

  return {
    data: data?.searchSalesforceCompany.searchRecords?.map(({ id, name }: SalesforceSearchRecordDTO) => ({
      label: `${name ?? '-'} (${id})`,
      value: id,
    })),
    error: !!error,
    onSearchSalesforceIds,
  };
};
