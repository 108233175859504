import React, { useEffect, useState } from 'react';
import * as S from './read-only-roles-wrapper.styled';
import { Dropdown, RatedPowerLoader } from '@ratedpower/components';
import { IOption } from '@ratedpower/components/dist/types/types';
import { useUsersManagementContext } from 'pages/users-management/users-management-data';
import { useRolesByCurrentUser } from 'utils/hooks/use-roles-by-current-user';
import { ReadOnlyRolesCustom } from 'pages/users-management/read-only-roles-tab/read-only-roles-wrapper/read-only-roles-custom/read-only-roles.custom';
import { useReadOnlyRolesConfig } from 'pages/users-management/read-only-roles-tab/config/use-read-only-roles-config';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data/index';
import { useTranslation } from 'react-i18next';
import HeaderRow from 'pages/users-management/read-only-roles-tab/read-only-roles-wrapper/read-only-roles-custom/header-row/header-row';
import { ReadOnlyRolesRow } from 'pages/users-management/read-only-roles-tab/read-only-roles-wrapper/read-only-roles-custom/read-only-roles-custom.styled';
import { Permission } from 'access-control/permissions';
import { ReadOnlyRolesConfigFeature, ReadOnlyRoles } from 'types/roles';

const ReadOnlyRolesWrapper: React.FC = () => {
  const {
    useUsersObject: { userRoleSummary },
  } = useUsersManagementContext();
  const { rolesByCurrentUser, error, loading } = useRolesByCurrentUser(false);
  const { t } = useTranslation();
  const readOnlyRolesConfig = useReadOnlyRolesConfig();
  const dropdownOptions = Object.keys(readOnlyRolesConfig).map((key) => ({
    label: t(readOnlyRolesConfig[key as ReadOnlyRolesConfigFeature].title),
    value: key,
  }));

  const readOnlyRolesMap = new Map<ReadOnlyRoles, Set<Permission>>(
    Object.values(ReadOnlyRoles).map((role) => [role, new Set<Permission>()])
  );

  useEffect(() => {
    if (!rolesByCurrentUser) return;
    rolesByCurrentUser.forEach((role: any) => {
      const permissionsSet = readOnlyRolesMap.get(role.name as ReadOnlyRoles);

      if (permissionsSet) {
        role.permissions?.forEach((permission: any) => {
          permissionsSet.add(permission.name as Permission);
        });
      }
    });
  }, [rolesByCurrentUser]);

  const [selectedOptions, setSelectedOptions] = useState<IOption[]>(dropdownOptions);

  const handleOnChange = (options: IOption[]): void => setSelectedOptions(options);

  const isSelected = (key: string): boolean => selectedOptions.some((option) => option.value === key);

  if (error) return <ErrorRetrievingData />;

  return loading ? (
    <RatedPowerLoader />
  ) : (
    <S.MainGridStyled>
      <Dropdown
        multipleChoice
        options={dropdownOptions}
        onChange={handleOnChange}
        placeholder={t('user:select-sections')}
        value={selectedOptions}
      />
      <ReadOnlyRolesRow>
        <HeaderRow userRoleSummary={userRoleSummary} />
      </ReadOnlyRolesRow>
      <S.ReadOnlyRolesWContainerStyled>
        <S.ReadOnlyRolesWrapperStyled>
          {Object.entries(readOnlyRolesConfig).map(
            ([key]) =>
              isSelected(key) && (
                <ReadOnlyRolesCustom
                  key={key}
                  feature={key as ReadOnlyRolesConfigFeature}
                  readonlyRoles={rolesByCurrentUser}
                  readOnlyRolesPermissionsMap={readOnlyRolesMap}
                />
              )
          )}
        </S.ReadOnlyRolesWrapperStyled>
      </S.ReadOnlyRolesWContainerStyled>
    </S.MainGridStyled>
  );
};

export default ReadOnlyRolesWrapper;
