import React from 'react';
import * as S from './kpis-data.styled';
import { PowerKPI } from 'pages/interactive-layout-creator/ilc-types';
import { useTranslation } from 'react-i18next';
import { outputAttributes } from 'utils/constants/design-result-attributes';
import { useConverter } from 'utils/conversor';

interface IKpisData {
  powerKpi: PowerKPI;
}

export function KpisData({ powerKpi }: IKpisData) {
  const { t } = useTranslation();
  const { getValueOf } = useConverter();
  const {
    ratedPower: ratedPowerAttribute,
    peakPower: peakPowerAttribute,
    ratioDcAc: ratioDcAcAttribute,
  } = outputAttributes(t);
  return (
    <S.KpisDataWrapper>
      <S.KpisDataRow>
        <S.ValueDisplay>{t('ilc:results.power-kpis.rated-power')}</S.ValueDisplay>
        <S.ValueDisplay>{getValueOf(ratedPowerAttribute, { ratedPower: powerKpi.ratedPower })}</S.ValueDisplay>
      </S.KpisDataRow>
      <S.KpisDataRow>
        <S.ValueDisplay>{t('ilc:results.power-kpis.peak-power')}</S.ValueDisplay>
        <S.ValueDisplay>{getValueOf(peakPowerAttribute, { peakPower: powerKpi.peakDcPower })}</S.ValueDisplay>
      </S.KpisDataRow>
      <S.KpisDataRow>
        <S.ValueDisplay>{t('ilc:results.power-kpis.dc-ac-ratio')}</S.ValueDisplay>
        <S.ValueDisplay>{getValueOf(ratioDcAcAttribute, { ratioDcAc: powerKpi.dcAcRatio })}</S.ValueDisplay>
      </S.KpisDataRow>
    </S.KpisDataWrapper>
  );
}
