import { IUseInputTemplatesObject } from 'types/input-templates';
import { RootState } from 'redux/root-reducer';
import { useSelector } from 'react-redux';
import { useInputTemplatesCommon } from './use-input-templates-common';
import { useUpdateInputTemplate } from './use-update-input-template';
import { useDeleteInputTemplate } from './use-delete-input-template';
import { useParams } from 'react-router-dom';

export function useInputTemplates(): IUseInputTemplatesObject {
  const user = useSelector((state: RootState) => state.currentUser.profile);
  const { id: companyIdQueryParam } = useParams<{ id: string }>();
  const { companyId: userCompanyId } = { ...user };
  const companyId = companyIdQueryParam ?? userCompanyId;
  const isOtherCompany = !!companyIdQueryParam && companyIdQueryParam !== userCompanyId;

  const {
    inputTemplatesData,
    currentTeam,
    setSelectedTeam,
    teamOptions,
    loading: fetchDataLoading,
    error,
    paginationComponent,
    currentPage,
    inputSearchProps,
    setInputTemplatesServerFilter,
  } = useInputTemplatesCommon({
    isOtherCompany,
    companyId,
  });

  const { updateInputTemplate, loading: updateInputTemplateLoading } = useUpdateInputTemplate();
  const handleEditTemplate = (id: string, name: string, notes: string) => {
    const updateInputTemplateDTO = { id, name, notes };
    updateInputTemplate({ variables: { updateInputTemplateDTO } });
  };

  const { deleteInputTemplate, loading: deleteInputTemplateLoading } = useDeleteInputTemplate();
  const handleDeleteTemplate = (id: string) => {
    deleteInputTemplate({ variables: { inputTemplateId: id } });
  };

  const loading = fetchDataLoading || updateInputTemplateLoading || deleteInputTemplateLoading;

  return {
    inputTemplatesData,
    currentTeam,
    setSelectedTeam,
    teamOptions,
    loading,
    error,
    paginationComponent,
    currentPage,
    setInputTemplatesServerFilter,
    inputSearchProps,
    handleEditTemplate,
    handleDeleteTemplate,
  };
}
