import React, { ReactNode } from 'react';
import { IUseInputTemplatesObject } from 'types/input-templates';
import { useInputTemplates } from './use-input-templates';

export interface ContextState {
  useInputTemplatesObject: IUseInputTemplatesObject;
}

export const Context = React.createContext<ContextState | null>(null);

export const useInputTemplatesContext = () => {
  const contextState = React.useContext(Context);
  if (contextState === null) {
    throw new Error('useInputTemplatesContext must be used within a InputTemplatesProvider tag');
  }
  return contextState;
};

const InputTemplatesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const useInputTemplatesObject = useInputTemplates();
  const contextValue = React.useMemo(() => ({ useInputTemplatesObject }), [useInputTemplatesObject]);

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export default InputTemplatesProvider;
