import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { DELETE_INPUT_TEMPLATE, GET_INPUT_TEMPLATES } from 'services/design-process-gql';
import { useToasts } from 'utils/hooks/use-toasts';

const useDeleteInputTemplate = () => {
  const { t } = useTranslation();
  const { addSuccessToast, addErrorToast } = useToasts();

  const [deleteInputTemplate, { loading }] = useMutation(DELETE_INPUT_TEMPLATE, {
    refetchQueries: [GET_INPUT_TEMPLATES],
    onCompleted: () => {
      addSuccessToast(t('design:input-template-delete-success'));
    },
    onError: () => {
      addErrorToast(t('errors:input-template-delete-error'));
    },
  });

  return { deleteInputTemplate, loading };
};

export { useDeleteInputTemplate };
