/* eslint-disable max-len */
import React from 'react';
import {
  DesignItemDetailsContainer,
  DesignItemDetailsSectionWrapper,
  DesignItemDetailsRow,
} from './design-table-row-details.styled';
import { IBessDesign } from 'types/design';
import { useConverter } from 'utils/conversor';
import { useTranslation } from 'react-i18next';
import { Typography } from '@ratedpower/components';
import { bessDesignAttributes } from 'utils/constants/bess-design-attributes';

interface IProps {
  item: IBessDesign;
}

const BessTableRowDetails: React.FC<IProps> = ({ item }) => {
  const { getValueOf } = useConverter();
  const { t } = useTranslation('design');

  if (!item.standaloneBatterySimulationOutputs) return null;

  return (
    <DesignItemDetailsContainer>
      <DesignItemDetailsSectionWrapper>
        <Typography nowrap className="section-header" weight={'bold'}>
          {t('design:power')}
        </Typography>
        <DesignItemDetailsRow>
          <Typography nowrap>{getValueOf(bessDesignAttributes.bess.energyCapacity, item)}</Typography>
          <Typography nowrap>{getValueOf(bessDesignAttributes.bess.activePowerAc, item)}</Typography>
        </DesignItemDetailsRow>
      </DesignItemDetailsSectionWrapper>
      <DesignItemDetailsSectionWrapper>
        <Typography nowrap className="section-header" weight={'bold'}>
          {t('design:supply')}
        </Typography>
        <DesignItemDetailsRow>
          <Typography nowrap>{t(`design:${bessDesignAttributes.bess.operationHours.label}`)}</Typography>
          <Typography nowrap>
            {getValueOf(bessDesignAttributes.bess.operationHours, item)} <span>hours</span>
          </Typography>
        </DesignItemDetailsRow>
      </DesignItemDetailsSectionWrapper>
      <DesignItemDetailsSectionWrapper>
        <Typography nowrap className="section-header" weight={'bold'}>
          {t('design-process:site')}
        </Typography>
        <DesignItemDetailsRow>
          <Typography nowrap>{t(`design:${bessDesignAttributes.site.installedArea.label}`)}</Typography>
          <Typography nowrap>{getValueOf(bessDesignAttributes.site.installedArea, item)}</Typography>
        </DesignItemDetailsRow>
      </DesignItemDetailsSectionWrapper>
      <DesignItemDetailsSectionWrapper>
        <Typography nowrap className="section-header" weight={'bold'}>
          {t('equipment')}
        </Typography>
        <DesignItemDetailsRow>
          <Typography nowrap>{t('bess-container')}</Typography>
          <Typography nowrap>{getValueOf(bessDesignAttributes.equipment.bessContainerCapacity, item)}</Typography>
        </DesignItemDetailsRow>
        <DesignItemDetailsRow>
          <Typography nowrap>
            {`${item.standaloneBatterySimulationInputs.inverter.manufacturer} ${item.standaloneBatterySimulationInputs.inverter.model}`}
          </Typography>
        </DesignItemDetailsRow>
        <DesignItemDetailsRow>
          <Typography nowrap>{getValueOf(bessDesignAttributes.equipment.storageInverterRatedPower, item)}</Typography>
        </DesignItemDetailsRow>
      </DesignItemDetailsSectionWrapper>
    </DesignItemDetailsContainer>
  );
};

export default BessTableRowDetails;
