/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { RudderAnalytics } from '@rudderstack/analytics-js';
import Cookies from 'js-cookie';
import { matchPath } from 'react-router-dom';

const rudderAnalytics = new RudderAnalytics();
export const isRudderstackReady =
  !!process.env.REACT_APP_RUDDERSTACK_WRITE_KEY && !!process.env.REACT_APP_RUDDERSTACK_DATA_PLANE_URL;

if (isRudderstackReady) {
  const writeKey = process.env.REACT_APP_RUDDERSTACK_WRITE_KEY!;
  const dataPlaneUrl = process.env.REACT_APP_RUDDERSTACK_DATA_PLANE_URL!;
  rudderAnalytics.load(writeKey, dataPlaneUrl, {
    storage: { cookie: { domain: process.env.REACT_APP_RUDDERSTACK_COOKIES_DOMAIN, secure: true } },
  });
}

export const track = rudderAnalytics.track;
export const identity = rudderAnalytics.identify;

export type RudderstackScreenResolution = '-1023' | '1024-1279' | '1280-1439' | '1440-1919' | '1920+';
export const PAGES = {
  '/': 'Home',
  '/projects': 'Projects',
  '/projects/:id': 'Designs',
  '/projects/:id/design-process': 'Design Process',
  '/projects/:id/design-process/:designId': 'Design Results',
  '/projects/:id/comparison': 'Design Comparison',
  '/equipment': 'Equipment',
  '/profile': 'Profile',
  '/profile/preferences': 'Preferences',
  '/profile/plan': 'Plan',
  '/capex': 'Capex',
  '/capex/:id?/view': 'Capex View Public sheet',
  '/capex/:id?/edit': 'Capex Edit sheet',
  '/my-company': 'My Company',
  '/users-management': 'Users Management',
  '/users-management/users': 'Users Management',
  '/users-management/roles': 'Roles & Permissions',
  '/users-management/teams': 'Teams',
  '/users-management/teams/:teamId/:contentType': 'Team page',
  '/customer-statistics': 'Customer Statistics',
  '/companies-activity': 'Companies Activity',
  '/companies-activity/:id': 'Company Activity',
  '/companies-activity/:id/activity': 'Company Activity - Activity tab',
  '/companies-activity/:id/projects-and-files': 'Company Activity - Projects tab',
  '/companies-activity/:id/support-teams': 'Company Activity - Teams tab',
  '/companies-activity/:id/support-users': 'Company Activity - Users tab',
  '/companies-activity/:id/designs': 'Company Activity - Designs tab',
  '/companies-activity/:id/company': 'Company Activity - Company tab',
  '/companies-activity/:id/input-templates': 'Company Activity - Input Templates tab',
  '/analytics': 'Analytics',
  '/statistics': 'Internal Statistics',
  '/new-feature': 'New Feature',
};

export const PAGES_ARRAY = Object.entries(PAGES);

export const findPageMatch = (pathname: string): string | null => {
  for (const [pattern, pageName] of PAGES_ARRAY) {
    const match = matchPath(pattern, pathname);
    if (match) {
      return pageName;
    }
  }
  return null;
};

export const rudderstrackPage = (pageName: string): void => {
  rudderAnalytics.page(pageName, pageName, {
    application_name: process.env.REACT_APP_RUDDERSTACK_APP_NAME,
    application_version: process.env.REACT_APP_VERSION,
  });
};

export const updateRudderstackCookiesDomain = () => {
  const newDomain = process.env.REACT_APP_RUDDERSTACK_COOKIES_DOMAIN;

  const cookiesUpdatedFlag = 'rudderstackCookiesUpdated';
  if (localStorage.getItem(cookiesUpdatedFlag) === 'true') {
    return;
  }

  const cookies = Cookies.get();

  Object.keys(cookies).forEach((cookieName) => {
    if (cookieName.startsWith('rl_')) {
      // rudderstack cookies
      const cookieValue = cookies[cookieName];
      Cookies.remove(cookieName);
      Cookies.set(cookieName, cookieValue, { domain: newDomain, secure: true });
    }
  });

  localStorage.setItem(cookiesUpdatedFlag, 'true');
};

export default rudderAnalytics;
