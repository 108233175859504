import { Permission, permissionsToShow } from 'access-control/permissions';
import { useEffect, useState } from 'react';
import { IRoleListItem } from 'types/roles';

export const usePermissionList = (roleSelected?: IRoleListItem) => {
  const [checkedPermissions, setCheckedPermissions] = useState<string[]>([]);

  useEffect(() => {
    setCheckedPermissions(roleSelected?.permissions || []);
  }, [roleSelected?.permissions]);

  const isChecked = (permission: Permission) => {
    return !!checkedPermissions?.find((checkedPermission: string) => checkedPermission === permission);
  };

  return {
    filteredPermissionsToShow: permissionsToShow,
    isChecked,
  };
};
