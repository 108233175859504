import React from 'react';
import * as S from './checkbox-group-scrollable.styled';
import { CheckboxGroup, ICheckboxOption } from '@ratedpower/components';

interface IProps {
  id: string;
  options: ICheckboxOption[];
  onChange: (values: ICheckboxOption[]) => void;
  infiniteScroll: string | JSX.Element;
}

export const CheckboxGroupScrollable: React.FC<IProps> = ({ id, options, onChange, infiniteScroll }) => (
  <S.Container>
    <CheckboxGroup
      id={id}
      key={id}
      options={options}
      onChange={(_value: ICheckboxOption, values: ICheckboxOption[]) => onChange(values)}
      direction="column"
    />
    {infiniteScroll}
  </S.Container>
);
