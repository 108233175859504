import { RPTable } from '@ratedpower/components';
import styled from 'styled-components';

export const DesignsRPTable = styled(RPTable)`
  th {
    width: 10%;
    &:nth-child(1),
    &:nth-child(2) {
      width: 5%;
    }
    &:nth-child(3) {
      width: 20%;
    }
  }
  tr {
    &.error {
      background-color: ${({ theme }) => theme.v2.error.bg.default};
    }
  }
`;

export const DesignNameWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.v2.spacing.xxs};
`;
