import { noDesigns } from 'types/projects';

export const projectFiltersTypes = {
  country: 'country',
  creator: 'creator',
  numDesigns: 'numDesigns',
  team: 'team',
  status: 'status',
};

export enum ProjectServerFilter {
  numDesigns = 'NUM_DESIGNS',
  country = 'COUNTRY',
  creator = 'CREATED_BY',
  team = 'TEAM',
  status = 'STATUS',
}

export const noDesignsArray: noDesigns[] = ['All', '< 5', '5 - 10', '> 10'];
export const noDesignsOptions = [
  { code: '', label: 'all', checked: true },
  { code: '< 5', label: '< 5', checked: false },
  { code: '5 - 10', label: '5 - 10', checked: false },
  { code: '> 10', label: '> 10', checked: false },
];
