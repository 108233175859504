import { ITab } from '@ratedpower/components';

export enum NotificationTab {
  ALL = 'ALL',
  UNREAD = 'UNREAD',
  READ = 'READ',
}

export const notificationTabs: ITab[] = [
  {
    id: NotificationTab.ALL,
    label: 'all',
  },
  {
    id: NotificationTab.UNREAD,
    label: 'action:unread',
  },
  {
    id: NotificationTab.READ,
    label: 'communication:read',
  },
];

export const NotificationsTabDefaultPageSize = 10;
