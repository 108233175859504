import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { useState } from 'react';
import { GET_COMPANIES_NAMES } from 'services/support-gql';
import { useCustomQuery } from 'utils/hooks/use-custom-query';
import { getSortDirection } from 'utils/sort';

export const useCompanySearch = () => {
  const { isAuthorized } = useAuthorization();
  const [searchBy, setSearchBy] = useState<string | string[]>('');
  const { data, paginationComponent, error, loading } = useCustomQuery(
    GET_COMPANIES_NAMES,
    {
      variables: {
        pagination: {
          page: 0,
          size: 10,
        },
        sorting: {
          property: 'name',
          direction: getSortDirection(true),
        },
        search: searchBy,
      },
      skip: !isAuthorized(Permission.COMPANY_SUPPORT_READ),
      fetchPolicy: 'network-only',
    },
    true
  );

  return {
    data: data?.result.content,
    error,
    loading,
    paginationComponent,
    setSearchBy,
  };
};
