import {
  DesignKPI,
  IlcLayoutAction,
  InverterElement,
  InverterPowerConfiguration,
  PowerStationElement,
  StructureElement,
  StructuresLayer,
} from '../ilc-types';

export function recalculateKpisPeakPowerAfterStructureAction(
  currentKpis: DesignKPI,
  elements: (StructureElement | PowerStationElement)[],
  stringDcPower: number,
  action: 'add' | 'remove'
): DesignKPI {
  return elements.reduce((acc: DesignKPI, structure) => {
    if (structure.type === 'POWER_STATIONS') return acc;
    const psKey = structure.psKey;

    const currentPowerStationKpis = acc.powerStationKpis[psKey];

    const updatedPeakDCPower =
      currentPowerStationKpis.powerKpis.peakDcPower + structure.strings * stringDcPower * (action === 'add' ? 1 : -1);
    const updatedDcAcRatio = updatedPeakDCPower / currentPowerStationKpis.powerKpis.ratedPower;

    const updatedPowerStationKpis = {
      ...currentPowerStationKpis,
      strings: currentPowerStationKpis.strings + structure.strings * (action === 'add' ? 1 : -1),
      powerKpis: {
        ...currentPowerStationKpis.powerKpis,
        peakDcPower: updatedPeakDCPower,
        dcAcRatio: updatedDcAcRatio,
      },
    };

    const areaKey = structure.areaKey;

    const currentAreaKpi = acc.areaKpis[areaKey];

    const updatedAreaPeakDCPower =
      currentAreaKpi.powerKpis.peakDcPower + structure.strings * stringDcPower * (action === 'add' ? 1 : -1);
    const updatedAreaDcAcRatio = updatedAreaPeakDCPower / currentAreaKpi.powerKpis.ratedPower;

    const updateAreaKpi = {
      ...currentAreaKpi,
      powerKpis: {
        ...currentAreaKpi.powerKpis,
        peakDcPower: updatedAreaPeakDCPower,
        dcAcRatio: updatedAreaDcAcRatio,
      },
    };

    const currentGlobalKpi = acc.powerKpis;

    const updatedGlobalPeakPower =
      currentGlobalKpi.peakDcPower + structure.strings * stringDcPower * (action === 'add' ? 1 : -1);
    const updatedGlobalDcAcRatio = updatedGlobalPeakPower / currentGlobalKpi.ratedPower;

    const updatedGlobalKpi = {
      ...currentGlobalKpi,
      peakDcPower: updatedGlobalPeakPower,
      dcAcRatio: updatedGlobalDcAcRatio,
    };

    return {
      ...acc,
      powerStationKpis: { ...acc.powerStationKpis, [psKey]: updatedPowerStationKpis },
      areaKpis: { ...acc.areaKpis, [areaKey]: updateAreaKpi },
      powerKpis: updatedGlobalKpi,
    };
  }, currentKpis);
}

function getPowerFromConfiguration(inverter: InverterElement, powerConfiguration: InverterPowerConfiguration): number {
  const { inverterCentralModel1Power, inverterCentralModel2Power, inverterStringModelPower, inverterType } =
    powerConfiguration;
  if (inverterType === 'CENTRAL') {
    return inverter.primary ? inverterCentralModel1Power : inverterCentralModel2Power;
  }
  return inverterStringModelPower;
}

export function recalculateKpisRatedPowerAfterInverterAction(
  currentKpis: DesignKPI,
  inverters: InverterElement[],
  powerConfiguration: InverterPowerConfiguration,
  action: 'add' | 'remove'
): DesignKPI {
  return inverters.reduce((acc: DesignKPI, inverter) => {
    const psKey = inverter.psKey;

    const currentPowerStationKpis = acc.powerStationKpis[psKey];
    const power = getPowerFromConfiguration(inverter, powerConfiguration);

    const updatedRatedPower =
      currentPowerStationKpis.powerKpis.ratedPower +
      power * powerConfiguration.electricalCosPhiInverters * (action === 'add' ? 1 : -1);
    const updatedDcAcRatio = currentPowerStationKpis.powerKpis.peakDcPower / updatedRatedPower;

    const updatedPowerStationKpis = {
      ...currentPowerStationKpis,
      powerKpis: {
        ...currentPowerStationKpis.powerKpis,
        ratedPower: updatedRatedPower,
        dcAcRatio: updatedDcAcRatio,
      },
    };

    const areaKey = inverter.areaKey;

    const currentAreaKpi = acc.areaKpis[areaKey];

    const updatedAreaRatedPower =
      currentAreaKpi.powerKpis.ratedPower +
      power * powerConfiguration.electricalCosPhiInverters * (action === 'add' ? 1 : -1);
    const updatedAreaDcAcRatio = currentAreaKpi.powerKpis.peakDcPower / updatedAreaRatedPower;

    const updateAreaKpi = {
      ...currentAreaKpi,
      powerKpis: {
        ...currentAreaKpi.powerKpis,
        ratedPower: updatedAreaRatedPower,
        dcAcRatio: updatedAreaDcAcRatio,
      },
    };

    const currentGlobalKpi = acc.powerKpis;

    const updatedGlobalRatedPower =
      currentGlobalKpi.ratedPower + power * powerConfiguration.electricalCosPhiInverters * (action === 'add' ? 1 : -1);
    const updatedGlobalDcAcRatio = currentGlobalKpi.peakDcPower / updatedGlobalRatedPower;

    const updatedGlobalKpi = {
      ...currentGlobalKpi,
      ratedPower: updatedGlobalRatedPower,
      dcAcRatio: updatedGlobalDcAcRatio,
    };

    return {
      ...acc,
      powerStationKpis: { ...acc.powerStationKpis, [psKey]: updatedPowerStationKpis },
      areaKpis: { ...acc.areaKpis, [areaKey]: updateAreaKpi },
      powerKpis: updatedGlobalKpi,
    };
  }, currentKpis);
}

export function handleGetKpisAfterSessionStructureActions(
  sessionResponse: IlcLayoutAction[],
  structures: StructuresLayer,
  kpisData: DesignKPI,
  stringDcPower: number
): DesignKPI {
  let kpis = kpisData;
  const structuresCopy = { ...structures };
  const updateStructuresCopy = (element: StructureElement): void => {
    structuresCopy[element.key] = element;
  };
  for (const layoutAction of sessionResponse) {
    const elementFromSession = Object.values(layoutAction.modifiedElement)[0];
    if (elementFromSession.type !== 'STRUCTURES') continue;
    if (layoutAction.action === 'REGROUP') {
      const currentElement = structuresCopy[elementFromSession.key];
      kpis = recalculateKpisPeakPowerAfterStructureAction(kpis, [currentElement], stringDcPower, 'remove');
      kpis = recalculateKpisPeakPowerAfterStructureAction(kpis, [elementFromSession], stringDcPower, 'add');
    }
    if (layoutAction.action === 'CREATE' && layoutAction.entity === 'STRUCTURE') {
      kpis = recalculateKpisPeakPowerAfterStructureAction(kpis, [elementFromSession], stringDcPower, 'add');
      updateStructuresCopy(elementFromSession);
    }
    if (layoutAction.action === 'DELETE' && layoutAction.entity === 'STRUCTURE') {
      kpis = recalculateKpisPeakPowerAfterStructureAction(kpis, [elementFromSession], stringDcPower, 'remove');
    }
  }
  return kpis;
}

export function handleGetKpisAfterSessionInverterActions(
  sessionResponse: IlcLayoutAction[],
  kpisData: DesignKPI,
  powerConfiguration: InverterPowerConfiguration
): DesignKPI {
  let kpis = kpisData;
  for (const layoutAction of sessionResponse) {
    const elementFromAction = Object.values(layoutAction.modifiedElement)[0];
    if (elementFromAction.type !== 'INVERTERS') continue;
    if (layoutAction.action === 'CREATE' && layoutAction.entity === 'INVERTER') {
      kpis = recalculateKpisRatedPowerAfterInverterAction(kpis, [elementFromAction], powerConfiguration, 'add');
    }
    if (layoutAction.action === 'DELETE' && layoutAction.entity === 'INVERTER') {
      kpis = recalculateKpisRatedPowerAfterInverterAction(kpis, [elementFromAction], powerConfiguration, 'remove');
    }
  }
  return kpis;
}
