import React, { useState } from 'react';
import { RadioGroup, IRadioOption } from '@ratedpower/components';
import { GET_USERS_BY_COMPANY } from 'services/support-gql';
import { ALL_USERS, USERS_PER_PAGE, usersOptions } from 'utils/constants/support';
import { useSearch } from 'utils/hooks/use-search';
import CheckboxListFilter from 'components/filters/checkbox-list-filter/checkbox-list-filter';
import { useCustomQuery } from 'utils/hooks/use-custom-query';
import { IUserDTO } from 'types/users';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useParams } from 'react-router-dom';
import { IServerFilter } from 'types/filter';

interface IProps {
  showInternalExternalOptions?: boolean;
  onChangeUsers: (users: string[]) => void;
}

const UsersFilter: React.FC<IProps> = ({ onChangeUsers, showInternalExternalOptions = true }) => {
  const { t } = useTranslation();
  const { inputSearchProps, getSearchFilter } = useSearch();
  const [usersFilterMode, setUsersFilterMode] = useState(usersOptions[2].value);
  const [filters, setFilters] = useState<IServerFilter[]>([]);
  const user = useSelector((state: RootState) => state.currentUser.profile);
  const { companyId: ratedPowerCompanyId } = { ...user };
  const { id: companyId } = useParams<{ id: string }>();
  const {
    data: users,
    error,
    paginationComponent,
  } = useCustomQuery(
    GET_USERS_BY_COMPANY,
    {
      variables: {
        search: getSearchFilter(),
        companyIds: setCompanyId(),
        pagination: {
          page: 0,
          size: USERS_PER_PAGE,
        },
        filters,
      },
      fetchPolicy: 'network-only',
    },
    true
  );

  function setCompanyId() {
    if (usersFilterMode === usersOptions[0].value) return [ratedPowerCompanyId];
    if (usersFilterMode === usersOptions[1].value) return [companyId];
    return [ratedPowerCompanyId, companyId];
  }

  const handleChangeUsersFilterMode = (option: IRadioOption) => {
    setUsersFilterMode(option.value);
    if (option.value === ALL_USERS) {
      onChangeUsers([]);
      setFilters([]);
      return;
    }
    setFilters([{ field: 'ROLE_TYPE', operator: 'IN', values: [option.value] }]);
  };

  return (
    <>
      {showInternalExternalOptions && (
        <RadioGroup
          id="users"
          options={usersOptions.map((userOption: IRadioOption) => ({
            label: userOption.label
              .split(' - ')
              .map((labelSlice: string) => t(labelSlice))
              .join(' - '),
            value: userOption.value,
          }))}
          selectedValue={usersFilterMode}
          onChange={handleChangeUsersFilterMode}
          direction="column"
        />
      )}
      {users && users?.result && (
        <CheckboxListFilter
          id="userFilter"
          items={users.result.content.map((user: IUserDTO) => ({
            name: user.name + ' ' + user.lastname + '    ' + user.email,
            id: user.id,
          }))}
          onSearch={inputSearchProps.onChange}
          error={error}
          onToggleItem={onChangeUsers}
          searchValue={inputSearchProps.value}
          paginationComponent={paginationComponent}
        />
      )}
    </>
  );
};

export default UsersFilter;
