import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, SplitButton } from '@ratedpower/components';
import { PlusSvg } from 'assets/icons';
import { TeamContentType } from 'types/teams';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { useTeamsProvider } from '../teams-data';
import { useLocation, useParams } from 'react-router-dom';
import NewTeamButtonWrapper from '../components/new-team-button';
import { useTeamsPermissions } from '../hooks/use-teams-permissions';
import { IndexIds } from 'index.ids';

interface ITopbarButton {
  hasPermission: boolean;
  buttonText: string;
  buttonAction?: () => void;
  dropdownActions?: {
    buttonText: string;
    buttonAction: () => void;
  }[];
  dataTest?: string;
}

const TeamsRightTopbar: React.FC = () => {
  const { t } = useTranslation();
  const { isAuthorized } = useAuthorization();
  const location = useLocation();

  const [, contentType] = useParams()['*']?.split('/') as string[];

  const { addMembersModal, createProjectModal, addProjectsModal } = useTeamsProvider();
  const { isCompaniesActivity, canCreateAndDeleteTeams, hasWritePermissions, isSupportRatedPowerCompany } =
    useTeamsPermissions();

  const getTopbarButton = (): ITopbarButton => {
    const pathnameArray = location.pathname.split('/');
    if (pathnameArray[pathnameArray.length - 1] === TeamContentType.PROJECTS) {
      return getProjectsTopbarButton();
    }

    if (pathnameArray[pathnameArray.length - 1] === TeamContentType.MEMBERS) {
      return {
        buttonText: t('user:teams.add-members'),
        hasPermission: hasWritePermissions || isSupportRatedPowerCompany,
        buttonAction: () => addMembersModal.setModalOpen(true),
        dataTest: IndexIds.Users.AddMemberButton,
      };
    }

    return {
      buttonText: t('user:teams.new-team'),
      hasPermission: canCreateAndDeleteTeams,
    };
  };

  const getProjectsTopbarButton = () => {
    if (isCompaniesActivity && !isAuthorized(Permission.PROJECT_SUPPORT_CREATE)) {
      return {
        buttonText: t('user:teams.add-existing-projects'),
        buttonAction: () => addProjectsModal.setModalOpen(true),
        hasPermission: hasWritePermissions || isSupportRatedPowerCompany,
      };
    }
    return {
      buttonText: t('user:teams.add-projects'),
      dropdownActions: [
        {
          buttonText: t('user:teams.new-project'),
          buttonAction: () => createProjectModal.setModalOpen(true),
        },
        {
          buttonText: t('user:teams.existing-project'),
          buttonAction: () => addProjectsModal.setModalOpen(true),
        },
      ],
      hasPermission: hasWritePermissions || isSupportRatedPowerCompany,
    };
  };

  const topbarButton = getTopbarButton();

  const { hasPermission, buttonText, dropdownActions, buttonAction, dataTest } = topbarButton;

  if (!hasPermission) return <></>;

  if (contentType === TeamContentType.PROJECTS) {
    if (!dropdownActions?.length) {
      return (
        <>
          <NewTeamButtonWrapper />
          <Button Icon={PlusSvg} text={buttonText} onClick={buttonAction} dataTest={dataTest} />
        </>
      );
    }
    return (
      <>
        <SplitButton
          options={dropdownActions?.map((option, index) => ({ label: option.buttonText, value: String(index) })) || []}
          onClickOption={(option) => dropdownActions && dropdownActions[Number(option.value)].buttonAction()}
          text={buttonText}
          variant="accent"
        />
        <NewTeamButtonWrapper />
      </>
    );
  }

  if (contentType === TeamContentType.MEMBERS) {
    return (
      <>
        <Button text={buttonText} onClick={buttonAction} dataTest={dataTest} variant="accent" />
        <NewTeamButtonWrapper />
      </>
    );
  } else if (contentType === TeamContentType.SETTINGS) {
    return <NewTeamButtonWrapper />;
  } else {
    return canCreateAndDeleteTeams ? <NewTeamButtonWrapper /> : <></>;
  }
};

export default TeamsRightTopbar;
