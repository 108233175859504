import styled from 'styled-components';

export const PowerStationKpisDisplayWrapper = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.xs};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.v2.spacing.xs};
  background-color: ${({ theme }) => theme.v2.bg.primary.default};
  border-radius: ${({ theme }) => theme.v2.spacing.xxs};
  max-height: 350px;
  overflow: auto;
`;

export const TitleHeader = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.v2.spacing.xxs};
  align-items: center;
  width: 100%;
`;

export const TextAndNewBadge = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: ${({ theme }) => theme.v2.spacing.xxs};
`;

export const PowerStationsCollapseContent = styled.div`
  padding-top: ${({ theme }) => theme.v2.spacing.s};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.v2.spacing.s};
`;
