import React, { useState } from 'react';
import { t } from 'i18next';
import { InputTextArea, Modal, Typography } from '@ratedpower/components';
import { useTheme } from 'styled-components';

interface IProps {
  currentNotes?: string;
  isActive: boolean;
  onSendNotes: (notes: string) => void;
  onCloseModal: () => void;
  canBeEmpty?: boolean;
  maxLength?: number;
}

const ModalEditNotes: React.FC<IProps> = ({
  currentNotes,
  isActive,
  onSendNotes,
  onCloseModal,
  canBeEmpty = false,
  maxLength = 140,
}) => {
  const theme = useTheme();
  const [formError, setFormError] = useState<string>();
  const [notes, setNotes] = useState<string | undefined>();

  const handleSave = () => {
    if (!notes && !canBeEmpty) {
      setFormError(t('errors:field-empty'));
      return;
    }
    onSendNotes(notes ?? '');
  };

  return (
    <Modal
      isActive={isActive}
      onClose={onCloseModal}
      buttons={{
        primary: {
          text: t('action:save'),
          onClick: handleSave,
        },
        secondary: {
          text: t('action:cancel'),
          onClick: onCloseModal,
        },
      }}
      title={<Typography size="l">{t('notes')}</Typography>}
      disableClickOutside
    >
      <InputTextArea
        value={notes ?? currentNotes}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setNotes(e.target.value);
          setFormError('');
        }}
        maxLength={maxLength}
        maxRows={3}
        error={!!formError}
        size="xl"
      />
      <Typography color={theme.red}>{formError}</Typography>
    </Modal>
  );
};

export default ModalEditNotes;
