import { Typography } from '@ratedpower/components';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputTemplate } from 'types/design-process';
import { InputTemplateCard } from './input-template-card';
import { EditTemplate } from 'types/input-templates';

type IProps = {
  error: any;
  inputTemplatesData: InputTemplate[];
  selectedInputTemplateId?: string;
  cursorPointerOnHover?: boolean;
  onSelectCard?: (id: string, name: string) => void;
  onEditTemplate?: EditTemplate;
  onDeleteTemplate?: (id: string) => void;
};

const LoadTemplateModalContent: React.FC<IProps> = ({
  error,
  inputTemplatesData,
  selectedInputTemplateId,
  cursorPointerOnHover = false,
  onSelectCard,
  onEditTemplate,
  onDeleteTemplate,
}) => {
  const { t } = useTranslation();

  if (error) return <ErrorRetrievingData />;

  return inputTemplatesData.length > 0 ? (
    <>
      {inputTemplatesData.map((card) => {
        const onEditTemplateAction = card.canEditDelete ? onEditTemplate : undefined;
        const onDeleteTemplateAction = card.canEditDelete ? onDeleteTemplate : undefined;

        return (
          <InputTemplateCard
            key={card.id}
            id={card.id}
            title={card.title}
            body={card.body}
            notes={card.notes}
            selected={selectedInputTemplateId === card.id}
            cursorPointerOnHover={cursorPointerOnHover}
            onSelectCard={onSelectCard}
            onEditTemplate={onEditTemplateAction}
            onDeleteTemplate={onDeleteTemplateAction}
          />
        );
      })}
    </>
  ) : (
    <Typography>{t('common:zero-results-found')}</Typography>
  );
};

export { LoadTemplateModalContent };
