import React, { ReactElement, useMemo } from 'react';
import { IRoleListItem } from 'types/roles';
import { SVGWrapper, Typography } from '@ratedpower/components';
import { IUserRoleSummary } from 'types/users';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import EditableInputTextCell from 'components/tables/editable-input-text-cell';
import { getOriginalRolesColors } from 'utils/constants/users';
import { useTheme } from 'styled-components';
import { CircleFullSvg } from 'assets/icons';

const columnHelper = createColumnHelper<IRoleListItem>();

export const useGetRolesTableColumns = (
  sortableHeader: (label: string, code: string) => ReactElement,
  userRoleSummary: IUserRoleSummary[]
) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return useMemo(
    () => [
      columnHelper.accessor('name', {
        header: '',
        cell: (info) => (
          <SVGWrapper size="m" icon={CircleFullSvg} color={getOriginalRolesColors(theme)[info.row.original.name]} />
        ),
      }),
      columnHelper.accessor('name', {
        header: () => sortableHeader('user:roles.name', 'name'),
        cell: (info) => <EditableInputTextCell info={info} />,
      }),
      columnHelper.accessor('name', {
        header: () => <Typography>{t('user:user-plural')}</Typography>,
        cell: (info) => {
          return (
            <Typography>
              {userRoleSummary?.find((role) => role?.roleName === info.getValue())?.userCounter ?? 0}
            </Typography>
          );
        },
      }),
      columnHelper.accessor('description', {
        header: () => <Typography>{t('description')}</Typography>,
        cell: (info) => (
          <Typography>{t(`user:roles.descriptions.${info.row.original.name}`, { defaultValue: '-' })}</Typography>
        ),
      }),
    ],
    [userRoleSummary, sortableHeader]
  );
};
