import { CustomScroll } from 'components/custom-scroll/custom-scroll.styled';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  
  *::before,
  *::after {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
    --rt-transition-show-delay: 0s !important;
    --rt-transition-closing-delay: 0s !important;
  }
  
  body {
    margin: 0;
    color: ${({ theme }) => theme.gray_darker};
    font-family: 'Lato', 'Ubuntu', 'Open Sans', sans-serif;
    font-size: inherit;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  button {
    border: 0;
    background-color: white;
    cursor: pointer;
  }

  button:hover,
  button:focus,
  a:focus {
    outline: none;
  }

  p {
    margin: 0;
  }
  
  p + p {
      margin: 0;
  }

  a {
    color: ${({ theme }) => theme.v2.text.primary.default};
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: ${({ theme }) => theme.v2.btn.ghost.text.default};
      text-decoration: none;
    }
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .text-overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .line-clamp-1 {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .blank-space {
    display: flex;
    flex-grow: 1;
  }

  .jvectormap-tip {
    font-size: 11px !important;
  }

  svg {
    height: auto;
    fill: currentColor;
  }

  .ReactCollapse--collapse {
    transition: all 300ms linear;
  }

  .visibility-hidden {
    visibility: hidden;
  }

  .design-process-modal {
    top: 126px;
    justify-content: start;
    align-items: start;
    left: 338px;

    @media (min-width: 1921px) {
      left: 408px;
    }
    
    #modal {
      max-width: calc(100% - 434px);
      max-height: calc(100vh - 140px);
    }
  }

  // rsuit component overrides
  .rs-progress-line {
    padding: 0;
  }

  .rs-slider-progress-bar {
    background-color: ${({ theme }) => theme.primary};
  }

  .rs-slider-handle {
    &::before {
      border-color: ${({ theme }) => theme.primary};
    }
  }

  .rs-slider-pass {
    &::before {
      border-color: ${({ theme }) => theme.primary} !important;
    }
  }

  .add-area-popup .mapboxgl-popup-content {
    padding: 0px!important;
  }

  .reflex-splitter, .reflex-container > .reflex-splitter {
    z-index: 1 !important;
  }

  .reflex-container, .reflex-container > .reflex-element {
    position: static !important;
  }

  .reactour__popover {
    border-radius: ${({ theme }) => theme.v2.spacing.xs};
  }

  .mapboxgl-ctrl-geocoder {
    font-family: 'Roboto';
    border-radius: ${({ theme }) => theme.v2.radius.xs};

    .mapboxgl-ctrl-geocoder--button:hover {
      background: none !important;
      svg {
        color: ${({ theme }) => theme.v2.icon.primary.default};
      }
    }

    svg {
      color: ${({ theme }) => theme.v2.icon.primary.default};
      fill: ${({ theme }) => theme.v2.icon.primary.default};
    }

    input {
      height: 32px;
      color: ${({ theme }) => theme.v2.input.text.default};
      border: none;
    }

    input:focus {
      border: 1px solid ${({ theme }) => theme.v2.input.stroke.active} !important;
    }

    .mapboxgl-ctrl-geocoder--suggestion {
      color: ${({ theme }) => theme.v2.text.primary.default};
    }
  }

  #livesdk__campaign, .livesdk__placement {
    z-index: 20 !important;
  }

  ${CustomScroll}
`;

export const zIndex = {
  sidebar: 99,
};

export default GlobalStyle;
