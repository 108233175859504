/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import * as THREE from 'three';
import React, { createContext, forwardRef, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useGesture } from '@use-gesture/react';
import FatLine from './fat-line';
import {
  PrimaryActionTypes,
  SecondaryActionTypes,
  useAddEdgeBetweenNodes,
  useAddLineBetweenNodesMode,
  useAddNodeBetweenTwoNodes,
  useAddNodeToExistingNodeMode,
  useDeleteNodeAndConnectingEdges,
  useDeleteNodeAndEdges,
  useDesignInputs,
  useModes,
  useMovementType,
  useMoveMultipleElements,
  usePointerType,
  useResetMode,
  useResetModeByStep,
  useRoads,
  useUpdateWithPreviousRoads,
} from '../ilc-store';
import { useWorldCoords } from '../hooks/use-world-coords';

import {
  calculateSelectedReferenceRectangleForRoads,
  distance,
  findClosestLineToPoint,
  getElementsGroupedByYIntersect,
  getMidpoints,
  getParallelLines,
  getPointerFromEvent,
  getPolygonCentroid,
  moveNodeAlongLine,
  nodePositionsToLine,
  selectedElementToRectangleWithPosition,
} from '../ilc-utils/geometry';
import { DrawableNode, DrawableRoad, Edge, Line, Point, RoadPolygonNodeTypes } from '../ilc-types';
import { useToasts } from '../../../utils/hooks/use-toasts';
import { useTranslation } from 'react-i18next';
import { ThreeEvent, useThree } from '@react-three/fiber';
import { getNodeIsEnabledByMode, getRoadIsDisabled } from '../ilc-utils/roads';
import { alignBySnapInGroup, alignBySnapToLine } from '../ilc-utils/align-to-line';

const context = createContext<any>(null);
export const Circle: any = forwardRef<any, any>(
  ({ children, opacity = 1, radius = 0.05, segments = 32, color = '#ff1050', ...props }, ref) => (
    <mesh rotation={[-Math.PI / 2, 0, 0]} ref={ref} {...props}>
      <circleGeometry args={[radius, segments]} />
      <meshBasicMaterial transparent={opacity < 1} opacity={opacity} color={color} />
      {children}
    </mesh>
  )
);

export function Nodes({ children, roadId }: { children: any; roadId: string }) {
  const [existingNodes, set] = useState<DrawableNode[]>([]);

  const action = useModes();
  const designInputs = useDesignInputs();
  const selectedElements = action.mode.type === PrimaryActionTypes.SELECT_RECTANGLES ? action.mode.payload : [];
  const selectedRectangles = designInputs
    ? selectedElements.map((element) => selectedElementToRectangleWithPosition(element, designInputs))
    : [];
  const onMoveMultipleElements = useMoveMultipleElements();
  const resetActionsByStep = useResetModeByStep();
  const elementsGroups = getElementsGroupedByYIntersect(selectedElements);

  const handleAlignSnapToRow = (line: Line, roadWidth: number) => {
    const centroids = Object.values(elementsGroups)
      .flat()
      .map((element) => element.centroid);
    const centroid = getPolygonCentroid(centroids);
    const roadBorders = getParallelLines(line, roadWidth / 2);
    const closestRoadBorder = findClosestLineToPoint(roadBorders, centroid);
    const elementsToUpdate = alignBySnapToLine(closestRoadBorder, elementsGroups);
    onMoveMultipleElements(elementsToUpdate);
    resetActionsByStep();
  };

  const handleAlignSnapInGroup = (line: Line, roadWidth: number) => {
    const centroids = selectedElements.map((element) => element.centroid);
    const roadBorders = getParallelLines(line, roadWidth / 2);
    const centroid = getPolygonCentroid(centroids);
    const closestRoadBorder = findClosestLineToPoint(roadBorders, centroid);
    const elementsToUpdate = alignBySnapInGroup(closestRoadBorder, selectedRectangles, selectedElements);
    onMoveMultipleElements(elementsToUpdate);
    resetActionsByStep();
  };

  const handleClickOnRoad = (line: Line, roadWidth: number) => {
    if (action.secondaryMode.type === SecondaryActionTypes.ALIGN_SELECTED_STRUCTURES_WITH_ROADS) {
      if (action.secondaryMode.payload === 'snap-to-row') {
        handleAlignSnapToRow(line, roadWidth);
        return;
      }
      if (action.secondaryMode.payload === 'snap-in-group') {
        handleAlignSnapInGroup(line, roadWidth);
      }
    }
  };

  const drawableRoads = useMemo(() => {
    const lines: DrawableRoad[] = [];
    for (const node of existingNodes) {
      for (const edge of node.connectedToEdges) {
        const connectedTargetNode = existingNodes.find((existingNode) => existingNode.userId === edge.target);
        const connectedNodePosition = connectedTargetNode?.position;
        if (connectedNodePosition) {
          const disabledEdge = getRoadIsDisabled(edge);
          lines.push({
            start: { x: node.position.x, z: node.position.z, y: 4.5 },
            end: { x: connectedNodePosition.x, z: connectedNodePosition.z, y: 4.5 },
            userId: `${node.userId}-${edge.target}`,
            node1: node.userId,
            node2: edge.target,
            disabled: disabledEdge,
            midPoint: {
              x: (node.position.x + connectedNodePosition.x) / 2,
              y: (node.position.z + connectedNodePosition.z) / 2,
            },
            width: node.roadWidth,
          });
        }
      }
    }

    return lines;
  }, [existingNodes]);

  const addNodeBetweenTwoNodes = useAddNodeBetweenTwoNodes();

  return (
    <context.Provider value={set}>
      {drawableRoads.map((line) => {
        return (
          <FatLine
            key={line.userId}
            color={line.disabled ? '#989E95' : 'black'}
            coordinates={[line.start, line.end]}
            width={line.width}
            userData={{ node1: line.node1, node2: line.node2 }}
            onClick={() => {
              handleClickOnRoad(nodePositionsToLine(line.start, line.end), line.width);
            }}
          />
        );
      })}
      {action.secondaryMode.type === SecondaryActionTypes.ALIGN_SELECTED_STRUCTURES_WITH_ROADS
        ? null
        : drawableRoads.map((line: any) =>
            line.disabled ? null : (
              <Circle
                key={`${line.userId}-midpoint`}
                radius={2}
                position={[line.midPoint.x, 5, line.midPoint.y]}
                onPointerDown={(e) => {
                  const intersectsWithRoadNodes = e.intersections.some(
                    (item) => item.eventObject.userData.type === RoadPolygonNodeTypes.ROAD_NODE
                  );
                  if (intersectsWithRoadNodes) return;
                  e.stopPropagation();
                  addNodeBetweenTwoNodes({ x: line.midPoint.x, y: -line.midPoint.y }, roadId, line.node1, line.node2);
                }}
                color={'yellow'}
                userId={`${line.userId}-midpoint`}
                userData={{ type: RoadPolygonNodeTypes.ROAD_MIDPOINT }}
              ></Circle>
            )
          )}
      {children}
    </context.Provider>
  );
}

interface INodesThreeJs {
  nodeKey: string;
  userId: string;
  name: string;
  nodesGraph: {
    [nodeId: string]: Point;
  };
  roadId: string;
  areaId: string;
  position: [number, number, number];
  color: string;
  connectedToBidirectional: Edge[];
  connectedToEdges: Edge[];
  onUpdate: (nodeId: string, coordinate: Point) => void;
  roadWidth: number;
}

export const NodeThreeJS: React.FC<INodesThreeJs> = ({
  nodeKey,
  name,
  userId,
  nodesGraph,
  color = 'black',
  connectedToBidirectional = [],
  connectedToEdges = [],
  position = [0, 0, 0],
  onUpdate,
  roadId,
  areaId,
  roadWidth,
}) => {
  const { t } = useTranslation('ilc');
  const { addErrorToast } = useToasts();
  const set = useContext(context);
  const action = useModes();
  const addEdgeBetweenNodes = useAddEdgeBetweenNodes();
  const addLineBetweenNodesMode = useAddLineBetweenNodesMode();
  const addNodeToExistingNode = useAddNodeToExistingNodeMode();
  const resetMode = useResetMode();
  const pointerType = usePointerType();
  const movementType = useMovementType();
  const deleteNodeAndEdges = useDeleteNodeAndEdges();
  const deleteNodeAndConnectingEdges = useDeleteNodeAndConnectingEdges();
  const roads = useRoads();
  const updateWithPreviousRoads = useUpdateWithPreviousRoads();
  const getWorldCoords = useWorldCoords();
  const { size } = useThree();
  const designInputs = useDesignInputs();
  const selectedReferenceElement =
    action.mode.type === PrimaryActionTypes.SELECT_REFERENCE_FOR_ROADS && action.mode.payload
      ? action.mode.payload
      : null;
  const selectedReferenceRectangle = calculateSelectedReferenceRectangleForRoads(
    selectedReferenceElement,
    designInputs,
    roadWidth
  );
  const isSelectingInitialNode =
    action.mode.type === PrimaryActionTypes.ADD_NEW_VERTEX_TO_EXISTING_VERTEX && !action.mode.payload?.connectedNode;
  const selectedInitialNodeForAddingNew =
    action.mode.type === PrimaryActionTypes.ADD_NEW_VERTEX_TO_EXISTING_VERTEX
      ? action.mode.payload?.connectedNode.initialNodeId
      : null;
  const selectedInitialNodeForConnecting =
    action.mode.type === PrimaryActionTypes.CONNECT_TWO_EXISTING_VERTICES ? action.mode.payload?.initialNodeId : null;
  const targetIsSameAsOrigin = selectedInitialNodeForConnecting === userId;
  const isSelectingElements = action.mode.type === PrimaryActionTypes.SELECT_RECTANGLES;
  const isAligningToRoads = action.secondaryMode.type === SecondaryActionTypes.ALIGN_SELECTED_STRUCTURES_WITH_ROADS;
  const hideNodes = isSelectingElements && isAligningToRoads;

  const getNodeColor = () => {
    if (hovered || selectedInitialNodeForConnecting === userId || selectedInitialNodeForAddingNew === userId)
      return '#ff1050';
    return color;
  };

  const preventNodeEditing = [...connectedToEdges, ...connectedToBidirectional].some((edge) => {
    if (getNodeIsEnabledByMode(action.mode.type)) return false;
    return getRoadIsDisabled(edge);
  });

  const midpoints = selectedReferenceRectangle ? getMidpoints(selectedReferenceRectangle) : null;

  const fullRectangleWithMidPoints =
    selectedReferenceRectangle && midpoints ? [...selectedReferenceRectangle, ...midpoints] : null;

  const [initialPosition, setInitialPosition] = useState(position);

  const positionVector = new THREE.Vector3(...position);
  const state = useMemo(() => {
    const drawableNode: DrawableNode = { position: positionVector, userId, connectedToEdges, roadId, roadWidth };
    return drawableNode;
  }, [positionVector, userId, connectedToEdges, roadWidth, roadId]);

  // Register this node on mount, unregister on unmount
  useLayoutEffect(() => {
    set((nodes: any) => [...nodes, state]);
    return () => set((nodes: any) => nodes.filter((n: any) => n !== state));
  }, [state, positionVector]);

  // Drag n drop, hover
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    document.body.style.cursor = hovered ? 'grab' : 'auto';
  }, [hovered]);

  const actionsThatPreventDragging = [
    PrimaryActionTypes.ADD_NODE_TO_CLOSEST,
    PrimaryActionTypes.CONNECT_TWO_EXISTING_VERTICES,
    PrimaryActionTypes.ADD_NEW_VERTEX_TO_EXISTING_VERTEX,
    PrimaryActionTypes.DELETE_NODE_AND_KEEP_CONNECTIONS,
    PrimaryActionTypes.DELETE_NODE_AND_CONNECTING_EDGES,
  ];

  const preventDragging = actionsThatPreventDragging.includes(action.mode.type) || pointerType === 'MOVE';

  const [initialRoads, setInitialRoads] = useState<any>(null);
  const bind: any = useGesture(
    {
      onDragStart: () => {
        if (preventDragging || preventNodeEditing) return;
        if (roads) setInitialRoads(roads);
      },
      onDrag: ({ down, xy: [x, y], dragging }) => {
        if (preventDragging || preventNodeEditing || !dragging) return;
        document.body.style.cursor = down ? 'grabbing' : 'grab';

        const newPosition = getWorldCoords(getPointerFromEvent({ clientX: x, clientY: y }, size));

        if (selectedReferenceElement && fullRectangleWithMidPoints) {
          const distanceToRespect = 10;
          let closestDistance = Infinity;
          let closestPoint: Point | null = null;
          for (let i = 0; i < fullRectangleWithMidPoints.length; i++) {
            const dist = distance(newPosition, fullRectangleWithMidPoints[i]);
            if (dist < closestDistance) {
              closestDistance = dist;
              closestPoint = fullRectangleWithMidPoints[i];
            }
          }
          if (closestDistance < distanceToRespect && closestPoint) {
            onUpdate(userId, { x: closestPoint.x, y: closestPoint.y });
            return;
          } else {
            onUpdate(userId, { x: newPosition.x, y: newPosition.y });
          }
        } else if (movementType === 'ORTHOGONAL') {
          const newPositionAlongLine = moveNodeAlongLine(
            { x: newPosition.x, y: newPosition.y },
            { x: initialPosition[0], y: -initialPosition[2] },
            connectedToBidirectional
              .map((edge) => edge.target)
              .map((connectedNode: string) => ({
                x: nodesGraph[connectedNode].x,
                y: nodesGraph[connectedNode].y,
              }))
          );
          if (newPositionAlongLine) onUpdate(userId, { x: newPositionAlongLine.x, y: newPositionAlongLine.y });
        } else {
          onUpdate(userId, { x: newPosition.x, y: newPosition.y });
        }
      },
      onDragEnd: () => {
        if (preventDragging || preventNodeEditing) return;
        document.body.style.cursor = 'auto';
        setInitialPosition([nodesGraph[userId].x, 5, -nodesGraph[userId].y]);
        updateWithPreviousRoads(initialRoads);
      },
    },
    {
      drag: {
        filterTaps: true,
      },
    }
  );

  const handleSelectNodeForAddingEdge = (nodeId: string) => {
    if (action.mode.type === PrimaryActionTypes.CONNECT_TWO_EXISTING_VERTICES) {
      if (!action.mode.payload) {
        addLineBetweenNodesMode({ initialNodeId: nodeId, initialNodeAreaId: areaId });
        return;
      }
      if (action.mode.payload.initialNodeAreaId !== areaId) {
        addErrorToast(t('errors.connect-roads-different-areas'), 'top-center');
        return;
      }
      addEdgeBetweenNodes(action.mode.payload.initialNodeId, nodeId, roadId);
      resetMode();
    }
  };

  const onClickNode = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
    if (targetIsSameAsOrigin) return;
    if (isSelectingInitialNode) {
      addNodeToExistingNode({ connectedNode: { roadId, initialNodeId: userId, initialNodeAreaId: areaId } });
      return;
    }
    if (action.mode.type === PrimaryActionTypes.CONNECT_TWO_EXISTING_VERTICES) {
      handleSelectNodeForAddingEdge(userId);
      return;
    }
    if (action.mode.type === PrimaryActionTypes.DELETE_NODE_AND_CONNECTING_EDGES) {
      deleteNodeAndConnectingEdges(userId, roadId);
      return;
    }
    if (action.mode.type === PrimaryActionTypes.DELETE_NODE_AND_KEEP_CONNECTIONS) {
      deleteNodeAndEdges(userId, roadId);
      return;
    }
  };

  if (preventNodeEditing || hideNodes) {
    return null;
  }

  return (
    <Circle
      {...bind()}
      opacity={0.5}
      radius={4}
      onClick={onClickNode}
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
      color={getNodeColor()}
      position={positionVector}
      roadId={roadId}
      key={nodeKey}
      name={name}
      userId={nodeKey}
      userData={{ type: RoadPolygonNodeTypes.ROAD_NODE }}
    />
  );
};
