import { useTheme } from 'styled-components';
import { IProjectsOptions } from 'types/support.types';
import { IOptionWithColor } from 'types/common';
import { ISort } from 'types/filter';
import { getDateMonthDDYYYY } from 'utils/date';
import * as S from './projects-files-tab.styled';
import { GenericStatus, projectStatusList, STATUS_COLORS } from 'utils/constants/status';
import { Button, SplitButton, IOption, SortableHeader, Tag, Typography } from '@ratedpower/components';
import ConverterInputNumber from 'components/form-components/converter-input-number';
import React from 'react';
import { defaultSelector } from 'utils/constants/support';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { IProjectListItem } from 'types/projects';
import { openExternalLink } from 'utils/navigation';
import { useListTeams } from 'pages/teams/hooks/use-list-teams';
import { IndexIds } from 'index.ids';

const columnHelper = createColumnHelper<IProjectListItem>();
export const useProjectFilesColumns = (projectsOptions: IProjectsOptions, hasCapexSupportPermission: boolean) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { teams } = useListTeams({ infiniteScroll: false, pageSize: 30 });
  const companyHasTeams = !!teams?.length;

  const handleSorting = (filter: ISort) => {
    projectsOptions.onSort(filter);
  };

  return [
    columnHelper.accessor('name', {
      header: () => (
        <SortableHeader
          onSort={handleSorting}
          selector={{
            label: t('support:projects-name'),
            code: 'name',
          }}
          sortedBy={projectsOptions ? projectsOptions.sortedBy : defaultSelector}
        />
      ),
      cell: (info) => (
        <S.ProjectCellContainer>
          <Button
            onClick={() => openExternalLink(`/projects/${info.row.original.id}`)}
            fontColor={theme.v2.text.primary.default}
            text={info.getValue()}
            variant="link"
            noPadding
            textHoverUnderlined
          />
          <Typography size="s" color={theme.v2.text.secondary.default}>
            {info.row.original.createdBy.name}
          </Typography>
        </S.ProjectCellContainer>
      ),
    }),
    columnHelper.accessor('region', {
      header: () => (
        <SortableHeader
          onSort={handleSorting}
          selector={{
            label: t('location'),
            code: 'country',
          }}
          sortedBy={projectsOptions ? projectsOptions.sortedBy : defaultSelector}
        />
      ),
      cell: (info) => (
        <Typography>
          {`${info.row.original.country || '-'}, ${info.row.original.region || '-'}, ${info.row.original.city || '-'}`}
        </Typography>
      ),
    }),
    columnHelper.accessor('publishedDate', {
      header: () => (
        <SortableHeader
          onSort={handleSorting}
          selector={{
            label: t('support:date-created'),
            code: 'publishedDate',
          }}
          sortedBy={projectsOptions ? projectsOptions.sortedBy : defaultSelector}
        />
      ),
      cell: (info) => <Typography>{getDateMonthDDYYYY(info.getValue())}</Typography>,
    }),
    columnHelper.accessor('maxSiteToProjectDistance', {
      header: () => (
        <SortableHeader
          onSort={handleSorting}
          selector={{
            label: t('support:distance-to-margin'),
            code: 'maxSiteToProjectDistance',
          }}
          sortedBy={projectsOptions ? projectsOptions.sortedBy : defaultSelector}
        />
      ),
      cell: (info) => {
        const { maxSiteToProjectDistance, id, status } = info.row.original;
        const baseValue = Math.round((maxSiteToProjectDistance * 0.001 + Number.EPSILON) * 100) / 100;
        if (!projectsOptions.hasWritePermissions) return <Typography>{baseValue}</Typography>;
        return (
          <S.InputContainer>
            <ConverterInputNumber
              baseValue={baseValue}
              updateForm={(newValue) => projectsOptions.onChange(id, status, Number(newValue) * 1000)}
              name="distance-to-margin"
              suffix={t('units:km-short')}
              size="s"
            />
          </S.InputContainer>
        );
      },
    }),
    ...(companyHasTeams
      ? [
          columnHelper.accessor('teamName', {
            header: () => (
              <SortableHeader
                onSort={handleSorting}
                selector={{
                  label: t('user:teams.team'),
                  code: 'teamName',
                }}
                sortedBy={projectsOptions ? projectsOptions.sortedBy : defaultSelector}
              />
            ),
            cell: (info) => <Typography>{info.getValue() || t('user:teams.public-teams')}</Typography>,
          }),
        ]
      : []),
    columnHelper.accessor('numDesigns', {
      header: () => (
        <S.CenteredText>
          <SortableHeader
            onSort={handleSorting}
            selector={{
              label: t('support:designs-number'),
              code: 'numDesigns',
            }}
            sortedBy={projectsOptions ? projectsOptions.sortedBy : defaultSelector}
          />
        </S.CenteredText>
      ),
      cell: (info) => <S.CenteredText>{info.getValue()}</S.CenteredText>,
    }),
    columnHelper.accessor('status', {
      header: () => (
        <SortableHeader
          onSort={handleSorting}
          selector={{
            label: t('support:status'),
            code: 'status',
          }}
          sortedBy={projectsOptions ? projectsOptions.sortedBy : defaultSelector}
        />
      ),
      cell: (info) => {
        const { maxSiteToProjectDistance, id, status } = info.row.original;
        if (!projectsOptions.hasWritePermissions) {
          return (
            <Tag color={STATUS_COLORS[info.getValue() as GenericStatus]} ellipsis={false}>
              {info.getValue()}
            </Tag>
          );
        }
        const optionSelected: IOptionWithColor | undefined = projectStatusList(theme).find(
          (item) => item.value === status
        );
        return (
          <SplitButton
            backgroundColor={optionSelected?.background}
            options={projectStatusList(theme).map((item) => ({ ...item, label: t(item.label) }))}
            onClickOption={(newStatus: IOption) =>
              projectsOptions.onChange(id, newStatus.value, maxSiteToProjectDistance)
            }
            text={t(optionSelected?.label)}
            textAlign="left"
            width="175px"
            variant="ghost"
          />
        );
      },
    }),
    columnHelper.accessor('id', {
      header: () => <></>,
      cell: () => {
        if (!hasCapexSupportPermission) return <></>;
        return (
          <Button
            onClick={() => openExternalLink(`/capex?companyId=${projectsOptions.companyId}`)}
            text={t('capex:capex').toLocaleUpperCase()}
            variant="link"
            dataTest={IndexIds.CompaniesActivity.Capex}
            textUnderlined
            noPadding
          />
        );
      },
    }),
  ];
};
