import styled from 'styled-components';

export const MainView = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.l};
  display: flex;
  flex-direction: column;
  height: calc(100vh - 180px);
  flex-grow: 1;

  th:nth-child(2) {
    width: 20%;
  }

  th:nth-child(1),
  th:nth-child(3) {
    width: 10%;
  }

  th:nth-child(4) {
    width: 55%;
  }

  td {
    white-space: normal;
    height: 50px;
  }

  .design-process-input {
    display: block;
  }

  .pagination-container {
    margin-top: 1.5rem;
  }

  /* Temporal patch solution until the web gets responsive */
  @media screen and (min-width: 1400px) {
    .table-container {
      tr {
        &:nth-last-child(-n + 3) {
          td .option-list ul,
          td .option-list {
            position: absolute;
          }
        }
      }
    }
  }
`;

export const RolesTabWrapper = styled.div`
  flex-grow: 1;
`;

export const TopbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.v2.spacing.l};
  padding-left: ${({ theme }) => theme.v2.spacing.l};
  padding-right: ${({ theme }) => theme.v2.spacing.l};
`;
