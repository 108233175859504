import THREE from 'three';
import { InverterType } from 'utils/constants/equipment-constants';

export type IlcValidationError = {
  code:
    | 'INTERSECTS_RA'
    | 'INTERSECTS_ST'
    | 'OUTSIDE_AA'
    | 'OVERLAPS_PS'
    | 'OVERLAPS_ROAD'
    | 'OVERLAPS_STRUCTURE'
    | 'VIOLATES_CLEARANCE'
    | 'VIOLATES_SETBACKS'
    | 'VIOLATES_INVERTER_GROUPING'
    | 'DISCONNECTED_ROADS';
  error: boolean;
  id: string;
  message: string;
  result: 'OK' | 'WARNING' | 'BLOCKER';
  type: 'SETBACKS' | 'STRUCTURES' | 'POWER_STATIONS' | 'ROADS';
  areaId: string;
};

export interface LayoutResponse {
  layers: LayoutLayers;
  editable: string[];
  offset: { x: any; y: any };
  bbox: { min: Point; max: Point };
  bboxAndOffset: BboxAndOffset;
  setbacks: Setbacks;
  utmZone: string;
}

export interface PolygonElement {
  exteriorRing: Point[];
  interiorRings: Point[];
  color: string;
  type: string;
  key: string;
}

export interface InverterElement {
  primary: boolean;
  psKey: string;
  areaKey: string;
  key: string;
  type: 'INVERTERS';
  color: string;
}

export interface AvailableAreaElement extends PolygonElement {
  type: 'AVAILABLE_AREAS';
  areaName: string;
}

export interface RestrictedAreaElement extends PolygonElement {
  type: 'USER_RESTRICTED_AREAS' | 'AUTO_GENERATED_RESTRICTED_AREAS';
}

export interface SubstationAreaElement extends PolygonElement {
  type: 'SUBSTATION_AREAS';
}

export interface SwitchingStationElement extends PolygonElement {
  type: 'SWITCHING_STATION_AREAS';
}

export interface BatteryAreaElement extends PolygonElement {
  type: 'BATTERY_AREAS';
}

export interface SwitchingStationLayer {
  [id: string]: SwitchingStationElement;
}

export interface InvertersLayer {
  [id: string]: InverterElement;
}

export interface BatteryAreaLayer {
  [id: string]: BatteryAreaElement;
}

export interface SubstationAreasLayer {
  [areaId: string]: SubstationAreaElement;
}

export interface StructuresLayer {
  [structureId: string]: StructureElement;
}

export interface PowerStationsLayer {
  [psId: string]: PowerStationElement;
}

export interface AvailableAreasLayer {
  [areaId: string]: AvailableAreaElement;
}

export interface UserRestrictedAreasLayer {
  [areaId: string]: RestrictedAreaElement;
}

export type Edge = {
  source: string;
  target: string;
  type:
    | 'ACCESS_ROAD'
    | 'CUSTOM_BRIDGE'
    | 'ILC_ROAD'
    | 'BRIDGE'
    | 'BRIDGE_OUTSIDE_AA'
    | 'ACCESS_BRIDGE'
    | 'HORIZONTAL_OR_VERTICAL_ROAD'
    | 'EXTERIOR_PERIMETER_ROAD'
    | 'SURROUNDING_PERIMETER_ROAD'
    | 'INNER_PERIMETER_ROAD';
};

export interface RoadElement {
  areaKey: string;
  color: string;
  type: 'ROADS';
  roadWidth: number;
  nodes: {
    [nodeId: string]: Point;
  };
  edges: Edge[];
}

export interface RoadsLayer {
  [roadId: string]: RoadElement;
}

export type LayoutLayers = {
  STRUCTURES: StructuresLayer;
  POWER_STATIONS: PowerStationsLayer;
  AVAILABLE_AREAS: AvailableAreasLayer;
  USER_RESTRICTED_AREAS: UserRestrictedAreasLayer;
  SUBSTATION_AREAS: SubstationAreasLayer;
  AUTO_GENERATED_RESTRICTED_AREAS: UserRestrictedAreasLayer;
  ROADS: RoadsLayer;
  BATTERY_AREAS: BatteryAreaLayer;
  SWITCHING_STATION_AREAS: SwitchingStationLayer;
  INVERTERS: InvertersLayer;
};

export type IlcLayoutAction = {
  action: 'MOVE' | 'DELETE' | 'CREATE' | 'REGROUP';
  entity: 'STRUCTURE' | 'PS' | 'ROAD' | 'INVERTER';
  timestamp: number;
  modifiedElement: Record<string, RoadElement | StructureElement | PowerStationElement | InverterElement>;
};

export type SessionInputs = {
  designName: string;
  projectName: string;
};

export type BboxAndOffset = {
  northeast: Point;
  southwest: Point;
  offsetEasting: number;
  offsetNorthing: number;
};

export type Setback = {
  type:
    | 'PS_TO_ST'
    | 'PS_TO_AA'
    | 'PS_TO_RA'
    | 'STRUCTURE_TO_ST'
    | 'STRUCTURE_TO_AA'
    | 'STRUCTURE_TO_RA'
    | 'ROAD_TO_ST'
    | 'ROAD_TO_AA'
    | 'ROAD_TO_RA'
    | 'PS_TO_ST_MIN'
    | 'PS_TO_AA_MIN'
    | 'PS_TO_RA_MIN'
    | 'STRUCTURE_TO_ST_MIN'
    | 'STRUCTURE_TO_AA_MIN'
    | 'STRUCTURE_TO_RA_MIN'
    | 'ROAD_TO_ST_MIN'
    | 'ROAD_TO_AA_MIN'
    | 'ROAD_TO_RA_MIN';
  exteriorOffset: Point[];
  interiorOffsets: Point[][];
};

export type Setbacks = {
  [key: string]: Setback[];
};

export interface Point {
  x: number;
  y: number;
}

export type NodePosition = {
  x: number;
  y: number;
  z: number;
};

export type DrawableRoad = {
  start: NodePosition;
  end: NodePosition;
  userId: string;
  node1: string;
  node2: string;
  disabled: boolean;
  midPoint: Point;
  width: number;
};

export interface DrawableRectangle {
  points: Point[];
  center: Point;
  id: string;
}

export type SlopeData = {
  slope: number;
  yIntercept: number;
  roundedYIntercept: number;
};

export type SelectedElementWithSlopeData = SelectedElement &
  SlopeData & {
    rectangle: Point[];
  };

export type IntersectionPoint = {
  point: Point;
  distance: number;
  offSet: Point;
};

export type AreaParametricInputs = {
  distanceBetweenStructures: number;
  distancePsToStructure: number;
  structureAngle: number;
  distanceRoadToStructure: number;
  distanceBetweenTables: number;
  distancePsToRoad: number;
  northSouthOriented: boolean;
  eastWest: boolean;
  clearanceDistance: number;
  structureType: 'TRACKER' | 'FIXED';
  areaName: string;
};

export type StructureParametricInputs = {
  strings: number;
  length: number;
  width: number;
};

export interface DesignInputs {
  areaParametricInputs: {
    [areaKey: string]: AreaParametricInputs;
  };
  structureParametricInputs: StructureParametricInputs[];
  designParametricInputs: {
    projectName: string;
    simulationName: string;
    stringDcPower: number;
    utmConvergence: number;
  };
}

export interface PowerKPI {
  dcAcRatio: number;
  peakDcPower: number;
  ratedPower: number;
}

export interface PowerStationKPIData {
  powerKpis: PowerKPI;
  areaKey: string;
  psKey: string;
  psName: string;
  strings: number;
}

export interface PowerStationKPI {
  [psId: string]: PowerStationKPIData;
}

export interface AreaKPI {
  [areaKey: string]: {
    areaName: string;
    areaKey: string;
    powerKpis: PowerKPI;
  };
}

export interface DesignKPI {
  areaKpis: AreaKPI;
  powerStationKpis: PowerStationKPI;
  powerKpis: PowerKPI;
}

export type KPISortByValue = 'name' | 'dcAcRatio' | 'peakDcPower' | 'ratedPower';

export type SortDirection = 'asc' | 'desc';

export interface RectangleElement {
  angle: number;
  centroid: { x: number; y: number };
  color: string;
  areaKey: string;
  length: number;
  width: number;
  type: string;
  key: string;
}

export interface StructureElement extends RectangleElement {
  psKey: string;
  strings: number;
  multiKey?: string;
  type: 'STRUCTURES';
}

export interface PowerStationElement extends RectangleElement {
  type: 'POWER_STATIONS';
  dcBess: boolean;
}

export interface PowerStationElementWithId extends PowerStationElement {
  id: string;
}

export interface StructureElementWithId extends StructureElement {
  id: string;
}

export type AlignToLineOption = 'snap-in-group' | 'snap-to-row';

export type SelectedElement = PowerStationElementWithId | StructureElementWithId;

export type AddStructurePayload = {
  selectedStructureOption?: StructureParametricInputs;
  selectedPs?: string;
  referenceStructureId?: string;
};

export type AddStructureData = {
  selectedPs: string;
  selectedStructureOption: StructureParametricInputs;
  coordinates: Point;
  referenceStructureId?: string;
  structureId?: string;
};

export type AddAction = {
  type: 'ADD';
  previousState: AddStructureData;
  newState: AddStructureData;
};

export type AddMultipleAction = {
  type: 'ADD_MULTIPLE';
  previousState: string[];
  newState: StructureElement[];
};

export type DeleteAction = {
  type: 'DELETE';
  previousState: StructureElement[];
  newState: StructureElement[];
};

export type MoveAction = {
  type: 'MOVE';
  previousState: (StructureElement | PowerStationElement)[];
  newState: (StructureElement | PowerStationElement)[];
};

export type RegroupAction = {
  type: 'REGROUP';
  previousState: StructureElement[];
  newState: StructureElement[];
};

export type RoadsChangeAction = {
  type: 'ROADS_CHANGE';
  previousState: RoadsLayer;
  newState: RoadsLayer;
};

export type Action = AddAction | DeleteAction | MoveAction | RegroupAction | RoadsChangeAction | AddMultipleAction;

export interface Line {
  point1: Point;
  point2: Point;
}

export type DrawableLine = {
  start: { x: number; y: number; z: number };
  end: { x: number; y: number; z: number };
  color: string;
  id: string;
};

export type Area = {
  id: string;
  label: string;
};

export type GenerateDesignResponse = { data: { simulationId: string }; error: null } | { data: null; error: string };

export type DrawableNode = {
  position: typeof THREE.Vector3;
  userId: string;
  connectedToEdges: Edge[];
  roadId: string;
  roadWidth: number;
};

export type InverterPowerConfiguration = {
  inverterCentralModel1Power: number;
  inverterCentralModel2Power: number;
  inverterStringModelPower: number;
  electricalCosPhiInverters: number;
  inverterType: InverterType;
};

export enum RoadPolygonNodeTypes {
  ROAD_NODE = 'ROAD_NODE',
  ROAD_MIDPOINT = 'ROAD_MIDPOINT',
}
