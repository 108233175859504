import { useMemo, useState } from 'react';
import { IServerFilter, ISort } from 'types/filter';
import { getSortDirection } from 'utils/sort';
import { useCustomQuery } from '../../../utils/hooks/use-custom-query';
import { useSearch } from '../../../utils/hooks/use-search';
import { Table, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { teamMemberListColumns } from 'utils/teams';
import { useModal } from 'utils/hooks/use-modal';
import { GET_COMPANY_USERS } from 'services/team-gql';
import { IMemberDTO } from 'types/teams';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux/store';

interface IUseListTeamMembers {
  showCurrentTeamMembers: boolean;
  teamId: string;
  canUnassign?: boolean;
  pageSize: number;
}

export const useListTeamMembers = ({ showCurrentTeamMembers, teamId, canUnassign, pageSize }: IUseListTeamMembers) => {
  const { t } = useTranslation();
  const { getSearchFilter, inputSearchProps } = useSearch();
  const teamsAssignedModal = useModal();
  const unassignMemberModal = useModal();

  const { id: companyIdFromUrl } = useParams<{ id: string }>();
  const profile = useSelector((state: RootState) => state.currentUser.profile);

  const [sortedBy, setSortedBy] = useState<ISort>({
    selector: {
      label: t('name'),
      code: 'name',
    },
    ascendent: true,
  });

  const [filterTeamId, setFilterTeamId] = useState<string | undefined>();
  const [filters, setFilters] = useState<IServerFilter[]>([]);

  const { loading, error, data, paginationComponent } = useCustomQuery(GET_COMPANY_USERS, {
    variables: {
      companyId: companyIdFromUrl || profile?.companyId,
      currentTeamId: teamId,
      showCurrentTeamMembers,
      filterTeamId,
      pagination: {
        page: 0,
        size: pageSize,
      },
      sorting: {
        property: sortedBy.selector.code,
        direction: getSortDirection(sortedBy.ascendent),
      },
      search: getSearchFilter(),
      filters: [...filters, { field: 'STATUS', operator: 'IN', values: ['ENABLED'] }],
    },
    fetchPolicy: 'network-only',
  });

  const onUnassignUser = (userId?: string, userName?: string) => {
    unassignMemberModal.setData({ userId, userName });
    unassignMemberModal.setModalOpen(true);
  };

  const onClickTeamsAssigned = (userId?: string) => {
    teamsAssignedModal.setData(userId);
    teamsAssignedModal.setModalOpen(true);
  };

  const tableData = useMemo(() => data?.getCompanyUsers?.content ?? [], [data]);

  const table: Table<IMemberDTO> = useReactTable({
    data: tableData,
    columns: teamMemberListColumns(t, {
      sortedBy,
      setSortedBy,
      onClickTeamsAssigned,
      ...(canUnassign && { onUnassignUser }),
    }),
    enableMultiRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    table,
    inputSearchProps,
    loading,
    paginationComponent,
    error,
    isEmptyData: !loading && tableData.length === 0,
    teamsAssignedModal,
    unassignMemberModal,
    setFilterTeamId,
    setFilters,
  };
};
