import { InverterElement } from '../ilc-types';

export function getInverterElement(
  isPrimary: boolean,
  psKey: string,
  areaKey: string,
  key: string,
  color: string
): InverterElement {
  return {
    primary: isPrimary,
    psKey,
    areaKey,
    key,
    type: 'INVERTERS',
    color,
  };
}
