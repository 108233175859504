import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tabs, TopbarWrapper, ITab } from '@ratedpower/components';
import InputSearch from 'components/form-components/input-search';
import { LayersSvg, FolderImportSvg, FavouriteSvg, FilterSvg } from 'assets/icons';
import { ISearchProps } from 'utils/hooks/use-search';
import { DESIGNS_TABS as tabs } from 'utils/constants/tabs';
import { useTheme } from 'styled-components';

interface IProps {
  selectedTab: string;
  onChangeTab: (e: string) => void;
  onFilterClick: () => void;
  inputSearchProps: ISearchProps;
}

const DesignsSubBar: React.FC<IProps> = ({ selectedTab = 'designs', inputSearchProps, onChangeTab, onFilterClick }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const pageTabs: ITab[] = [
    {
      id: tabs.designs,
      label: t('designs'),
      Icon: LayersSvg,
    },
    {
      id: tabs.favorites,
      label: t('favorites'),
      Icon: FavouriteSvg,
    },
    {
      id: tabs.archived,
      label: t('archived'),
      Icon: FolderImportSvg,
    },
  ];

  return (
    <TopbarWrapper
      type="secondary"
      padding={'0'}
      left={<Tabs onClickTab={onChangeTab} tabActive={selectedTab} tabs={pageTabs} />}
      right={
        <>
          <InputSearch size="s" value={inputSearchProps.value} onChange={inputSearchProps.onChange} />
          <Button
            fontColor={theme.v2.text.accent.default}
            fontColorHover={theme.v2.text.accent.hover}
            iconPosition="left"
            size="m"
            variant="ghost"
            onClick={onFilterClick}
            text={t('equipment:filters')}
            Icon={FilterSvg}
            textHoverUnderlined={false}
            textUnderlined={false}
          />
        </>
      }
      noBorder
    />
  );
};

export default DesignsSubBar;
