import { useCallback, useMemo } from 'react';
import { temperatureUnits } from './temperature';
import { lengthUnits, areaUnits } from './length';
import { volumeUnits } from './volume';
import { surfaceUnits } from './surface';
import { invertedSurfaceUnits } from './inverted-surface';
import { dimensionConverter } from './dimension';
import { IAttribute } from '../constants/design-attributes';
import { resolveKey } from '../objects';
import {
  IUnits,
  UnitSystem,
  IConverters,
  IConverter,
  IConvertedValueToShow,
  IConvertToShowOptions,
  IConvertToSendOptions,
  UnitSize,
  Converter,
} from 'types/preferences.types';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { elasticityUnits } from './elasticity';
import { unitaryMassUnits } from './unitary-mass';
import { truncateExponentialNumber } from 'utils/numbers';

interface IUseConverter {
  converters: IConverters | undefined;
  getValueOf: (attribute: IAttribute, obj: { [key: string]: any }, currency?: string) => any;
  convertValueOf: (attribute: IAttribute, obj: { [key: string]: any }, options?: IConvertToShowOptions) => any;
  showValue: (result: IConvertedValueToShow, unitAppended?: string) => string;
  getOnlyValueOf: (attribute: IAttribute, obj: { [key: string]: any }) => any;
}

export const useConverter = (localPreferences?: { [key in Converter]?: UnitSystem }): IUseConverter => {
  const preferences = useSelector((state: RootState) => state.currentUser.preferences);

  const converters = useMemo(
    () => ({
      temperature: unitConverter(temperatureUnits, localPreferences?.temperature ?? preferences.temperature),
      length: unitConverter(lengthUnits, localPreferences?.length ?? preferences.length),
      surface: unitConverter(surfaceUnits, localPreferences?.length ?? preferences.length),
      invertedSurface: unitConverter(invertedSurfaceUnits, localPreferences?.surface ?? preferences.surface),
      volume: unitConverter(volumeUnits, localPreferences?.surface ?? preferences.surface),
      area: unitConverter(areaUnits, localPreferences?.length ?? preferences.length),
      elasticity: unitConverter(elasticityUnits, localPreferences?.length ?? preferences.length),
      unitaryMass: unitConverter(unitaryMassUnits, localPreferences?.length ?? preferences.length),
      percentage: percentageConverter(),
      dimension: dimensionConverter(),
      decimal: decimalConverter(),
    }),
    [preferences, localPreferences?.length, localPreferences?.temperature, localPreferences?.surface]
  );

  /**
   * @function getValueOf Obtain showed value of a defined attribute
   * @param {IAttribute} attribute - Defined attribute
   * @param {{[key: string]: any; }} obj - Object from which it will be obtain the requested value
   * @param {string} currency - (optional) for those attributes that requires currency for being rendered
   * @returns {any} - Formatted value acording attribute.render() function.
   */
  const getValueOf = useCallback(
    (attribute: IAttribute, obj: { [key: string]: any }, currency?: string) => {
      // Resolve key/s from received object
      let value = resolveKey(obj, attribute.key) ?? '';

      // Convert value
      if (attribute.converter && converters?.[attribute.converter.name]) {
        const converter: IConverter = converters[attribute.converter.name];
        const options = attribute.converter?.options?.decimals
          ? { ...attribute.converter?.options }
          : { ...attribute.converter?.options, decimals: 1 };

        if (Array.isArray(value)) {
          value = value.map((v) => (v ? converter.toShow(v, options) : ''));
        } else if (typeof value === 'string' || typeof value === 'number') {
          const numericValue = typeof value === 'string' ? parseFloat(value) : value;
          value = converter.toShow(numericValue, options);
        }
      }
      // Render value
      return attribute.render(value, currency);
    },
    [converters]
  );

  const getOnlyValueOf = useCallback(
    (attribute: IAttribute, obj: { [key: string]: any }) => {
      // Resolve key/s from received object
      let value = resolveKey(obj, attribute.key) ?? '';

      // Convert value
      if (attribute.converter && converters?.[attribute.converter.name]) {
        const converter: IConverter = converters[attribute.converter.name];
        const options = attribute.converter.options ?? { decimals: 1 };

        if (Array.isArray(value)) {
          value = value.map((v) => (v ? converter.toShow(v, options) : ''));
        } else if (typeof value === 'string' || typeof value === 'number') {
          const numericValue = typeof value === 'string' ? parseFloat(value) : value;
          value = converter.toShow(numericValue, options).value;
        }
      }
      // Render value
      return value;
    },
    [converters]
  );

  const convertValueOf = useCallback(
    (attribute: IAttribute, obj: { [key: string]: any }, options?: IConvertToShowOptions) => {
      // Resolve key/s from received object
      let result = resolveKey(obj, attribute.key) ?? '';

      // Convert value
      if (attribute.converter && converters?.[attribute.converter.name]) {
        const converter: IConverter = converters[attribute.converter.name];
        const finalOptions = {
          ...{ decimals: 1 },
          ...attribute.converter.options,
          ...options,
        };

        if (Array.isArray(result)) {
          result = result.map((v) => (v ? converter.toShow(v, finalOptions) : ''));
        } else if (typeof result === 'string' || typeof result === 'number') {
          const numericValue = typeof result === 'string' ? parseFloat(result) : result;
          result = converter.toShow(numericValue, finalOptions);
        }
        return result.value;
      }
      if (typeof result === 'number' && options?.decimals !== undefined) {
        return result.toFixed(options.decimals);
      }

      return result;
    },
    [converters]
  );

  const showValue = (result: IConvertedValueToShow, unitAppended?: string): string => {
    return `${result.value} ${result.unit}${unitAppended}`;
  };

  return {
    converters,
    getValueOf,
    convertValueOf,
    showValue,
    getOnlyValueOf,
  };
};

/**
 * This function is used for converting units from international to imperial system for one dimension only
 */
const unitConverter = (units: IUnits, preferences: UnitSystem): IConverter => {
  function getUnit(size: UnitSize) {
    const conversor = units[preferences][size];
    return conversor?.unit ?? '';
  }

  function toShow(value: number, options?: IConvertToShowOptions): IConvertedValueToShow {
    const convertToSize = options?.size ?? 'M';

    const conversor = units[preferences][convertToSize] ?? units[preferences].M;
    let convertedValue: number | string = conversor.toShow(value);
    if (!!options?.trailingZeros && options?.decimals) {
      return {
        value: Number(convertedValue).toFixed(options?.decimals),
        unit: conversor.unit,
      };
    }

    if (options?.decimals !== undefined && !options?.exponentialNotation) {
      convertedValue = parseFloat(convertedValue.toFixed(options?.decimals));
    }
    if (options?.exponentialNotation === true) {
      convertedValue = truncateExponentialNumber(convertedValue, options.decimals);
    }
    return { value: convertedValue, unit: conversor.unit };
  }

  function toSend(value: number, options?: IConvertToSendOptions): number {
    const convertToSize = options?.size ?? 'M';

    const conversor = units[preferences][convertToSize];
    if (!conversor) throw new Error(`Converter not found for ${preferences} UnitSystem and ${convertToSize} UnitSize`);
    const convertedValue = conversor.toSend(value);
    return convertedValue;
  }

  return {
    getUnit,
    toShow,
    toSend,
  };
};

const percentageConverter = () => {
  function toShow(value: number, options?: IConvertToShowOptions) {
    const newValue = parseFloat(String(value * 100)).toFixed(options?.decimals ?? 1);
    return { value: newValue, unit: '%' };
  }

  function toSend(value: number) {
    return value / 100;
  }

  return {
    toShow,
    toSend,
  };
};

const decimalConverter = (): IConverter => {
  function toShow(value: number, options?: IConvertToShowOptions) {
    if (!!options && 'decimals' in options) {
      if (options?.trailingZeros) {
        return { value: Number(value).toFixed(options?.decimals) };
      }
      return { value: parseFloat(value.toFixed(options?.decimals)) };
    }
    return { value };
  }

  const toSend = (value: number) => value;

  return {
    toShow,
    toSend,
  };
};
