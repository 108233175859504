import { getApiUrl } from 'utils/api_url';
import { IInputs } from 'types/design-process';

export const getSimulationInputs = async (
  projectId: string,
  simulationId: string,
  post: any,
  onErrorCallback: (message: string) => void
): Promise<IInputs | null> => {
  const baseEndpoint = `projects/${projectId}/re-calculate`;
  const endpoint = `clone/${simulationId}`;
  const url = `${getApiUrl()}/${baseEndpoint}/${endpoint}?companyEquipment=false`;

  const response = await post(url, { html: 'htmlClone' });

  if (response.error) {
    onErrorCallback('Error fetching simulation inputs');
    return null;
  }

  return response?.data?.data ?? null;
};
