import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FilterSidebarItem,
  RadioGroup,
  FilterSidebar,
  IRadioOption,
  ICheckboxOption,
  CheckboxGroup,
} from '@ratedpower/components';
import { noDesignsArray } from 'utils/constants/projects';
import { useGetProjectCreatos } from 'pages/projects/use-get-project-creators';
import { IProjectsFilters, noDesigns } from 'types/projects';
import CheckboxListFilter from '../checkbox-list-filter/checkbox-list-filter';
import { useGetProjectCountries } from 'pages/projects/use-get-project-countries';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ROLE_TYPE } from 'types/roles';
import { CheckboxGroupScrollable } from 'components/checkbox-group-scrollable/checkbox-group-scrollable';
import TeamsFilter from '../teams-filter';
import { projectStatusList } from 'utils/constants/status';
import { useTheme } from 'styled-components';

interface IProps {
  id?: string;
  onApply: (filters?: IProjectsFilters) => void;
  onClear: () => void;
  onClose: () => void;
  isOpen: boolean;
  showStatusFilter?: boolean;
  showTeamsFilter?: boolean;
}

const ProjectsFilterPanel: React.FC<IProps> = ({
  id = 'default-id',
  onApply,
  onClear,
  onClose,
  isOpen,
  showStatusFilter = false,
  showTeamsFilter = true,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const profile = useSelector((state: RootState) => state.currentUser.profile);

  const isInternalUser = profile?.roleType === ROLE_TYPE.INTERNAL;

  const {
    fetchedCreators,
    inputSearchProps: { onChange: onSearchCreator },
    paginationComponent: creatorsPaginationComponent,
  } = useGetProjectCreatos();

  const {
    data: fetchedCountries,
    inputSearchProps: { onChange: onSearchCountry },
    paginationComponent: countryPaginationComponent,
  } = useGetProjectCountries();

  const [currentFilters, setCurrentFilters] = useState<IProjectsFilters | undefined>();

  const handleApply = () => {
    onApply(currentFilters);
  };

  const handleClear = () => {
    setCurrentFilters(undefined);
    onClear();
  };

  return (
    <FilterSidebar
      onApply={handleApply}
      onClear={handleClear}
      isOpen={isOpen}
      onClose={onClose}
      onApplyTitle={t('action:apply')}
      onClearTitle={t('action:clear')}
      sidebarTitle={t('filters.title', 'Filters')}
    >
      <FilterSidebarItem title={t('project:num-designs')} maxHeightContent="100%" hasBorderBottom>
        <RadioGroup
          id="countProjectsFilter"
          direction="column"
          selectedValue={currentFilters?.numDesigns ?? noDesignsArray[0]}
          onChange={(newOption) =>
            setCurrentFilters((filters) => ({ ...filters, numDesigns: newOption.value as noDesigns }))
          }
          options={noDesignsArray.map(
            (option) =>
              ({
                label: t(option as string),
                value: option,
              } as IRadioOption)
          )}
        />
      </FilterSidebarItem>
      {fetchedCountries && fetchedCountries.length > 0 && (
        <FilterSidebarItem title={t('filters.countries')} maxHeightContent="none" hasBorderBottom>
          <>
            {isInternalUser ? (
              <CheckboxListFilter
                id={`${id}-filter-project-country`}
                items={fetchedCountries.map((option) => ({
                  name: option,
                  id: option,
                  checked: currentFilters?.country?.includes(option),
                }))}
                onSearch={onSearchCountry}
                onToggleItem={(countries: string[]) =>
                  setCurrentFilters((filters) => ({ ...filters, country: countries }))
                }
                paginationComponent={countryPaginationComponent}
              />
            ) : (
              <CheckboxGroupScrollable
                id={`${id}-filter-project-country-2`}
                options={fetchedCountries.map((option) => ({
                  label: option,
                  value: option,
                  checked: currentFilters?.country?.includes(option),
                }))}
                onChange={(countries: ICheckboxOption[]) =>
                  setCurrentFilters({
                    ...currentFilters,
                    country: countries.filter((c) => c.checked).map((c) => c.value),
                  })
                }
                infiniteScroll={countryPaginationComponent}
              />
            )}
          </>
        </FilterSidebarItem>
      )}
      {showStatusFilter && (
        <FilterSidebarItem title={t('support:status')} maxHeightContent="none" hasBorderBottom>
          <CheckboxGroup
            id={`${id}-filter-project-status`}
            direction="column"
            options={projectStatusList(theme).map((status) => ({
              value: status.value,
              label: t(status.label),
              checked: currentFilters?.status?.includes(status.value) ?? false,
            }))}
            onChange={(_value: ICheckboxOption, statuses: ICheckboxOption[]) =>
              setCurrentFilters((filters) => ({
                ...filters,
                status: statuses.filter((status) => status.checked).map((status) => status.value),
              }))
            }
          />
        </FilterSidebarItem>
      )}
      {fetchedCreators?.length > 0 && (
        <FilterSidebarItem title={t('filters.creator')} maxHeightContent="none" hasBorderBottom>
          <>
            {isInternalUser ? (
              <CheckboxListFilter
                id={`${id}-filter-project-creator`}
                items={fetchedCreators.map((option) => ({
                  name: `${option.name} ${option.lastname}`,
                  id: option.id,
                  checked: currentFilters?.creator?.includes(option.id),
                }))}
                onSearch={onSearchCreator}
                onToggleItem={(creators: string[]) =>
                  setCurrentFilters((filters) => ({ ...filters, creator: creators }))
                }
                paginationComponent={creatorsPaginationComponent}
              />
            ) : (
              <CheckboxGroupScrollable
                id={`${id}-filter-project-creator-2`}
                options={fetchedCreators.map((option) => ({
                  label: option.name,
                  value: option.id,
                  checked: currentFilters?.creator?.includes(option.id),
                }))}
                onChange={(creators: ICheckboxOption[]) =>
                  setCurrentFilters((filters) => ({
                    ...filters,
                    creator: creators.filter((c) => c.checked).map((c) => c.value),
                  }))
                }
                infiniteScroll={creatorsPaginationComponent}
              />
            )}
          </>
        </FilterSidebarItem>
      )}
      {showTeamsFilter && (
        <TeamsFilter
          teamsChecked={currentFilters?.team || []}
          onChangeTeams={(_newOption, teamsSelected) => {
            setCurrentFilters((filters) => ({
              ...filters,
              team: teamsSelected.filter((team) => team.checked).map((team) => team.value),
            }));
          }}
        />
      )}
    </FilterSidebar>
  );
};

export default ProjectsFilterPanel;
