import React, { useState, useMemo } from 'react';
import * as S from './power-stations-kpis-display.styled';
import { useTranslation } from 'react-i18next';
import { InverterElement, PowerStationKPIData } from 'pages/interactive-layout-creator/ilc-types';
import { ChevronDownSvg, ChevronUpSvg, FilterSvg } from 'assets/icons';
import { StatusSVGWrapper, Typography, Button, Badge } from '@ratedpower/components';
import { Collapse } from 'react-collapse';
import {
  useInverters,
  useKpisPowerStationsFilterState,
  useUpdateKpisPowerStationsFilterState,
} from 'pages/interactive-layout-creator/ilc-store';
import { CameraControls } from '@react-three/drei';
import { track } from 'rudderstack/utils';
import { RudderstackEvent } from 'rudderstack/types';
import {
  sortPowerStationsByKPIValue,
  sortPowerStationsByName,
} from 'pages/interactive-layout-creator/ilc-utils/sorting';
import { SinglePowerStationKpis } from '../single-power-station-kpis';
import { dateHasExpired } from 'utils/date';

interface IPowerStationsKpisDisplay {
  powerStationsKpis: PowerStationKPIData[];
  controls: typeof CameraControls | undefined;
}

export function PowerStationsKpisDisplay({ powerStationsKpis, controls }: IPowerStationsKpisDisplay) {
  const { t } = useTranslation('ilc');
  const [expanded, setExpanded] = useState(false);
  const updateKpisPowerStationsFilterState = useUpdateKpisPowerStationsFilterState();
  const { sortBy, sortDirection } = useKpisPowerStationsFilterState();
  const invertersLayer = useInverters();
  const listOfInverters = invertersLayer !== null ? Object.values(invertersLayer) : [];

  function getInvertersByPsKey(psKey: string, isPrimary: boolean): InverterElement[] {
    return listOfInverters.filter((inv) => inv.psKey === psKey && inv.primary === isPrimary);
  }

  const powerStationsKpisToDisplay = useMemo(() => {
    switch (sortBy) {
      case 'name': {
        return sortPowerStationsByName(powerStationsKpis);
      }
      case 'peakDcPower': {
        return sortPowerStationsByKPIValue('peakDcPower', powerStationsKpis, sortDirection);
      }
      case 'ratedPower': {
        return sortPowerStationsByKPIValue('ratedPower', powerStationsKpis, sortDirection);
      }
      case 'dcAcRatio': {
        return sortPowerStationsByKPIValue('dcAcRatio', powerStationsKpis, sortDirection);
      }
    }
  }, [sortBy, powerStationsKpis, sortDirection]);

  return (
    <S.PowerStationKpisDisplayWrapper>
      <div>
        <S.TitleHeader>
          <StatusSVGWrapper
            icon={expanded ? ChevronUpSvg : ChevronDownSvg}
            onClick={() => {
              track(RudderstackEvent.PV_EDITED_RESULTS_CLICK);
              setExpanded(!expanded);
            }}
            size="s"
            active={false}
          />
          <S.TextAndNewBadge>
            <Typography weight="bold">{t('results.list-of-power-stations')}</Typography>
            {!dateHasExpired(new Date('2025-3-31'), 20) && <Badge text={t('common:new')} />}
          </S.TextAndNewBadge>
          <Button size="m" variant="ghost" onClick={() => updateKpisPowerStationsFilterState(true)} Icon={FilterSvg} />
        </S.TitleHeader>
        <Collapse isOpened={expanded}>
          <S.PowerStationsCollapseContent>
            {powerStationsKpisToDisplay.map((psData) => {
              return (
                <SinglePowerStationKpis
                  key={psData.psKey}
                  psData={psData}
                  controls={controls}
                  primaryInverters={getInvertersByPsKey(psData.psKey, true)}
                  secondaryInverters={getInvertersByPsKey(psData.psKey, false)}
                />
              );
            })}
          </S.PowerStationsCollapseContent>
        </Collapse>
      </div>
    </S.PowerStationKpisDisplayWrapper>
  );
}
