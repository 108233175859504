import React from 'react';
import {
  EditableInput,
  PopupWrapper,
  SortableHeader,
  StatusSVGWrapper,
  SVGWrapper,
  Typography,
} from '@ratedpower/components';
import { TFunction } from 'i18next';
import { createColumnHelper } from '@tanstack/table-core';
import { IProjectListItem, IProjectsListOptions } from 'types/projects';
import { DefaultTheme } from 'styled-components';
import { DESIGN_ACTIONS } from 'utils/constants/designs';
import { hasValidCharacters, PROJECT_DESIGN_UNALLOWED_CHARS } from 'utils/validation';
import { getDateMonthDDYYYY } from 'utils/date';
import { DotsSvg, FavouriteOutlineSvg, FavouriteSvg, FolderExportSvg, FolderImportSvg, TrashSvg } from 'assets/icons';
import { PROJECTS_STATUS } from 'utils/constants/tabs';
import { IndexIds } from 'index.ids';

const columnHelper = createColumnHelper<IProjectListItem>();

const handleClickAction = (
  e: any,
  callback: (action: string, project: IProjectListItem) => void,
  action: string,
  project: IProjectListItem
) => {
  e.stopPropagation();
  callback(action, project);
};

const canEditProjects = (options: IProjectsListOptions, creatorId: string, teamId?: string) => {
  const projectIsPublic = !teamId;
  return (
    (options.canEditInUserTeam && (!!teamId || (creatorId === options.userId && projectIsPublic))) ||
    (options.canEditOnlyOwnProjects && creatorId === options.userId)
  );
};

const validateProjectName = (name: string) => {
  return name.length <= 100 && hasValidCharacters(PROJECT_DESIGN_UNALLOWED_CHARS)(name);
};

export const projectListColumns = (t: TFunction, theme: DefaultTheme, options: IProjectsListOptions) => [
  columnHelper.accessor('isFavorite', {
    id: 'favorite',
    meta: {
      cellClassName: 'min-cell',
    },
    cell: (info) => {
      const isFavorite = info.getValue();

      return (
        <StatusSVGWrapper
          active={isFavorite}
          icon={isFavorite ? FavouriteSvg : FavouriteOutlineSvg}
          size={'m'}
          onClick={(e: React.MouseEvent<Element, MouseEvent>) =>
            handleClickAction(e, options.onPerform, DESIGN_ACTIONS.favorite, info.row.original)
          }
        />
      );
    },
    header: () => <></>,
  }),
  columnHelper.accessor('name', {
    id: 'name',
    cell: (info) => (
      <>
        {options.canAlwaysEdit || canEditProjects(options, info.row.original.createdBy.id, info.row.original.teamId) ? (
          <EditableInput
            validationFunction={validateProjectName}
            value={info.getValue()}
            onEditName={(newName: string) => options.onEditName(newName, info.row.original.id)}
          />
        ) : (
          <Typography nowrap>{info.getValue()}</Typography>
        )}
      </>
    ),
    header: () => (
      <SortableHeader
        selector={{ label: t('name'), code: 'name' }}
        sortedBy={options.sortedBy}
        onSort={options.onSort}
      />
    ),
  }),
  columnHelper.accessor('createdBy', {
    id: 'createdBy',
    cell: (info) => (
      <Typography nowrap className="created-by">
        {`${info.row.original.createdBy.name} ${info.row.original.createdBy.surname}`}
      </Typography>
    ),
    header: () => (
      <SortableHeader
        selector={{ code: 'createdBy.name', label: t('created-by') }}
        sortedBy={options.sortedBy}
        onSort={options.onSort}
      />
    ),
  }),
  columnHelper.accessor('numDesigns', {
    id: 'numDesigns',
    cell: (info) => (
      <Typography nowrap as={'p'}>
        {info.getValue()
          ? info.getValue() === 1
            ? t('project:n-designs', { count: info.getValue() })
            : t('project:n-designs-plural', { count: info.getValue() })
          : '-'}
      </Typography>
    ),
    header: () => <></>,
  }),
  columnHelper.accessor((row) => row, {
    id: 'location',
    cell: (info) => (
      <Typography nowrap>
        {info.row.original.country ?? '-'}
        {info.row.original.region ? `, ${info.row.original.region}` : ''}
        {info.row.original.city ? `, ${info.row.original.city}` : ''}
      </Typography>
    ),
    header: () => <></>,
  }),
  ...(options.companyHasTeams
    ? [
        columnHelper.accessor('teamName', {
          id: 'teamName',
          cell: (info) => <Typography nowrap>{info.getValue() ?? t('user:teams.public-teams')}</Typography>,
          header: () => <Typography color={theme.v2.text.secondary.default}>{t('user:teams.team')}</Typography>,
        }),
      ]
    : []),
  columnHelper.accessor('publishedDate', {
    id: 'publishedDate',
    cell: (info) => (
      <Typography nowrap>
        {t('project:list-project-date', { dateFormat: getDateMonthDDYYYY(info.getValue()) })}
      </Typography>
    ),
    header: () => (
      <SortableHeader
        selector={{ label: t('date'), code: 'publishedDate' }}
        sortedBy={options.sortedBy}
        onSort={options.onSort}
      />
    ),
  }),
  columnHelper.accessor((row) => row.status, {
    id: 'actions',
    meta: {
      cellClassName: 'sticky-column',
    },
    cell: (info) => {
      const disableCondition = !(
        options.canAlwaysEdit || canEditProjects(options, info.row.original.createdBy.id, info.row.original.teamId)
      );

      return (
        <>
          <div className="expanded">
            {info.getValue() === PROJECTS_STATUS.archived ? (
              <StatusSVGWrapper
                icon={FolderExportSvg}
                onClick={(e: React.MouseEvent<Element, MouseEvent>) =>
                  handleClickAction(e, options.onPerform, DESIGN_ACTIONS.unarchive, info.row.original)
                }
                tooltip={{ text: t('action:unarchive'), place: 'bottom' }}
                disabled={disableCondition}
                active={false}
                size="m"
              />
            ) : (
              <StatusSVGWrapper
                icon={FolderImportSvg}
                onClick={(e: React.MouseEvent<Element, MouseEvent>) =>
                  handleClickAction(e, options.onPerform, DESIGN_ACTIONS.archive, info.row.original)
                }
                tooltip={{ text: t('action:archive'), place: 'bottom' }}
                disabled={disableCondition}
                active={false}
                size="m"
              />
            )}
            <StatusSVGWrapper
              icon={TrashSvg}
              onClick={(e: React.MouseEvent<Element, MouseEvent>) =>
                handleClickAction(e, options.onPerform, DESIGN_ACTIONS.delete, info.row.original)
              }
              tooltip={{ text: t('action:delete'), place: 'bottom' }}
              disabled={disableCondition}
              active={false}
              size="m"
              dataTest={IndexIds.Projects.DeleteProject}
            />
          </div>
          <div className="collapsed">
            <PopupWrapper
              popupPosition="leftCenter"
              onClickOption={({ value }) => options.onPerform(value, info.row.original)}
              options={[
                info.row.original.status === PROJECTS_STATUS.archived
                  ? {
                      labelIcon: FolderExportSvg,
                      label: t('action:unarchive'),
                      value: DESIGN_ACTIONS.unarchive,
                      disabled: disableCondition,
                    }
                  : {
                      labelIcon: FolderImportSvg,
                      label: t('action:archive'),
                      value: DESIGN_ACTIONS.archive,
                      disabled: disableCondition,
                    },
                {
                  labelIcon: TrashSvg,
                  label: t('action:delete'),
                  value: DESIGN_ACTIONS.delete,
                  disabled: disableCondition,
                },
              ]}
            >
              <SVGWrapper size="s" icon={DotsSvg} />
            </PopupWrapper>
          </div>
        </>
      );
    },
    header: () => <></>,
  }),
];
