import React from 'react';
import { useCompanyActivityState } from '../company-activity-state';
import InputTemplatesProvider from 'pages/input-templates/hooks/use-input-templates-context';
import InputTemplates from 'pages/input-templates/input-templates';

const InputTemplatesTab: React.FC = () => {
  const { companyId } = useCompanyActivityState();

  return (
    <InputTemplatesProvider>
      <InputTemplates companyId={companyId ?? ''} showCompanyFilters={false} titleSize="small" />
    </InputTemplatesProvider>
  );
};

export { InputTemplatesTab };
