import React from 'react';
import * as S from './design-card.styled';
import { useTranslation } from 'react-i18next';
import { IDesign, SimulationSecondaryCharacteristicsTag } from 'types/design';
import { useConverter } from 'utils/conversor';
import { designAttributes } from 'utils/constants/design-attributes';
import { Checkbox, StatusSVGWrapper, Typography } from '@ratedpower/components';
import { useCanEditDesigns } from 'utils/hooks/use-can-edit-designs';
import { GenericStatus } from 'utils/constants/status';
import { FavouriteOutlineSvg } from 'assets/icons/index';
import { getDynamicDate } from 'utils/date';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DesignTag } from 'components/design-components/design-tag/design-tag';
import {
  getDesignIsILC,
  getPrimaryTagText,
  getSecondaryTagText,
  selectedPrimaryTagColor,
  selectedSecondaryTagColor,
} from 'utils/design-tags';
import { useHybridBessSku } from 'utils/hooks/sku/use-hybrid-bess-sku';

interface IProps {
  item: IDesign;
  selected: boolean;
  onSelect: (id: string) => void;
  onFavorite: (item: any) => void;
}

const DesignCard: React.FC<IProps> = ({ item, selected, onSelect, onFavorite }) => {
  const { t } = useTranslation();
  const { getValueOf } = useConverter();
  const { canEditDesign } = useCanEditDesigns();
  const flags = useFlags();
  const { restrictHybridBessSku } = useHybridBessSku();

  const canEditThisDesign: boolean = canEditDesign(item.createdBy.id, item.projectTeamId);
  const itemHasErrorStatus: boolean = item.status === GenericStatus.ERROR;

  const isDisabledColor = (getDesignIsILC(item.secondaryTag) && !flags?.skuIlc) || restrictHybridBessSku(item);

  const tagVariant: string = selectedPrimaryTagColor(item, isDisabledColor);
  const tagText: string = t(getPrimaryTagText(item));

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, id: string): void => {
    e.preventDefault();
    onSelect(id);
  };

  const handleFavorite = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    onFavorite(item);
  };

  return (
    <S.DesignCardContainer
      className={selected ? 'selected' : ''}
      data-testid="design-card-container"
      onClick={() => onSelect(item.id)}
    >
      <div className="card-image">
        <DesignTag color={tagVariant} position={{ top: 8, right: 8 }}>
          {tagText}
        </DesignTag>
        {item.secondaryTag === SimulationSecondaryCharacteristicsTag.EDITED && (
          <DesignTag color={selectedSecondaryTagColor(item, !flags?.skuIlc)} position={{ top: 38, right: 8 }}>
            {t(getSecondaryTagText(item))}
          </DesignTag>
        )}
        {!itemHasErrorStatus && getValueOf(designAttributes.satellitePicture.thumbnail, item)}
      </div>
      <S.CardHeader onClick={(e) => e.stopPropagation()}>
        <S.CardHeaderTitleRow>
          <Checkbox
            id={item.id}
            checked={selected}
            onChange={(e) => handleCheckboxChange(e, item.id)}
            dataTest="select-checkbox"
          />
          <S.ProjectTitle>
            {item.number}. {item.name}
          </S.ProjectTitle>
          <StatusSVGWrapper
            size={'s'}
            onClick={handleFavorite}
            active={item.isFavorite}
            icon={FavouriteOutlineSvg}
            disabled={!canEditThisDesign}
            dataTest="favorite-icon"
          />
        </S.CardHeaderTitleRow>
        <S.CardHeaderMainRow>
          {!itemHasErrorStatus ? (
            <>
              <S.MainRowTypography>{getValueOf(designAttributes.power.ratedPower, item)}</S.MainRowTypography>
              <S.MainRowTypography>{getValueOf(designAttributes.power.peakPower, item)}</S.MainRowTypography>
              <S.MainRowTypography>{getValueOf(designAttributes.energy.specificProduction, item)}</S.MainRowTypography>
              <S.MainRowTypography>{getValueOf(designAttributes.equipment.gcr, item)}</S.MainRowTypography>
            </>
          ) : (
            <div className="error">
              <p>ERROR :D</p>
            </div>
          )}
        </S.CardHeaderMainRow>
        <S.CardHeaderAuthorRow>
          <Typography size="s">{`${item.createdBy.name} ${item.createdBy.lastname}`}</Typography>
          <Typography size="s">
            {t('design:design-date', {
              dateFormat: getDynamicDate(item.publishedDate),
            })}
          </Typography>
        </S.CardHeaderAuthorRow>
      </S.CardHeader>
    </S.DesignCardContainer>
  );
};

export default DesignCard;
