import React, { useEffect } from 'react';
import { IBessDesign, IDesign } from 'types/design';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useDesignsTableColumns } from './use-designs-table-columns';
import { ISort } from '@ratedpower/components';
import { useNavigate } from 'react-router-dom';
import { buildRelativeDesignProjectUrl } from 'utils/navigation/navigation';
import { isPvDesign } from 'utils/design-type-functions';
import { DesignsRPTable } from './designs-table.styled';
import DesignTableRowDetails from './design-details/design-table-row-details';
import BessTableRowDetails from './design-details/bess-table-row-details';

interface IProps {
  designs: (IDesign | IBessDesign)[];
  rowsSelected: (IDesign | IBessDesign)[];
  viewDetailed: boolean;
  zeroDesignsCreated: boolean;
  sortBy: ISort;
  onSort: React.Dispatch<React.SetStateAction<ISort>>;
  onPerform: (action: string, item: IDesign | IBessDesign) => void;
  onEditDesignName: (id: string, newName: string) => void;
}

const DesignDetailsWrapper: React.FC<{ element?: IDesign | IBessDesign }> = ({ element }) => {
  if (!element) return <>Hola</>;
  return (
    <>
      {isPvDesign(element) ? (
        <DesignTableRowDetails {...{ item: element }} />
      ) : (
        <BessTableRowDetails {...{ item: element }} />
      )}
    </>
  );
};

const DesignsList: React.FC<IProps> = ({ designs, viewDetailed, ...rest }) => {
  const navigate = useNavigate();

  const columns = useDesignsTableColumns(rest);
  const table = useReactTable({
    data: designs,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    table.toggleAllRowsExpanded(viewDetailed);
  }, [viewDetailed]);

  return (
    <DesignsRPTable
      table={table}
      onRowClick={(row: IDesign | IBessDesign) => navigate(buildRelativeDesignProjectUrl(row.id, row.primaryTag))}
      collapsibleComponent={<DesignDetailsWrapper />}
    />
  );
};

export default DesignsList;
