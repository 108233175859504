import { GET_PROJECT_COUNTRIES } from 'services/projects-gql';
import { useCustomQuery } from 'utils/hooks/use-custom-query';
import { useSearch } from 'utils/hooks/use-search';

export const useGetProjectCountries = () => {
  const { getSearchFilter, inputSearchProps } = useSearch();
  const { data, paginationComponent } = useCustomQuery(
    GET_PROJECT_COUNTRIES,
    {
      variables: { pagination: { page: 0, size: 12 }, search: getSearchFilter()?.[0] },
      fetchPolicy: 'network-only',
    },
    true
  );

  return {
    data: data?.result.content || [],
    paginationComponent,
    inputSearchProps,
  };
};
