import styled, { css } from 'styled-components';

interface IProps {
  selected?: boolean;
}

const cardSelected = css`
  border-color: ${({ theme }) => theme.v2.stroke.accent.default};
  transition: all 0.2s ease-out;
  z-index: 1;
`;

export const CapexCardContainer = styled.div<IProps>`
  border-radius: ${({ theme }) => theme.v2.radius.s};
  box-shadow: ${({ theme }) => theme.v2.elevation.shadow.m};
  background-color: transparent;
  cursor: pointer;
  width: 276px;
  border: 1px solid transparent;

  ${({ selected }) => selected && cardSelected}
  &:hover {
    ${({ selected }) => !selected && cardSelected}
  }
`;

export const CardFooter = styled.div<{ hidden: boolean }>`
  ${({ hidden }) => hidden && 'display: none;'}

  overflow: hidden;
  border-top: 1px solid ${({ theme }) => theme.v2.stroke.primary.default};

  .notes {
    padding: ${({ theme }) => theme.v2.spacing.m};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.v2.spacing.xs};

    .notes-description {
      display: flex;
      justify-content: space-between;

      p {
        padding: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 218px;
      }
    }
  }
`;

export const CapexCardHeader = styled.div`
  background-color: ${({ theme }) => theme.primary_5};
  padding: ${({ theme }) => `${theme.v2.spacing.xs} ${theme.v2.spacing.m}`};
  gap: ${({ theme }) => theme.v2.spacing.xs};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const CardOptions = styled.div`
  column-gap: ${({ theme }) => theme.v2.spacing.xs};
  align-items: center;
  display: inline-flex;
  margin-left: auto;
`;

export const CapexCardTitle = styled.div`
  align-items: flex-start;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;

  .template-name {
    width: 164px;
  }
`;

export const CapexCardContent = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.m};
  gap: ${({ theme }) => theme.v2.spacing.m};
  display: flex;
  flex-direction: column;
  min-height: 60px;
  background-color: transparent;

  &.notClickable {
    cursor: initial;
  }
`;

export const CardRow = styled.div`
  display: flex;

  p {
    padding-right: ${({ theme }) => theme.v2.spacing.xs};
  }
`;

export const DeleteBar = styled.div`
  display: inline-grid;
  align-items: center;
  grid-template-columns: minmax(50%, auto) 1fr;
  height: 100%;
  width: 100%;
`;
