import styled from 'styled-components';
import { Typography } from '@ratedpower/components';

export const KpisDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.v2.spacing.xs};
`;

export const KpisDataRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ValueDisplay = styled(Typography)`
  span {
    font-size: ${({ theme }) => theme.v2.font.size.s};
    color: ${({ theme }) => theme.v2.text.secondary.default};
  }
`;
